import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetKeywordUrls } from "services/Sidebar";
import { MdKey } from "react-icons/md";

const SidebarLinks = ({ routes, closeOnClick, admin = false }) => {
  const url = window.location.href;
  const parts = url.split("/");
  var lastPart = "";
  if (window.location.href.indexOf("/analyse/") >= 0) {
    lastPart = parts[parts.length - 1];
    lastPart = lastPart.replace(/%20/g, " ");
  }

  const [keywordurls, setKeywordUrls] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [updateSidebar, setUpdateSidebar] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      //document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetKeywordUrls();
      if (response?.status === 200) {
        setKeywordUrls(response?.data?.keyword_urls);
        if (!keyword?.length)
          setKeyword(response?.data?.keyword_urls?.[0]?.search_id);
      } else {
      }
      //document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [updateSidebar, keyword]);

  useEffect(() => {
    if (url.indexOf("/analyse/") >= 0) {
      setKeyword(lastPart);
    }
  }, [lastPart, url]);

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      return (
        (
          <NavLink
            key={route.id}
            to={
              admin
                ? "/admin" + route.path
                : route.path === "/analyse"
                ? `/analyse/${keyword}`
                : route.path
            }
            className={({ isActive }) => {
              return `hover:text-primary hover:font-semibold ${
                isActive
                  ? "text-primary font-semibold"
                  : "text-inactive font-medium"
              }`;
            }}
            onClick={() => {
              setUpdateSidebar((prev) => !prev);
              closeOnClick();
            }}
          >
            {({ isActive }) => {
              return (
                <div className="px-3 my-3">
                  <li className="flex items-center">
                    <div
                      className={`rounded-full p-2 shadow-sm ${
                        isActive
                          ? "bg-primary text-white shadow-primary"
                          : "bg-white"
                      }  `}
                    >
                      {route.icon}
                    </div>
                    <div className="text-[14px] ml-3">{route.name}</div>
                  </li>
                  {route.name === "Analyse" && isActive && (
                    <div className="flex flex-col text-inactive font-medium">
                      {keywordurls?.map((keywordurl, index) => (
                        <NavLink
                          key={index}
                          className={`flex ml-8 items-center hover:text-primary hover:font-semibold
                        ${
                          keyword === keywordurl?.search_id
                            ? "text-primary font-semibold"
                            : ""
                        }`}
                          to={`/analyse/${keywordurl?.search_id}`}
                          onClick={(event) => {
                            setKeyword(keywordurl?.search_id);
                          }}
                        >
                          <div className="rounded-full p-2 shadow-sm bg-white">
                            <MdKey size={26} />
                          </div>
                          <div key={index} className="text-[14px] ml-3">{`${
                            keywordurl?.keyword_url
                          } ${
                            !keywordurl?.additional_keyword_url?.length
                              ? ``
                              : `, ${keywordurl?.additional_keyword_url}`
                          }`}</div>
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              );
            }}
          </NavLink>
        )
      );
    });
  };
  return createLinks(routes);
};

export default SidebarLinks;
