import RightButton from "components/Buttons/RightButton";
import Card from "components/Card/Card";
import Container from "components/Container/Container";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";
import TableUsers from "components/Tables/TableUsers";
import CustomWidget from "components/Widget/CustomWidget";
import { useCallback, useMemo, useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { LiaCheckCircle, LiaTimesCircle } from "react-icons/lia";
import { MdPersonOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  GetClients,
  GetClientsByType,
  ManageUserStatus,
} from "services/Admin/Users";

const Clients = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [user_count, setUserCount] = useState(0);
  const [active_user_count, setActiveUserCount] = useState(0);
  const [intervention_count, setInterventionCount] = useState(0);
  const [invoice_count, setInvoiceCount] = useState(0);
  const [browseType, setBrowseType] = useState("total_accounts");
  const [updateTable, setUpdateTable] = useState(true);

  const removeCheckedElement = useCallback(
    (elements) => {
      setData(
        data.filter((d) => {
          return !elements.includes(d.id);
        })
      );
    },
    [data]
  );

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetClients();

      if (response?.status === 200) {
        setData(response.data.user_data);
        setUserCount(response.data.user_count);
        setActiveUserCount(response.data.active_user_count);
        setInterventionCount(response.data.intervention_count);
        setInvoiceCount(response.data.invoice_count);
        document.getElementById("prewaiting").setAttribute("hidden", "true");
      } else {
        // setErrorMsg(response?.data?.detail);
      }
    };

    fetchData();
  }, [updateTable]);

  useEffect(() => {
    const fetchData = async () => {
      if (!browseType.length) return;
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetClientsByType(browseType);

      if (response?.status === 200) {
        setData(response?.data?.user_data);
        // setUserCount(response.data.user_count);
        // setActiveUserCount(response.data.active_user_count);
        // setInterventionCount(response.data.intervention_count);
        // setInvoiceCount(response.data.invoice_count);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, [browseType]);

  const BrowseByType = (type) => {
    switch (type) {
      case "Nombre de comptes":
        setBrowseType("total_accounts");
        break;
      case "Nombre de comptes actifs":
        setBrowseType("active_accounts");
        break;
      case "Demandes d'interventions":
        setBrowseType("intervention_requests");
        navigate("/admin/demande-dinterventions");
        break;
      case "Total Factures Envoyées":
        setBrowseType("total_invoices_sent");
        navigate("/admin/factures");
        break;
      default:
        break;
    }
  };

  const columns = useMemo(
    () => [
      // {
      //   id: "checkbox",
      //   Cell: ({
      //     selected,
      //     addToSelected,
      //     removeFromSelected,
      //     row: {
      //       original: { id },
      //     },
      //   }) => {
      //     const handleOnChange = useCallback(() => {
      //       //Supprimer du tableau si c'était coché
      //       if (selected.includes(id)) {
      //         removeFromSelected(id);
      //       } else {
      //         addToSelected(id);
      //       }
      //     }, [addToSelected, removeFromSelected, id, selected]);

      //     return (
      //       <CheckBox
      //         onChange={handleOnChange}
      //         checked={selected.includes(id)}
      //         extra="border-2 border-[#BEBEBE]"
      //       />
      //     );
      //   },
      //   columnId: 0,
      // },
      {
        Header: "Nom",
        accessor: "full_name",
        Cell: ({
          cell: { value },
          row: {
            original: { id },
          },
        }) => {
          return (
            // <Link to={`/admin/comptes-clients/${id}`}>
            //   <div className="py-4 font-semibold text-[12.008px] mx-2 ">
            //     {value}
            //   </div>
            // </Link>
            <Link to={`/admin/comptes-clients/${id}`}>
              <div className="py-4 font-semibold text-[12.008px] mx-2 text-center">
                <span className="whitespace-normal break-words">{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Raison social",
        accessor: "social_reason",
        hidden: true,
        Cell: ({
          cell: { value },
          row: {
            original: { id },
          },
        }) => {
          return (
            // <Link to={`/admin/comptes-clients/${id}`}>
            //   <div className="py-4 text-[12.008px] font-normal mx-2">
            //     {value}
            //   </div>
            // </Link>
            <Link to={`/admin/comptes-clients/${id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                <span className="whitespace-normal break-words">{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({
          cell: { value },
          row: {
            original: { id },
          },
        }) => (
          // <Link to={`/admin/comptes-clients/${id}`}>
          //   <div className="py-4 text-[12.008px] font-normal mx-2">{value}</div>
          // </Link>
          <Link to={`/admin/comptes-clients/${id}`}>
            <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
              <span className="whitespace-normal break-all">{value}</span>
            </div>
          </Link>
        ),
      },
      {
        Header: "Ville",
        accessor: "province",
        Cell: ({
          cell: { value },
          row: {
            original: { id },
          },
        }) => {
          return (
            // <Link to={`/admin/comptes-clients/${id}`}>
            //   <div className="py-4 text-[12.008px] font-normal mx-2">
            //     {value}
            //   </div>
            // </Link>
            <Link to={`/admin/comptes-clients/${id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                <span className="whitespace-normal break-words">{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Abonnement",
        accessor: "subscription_at",
        hidden: true,
        Cell: ({
          cell: { value },
          row: {
            original: { id },
          },
        }) => {
          return (
            // <Link to={`/admin/comptes-clients/${id}`}>
            //   <div className="py-4 text-[12.008px] font-normal mx-2">
            //     {value}
            //   </div>
            // </Link>
            <Link to={`/admin/comptes-clients/${id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2 flex flex-col items-center">
                {/* <span className="whitespace-normal break-all">{value}</span> */}
                <pre className="text-left">{value}</pre>
              </div>
            </Link>
          );
        },
      },
      {
        id: "dots",
        Cell: ({
          row: {
            original: { id, activated },
          },
        }) => {
          const navigate = useNavigate();

          const seeClient = useCallback(() => {
            navigate(`/admin/comptes-clients/${id}`);
          }, [id, navigate]);

          return (
            <MenuDropdown
              trigger={
                <button className="active:scale-95 transition-all hover:bg-gray200 p-1 rounded-full">
                  <BsThreeDotsVertical size={18} className="cursor-pointer" />
                </button>
              }
              menu={[
                <MenuDropdownButton
                  key={0}
                  text="Voir le client"
                  onClick={seeClient}
                />,
                <MenuDropdownButton
                  key={0}
                  text={activated ? "Suspend" : "Reactive"}
                  onClick={async (event) => {
                    var payload = {};
                    payload["user_id"] = id;
                    payload["activated"] = !activated;
                    const response = await ManageUserStatus(payload);
                    console.log(response);
                    if (response?.status === 200) {
                      setUpdateTable((prev) => !prev);
                    }
                  }}
                />,
              ]}
            />
          );
        },
      },
    ],
    []
  );

  const gotoAddUser = () => {
    navigate("/admin/comptes-clients/new");
  };

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Comptes clients</p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
      </Container>

      <div className="my-6 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <CustomWidget
          title="Nombre de comptes"
          value={user_count}
          text="total_accounts"
          icon={<MdPersonOutline className="text-[#858585]" size={20} />}
          type={browseType}
          BrowseByType={BrowseByType}
        />
        <CustomWidget
          title="Nombre de comptes actifs"
          value={active_user_count}
          text="active_accounts"
          icon={<LiaCheckCircle className="text-success" size={22} />}
          type={browseType}
          BrowseByType={BrowseByType}
        />
        <CustomWidget
          title="Demandes d'interventions"
          value={intervention_count}
          text="intervention_requests"
          icon={<LiaTimesCircle className="text-danger" size={22} />}
          type={browseType}
          BrowseByType={BrowseByType}
        />
        <CustomWidget
          title="Total Factures Envoyées"
          value={invoice_count}
          text="total_invoices_sent"
          icon={<FaFileInvoiceDollar className="text-success" size={20} />}
          type={browseType}
          BrowseByType={BrowseByType}
        />
      </div>

      <Container extra="mt-8 sm:mt-10">
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Utilisateurs</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal ">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <RightButton text="Ajouter un compte" onClick={gotoAddUser} />
      </Container>

      <Card
        extra={
          "w-full pb-14 sm:overflow-x-auto mt-8 !bg-transparent rounded-none"
        }
      >
        <TableUsers
          columns={columns}
          data={data}
          removeCheckedElement={removeCheckedElement}
        />
      </Card>
    </>
  );
};

export default Clients;
