import Card from "components/Card/Card";
import Input from "components/Input/Input";
import InputLabel from "components/InputLabel/InputLabel";
import CustomRadio from "components/Radio/CustomRadio";
import HorizontalInputLayout from "components/forms/Parametres/HorizontalInputLayout";
import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { useEffect, Component } from "react";
import { UpdateSettingInformation } from "services/Parametres";
import InputError from "components/InputError/InputError";
import UploadAvatar from "components/UploadFile/UploadAvatar";
import { useNavigate } from "react-router-dom";
import "react-country-state-city/dist/react-country-state-city.css";
import CountrySelect from "components/SelectRegion/CountrySelect";
import { State, City } from "country-state-city";
import { FixedSizeList as List } from "react-window";
import Select from "react-select";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import ImageCrop from "components/ImageCrop/ImageCrop";
import BaseModal from "components/Modals/BaseModal";
import CountDownTimer from "../RegisterSteps/CountDownTimer";
import AuthCode from "react-auth-code-input";
import CustomStepsButton from "components/Buttons/CustomStepsButton";
import { VerifyCode, VerifySettingCode } from "services/UserAuthentication";
import { ConfirmUsername, ConfirmEmail, SendVerifyCode, GenerateCode } from "services/UserAuthentication";
import 'react-phone-number-input/style.css';
import "./Style.css";

const height = 35;
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children?.length || 0}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const UserInfo = ({ userInfo }) => {
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    defaultValues: {
      vat_number: "FR"
    }
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navgiate = useNavigate();
  const [selected, setSelected] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [stateData, setStateData] = useState([]);
  const [province, setProvince] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [verifyCode, setVerifyCode] = useState();
  const [ErrorIsOpen, setErrorIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (userInfo) {

      setPhoneNumber(userInfo?.phone);
      setCountryName(userInfo?.pays);
      setProvince(userInfo?.province);

      setSelected(userInfo.user_type);
      Object.keys(userInfo).forEach((key) => {
        setValue(key, userInfo[key]);
      });
      //var split_string = userInfo["full_name"]?.split(" ");
      //if (split_string) {
      // setValue("firstname", split_string[0]);
      // setValue("lastname", split_string.slice(1).join(" "));

      const date = new Date(); // Assuming you have a date object

      // Extract the year, month, and day from the date object
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month as it is zero-based
      const day = String(date.getDate()).padStart(2, '0');

      // Concatenate the year, month, and day with hyphens between them
      const formattedDate = `${year}-${month}-${day}`;

      setValue("updated_at", formattedDate);
      setValue("password", "");
      setValue("full_name", userInfo["full_name"]);
      //}
    }
  }, [userInfo, setValue]);

  const handleOnChange = (res) => {
    setVerifyCode(res);
  };

  const onSubmit = async (data) => {
    if (!phoneNumber?.length || !countryName?.length || !province?.length)
      return;
    const payload = getValues();
    if (selected === "Particulier") {
      // payload["number_siret"] = "";
      payload["activity"] = "";
      payload["social_reason"] = "";
      // payload["vat_number"] = "";
    }
    payload["user_type"] = selected;
    payload["pays"] = countryName;
    payload["province"] = province;
    payload["phone"] = phoneNumber;

    if (!data["password"].length) {
      payload["password"] = null;
    }
    //console.log(payload);
    var response;

    if (payload["full_name"] !== userInfo["full_name"]) {
      response = await ConfirmUsername({ "username": payload["full_name"] });
      if (response?.status !== 200) {
        setErrorIsOpen(true);
        setErrorMsg(response?.data?.detail);
        return;
      }
    }
    if (payload["email"] !== userInfo["email"]) {
      response = await ConfirmEmail({ "email": payload["email"] });
      if (response?.status === 200) {
        setIsOpen(true);

        response = await GenerateCode({ "email": payload["email"] });
        if (response?.status === 200) {
          console.log(response?.data?.email);
          response = await SendVerifyCode(response?.data?.email);
        }
        return;
      } else {
        setErrorIsOpen(true);
        setErrorMsg(response?.data?.detail);
        return;
      }
    } else {
      document.getElementById("prewaiting").removeAttribute("hidden");

      if (selectedFiles.length)
        payload["avatar_url"] = selectedFiles[0].filepath;
      payload["forgot_password_token"] = "";
      response = await UpdateSettingInformation(payload);
      if (response?.status === 200) {
        localStorage.setItem("user", JSON.stringify(response?.data));
        navgiate("/");
      } else {
        setErrorIsOpen(true);
        setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }
  };

  const handleCountry = (value) => {
    const data = City.getAllCities();
    let filteredData = [];
    for (let item of data) {
      if (item?.countryCode === value?.value) filteredData.push(item);
    }
    setStateData(filteredData);
  }
  return (
    <Card extra="bg-white py-4 px-5 sm:py-5 sm:px-10 mt-8">
      <h2 className="text-main font-medium text-xl">Informations</h2>
      <form
        className="flex flex-col gap-y-3 mt-4"
        autoComplete="on"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-y-3">
          {/* <UploadAvatar selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
          {selectedFiles.length === 0 && <InputError message="Avatar est requis." />} */}
          <ImageCrop selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
          {/* {selectedFiles.length === 0 && <InputError message="Avatar est requis." />} */}
        </div>
        <div className="flex gap-x-12">
          <div className="flex gap-x-1 items-center">
            <CustomRadio
              name="user_type"
              id="societe"
              {...register("user_type", { required: true })}
              value="Société"
              selected={selected}
              onChange={(event) => {
                setSelected(event.target.value);
              }}
            />
            <label htmlFor="societe" className="cursor-pointer">
              Société
            </label>
          </div>
          <div className="flex gap-x-1 items-center">
            <CustomRadio
              name="user_type"
              id="particulier"
              {...register("user_type", { required: true })}
              value="Particulier"
              selected={selected}
              onChange={(event) => {
                setSelected(event.target.value);
              }}
            />
            <label htmlFor="particulier" className="cursor-pointer">
              Particulier
            </label>
          </div>
        </div>
        <HorizontalInputLayout>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="prenom">Prénom</InputLabel>
            <Input
              id="prenom"
              placeholder="Entrez votre prénom"
              autoComplete="given-name"
              {...register("first_name", { required: true })}
              className="w-full"
            />
            {errors.first_name ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          </div>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="nom">Nom</InputLabel>
            <Input
              id="nom"
              placeholder="Entrez votre nom"
              autoComplete="family-name"
              {...register("last_name", { required: true })}
              className="w-full"
            />
            {errors.last_name ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          </div>
        </HorizontalInputLayout>
        {selected === "Société" && (
          <>
            <HorizontalInputLayout>
              <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
                <InputLabel htmlFor="activite">Activité</InputLabel>
                <Input
                  id="activite"
                  autoComplete="off"
                  placeholder="Graphiste"
                  {...register("activity", { required: true })}
                  className="w-full"
                />
                {errors.activity ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
              </div>
              <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
                <InputLabel htmlFor="social">Raison sociale</InputLabel>
                <Input
                  id="social"
                  autoComplete="off"
                  placeholder="Novaweb"
                  {...register("social_reason", { required: true })}
                  className="w-full"
                />
                {errors.social_reason ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
              </div>
            </HorizontalInputLayout>
          </>
        )}
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="address">Adresse</InputLabel>
          <Input
            id="address"
            placeholder="Entrez votre adresse"
            autoComplete="off"
            {...register("address", { required: true })}
            className="w-full"
          />
          {errors.address ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div>
        <HorizontalInputLayout>
          <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
            <InputLabel htmlFor="code-postal">Code postal</InputLabel>
            <Input
              id="code-postal"
              placeholder="69500"
              autoComplete="off"
              {...register("postal_code", { required: true })}
              className="w-full"
            />
            {errors.postal_code ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          </div>
          <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
            <InputLabel htmlFor="ville">Ville</InputLabel>
            {/* <Input
              id="ville"
              autoComplete="off"
              placeholder="Entrez votre ville"
              {...register("province", { required: true })}
              className="w-full"
            /> */}
            <Select
              components={{ MenuList }}
              placeholder={province?.length ? province : "Sélectionnez votre ville"}
              options={stateData}
              getOptionLabel={(options) => options.name}
              getOptionValue={(options) => options.name}
              onChange={(e) => {
                setProvince(e.name);
              }}
            />
            {province?.length ? <></> : <InputError message={"Ce champ est obligatoire."} />}
          </div>
        </HorizontalInputLayout>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="pays">Pays</InputLabel>
          <CountrySelect
            countryName={countryName}
            setCountryName={setCountryName}
            setCountryCode={setCountryCode}
            handleCountry={handleCountry}
          />
          {countryName?.length ? <></> : <InputError message={"Ce champ est obligatoire."} />}
        </div>
        {/* <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="pays">Pays</InputLabel>
          <Input
            id="pays"
            autoComplete="off"
            placeholder="Entrez votre pays"
            {...register("pays", { required: true })}
            className="w-full"
          />
          {errors.pays ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          <CountrySelect
            id="pays"
            onChange={(e) => {
              setCountryName(e.name);
            }}
            placeHolder="Select Country"
            showFlag
          />
          <CustomSelect />
        </div> */}
        {selected === "Société" && (
          <HorizontalInputLayout>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <InputLabel htmlFor="siret">Numéro de Siret</InputLabel>
              {/* <Input
                id="siret"
                autoComplete="off"
                pattern="\d{9}\(\d{5}\)"
                placeholder="123456789(12345)"
                {...register("number_siret", { required: true })}
                className="w-full"
                maxLength={14}
              /> */}
              <Input
                id="siret"
                autoComplete="off"
                pattern="\d{9}|\d{14}"
                placeholder="123456789(12345)"
                className="w-full bg-[#e9ecef]"
                disabled
                readOnly
                {...register("number_siret", { required: true })}
                maxLength={14}
              />
              {errors.number_siret ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
            </div>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <InputLabel htmlFor="tva">Numéro de TVA</InputLabel>
              <Input
                id="tva"
                autoComplete="off"
                {...register("vat_number", { required: true })}
                className="w-full bg-[#e9ecef]"
                pattern="FR\d{9}"
                disabled
                readOnly
                placeholder={`FR123456789`}
                maxLength={11}
                onChange={(event) => {
                  var input = document.getElementById("tva");
                  input.addEventListener("input", function (event) {
                    if (event.target.value.length < 2) {
                      event.target.value = "FR" + event.target.value;
                    }
                  });
                }}
              />
              {errors.vat_number ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
            </div>
          </HorizontalInputLayout>
        )}
        <HorizontalInputLayout>
          <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
            <InputLabel htmlFor="telephone">Téléphone</InputLabel>
            {/* <Input
              id="telephone"
              type="tel"
              placeholder="XXXXXXXXXXXXXXX"
              autoComplete="off"
              className="w-full hidden"
              {...register("phone", { required: true })}
            /> */}
            {/* <PhoneInput
              id="telephone"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              countries={"us"}
              error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
            />
            {phoneNumber && isValidPhoneNumber(phoneNumber) ? <></> : <InputError message={"Ce champ est obligatoire."} />} */}
            <PhoneInput
              international
              defaultCountry={countryCode}
              value={phoneNumber}
              onChange={phone => setPhoneNumber(phone)}
            />
            {phoneNumber && isValidPhoneNumber(phoneNumber) ? <></> : <InputError message={"Ce champ est obligatoire."} />}
          </div>
          <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              type="email"
              autoComplete="email"
              placeholder="Entrez votre email"
              {...register("email", { required: true })}
              className="w-full"
            />
            {errors.email ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          </div>
        </HorizontalInputLayout>
        <div>
          <InputLabel htmlFor="site">Site internet</InputLabel>
          <Input
            id="site"
            placeholder="www.reeact.com"
            pattern="^(?:https?:\/\/)?(:www\.)([a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)$"
            autoComplete="url"
            {...register("site_internet",
              // { required: true }
            )}
            className="w-full"
          />
          {/* {errors.site_internet ? <InputError message={"Ce champ est obligatoire."} /> : <></>} */}
        </div>
        {/* <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="password">Nouveau de passe</InputLabel>
          <Input
            id="password"
            type="password"
            autoComplete="off"
            placeholder="XXXXXXXXXXXXXXX"
            {...register("password1", { required: true })}
            className="w-full"
          />
          {errors.password1 ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div> */}
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="Username">Nom d'utilisateur</InputLabel>
          <Input
            id="username"
            placeholder="Entrez votre Nom d'utilisateur"
            autoComplete="off"
            {...register("full_name", { required: true })}
            className="w-full"
          />
          {errors.full_name ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div>
        <InputLabel htmlFor="password">Password</InputLabel>
        <div className="relative w-full">
          <div className="absolute inset-y-0 right-0 flex items-center px-2">
            <input className="hidden js-password-toggle" id="toggle" type="checkbox" onClick={(event) => {
              const password = document.querySelector('.js-password'),
                passwordLabel = document.querySelector('.js-password-label')
              if (password.type === 'password') {
                password.type = 'text'
                passwordLabel.innerHTML = 'hide'
              } else {
                password.type = 'password'
                passwordLabel.innerHTML = 'show'
              }
              password.focus()
            }} />
            <label class="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label" for="toggle" style={{ marginTop: "0px" }}>show</label>
          </div>
          <Input {...register("password")} className="appearance-none border-3 rounded-[100px] w-full py-3 px-3 leading-tight border-gray-300 focus:outline-none focus:bg-white text-gray-700 pr-16 font-mono js-password" id="password" type="password" autocomplete="off" />
          {errors.password ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div>
        {/* <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="cpassword">Confirmer mot de passe</InputLabel>
          <Input
            id="cpassword"
            type="password"
            autoComplete="off"
            placeholder="XXXXXXXXXXXXXXX"
            {...register("confirm", { required: true })}
            className="w-full"
          />
          {errors.confirm ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div> */}
        <div className="flex justify-center sm:justify-end">
          <button
            className="bg-main text-white rounded-[48px]
            active:scale-95 transition-all duration-200 h-14 w-64 my-4"
          // onClick={async (event) => {
          //   const response = await UpdateSettingInformation(getValues());
          // }}
          >
            <p className="font-medium text-sm text-center">Modifier</p>
          </button>
        </div>
      </form>
      {
        isOpen &&
        <BaseModal closeModal={() => { setIsOpen(false) }} purpose="Vérifiez votre nouvel e-mail.">
          <div className="flex flex-col w-full">
            <div className="text-center">
              <CountDownTimer totalSec={10 * 1000} email={getValues()["email"]} />
              <div className="mt-5">
                <AuthCode allowedCharacters='numeric' onChange={handleOnChange} inputClassName="input" length={6} />
              </div>
            </div>
            <div className="flex justify-end mt-12 mb-6">
              <div className="flex gap-x-3 items-center">
                <CustomStepsButton
                  onClick={(event) => { setIsOpen(false) }}
                  extra="bg-gray100"
                  textColor="text-main"
                >
                  Annuler
                </CustomStepsButton>
                <CustomStepsButton
                  // type="submit"
                  extra="bg-main"
                  textColor="text-white"
                  onClick={async (event) => {
                    document.getElementById("prewaiting").removeAttribute("hidden");
                    var payload = {
                      "email": getValues()["email"],
                      "verify_code": verifyCode
                    }
                    var response = await VerifySettingCode(payload);
                    var emailVerifyCode = "";
                    if (response?.status === 200) {
                      emailVerifyCode = response?.data?.token;

                      const payload = getValues();
                      if (selected === "Particulier") {
                        payload["number_siret"] = "";
                        payload["activity"] = "";
                        payload["social_reason"] = "";
                        payload["vat_number"] = "";
                      }
                      payload["user_type"] = selected;
                      payload["pays"] = countryName;
                      payload["province"] = province;
                      payload["phone"] = phoneNumber;

                      if (!getValues()["password"].length) {
                        payload["password"] = null;
                      }

                      document.getElementById("prewaiting").removeAttribute("hidden");

                      if (selectedFiles.length)
                        payload["avatar_url"] = selectedFiles[0].filepath;
                      payload["forgot_password_token"] = emailVerifyCode;
                      response = await UpdateSettingInformation(payload);
                      if (response?.status === 200) {
                        localStorage.setItem("user", JSON.stringify(response?.data));
                        navgiate("/");
                      } else {
                        setIsOpen(false);
                        setErrorIsOpen(true);
                        setErrorMsg("Le paramètre utilisateur n'est pas valide.");
                      }

                      document.getElementById("prewaiting").setAttribute("hidden", "true");

                    } else {
                      setIsOpen(false);
                      setErrorIsOpen(true);
                      setErrorMsg("Le paramètre utilisateur n'est pas valide.");
                      return;
                    }
                    document.getElementById("prewaiting").setAttribute("hidden", "true");
                  }}
                >
                  Valider
                </CustomStepsButton>
              </div>
            </div>
          </div>
        </BaseModal>
      }
      {
        ErrorIsOpen &&
        <BaseModal closeModal={() => { setErrorIsOpen(false) }} purpose="Error">
          {errorMsg}
        </BaseModal>
      }
    </Card>
  );
};

export default memo(UserInfo);
