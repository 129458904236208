import { useNavigate } from "react-router-dom";

import Container from "components/Container/Container";
import Card from "components/Card/Card";
import { useState, useMemo, useCallback, useEffect } from "react";
import eebleu from "assets/ee-bleu.png";
import RightButton from "components/Buttons/RightButton";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";

import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DeleteMsgService, GetAllMessagesService } from "services/Message";
import TableMessagerie from "components/Tables/TableMessagerie";

const Messagerie = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllMessagesService();
      if (response.status === 200) {
        setData(response.data);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }

    fetchData();
  }, []);

  const removeCheckedElement = useCallback(
    (elements) => {
      setData(
        data.filter((d) => {
          return !elements.includes(d.id);
        })
      );
    },
    [data]
  );

  const columns = useMemo(
    () => [
      // {
      //   id: "checkbox",
      //   Cell: ({
      //     selected,
      //     addToSelected,
      //     removeFromSelected,
      //     row: {
      //       original: { id },
      //     },
      //   }) => {
      //     const handleOnChange = useCallback(() => {
      //       //Supprimer du tableau si c'était coché
      //       if (selected.includes(id)) {
      //         removeFromSelected(id);
      //       } else {
      //         addToSelected(id);
      //       }
      //     }, [addToSelected, removeFromSelected, id, selected]);

      //     return (
      //       <CheckBox
      //         onChange={handleOnChange}
      //         checked={selected.includes(id)}
      //         extra="border-2"
      //       />
      //     );
      //   },
      //   columnId: 0,
      // },
      {
        columnId: 1,
        accessor: "star",
        Cell: () => {
          const [starred, setStarred] = useState(false);

          return (
            <div className="max-md:pl-6 pr-1" hidden>
              {starred ? (
                <AiFillStar
                  size={20}
                  onClick={() => setStarred(false)}
                  className="text-main cursor-pointer"
                />
              ) : (
                <AiOutlineStar
                  size={20}
                  onClick={() => setStarred(true)}
                  className="text-main cursor-pointer"
                />
              )}
            </div>
          );
        },
      },
      {
        Cell: () => (
          <div className="max-md:pl-6 pr-1">
            <FcGoogle size={20} className="" />
          </div>
        ),
        columnId: 2,
        accessor: "logo",
      },
      {
        accessor: "analysis_selection",
        columnId: 3,
        Cell: ({ value, row }) => (
          // <div
          //   className="flex text-primary text-sm
          //   font-medium py-6 px-2 2xl:pl-5 cursor-pointer
          //   justify-center max-md:text-xs"
          //   onClick={() => navigate(`/messagerie/${row.original.id}`)}
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex text-primary text-sm
            font-medium py-6 px-2 2xl:pl-5 cursor-pointer
            justify-center max-md:text-xs"
            onClick={() => navigate(`/messagerie/${row.original.id}`)}
          >
            <span className="whitespace-normal break-all">{value}</span>
          </div>
        ),
      },
      {
        accessor: "updated_at",
        columnId: 5,
        Cell: ({ value }) => (
          // <div
          //   className="flex text-primary text-xs
          // font-normal px-1"
          // >
          //   {new Date(value).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })} {new Date(value).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", second: "2-digit" })}
          // </div>
          <div
            className="flex text-primary text-xs
          font-normal px-1"
          >
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })} {new Date(value).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", second: "2-digit" })}
            </span>
          </div>
        ),
      },
      {
        accessor: "dots",
        columnId: 6,
        Cell: ({
          row: {
            original: { id },
          },
        }) => {
          const removeElement = useCallback(async () => {
            document.getElementById("prewaiting").removeAttribute("hidden");
            const response = await DeleteMsgService({ "msg_id": id });
            document.getElementById("prewaiting").setAttribute("hidden", "true");

            if (response.status === 200)
              removeCheckedElement([id]);
          }, [id]);
          const seeMessage = useCallback(() => {
            navigate(`/messagerie/${id}`);
          }, [id]);
          return (
            <MenuDropdown
              trigger={
                <button className="active:scale-95 transition-all">
                  <BsThreeDotsVertical size={18} />
                </button>
              }
              menu={[
                <MenuDropdownButton text="Voir" onClick={seeMessage} />,
                <MenuDropdownButton text="Supprimer" onClick={removeElement} />,
              ]}
            />
          );
        },
      },
    ],
    [
      navigate,
      removeCheckedElement
    ]
  );

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Messagerie</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>

        <RightButton
          text="Nouveau message"
          onClick={() => navigate("/messagerie/nouveau")}
        />
      </Container>

      {/* <Card extra={"w-full pb-6 sm:overflow-x-auto mt-8 mb-4 !bg-transparent"}> */}
      <Card extra={"w-full pb-6 mt-8 mb-4 !bg-transparent"}>
        {data.length > 0 ? (
          // <TableMessages
          //   data={data}
          //   columns={columns}
          //   removeCheckedElement={removeCheckedElement}
          // />
          <TableMessagerie
            data={data}
            columns={columns}
          />
        ) : (
          <div className="flex flex-col">
            <div className="sm:mt-48 mt-24">
              <p
                className="font-semibold sm:text-4xl text-main
                text-center text-xl"
              >
                Vous n’avez pas de message
                <br />
                pour le moment.
              </p>
            </div>
            <div className="absolute bottom-0 left-0">
              <img
                src={eebleu}
                alt="ee-logo"
                className="w-2/3 h-2/3 sm:w-full sm:h-full"
              />
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default Messagerie;
