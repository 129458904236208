import Container from "components/Container/Container";
import { FcGoogle } from "react-icons/fc";
import avatar from "assets/avatars/men.png";
import Card from "components/Card/Card";
import HorizontalInputLayout from "components/forms/Parametres/HorizontalInputLayout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetClientDetailInformation } from "services/Admin/Users";

const DetailsUser = () => {
  const { clientId } = useParams();
  const [user, setUser] = useState([]);
  const [keywordUrlData, setKeywordUrlData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetClientDetailInformation(clientId);
      if (response?.status === 200) {
        setUser(response?.data?.user_data);
        setKeywordUrlData(response?.data?.keyword_url_data ?? []);
      } else {
      }
      document.getElementById("prewaiting").setAttribute("hidden", true);
    };
    fetchData();
  }, []);
  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">
            Détails utilisateur
          </p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
      </Container>
      <Card extra="w-full mt-8 mb-8">
        <div
          className="flex w-full bg-white rounded-[20px] pt-8 md:px-16
          justify-center pb-8 px-4"
        >
          <div className="flex w-full flex-col gap-y-10">
            <div className="flex justify-between">
              <div className="flex gap-x-4 items-center">
                <span className="flex">
                  <img
                    src={process.env.REACT_APP_SERVER_URL + user.avatar_url}
                    alt="Avatar"
                    className="h-10 w-10 rounded-full"
                  />
                </span>
                <div className="flex flex-col gap-y-2">
                  <p className="font-semibold text-sm text-main">
                    {user.full_name}
                  </p>
                  <p className="font-normal text-main text-sm">
                    {user.role === 0
                      ? "Super Admin"
                      : user.role === 1
                      ? "Admin"
                      : "User"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-y-2 md:mr-14">
                <p className="text-green text-sm font-semibold">Actif</p>
                <p className="font-normal text-sm text-main">
                  {`${
                    user?.subscription_at === null ||
                    user?.subscription_at?.length === 0
                      ? ""
                      : "29€ "
                  }${
                    keywordUrlData?.length > 1
                      ? `+ ${
                          10 *
                          keywordUrlData.filter(
                            (keyword_url) =>
                              keyword_url.stripe_id !== null &&
                              keyword_url.stripe_id.trim() !== ""
                          )?.length
                        }€`
                      : ""
                  }`}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col gap-y-2 md:ml-14">
                <p className="font-semibold text-sm text-main">Mots clés</p>
                <p className="font-normal text-main text-sm">
                  {/* {user.keyword_url ? user.keyword_url : "Vide"} */}
                  {keywordUrlData?.map((value, index) => {
                    if (index === 0) return `${value.keyword_url}`;
                    else return ` | ${value.keyword_url}`;
                  })}
                </p>
              </div>
              <div className="flex flex-col gap-y-2">
                <p className="font-semibold text-sm text-main">
                  Moteurs/Réseaux
                </p>
                <FcGoogle size={20} />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card extra="bg-white py-4 px-5 sm:py-5 sm:px-10 my-8 ">
        <h2 className="text-main font-medium text-xl">Informations</h2>
        <div className="flex flex-col gap-y-3 mt-3">
          <HorizontalInputLayout>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <span className="font-semibold text-sm" htmlFor="activite">
                Activité
              </span>
              <span className="text-sm font-normal">
                {user.activity ? user.activity : "Vide"}
              </span>
            </div>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <span className="font-semibold text-sm" htmlFor="social">
                Raison sociale
              </span>
              <span className="text-sm font-normal">
                {user.social_reason ? user.social_reason : "Vide"}
              </span>
            </div>
          </HorizontalInputLayout>
          <div className="flex flex-col gap-y-2">
            <span className="font-semibold text-sm" htmlFor="address">
              Adresse
            </span>
            <span className="text-sm font-normal">
              {user.address ? user.address : "Vide"}
            </span>
          </div>
          <HorizontalInputLayout>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <span className="font-semibold text-sm" htmlFor="code-postal">
                Code postal
              </span>
              <span className="text-sm font-normal">
                {user.postal_code ? user.postal_code : "Vide"}
              </span>
            </div>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <span className="font-semibold text-sm" htmlFor="ville">
                Ville
              </span>
              <span className="text-sm font-normal">
                {user.province ? user.province : "Vide"}
              </span>
            </div>
          </HorizontalInputLayout>
          <div className="flex flex-col gap-y-2">
            <span className="font-semibold text-sm" htmlFor="pays">
              Pays
            </span>
            <span className="text-sm font-normal">
              {user.pays ? user.pays : "Vide"}
            </span>
          </div>
          <HorizontalInputLayout>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <span className="font-semibold text-sm" htmlFor="siret">
                Numéro de Siret
              </span>
              <span className="text-sm font-normal">
                {user.number_siret ? user.number_siret : "Vide"}
              </span>
            </div>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <span className="font-semibold text-sm" htmlFor="tva">
                Numéro de TVA
              </span>
              <span className="text-sm font-normal">
                {user.vat_number ? user.vat_number : "Vide"}
              </span>
            </div>
          </HorizontalInputLayout>
          <HorizontalInputLayout>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <span className="font-semibold text-sm" htmlFor="telephone">
                Téléphone
              </span>
              <span className="text-sm font-normal">
                {user.phone ? user.phone : "Vide"}
              </span>
            </div>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <span className="font-semibold text-sm" htmlFor="email">
                Email
              </span>
              <span className="text-sm font-normal">
                {user.email ? user.email : "Vide"}
              </span>
            </div>
          </HorizontalInputLayout>
          <div className="flex flex-col gap-y-2">
            <span className="font-semibold text-sm" htmlFor="site">
              Site internet
            </span>
            <span className="text-sm font-normal">
              {user.site_internet ? user.site_internet : "Vide"}
            </span>
          </div>
          <div className="flex flex-col gap-y-2">
            <span className="font-semibold text-sm" htmlFor="username">
              Nom d'utilisateur
            </span>
            <span className="text-sm font-normal">
              {user.full_name ? user.full_name : "Vide"}
            </span>
          </div>
        </div>
      </Card>
    </>
  );
};

export default DetailsUser;
