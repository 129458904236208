import React from "react";

const SearchSettingSection = ({ setAnalyseTabActive, setInterventionActive, setAlertTabActive, setFacturesTabActive, setMessagerieTabActive,
  analyseCount, interventionCount, alertCount, facutresCount, messagerieCount, active }) => {
  return (
    <div className="flex flex-col max-xl:w-full">
      <div className="flex rounded-[36.75px] items-center bg-white font-semibold max-sm:mt-2 max-xl:hidden">
        <div className="flex">
          <span
            onClick={setAnalyseTabActive}
            className={`max-md:px-10 px-12 py-3 cursor-pointer text-secondary text-[13px] rounded-[36.75px] whitespace-normal break-all
       ${active === "analyse" ? "bg-primary text-white" : "text-[#284F60]"}`}
          >
            Analyse
          </span>
          {analyseCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#ec4899] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{analyseCount}</div>}
        </div>
        <div className="flex">
          <span
            onClick={setInterventionActive}
            className={`text-[13px] max-md:px-10 py-3 text-secondary rounded-[36.75px] cursor-pointer px-12 whitespace-normal break-all
      ${active === "intervention" ? "bg-primary text-white" : "text-[#284F60]"}`}
          >
            Intervention
          </span>
          {interventionCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#d946ef] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{interventionCount}</div>}
        </div>
        <div className="flex">
          <span
            onClick={setAlertTabActive}
            className={`flex text-[13px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-12 py-3 whitespace-normal break-all
          ${active === "alert" ? "bg-primary  text-white" : "text-[#284F60]"}`}
          >
            Alerte
          </span>
          {alertCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#14b8a6] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{alertCount}</div>}
        </div>
        <div className="flex">
          <span
            onClick={setFacturesTabActive}
            className={`flex text-[13px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-12 py-3 whitespace-normal break-all
          ${active === "factures" ? "bg-primary  text-white" : "text-[#284F60]"}`}
          >
            Factures
          </span>
          {facutresCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#3b82f6] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{facutresCount}</div>}
        </div>
        <div className="flex">
          <span
            onClick={setMessagerieTabActive}
            className={`flex text-[13px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-12 py-3 whitespace-normal break-all
          ${active === "messagerie" ? "bg-primary  text-white" : "text-[#284F60]"}`}
          >
            Messagerie
          </span>
          {messagerieCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#ef4444] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{messagerieCount}</div>}
        </div>
      </div>
      <div className="flex flex-col rounded-[36.75px] items-center bg-white font-semibold max-sm:mt-2 xl:hidden">
        <span
          onClick={setAnalyseTabActive}
          className={`max-md:px-10 px-20 py-3 cursor-pointer text-secondary text-[13px] rounded-[36.75px] whitespace-normal break-all
       ${active === "analyse" ? "bg-primary text-white" : "text-[#284F60]"}`}
        >
          Analyse
        </span>
        <span
          onClick={setInterventionActive}
          className={`text-[13px] max-md:px-10 py-3 text-secondary rounded-[36.75px] cursor-pointer px-20 whitespace-normal break-all
      ${active === "intervention" ? "bg-primary text-white" : "text-[#284F60]"}`}
        >
          Intervention
        </span>
        <span
          onClick={setAlertTabActive}
          className={`flex text-[13px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-20 py-3 whitespace-normal break-all
        ${active === "alert" ? "bg-primary  text-white" : "text-[#284F60]"}`}
        >
          Alerte
        </span>
        <span
          onClick={setFacturesTabActive}
          className={`flex text-[13px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-20 py-3 whitespace-normal break-all
        ${active === "factures" ? "bg-primary  text-white" : "text-[#284F60]"}`}
        >
          Factures
        </span>
        <span
          onClick={setMessagerieTabActive}
          className={`flex text-[13px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-20 py-3 whitespace-normal break-all
        ${active === "messagerie" ? "bg-primary  text-white" : "text-[#284F60]"}`}
        >
          Messagerie
        </span>
      </div>
    </div>
  );
};

export default SearchSettingSection;
