import RightButton from "components/Buttons/RightButton";
import Card from "components/Card/Card";
import Container from "components/Container/Container";
import Spacer from "components/Spacer/Spacer";
import { useState, useEffect } from "react";
import { HiDownload } from "react-icons/hi";
import { MdInfoOutline } from "react-icons/md";
import { GetFacturesById } from "services/Admin/Factures";
import { useParams } from "react-router-dom";

const BackDetailsFactures = () => {

  const [factures, setFactures] = useState({});
  const [totalFactures, setTotalFactures] = useState(0);
  const { factureId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetFacturesById(factureId);

      if (response?.status === 200) {
        setFactures(response.data);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Détails facture</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <RightButton icon={<HiDownload />} text="Télécharger vos Factures" onClick={(event) => {
          if (factures.pdf_url === null)
            return;
          window.open(process.env.REACT_APP_SERVER_URL + factures.pdf_url, '_blank');
        }} />
      </Container>
      <Card extra="w-full p-3 sm:p-10 mt-8 mb-8 bg-white">
        <div className="text-sm mb-3">ID Facture</div>
        <div className="grid grid-cols-2 sm:grid-cols-4 2xl:grid-cols-5 text-sm place-content-between">
          <div className="font-semibold text-2xl mb-8">#000{factureId}</div>
          <Spacer />
          <div className="text-sm font-semibold pt-2 mb-9">
            Date: {factures.date}
          </div>

          <div className="font-semibold text-sm mb-2">Bill from:</div>
          <Spacer />
          <div className="font-semibold text-sm mb-2">Bill to:</div>

          <div className="mb-2">{factures.bill_from?.name}</div>
          <Spacer />
          <div className="mb-2">{factures.bill_to?.name}</div>

          <div className="mb-2">{factures.bill_from?.address}</div>
          <Spacer />
          <div className="mb-2">{factures.bill_to?.address}</div>

          <div className="mb-2">{factures.bill_from?.phone_number}</div>
          <Spacer />
          <div className="mb-2">{factures.bill_to?.phone_number}</div>
        </div>
        <div className="text-black text-[20px] font-semibold mt-8 mb-4">
          Détails de la facture
        </div>
        <div className="whitespace-nowrap overflow-x-scroll scrollbar-hide">
          <table className="w-full text-black text-sm">
            <thead>
              <tr>
                <th className="text-start border-b border-linecolor pb-3">
                  <span className="font-normal">Détail de la facture</span>
                </th>
                <th className="text-start border-b border-linecolor pb-3 px-1">
                  <span className="font-normal">Prix</span>
                </th>
                <th className="text-start border-b border-linecolor pb-3 px-1">
                  <span className="font-normal">Qté</span>
                </th>
                <th className="text-start border-b border-linecolor pb-3 px-1">
                  <span className="font-normal">Total</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td>
                  <div className="py-3">Lorem Ipsum</div>
                </td>
                <td className="px-1">3000 €</td>
                <td className="px-1">2</td>
                <td className="px-1">6000 €</td>
              </tr>
              <tr>
                <td>
                  <div className="py-3">Lorem Ipsum</div>
                </td>
                <td className="px-1">3000 €</td>
                <td className="px-1">2</td>
                <td className="px-1">6000 €</td>
              </tr> */}
              {
                factures?.invoice_detail?.invoice_detail.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="py-3 whitespace-normal break-all">{value.service_name}</div>
                      </td>
                      <td className="px-1">{value.price} {factures.invoice_detail?.currency.toLowerCase() === 'eur' ? '€' : 'USD'}</td>
                      <td className="px-1">{value.count}</td>
                      <td className="px-1">{value.price * value.count} {factures.invoice_detail?.currency.toLowerCase() === 'eur' ? '€' : 'USD'}</td>
                    </tr>
                  )
                })
              }
              {
                factures?.invoice_detail?.promo_apply?.coupon_code ?
                  <tr>
                    <td className="py-3">Promo</td>
                    <td className="px-1">
                      {
                        factures?.invoice_detail?.promo_apply?.discount_amount
                      } {factures?.invoice_detail?.promo_apply?.coupon_type === "eur" ? '€' : factures?.invoice_detail?.currency.toLowerCase() === 'USD' ? 'USD' : '%'}
                    </td>
                    <td className="px-1"></td>
                    <td className="px-1">
                      {
                        factures?.invoice_detail?.promo_apply?.coupon_type === "eur" ?
                          ((factures?.invoice_detail?.promo_apply?.discount_amount * 1.0 / 100)).toFixed(2) :
                          (29 * (factures?.invoice_detail?.promo_apply?.discount_amount) * 1.0 / 100).toFixed(2)
                      } {factures.invoice_detail?.currency.toLowerCase() === 'eur' ? '€' : 'USD'}
                    </td>
                  </tr> : <></>
              }
            </tbody>
            <tfoot>
              <tr>
                <td
                  colSpan={3}
                  className="font-semibold text-base border-t border-linecolor"
                >
                  <div className="py-3">Total</div>
                </td>
                {factures?.invoice_detail?.promo_apply?.coupon_code ?
                  <td className="border-t border-linecolor px-1">{(factures?.invoice_detail?.invoice_detail.reduce((accumulator, item) => { return accumulator + (item.price * item.count) }, 0) -
                    (factures?.invoice_detail?.promo_apply?.coupon_type === "eur" ?
                      ((factures?.invoice_detail?.promo_apply?.discount_amount * 1.0 / 100)) :
                      (29 * (factures?.invoice_detail?.promo_apply?.discount_amount) * 1.0 / 100))).toFixed(2)} {factures.invoice_detail?.currency.toLowerCase() === 'eur' ? '€' : 'USD'}</td>
                  : <td>{factures?.invoice_detail?.invoice_detail.reduce((accumulator, item) => { return accumulator + (item.price * item.count) }, 0)} {factures.invoice_detail?.currency.toLowerCase() === 'eur' ? '€' : 'USD'}</td>
                }
              </tr>
            </tfoot>
          </table>
        </div>

        <div className="my-8">
          <div className="text-sm font-semibold">Méthode de paiement</div>
          <div className="text-sm font-normal">{factures?.payment_method}</div>
        </div>

        <div className="flex bg-grayUpload text-sm text-black p-4 rounded-[12px] mb-3 gap-x-3">
          <div>
            <MdInfoOutline size={30} />
          </div>
          <div>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Necessitatibus unde qui beatae porro exercitationem harum odio
            corporis officiis ipsa nemo, ipsam maxime. Lorem, ipsum dolor sit
            amet consectetur adipisicing elit.
          </div>
        </div>
      </Card>
    </>
  );
};

export default BackDetailsFactures;
