import RightButton from "components/Buttons/RightButton";
import Card from "components/Card/Card";
import CheckBox from "components/CheckBox/CheckBox";
import Container from "components/Container/Container";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";
import TableHistoriques from "components/Tables/TableHistoriques";
import { useCallback, useMemo, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { LiaCheckCircle } from "react-icons/lia";
import { Link, useNavigate } from "react-router-dom";
import {
  GetAllFactures,
  DownloadAdminInvoices,
  GetFacturesByType,
  RefundInvoice,
} from "services/Admin/Factures";
import { useEffect } from "react";
import CustomWidget from "components/Widget/CustomWidget";

const BackFactures = () => {
  const [data, setData] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [browseType, setBrowseType] = useState("total_invoices");
  const [updateTable, setUpdateTable] = useState(false);

  const removeCheckedElement = useCallback(
    (elements) => {
      setData(
        data.filter((d) => {
          return !elements.includes(d.id);
        })
      );
    },
    [data]
  );

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllFactures();

      if (response?.status === 200) {
        setData(response.data?.invoices ?? []);
        setPaidInvoices(response.data?.paid_invoice_count ?? 0);
        setInvoiceCount(response.data?.invoice_count ?? 0);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, [updateTable]);

  useEffect(() => {
    const fetchData = async () => {
      if (!browseType.length) return;
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetFacturesByType(browseType);

      if (response?.status === 200) {
        setData(response?.data?.invoices ?? []);
      } else {
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, [browseType]);

  const BrowseByType = (type) => {
    switch (type) {
      case "Total Factures":
        setBrowseType("total_invoices");
        break;
      case "Factures Payées":
        setBrowseType("paid_invoices");
        break;
      case "Total Factures Envoyées":
        setBrowseType("invoices_sent");
        break;
      default:
        break;
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "checkbox",
        Cell: ({
          selected,
          addToSelected,
          removeFromSelected,
          row: {
            original: { id },
          },
        }) => {
          // const handleOnChange = useCallback(() => {
          //   //Supprimer du tableau si c'était coché
          //   if (selected.includes(id)) {
          //     removeFromSelected(id);
          //   } else {
          //     addToSelected(id);
          //   }
          // }, [addToSelected, removeFromSelected, id, selected]);
          const handleOnChange = useCallback(() => {
            //Supprimer du tableau si c'était coché
            if (selected.includes(id)) {
              removeFromSelected(id);
              // eslint-disable-next-line react-hooks/exhaustive-deps
              selected = selected.filter((item) => item !== id);
            } else {
              addToSelected(id);
              selected.push(id);
            }
            setSelectedIDs(selected);
          }, [addToSelected, removeFromSelected, id, selected]);

          return (
            <CheckBox
              onChange={handleOnChange}
              checked={selected?.includes(id)}
              extra="border-2 border-[#BEBEBE]"
            />
          );
        },
        columnId: 0,
      },
      {
        Header: "ID Invoice",
        accessor: "id",
        Cell: ({ cell: { value, row } }) => {
          return (
            // <Link to={`/admin/factures/${row.original.id}`}>
            //   <div className="py-4 font-semibold text-[12.008px] mx-2 ">
            //     #{value}
            //   </div>
            // </Link>
            <Link to={`/admin/factures/${row.original.id}`}>
              <div className="py-4 font-semibold text-[12.008px] mx-2 text-center">
                <span className="whitespace-normal break-words">#{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        Cell: ({ cell: { value, row } }) => {
          return (
            // <Link to={`/admin/factures/${row.original.id}`}>
            //   <div className="py-4 text-[12.008px] font-normal mx-2">
            //     {value}
            //   </div>
            // </Link>
            <Link to={`/admin/factures/${row.original.id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                <span className="whitespace-normal break-words">{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "DateTime",
        accessor: "created_at",
        Cell: ({ cell: { value, row } }) => {
          return (
            // <Link to={`/admin/factures/${row.original.id}`}>
            //   <div className="py-4 text-[12.008px] font-normal mx-2">
            //     {value}
            //   </div>
            // </Link>
            <Link to={`/admin/factures/${row.original.id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                <span className="whitespace-normal break-words">
                  {new Date(value).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }) ?? ""}
                </span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          cell: { value },
          row: {
            original: { id },
          },
        }) => {
          // return (
          //   <Link to={`/admin/factures/${id}`}>
          //     {value === "On Hold" ? (
          //       <div className="py-4 flex text-[12.008px] font-medium leading-none text-warning mx-2">
          //         En attente
          //       </div>
          //     ) : value === "Canceled" ? (
          //       <div className="py-4 flex text-primary text-[12.008px] font-medium leading-none mx-2">
          //         Annulé
          //       </div>
          //     ) : (
          //       <div className="py-4 flex text-success text-[12.008px] font-medium leading-none mx-2">
          //         Complété
          //       </div>
          //     )}
          //   </Link>
          return (
            <Link to={`/admin/factures/${id}`}>
              {value === "On Hold" ? (
                <div className="py-4 flex text-[12.008px] font-medium leading-none text-warning mx-2 text-center">
                  <span className="whitespace-normal break-words">
                    En attente
                  </span>
                </div>
              ) : value === "Canceled" ? (
                <div className="py-4 flex text-primary text-[12.008px] font-medium leading-none mx-2 text-center">
                  <span className="whitespace-normal break-words">Annulé</span>
                </div>
              ) : value === "Refunded" ? (
                <div className="py-4 flex text-danger text-[12.008px] font-medium leading-none mx-2 text-center">
                  <span className="whitespace-normal break-words">Remboursé</span>
                </div>
              ) : (
                <div className="py-4 flex text-success text-[12.008px] font-medium leading-none mx-2 text-center">
                  <span className="whitespace-normal break-words">
                    Complété
                  </span>
                </div>
              )}
            </Link>
          );
        },
      },
      {
        Header: "Détails des services",
        accessor: "service_detail",
        Cell: ({
          cell: { value },
          row: {
            original: { id },
          },
        }) => {
          return (
            // <Link to={`/admin/factures/${id}`}>
            //   <div className="py-4 text-[12.008px] font-normal mx-2">
            //     {value}
            //   </div>
            // </Link>
            <Link to={`/admin/factures/${id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                <span className="whitespace-normal break-words">{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        id: "dots",
        Cell: ({
          row: {
            original: { id },
          },
        }) => {
          const navigate = useNavigate();
          const removeElement = useCallback(async () => {
            // document.getElementById("prewaiting").removeAttribute("hidden");
            // const response = await DeleteInvoices({ "invoice_ids": [id] });
            // document.getElementById("prewaiting").setAttribute("hidden", "true");
            removeCheckedElement([id]);
          }, [id]);

          const seeFacture = useCallback(() => {
            navigate(`/admin/factures/${id}`);
          }, [id, navigate]);

          return (
            <MenuDropdown
              trigger={
                <button className="active:scale-95 transition-all hover:bg-gray200 p-1 rounded-full">
                  <BsThreeDotsVertical size={18} className="cursor-pointer" />
                </button>
              }
              menu={[
                <MenuDropdownButton
                  text="Voir la facture"
                  onClick={seeFacture}
                />,
                // <MenuDropdownButton
                //   text="Supprimer la facture"
                //   onClick={removeElement}
                // />,
                <MenuDropdownButton
                  text="Télécharger la facture"
                  onClick={async () => {
                    var postData = { invoice_ids: [id] };
                    const response = await DownloadAdminInvoices(postData);
                    if (response?.data === undefined) return;
                    window.open(
                      process.env.REACT_APP_SERVER_URL + response?.data
                    );
                  }}
                />,
                <MenuDropdownButton
                  text="Rembourser la facture"
                  onClick={async (event) => {
                    const response = await RefundInvoice(id);
                    if (response?.status === 200) {
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
              ]}
            />
          );
        },
      },
    ],
    [removeCheckedElement]
  );

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Factures</p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
      </Container>

      <div className="my-6 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <CustomWidget
          title="Total Factures"
          value={invoiceCount}
          text="total_invoices"
          type={browseType}
          icon={<FaFileInvoiceDollar className="text-[#858585]" size={20} />}
          BrowseByType={BrowseByType}
        />
        <CustomWidget
          title="Factures Payées"
          value={paidInvoices}
          text="paid_invoices"
          type={browseType}
          icon={<LiaCheckCircle className="text-success" size={22} />}
          BrowseByType={BrowseByType}
        />
        <CustomWidget
          title="Total Factures Envoyées"
          value={invoiceCount}
          text="invoices_sent"
          type={browseType}
          icon={<FaFileInvoiceDollar className="text-success" size={20} />}
          BrowseByType={BrowseByType}
        />
      </div>

      <Container extra="mt-8 sm:mt-10">
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Historiques</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal ">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <RightButton
          icon={<HiDownload />}
          text="Télécharger vos factures"
          onClick={async (event) => {
            var postData = { invoice_ids: selectedIDs };
            const response = await DownloadAdminInvoices(postData);
            if (response?.data === undefined) return;
            window.open(process.env.REACT_APP_SERVER_URL + response?.data);
          }}
        />
      </Container>

      <Card
        extra={
          "w-full pb-6 sm:overflow-x-auto mt-8 !bg-transparent rounded-none"
        }
      >
        <TableHistoriques
          columns={columns}
          data={data}
          removeCheckedElement={removeCheckedElement}
        />
        {/* <BackTableInterventions
          columns={columns}
          data={data}
          size={8}
        /> */}
      </Card>
    </>
  );
};

export default BackFactures;
