import { Link } from "react-router-dom";

const DisplayMessage = ({ updated_at, user_id, messaging, attachments, full_name }) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const currentDate = new Date(updated_at);
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

  return (
    <div className="w-full flex flex-col gap-y-4 h-auto">
      <div className="flex flex-col gap-y-2">
        {/* <p className="text-main text-sm font-semibold">{updated_at}</p> */}
        <p className="text-main text-sm font-semibold">{formattedDate.includes("NaN/") ? "" : formattedDate }</p>
        {/* <p className="text-main text-sm font-semibold">{user_id === user.id ? user.full_name : 'Reeact'}</p> */}
        <p className="text-main text-sm font-semibold">{user_id === user.id ? "Me" : user.user_id === user.message_to ? "Reeact" : full_name}</p>
      </div>
      <div
        className="bg-grayUpload rounded-xl p-4 
       w-full"
      >
        <p className="text-main text-sm font-normal">{messaging}</p>
      </div>
      <div className="w-full flex flex-col gap-y-2">
        <p className="text-main text-sm font-semibold">
          Pièces jointes {attachments?.length}
        </p>
        <div className="flex gap-x-2">
          {attachments?.map((attachment, index) => (
            <div key={index}>
              <Link
                to={process.env.REACT_APP_SERVER_URL + attachment.filepath}
                target="_blank"
                className="text-main md:text-sm text-xs font-normal flex gap-x-2"
              >
                {attachment?.filename}
                {(attachments?.length !== index + 1) && <div className="w-px h-4 bg-main" />}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div
        className="w-full h-px bg-linecolor
        md:mt-4 md:mb-4 mt-2"
      />
    </div>
  );
};

export default DisplayMessage;
