import SignIn from "components/forms/Login/Login";
import AxeptioIntegration from "components/AxeptioIntegration";
import logowhite from "assets/logowhite.png";
import logoblue from "assets/logoblue.png";
import ee from "assets/ee.png";

const Login = () => {
  return (
    <>
      <AxeptioIntegration />
      <div
        className="flex relative min-h-screen
    max-sm:justify-center"
      >
        <div
          className="w-[50%] hidden
        bg-main sm:block relative"
        >
          <img src={logowhite} alt="" className="w-2/3 m-auto mt-64" />
        </div>
        <div className="flex max-md:flex-col justify-center items-center lg:w-[50%]">
          <span>
            <img
              src={logoblue}
              alt="logo"
              className="w-9/12 m-auto hidden max-sm:block"
            />
          </span>
          <SignIn />
        </div>
        <div
          className="absolute bottom-0 hidden
        sm:block"
        >
          <img src={ee} alt="ee-logo" className="w-[80%] h-full" />
        </div>
      </div>
    </>
  );
};

export default Login;
