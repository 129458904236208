import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});



const GetAllIntervenstionsService = async (active) => {
  try {
    const response = await api.get(`intervention_requests/${active}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const GetIntervenstionByIdService = async (id) => {
  try {
    const response = await api.get(`intervention_requests/information/${id}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const SendRequestForInterventionService = async (data, id) => {
  try {
    const response = await api.post(`intervention_requests/information/${id}`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}
const GetFacturesId = async (intervention_id) => {
  try {
    const response = await api.get(`intervention_requests/quote/${intervention_id}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const RefuseIntervention = async (id) => {
  try {
    const response = await api.get(`intervention/refuse/${id}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export {
  GetAllIntervenstionsService,
  GetIntervenstionByIdService,
  SendRequestForInterventionService,
  GetFacturesId,
  RefuseIntervention
};