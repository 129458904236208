import { createBrowserRouter, redirect } from "react-router-dom";

import BackofficeLayout from "layouts/BackofficeLayout/BackofficeLayout";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";

import DevisInterventions from "views/Dashboard/Interventions/DevisInterventions";
import AnalyseResultats from "views/Dashboard/Analyse/AnalyseResultats";
import DetailsFactures from "views/Dashboard/Factures/DetailsFactures";
import Interventions from "views/Dashboard/Interventions/Interventions";
import NouveauMessage from "views/Dashboard/Messagerie/NouveauMessage";
import DetailsMessages from "views/Dashboard/Messagerie/DetailsMessages";
import Confirmation from "views/Dashboard/Interventions/Confirmation";
import Messagerie from "views/Dashboard/Messagerie/Messagerie";
import Parametres from "views/Dashboard/Parametres/Parametres";
import Factures from "views/Dashboard/Factures/Factures";
import Alertes from "views/Dashboard/Alertes/Alertes";
import Analyse from "views/Dashboard/Analyse/Analyse";
import Dashboard from "views/Dashboard/Dashboard";
import Register from "views/Register/Register";
import Success from "views/Register/Success";
import AfterRegister from "views/Register/AfterRegister";
import Login from "views/Login/Login";
import Error403 from "views/Errors/403";
import Error404 from "views/Errors/404";
import Error500 from "views/Errors/500";
import Error503 from "views/Errors/503";

import Clients from "views/Admin/Clients/Clients";
import BackInterventions from "views/Admin/Interventions/Interventions";
import BackFactures from "views/Admin/Factures/Factures";
import Promos from "views/Admin/Promos/Promos";
import Statistiques from "views/Admin/Statistiques/Statistiques";
import BackParametres from "views/Admin/Parametres/Parametres";
import MessagerieAdmin from "views/Admin/Messagerie/MessagerieAdmin";
import AddUser from "views/Admin/Clients/AddUser";
import DetailsUser from "views/Admin/Clients/DetailsUser";
import BackDetailsFactures from "views/Admin/Factures/DetailsFactures";
import NouveauCode from "views/Admin/Promos/NouveauCode";
import BackDetailsMessages from "views/Admin/Messagerie/DetailsMessages";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import AnalyseKeywordUrl from "views/Dashboard/Analyse/AnalyseKeywordUrl";
import Search from "views/Dashboard/Search/Search";
import AdminSearch from "views/Admin/Search/AdminSearch";
import NouveauMessageAdmin from "views/Admin/Messagerie/NouveauMessageAdmin";
import UpdateCode from "views/Admin/Promos/UpdateCode";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/register-steps",
    element: <AfterRegister />,
  },
  // {
  //   path: "/register",
  //   element: <AfterRegister />,
  // },
  {
    path: "/",
    loader: async () => {
      //TODO Vérifier si l'utilisateur s'est connecté d'abord, return null si tout va bien  ou le rediriger pour qu'il se connecte
      const jwt = localStorage.getItem('jwt');
      if (!jwt) return redirect("/login");
      const user = JSON.parse(localStorage.getItem('user'));
      if ((jwt && user && user?.role < 2)) return redirect("/admin");
      return null;
    },
    element: <DashboardLayout />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "analyse", element: <Analyse /> },
      { path: "analyse/:keyword_param", element: <AnalyseKeywordUrl /> },
      { path: "resultat-analyse", element: <AnalyseResultats /> },
      { path: "demande-dinterventions", element: <Interventions /> },
      {
        path: "demande-dinterventions/:interventionId/devis",
        element: <DevisInterventions />,
      },
      { path: "alertes", element: <Alertes /> },
      { path: "factures", element: <Factures /> },
      { path: "factures/:factureId", element: <DetailsFactures /> },
      { path: "messagerie", element: <Messagerie /> },
      { path: "messagerie/nouveau", element: <NouveauMessage /> },
      { path: "messagerie/:messageId", element: <DetailsMessages /> },
      { path: "parametres", element: <Parametres /> },
      { path: "search", element: <Search /> },
    ],
    errorElement: <Error403 />,
  },
  {
    path: "/admin",
    loader: async () => {
      //TODO Vérifier si l'utilisateur s'est connecté d'abord, return null si tout va bien  ou le rediriger pour qu'il se connecte
      const jwt = localStorage.getItem('jwt');
      const user = JSON.parse(localStorage.getItem('user'));
      if (!(jwt && user && user?.role < 2)) return redirect("/");
      return null;
    },
    element: <BackofficeLayout />,
    children: [
      {
        index: true,
        loader: async () => {
          return redirect("comptes-clients");
        },
      },
      { path: "demande-dinterventions", element: <BackInterventions /> },
      { path: "factures", element: <BackFactures /> },
      { path: "factures/:factureId", element: <BackDetailsFactures /> },
      { path: "comptes-clients", element: <Clients /> },
      { path: "comptes-clients/new", element: <AddUser /> },
      { path: "comptes-clients/:clientId", element: <DetailsUser /> },
      { path: "codes-promos", element: <Promos /> },
      { path: "codes-promos/new", element: <NouveauCode /> },
      { path: "codes-promos/update", element: <UpdateCode /> },
      { path: "statistiques", element: <Statistiques /> },
      { path: "parametres", element: <BackParametres /> },
      { path: "messagerie", element: <MessagerieAdmin /> },
      { path: "messagerie/nouveau", element: <NouveauMessageAdmin /> },
      {
        path: "messagerie/:messageId",
        element: <BackDetailsMessages />,
      },
      { path: "search", element: <AdminSearch /> },
    ],
  },
  { path: "/confirmation", element: <Confirmation /> },
  { path: "/register-success", element: <Success /> },
  { path: "/403", element: <Error403 /> },
  { path: "/500", element: <Error500 /> },
  { path: "/503", element: <Error503 /> },
]);

export default router;
