import SidebarLinks from "components/SidebarLink/SidebarLinks";
import routes from "constants/routes";
import IconButton from "components/Buttons/IconButton";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { BiPlus } from "react-icons/bi";

const BackSidebar = ({ open, closeOnClick }) => {
  return (
    // <div
    //   className={`flex min-h-full fixed bg-white border-t border-t-gray200 z-30 transition-all duration-300 ease-linear ${
    //     open ? "translate-x-0" : "-translate-x-96"
    //   }`}
    // >
    <div
      className={`flex min-h-full fixed bg-white border-t border-t-gray200 z-30 transition-all duration-300 ease-linear w-[310px] ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <div className="border-r border-r-gray200 flex flex-col px-2 pt-7">
        <IconButton icon={<FcGoogle size={20} />} className="bg-white" />
        <IconButton
          icon={<FaFacebookF size={20} />}
          className="bg-[#3B5998] text-white opacity-20"
        />
        <IconButton
          icon={<FaInstagram size={20} />}
          className="bg-[#D43377] text-white opacity-20"
        />
        <IconButton
          icon={<FaTwitter size={20} />}
          className="bg-[#55ACEE] text-white opacity-20"
        />
        <IconButton icon={<BiPlus size={20} className="text-primary" />} />
      </div>
      <ul className="pt-4 px-2">
        <SidebarLinks
          routes={routes.back}
          closeOnClick={closeOnClick}
          admin={true}
        />
      </ul>
    </div>
  );
};
export default BackSidebar;
