import { useState, memo } from "react";

import { useForm } from "react-hook-form";

import InputLabel from "components/InputLabel/InputLabel";
import TypePromo from "components/TypePromo/TypePromo";
import InputDate from "components/InputDate/InputDate";
import InputHour from "components/InputHour/InputHour";
import Input from "components/Input/Input";
import { AddPromocodes, DeletePromocodeById } from "services/Admin/Promos";
import { useNavigate } from "react-router-dom";
import InputError from "components/InputError/InputError";
import CustomCheckBox from "components/CheckBox/CustomCheckBox";
import BaseModal from "components/Modals/BaseModal";
import CustomInputHour from "components/InputHour/CustomInputHour";

const UpdateCodePromo = ({ codePromo }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const afterDate = new Date(
    currentYear + 1,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const [startedDate, setStartedDate] = useState(
    new Date(codePromo?.start_at).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );
  const [startedHour, setStartedHour] = useState("00:00");
  const [finishedDate, setFinishedDate] = useState(
    new Date(codePromo?.end_at).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );
  const [finishedHour, setFinishedHour] = useState("00:00");
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const navigator = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      codepromo: codePromo?.code_title,
      valeurpromo: codePromo?.amount,
      datedepart: startedDate,
      heuredepart: startedHour,
      datefin: "",
      heurefin: "",
    },
  });

  const [endDate, setEndDate] = useState(true);

  const [active, setActive] = useState(
    codePromo?.method ? "fixe" : "pourcentage"
  );

  const setPercent = () => {
    setActive("pourcentage");
  };

  const setFix = () => {
    setActive("fixe");
  };

  const showEndDate = () => {
    setEndDate(!endDate);
  };

  //fonction de soumission du formulaire

  const calculateDayDifference = (date1, date2) => {
    // Convert both dates to UTC to ensure accurate calculation
    var utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    var utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    // Calculate the difference in milliseconds
    var diffMilliseconds = Math.abs(utc2 - utc1);

    // Convert milliseconds to days
    var diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  const onSubmit = async (data) => {
    const startDateStr = startedDate;
    const startDateparts = startDateStr.split("/");

    const finishDateStr = finishedDate;
    const finishDateparts = finishDateStr.split("/");
    // console.log(
    //   data.codepromo,
    //   data.valeurpromo,
    //   active === "fixe",
    //   `${startDateparts[2]}-${startDateparts[1]}-${startDateparts[0]}`,
    //   startedHour,
    //   `${finishDateparts[2]}-${finishDateparts[1]}-${finishDateparts[0]}`,
    //   finishedHour
    // );
    var startDate =
      startDateparts?.[1] +
      "/" +
      startDateparts?.[0] +
      "/" +
      startDateparts?.[2] +
      " " +
      startedHour +
      ":00";
    var endDate =
      finishDateparts?.[1] +
      "/" +
      finishDateparts?.[0] +
      "/" +
      finishDateparts?.[2] +
      " " +
      finishedHour +
      ":00";

    console.log(startDate);
    console.log(endDate);
    if (new Date(startDate).toString() === "Invalid Date") {
      setErrorMsg("La date de début n'est pas valide.");
      setIsOpen(true);
      return;
    }
    if (new Date(endDate).toString() === "Invalid Date") {
      setErrorMsg("La date de fin n'est pas valide.");
      setIsOpen(true);
      return;
    }

    if (new Date(startDate) < new Date()) {
      setErrorMsg("La date de début doit être supérieure à la date actuelle.");
      setIsOpen(true);
      return;
    }
    if (new Date(startDate) >= new Date(endDate)) {
      setErrorMsg("La date de début doit être inférieure à la date de fin.");
      setIsOpen(true);
      return;
    }
    if (calculateDayDifference(new Date(startDate), new Date(endDate)) < 30) {
      setErrorMsg(
        "Le délai entre la date de début et la date de fin est supérieur à 1 mois."
      );
      setIsOpen(true);
      return;
    }
    document.getElementById("prewaiting").removeAttribute("hidden");
    let response = await DeletePromocodeById(codePromo?.id);
    if (response?.status === 200) {
      var postData = {
        code_title: data.codepromo,
        method: active === "fixe",
        amount: parseFloat(data.valeurpromo),
        start_at: `${startDateparts[2]}-${startDateparts[1]}-${startDateparts[0]} ${startedHour}:00`,
        end_at: `${finishDateparts[2]}-${finishDateparts[1]}-${finishDateparts[0]} ${finishedHour}:00`,
      };
      response = await AddPromocodes(postData);
      document.getElementById("prewaiting").setAttribute("hidden", "true");
      navigator("/admin/codes-promos");
    }
  };

  return (
    <div
      className="w-full flex flex-col rounded-[20px] h-auto
      md:px-20 px-4 pt-12 pb-12 bg-white gap-y-6 relative"
    >
      <h2 className="text-main font-semibold md:text-2xl text-xl">
        Nouveau code promo
      </h2>
      <form
        className="flex flex-col gap-y-6"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-y-3">
          <InputLabel htmlFor="code-promo" className="text-sm">
            Code Promo
          </InputLabel>
          <Input
            type="text"
            id="code-promo"
            autoComplete="off"
            placeholder="Nom du code"
            className="md:w-[48%] w-full h-12"
            {...register("codepromo", { required: true })}
          />
          {errors.codepromo ? (
            <InputError message={"Ce champ est obligatoire."} />
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col gap-y-3">
          <InputLabel htmlFor="valeur" className="text-sm">
            Valeur
          </InputLabel>
          <div
            className="flex max-md:flex-col w-full gap-x-12
          justify-between gap-y-4"
          >
            {/* composant pour sélectionner le type de montant:
            poucentage ou fixe */}
            <TypePromo
              active={active}
              setFix={setFix}
              setPercent={setPercent}
            />
            <Input
              type="number"
              id="valeur"
              autoComplete="off"
              placeholder={active === "fixe" ? "0.00€" : "0.00%"}
              className="w-full h-12"
              {...register("valeurpromo", { required: true })}
              min="0"
              max={active === "fixe" ? "Number.POSITIVE_INFINITY" : "100"}
            />
            {errors.valeurpromo ? (
              <InputError message={"Ce champ est obligatoire."} />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className="flex max-md:flex-col w-full gap-x-12
          gap-y-4 justify-between"
        >
          <div className="flex flex-col gap-y-3 w-full">
            <InputLabel htmlFor="date-depart" className="text-sm">
              Date de départ
            </InputLabel>

            {/* Composant pour sélectionner la date */}
            <InputDate
              extra="md:top-12 top-28"
              selectedDate={startedDate}
              setSelectedDate={setStartedDate}
            />
          </div>
          <div className="flex flex-col gap-y-3 w-full">
            <InputLabel htmlFor="heure-depart" className="text-sm">
              Heure de départ
            </InputLabel>

            {/* Composant pour sélectionner l'heure */}
            <CustomInputHour
              extra="md:top-6 top-36"
              selectedHour={startedHour}
              setSelectedHour={setStartedHour}
            />
          </div>
        </div>
        <div className="flex gap-x-3 items-center">
          <CustomCheckBox onChange={showEndDate} checked={endDate}/>
          <p className="text-sm font-normal text-main">Fixer une date de fin</p>
        </div>
        {endDate === true && (
          <div
            className="flex max-md:flex-col w-full gap-x-12
            gap-y-4 justify-between"
          >
            <div className="flex flex-col gap-y-3 w-full">
              <InputLabel htmlFor="date-fin" className="text-sm">
                Date de fin
              </InputLabel>
              <InputDate
                extra="md:top-48 top-[38%]"
                selectedDate={finishedDate}
                setSelectedDate={setFinishedDate}
              />
            </div>
            <div className="flex flex-col gap-y-3 w-full">
              <InputLabel htmlFor="heure-fin" className="text-sm">
                Heure de fin
              </InputLabel>
              <CustomInputHour
                extra="md:top-40 top-[42%]"
                selectedHour={finishedHour}
                setSelectedHour={setFinishedHour}
              />
            </div>
          </div>
        )}
        <div className="flex justify-end">
          <button
            className="bg-main text-white rounded-[48px] mt-4
              active:scale-95 transition-all duration-200 h-14 w-64"
            // onClick={(event) => {
            // }}
          >
            <p className="font-medium text-sm text-center">Valider</p>
          </button>
        </div>
      </form>
      {isOpen && (
        <BaseModal
          closeModal={() => {
            setIsOpen(false);
          }}
          purpose="Error"
        >
          {errorMsg}
        </BaseModal>
      )}
    </div>
  );
};

export default memo(UpdateCodePromo);
