import { useCallback } from 'react';
import { GoogleLogin } from 'react-google-login';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import google from "assets/socials/logo-google.svg";
import { useState, useEffect } from 'react';
import axios from 'axios';

const GoogleSignUp = ({updateParentState}) => {

  const [user, setUser] = useState([]);
  //const [profile, setProfile] = useState([]);

  const onGoogleSignInSuccess = useCallback((response) => {
    // Handle successful sign-in
  }, []);

  const onGoogleSignInFailure = useCallback((error) => {
    // Handle sign-in failure
    console.error(error);
  }, []);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            updateParentState(res.data);
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );

  const renderSignInButton = ({ onClick }) => (
    <button
      onClick={() => login()}
      className="flex sm:w-[452px] h-12 border
        rounded-[48px] items-center justify-center
        gap-x-4 w-full border-gray-300"
    >
      <img src={google} alt="google-logo" className="w-6 h-8" />
      <p className="text-main text-[14px]">Inscrivez-vous avec Google</p>
    </button>
  );

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      buttonText="Sign in with Google"
      onSuccess={onGoogleSignInSuccess}
      onFailure={onGoogleSignInFailure}
      cookiePolicy={'single_host_origin'}
      render={renderSignInButton}
    />
  );
};

export default GoogleSignUp;