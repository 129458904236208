import Container from "components/Container/Container";
import Card from "components/Card/Card";
import DisplayMessage from "components/DisplayMessage/DisplayMessage";
import ConversationContainer from "components/ConversationContainer/ConversationContainer";
import RightButton from "components/Buttons/RightButton";
import { useParams } from "react-router-dom";

// import { conversations } from "constants/Conversations";
import { useNavigate } from "react-router-dom";
import { GetMessageByIdService } from "services/Admin/Messagerie";
import { IoArrowBack } from "react-icons/io5";
import { useEffect, useState } from "react";

const BackDetailsMessages = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetMessageByIdService(params.messageId);
      if (response.status === 200) {
        console.log(response.data);
        setConversations(response.data);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }

    fetchData();
  }, [params]);

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Messagerie</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <RightButton
          icon={<IoArrowBack />}
          text="Retour"
          onClick={() => navigate("/admin/messagerie")}
        />
      </Container>
      <Card extra={"w-full pb-6 sm:overflow-x-auto mt-8 mb-4 !bg-transparent"}>
        <ConversationContainer
          sujet={conversations?.original?.analysis_selection}
          // lastConversation={conversations[conversations.length - 1]}
          lastConversation={conversations?.original}
          messageId={params.messageId}
          setConversations={setConversations}
        >
          {/* {conversations?.length ? conversations.map((conversation) => (
            <DisplayMessage key={conversation.id} {...conversation} />
          )) : <></>} */}
          <DisplayMessage {...conversations?.original}/>
          {conversations?.history?.length ? conversations?.history?.map((conversation) => (
            <DisplayMessage key={conversation.id} {...conversation} />
          )) : <></>}
        </ConversationContainer>
      </Card>
    </>
  );
};

export default BackDetailsMessages;
