import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});


// const user = JSON.parse(localStorage.getItem('user'));

const GetAllMessagesService = async () => {
  try {
    const response = await api.get(`messaging`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const GetMessageByIdService = async (id) => {
  try {
    const response = await api.get(`messaging/${id}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const SendNewMessageService = async (data) => {
  try {
    const response = await api.post(`messaging`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const SendReplyService = async (data, id) => {
  try {
    const response = await api.post(`messaging/${id}`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const DeleteMsgService = async (id) => {
  try {
    const response = await api.post(`message/delete`, id, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export {
  GetAllMessagesService,
  GetMessageByIdService,
  SendNewMessageService,
  SendReplyService,
  DeleteMsgService,
};