import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});


// const user = JSON.parse(localStorage.getItem('user'));

const GetUserDashboardData = async () => {
    try {
        const response = await api.get(`user/dashboard`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export {
    GetUserDashboardData,
};