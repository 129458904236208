import React from "react";

const CustomCheckBox = ({ onChange, checked, extra, disabled }) => {
  return (
    <>
      <input
        type="checkbox"
        className={`w-4 h-4 text-primary cursor-pointer
              focus:ring-0 rounded ${extra}`}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    </>
  );
};

export default CustomCheckBox;
