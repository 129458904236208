import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});



const GetAllMessages = async () => {
    try {
        const response = await api.get(`admin/messaging`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

const SendNewMessageService = async (data, id) => {
    try {
        const response = await api.post(`admin/send_message/${id}`, data, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

const GetMessageByIdService = async (id) => {
    try {
        const response = await api.get(`admin/messaging/${id}`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });

        return response;
    } catch (error) {
        return error;
    }
}

const SendReplyAdminService = async (data, id) => {
    try {
        const response = await api.post(`admin/messaging/${id}`, data, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

const DeleteMessageById = async (id) => {
    try {
        const response = await api.post(`admin/messaging/delete`, { "message_id": id }, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export {
    GetAllMessages,
    DeleteMessageById,
    GetMessageByIdService,
    SendReplyAdminService,
    SendNewMessageService
};