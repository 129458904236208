import Chart from "react-apexcharts";

const RadialComponent = ({ status, number, options, series, setCategory, statuse, category }) => {
  return (
    <div className="flex min-w-[130px] justify-center max-md:mr-20 max-sm:mr-0" onClick={(event) => {
      setCategory(statuse);
    }}>
      <div className="relative md:-left-1/4 -left-[20%]">
        <span
          className="absolute flex flex-col gap-y-2 bg-grayLight
          text-main rounded-[20px] max-xl:w-40 xl:w-52 md:h-[75px] p-4 justify-center
          md:top-[1.7rem] top-4 md:left-28 left-[7.5rem] shadow-md h-16"
        >
          {/* <p className="text-base font-medium text-right">{status}</p> */}
          <p className={`text-base ${statuse === category ? 'font-black text-purple-600' : 'font-medium'} text-right mr-[25px]`}>
            <span className="whitespace-normal break-all">
              {status}
            </span>
          </p>
          {/* <p className="font-medium text-xl text-right">{number}</p> */}
          <p className={`${statuse === category ? 'font-black text-2xl text-purple-600' : 'font-medium text-xl'} text-right mr-[41px]`}>
            <span className="whitespace-normal break-all">
              {number}
            </span>
          </p>
        </span>
        <div className="max-md:hidden">
          <Chart
            options={options}
            series={[series]}
            type="radialBar"
            height={130}
          />
        </div>
        <div className="md:hidden">
          <Chart
            options={options}
            series={[series]}
            type="radialBar"
            height={100}
          />
        </div>
      </div>
    </div>
  );
};

export default RadialComponent;
