import IconButton from "components/Buttons/IconButton";
import SidebarLinks from "components/SidebarLink/SidebarLinks";
import routes from "constants/routes";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { BiPlus } from "react-icons/bi";
import { ImAddressBook } from "react-icons/im";
import DownloadIconButton from "components/Buttons/DownloadIconButton";
import { saveAs } from "file-saver";

const Sidebar = ({ open, closeOnClick }) => {
  return (
    // <div
    //   className={`flex min-h-full fixed bg-white border-t border-t-gray200 z-40 transition-all duration-300 ease-linear ${
    //     open ? "translate-x-0" : "-translate-x-96"
    //   }`}
    // >
    <div
      className={`flex min-h-full fixed bg-white border-t border-t-gray200 z-40 transition-all duration-300 ease-linear w-[310px] ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <div className="border-r border-r-gray200 flex flex-col px-2 pt-7">
        <IconButton icon={<FcGoogle size={20} />} className="bg-white" />
        <IconButton
          icon={<FaFacebookF size={20} />}
          className="bg-[#3B5998] text-white opacity-20"
        />
        <IconButton
          icon={<FaInstagram size={20} />}
          className="bg-[#D43377] text-white opacity-20"
        />
        <IconButton
          icon={<FaTwitter size={20} />}
          className="bg-[#55ACEE] text-white opacity-20"
        />
        {/* <DownloadIconButton
          icon={<ImAddressBook size={20} />}
          className="bg-[#b155ee] text-white"
          onClick={(event) => {
            saveAs(
              process.env.REACT_APP_SERVER_URL +
                "static/terms/CGV-CGU-RGPD REEACT V2.pdf",
              "CGV-CGU-RGPD REEACT V2.pdf"
            );
          }}
        /> */}
        <IconButton icon={<BiPlus size={20} className="text-primary" />} />
      </div>

      {/* <ul className="pt-4"> */}
      <ul
        className={`pt-4`}
        style={{ maxHeight: window.innerHeight - 80, overflow: "auto" }}
      >
        <SidebarLinks routes={routes.front} closeOnClick={closeOnClick} />
        <a className="absolute bottom-20 text-gray-500 text-[12px] text-center">
          <p>©2023 Reeact.io by Reeact.io</p>
          <p>All Rights Reserved</p>
          <span
            className="hover:underline cursor-pointer"
            onClick={(event) => {
              window.open(
                process.env.REACT_APP_SERVER_URL +
                  "static/terms/CGV-CGU-RGPD REEACT V2.pdf"
              );
            }}
          >
            <p>Conditions générales de ventes et</p>
            <p>d’utilisation du logiciel</p>
          </span>
        </a>
      </ul>
    </div>
  );
};
export default Sidebar;
