import AdminInfo from "components/forms/Parametres Admin/AdminInfo";
import Container from "components/Container/Container";
import Card from "components/Card/Card";
import { useState, useEffect } from "react";
import { GetSettingInformation } from "services/Admin/Parametres";

const BackParametres = () => {

  const [userInfo, setUserInfo] = useState({});
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetSettingInformation();
      if (response?.status === 200) {
        setUserInfo(response?.data);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Paramètres</p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
      </Container>
      <Card extra="w-full p-3 mt-6 mb-8">
        <AdminInfo userInfo={userInfo} />
      </Card>
    </>
  );
};

export default BackParametres;
