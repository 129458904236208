import React from "react";

const AdminSearchSettingSection = ({ setUserTabActive, setInterventionActive, setFacturesTabActive, setCodePromoTabActive, setMessagerieTabActive,
  usersCount, interventionCount, codePromosCount, facutresCount, messagerieCount, active }) => {
  return (
    <div className="flex flex-col max-xl:w-full">
      <div className="flex rounded-[36.75px] items-center bg-white font-semibold max-sm:mt-2 max-xl:hidden">
        <div className="flex">
          <span
            onClick={setUserTabActive}
            className={`max-md:px-10 px-10 py-3 cursor-pointer text-secondary text-[11px] rounded-[36.75px] whitespace-normal break-all
       ${active === "clients" ? "bg-primary text-white" : "text-[#284F60]"}`}
          >
            Clients
          </span>
          {usersCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#ec4899] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{usersCount}</div>}
        </div>
        <div className="flex">
          <span
            onClick={setInterventionActive}
            className={`text-[11px] max-md:px-10 py-3 text-secondary rounded-[36.75px] cursor-pointer px-10 whitespace-normal break-all
      ${active === "interventions" ? "bg-primary text-white" : "text-[#284F60]"}`}
          >
            Interventions
          </span>
          {interventionCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#d946ef] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{interventionCount}</div>}
        </div>
        <div className="flex">
          <span
            onClick={setFacturesTabActive}
            className={`flex text-[11px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-10 py-3 whitespace-normal break-all
          ${active === "factures" ? "bg-primary  text-white" : "text-[#284F60]"}`}
          >
            Factures
          </span>
          {facutresCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#3b82f6] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{facutresCount}</div>}
        </div>
        <div className="flex">
          <span
            onClick={setCodePromoTabActive}
            className={`flex text-[11px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-10 py-3 whitespace-normal break-all
          ${active === "promo_code" ? "bg-primary  text-white" : "text-[#284F60]"}`}
          >
            Code Promos
          </span>
          {codePromosCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#14b8a6] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{codePromosCount}</div>}
        </div>
        <div className="flex">
          <span
            onClick={setMessagerieTabActive}
            className={`flex text-[11px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-10 py-3 whitespace-normal break-all
          ${active === "messagerie" ? "bg-primary  text-white" : "text-[#284F60]"}`}
          >
            Messagerie
          </span>
          {messagerieCount > 0 && <div className="relative inline-flex items-center justify-center w-7 h-7 text-xs font-bold text-white bg-[#ef4444] rounded-full -top-2 -right-[-15px] dark:border-gray-900">{messagerieCount}</div>}
        </div>
      </div>
      <div className="flex flex-col rounded-[36.75px] items-center bg-white font-semibold max-sm:mt-2 xl:hidden">
        <span
          onClick={setUserTabActive}
          className={`max-md:px-10 px-20 py-3 cursor-pointer text-secondary text-[11px] rounded-[36.75px] whitespace-normal break-all
       ${active === "clients" ? "bg-primary text-white" : "text-[#284F60]"}`}
        >
          Clients
        </span>
        <span
          onClick={setInterventionActive}
          className={`text-[11px] max-md:px-10 py-3 text-secondary rounded-[36.75px] cursor-pointer px-20 whitespace-normal break-all
      ${active === "interventions" ? "bg-primary text-white" : "text-[#284F60]"}`}
        >
          Intervention
        </span>
        <span
          onClick={setFacturesTabActive}
          className={`flex text-[11px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-20 py-3 whitespace-normal break-all
        ${active === "factures" ? "bg-primary  text-white" : "text-[#284F60]"}`}
        >
          Factures
        </span>
        <span
          onClick={setCodePromoTabActive}
          className={`flex text-[11px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-20 py-3 whitespace-normal break-all
        ${active === "promo_code" ? "bg-primary  text-white" : "text-[#284F60]"}`}
        >
          Code Promos
        </span>
        <span
          onClick={setMessagerieTabActive}
          className={`flex text-[11px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-20 py-3 whitespace-normal break-all
        ${active === "messagerie" ? "bg-primary  text-white" : "text-[#284F60]"}`}
        >
          Messagerie
        </span>
      </div>
    </div>
  );
};

export default AdminSearchSettingSection;
