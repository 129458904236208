import { useState } from "react";

import { useNavigate } from "react-router-dom";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import { BsCheckCircleFill } from "react-icons/bs";

import stripe from "assets/card/stripe logo.svg";
import cards from "assets/card/all.png";
import { useForm } from "react-hook-form";

const RegisterSteps = ({ profile }) => {
  const [page, setPage] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [selected, setSelected] = useState("Particulier");
  const [inputError, setInputError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryName, setCountryName] = useState("FRANCE");
  const [countryCode, setCountryCode] = useState("FR");
  const [province, setProvince] = useState("");
  const [paymentId, setPaymentId] = useState("");

  const navigate = useNavigate();

  const backToPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const nextToPage = () => {
    setPage(page + 1);
  };

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "Particulier",
      first_name: profile.firstname,
      last_name: profile.lastname,
      email: profile.email,
      vat_number: "FR",
    },
  });
  const {
    register: register1,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    getValues: getValues2,
  } = useForm({});
  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return (
          <Step1
            register={register}
            watch={watch}
            profile={profile}
            handleSubmit={handleSubmit}
            errors={errors}
            nextToPage={nextToPage}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            countryName={countryName}
            countryCode={countryCode}
            phoneNumber={phoneNumber}
            province={province}
            setCountryName={setCountryName}
            setCountryCode={setCountryCode}
            setPhoneNumber={setPhoneNumber}
            setProvince={setProvince}
            selected={selected}
            setSelected={setSelected}
          />
        );
      // case 1:
      //   return (
      //     <Step2
      //       register={register1}
      //       errors={errors2}
      //       promoCode={promoCode}
      //       setPromoCode={setPromoCode}
      //       inputError={inputError}
      //       page={page}
      //       backToPage={backToPage}
      //       handleSubmit={handleSubmit2}
      //       getValues={getValues}
      //       getValues2={getValues2}
      //       profile={profile}
      //       selectedFiles={selectedFiles}
      //       nextToPage={nextToPage}
      //       setPaymentId={setPaymentId}
      //     />
      //   );
      case 1:
        return (
          <Step3
            register={register1}
            promoCode={promoCode}
            page={page}
            backToPage={backToPage}
            getValues={getValues}
            getValues2={getValues2}
            profile={profile}
            selectedFiles={selectedFiles}
            countryName={countryName}
            countryCode={countryCode}
            phoneNumber={phoneNumber}
            province={province}
            selected={selected}
            paymentId={paymentId}
          />
        );
      default:
        return null;
    }
  };

  return (
    // <div
    //   className="flex flex-col bg-white rounded-[20px] md:px-24
    //   pt-8 z-10 w-full px-6 mb-16 shadow-lg"
    // >
    <div
      className="flex flex-col bg-white rounded-[20px] md:px-12 pt-8 z-10 sm:w-[80%] px-6 mb-16 shadow-lg max-sm:w-full"
    >
      <h2 className="text-main font-semibold md:text-[32px] text-2xl">
        Votre inscription Reeact
      </h2>
      {/* <div className="flex items-center mt-8 mb-2 gap-x-2"> */}
      <div className="flex items-center mt-8 mb-2 gap-x-2 justify-center">
        {/* <p className="text-main font-medium md:text-xl text-sm">Register</p> */}
        <p className="text-main font-medium md:text-xl text-sm">
          <span className="whitespace-normal break-all text-black">
            Register
          </span>
        </p>
        <div className="flex md:gap-x-2 gap-x-1 items-center">
          <div className="bg-main md:w-[25px] w-3 h-px" />
          <BsCheckCircleFill size={20} />
          <div className="bg-main md:w-[25px] w-3 h-px" />
        </div>
        {/* <p className="text-main font-medium md:text-xl text-sm">Informations</p> */}
        <p className="text-main font-medium md:text-xl text-sm">
          <span className="whitespace-normal break-all text-black">
            Informations
          </span>
        </p>
        {/* <div className="flex md:gap-x-2 gap-x-1 items-center">
          <div className="bg-main md:w-[25px] w-3 h-px" />
          <BsCheckCircleFill
            size={20}
            className={`${page < 1 ? "text-gray-400" : "text-black"}`}
          />
          <div className="bg-main md:w-[25px] w-3 h-px" />
        </div> */}
        {/* <p
          className={`text-main font-medium md:text-xl text-sm ${page === 0 ? "text-gray-400" : ""
            }`}
        >
          Paiement
        </p> */}
        {/* <p className={`text-main font-medium md:text-xl text-sm`}>
          <span
            className={`whitespace-normal break-all ${
              page < 2 ? "text-gray-400" : "text-black"
            }`}
          >
            Vérifier
          </span>
        </p> */}
        {/* <div className="flex md:gap-x-2 gap-x-1 items-center">
          <div className="bg-main md:w-[25px] w-3 h-px" />
          <BsCheckCircleFill
            size={20}
            className={`${page < 2 ? "text-gray-400" : "text-black"}`}
          />
          <div className="bg-main md:w-[25px] w-3 h-px" />
        </div>
        <p
          className={`text-main font-medium md:text-xl text-sm ${
            page === 2 ? "text-gray-400" : "text-black"
          }`}
        >
          <span className="whitespace-normal break-all">Vérifier</span>
        </p> */}
      </div>
      {conditionalComponent()}
      {/* <div className="flex justify-end mt-12 mb-6">
        {page === 1 ?
          <div className="flex gap-x-3 items-center">
            <StepsButton
              onClick={backToPage}
              extra="bg-gray100"
              textColor="text-main"
            >
              Retour
            </StepsButton>
            <StepsButton
              type="submit"
              onClick={() => {
                //page === 0 ? handleSubmit() : nextToPage();
                // page === 0 ? nextToPage() : handleSubmit();
                // handleSubmit();
                handleSubmitUltimate();
              }}
              extra="bg-main"
              textColor="text-white"
            >
              Valider
            </StepsButton>
          </div> : <></>
        }
      </div> */}
      {page === 1 && (
        <div className="flex justify-end gap-x-6 mb-8">
          <img src={stripe} alt="Stripe" />
          <img src={cards} alt="Cartes" className="mr-4" />
        </div>
      )}
    </div>
  );
};

export default RegisterSteps;
