import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

const GetClients = async () => {
  try {
    const response = await api.get(`admin/users`, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const GetClientsByType = async (type) => {
  try {
    const response = await api.get(`admin/users/${type}`, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetClientDetailInformation = async (clientId) => {
  try {
    const response = await api.get(`admin/user/${clientId}`, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const AddClient = async (payload) => {
  try {
    const response = await api.post(
      `admin/users`,
      { user_data: payload },
      {
        headers: {
          authorization:
            localStorage.getItem("jwt_type") +
            " " +
            localStorage.getItem("jwt"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
const ManageUserStatus = async (payload) => {
  try {
    const response = await api.post(`admin/activate_user`, payload, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export {
  GetClients,
  GetClientDetailInformation,
  AddClient,
  GetClientsByType,
  ManageUserStatus,
};
