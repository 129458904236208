import Input from "components/Input/Input";
import HorizontalInputLayout from "./HorizontalInputLayout";
import { memo } from "react";
import { useForm } from "react-hook-form";
import InputLabel from "components/InputLabel/InputLabel";
import InputError from "components/InputError/InputError";
import { AddNewCard } from "services/Parametres";
import { useNavigate } from "react-router-dom";
import { CreatePaymentMethod } from "services/Parametres";
import { useState } from "react";
import BaseModal from "components/Modals/BaseModal";

const KeywordNewCard = ({ setIsAddSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = async (data) => {
    document.getElementById("prewaiting").removeAttribute("hidden");

    const expiration_date = data.expiration_date.split("/");
    const cardData = {
      type: "card",
      "card[number]": data.card_number,
      "card[exp_month]": expiration_date[0],
      "card[exp_year]": expiration_date[1],
      "card[cvc]": data.cvc,
    };
    const paymentResponse = await CreatePaymentMethod(cardData);
    if (paymentResponse?.status === 200) {
      data["pm_id"] = paymentResponse?.data?.id;
      const response = await AddNewCard({
        payment_data: data,
      });
      if (response?.status === 200) {
        setIsAddSuccess((state) => !state);
      }
    } else {
      setIsOpen(true);
      switch (paymentResponse?.response?.data?.error?.code) {
        case "card_declined":
          setErrorMsg("Votre carte a été refusée.");
          break;
        case "expired_card":
          setErrorMsg(
            "La carte a expiré. Vérifiez la date d'expiration ou utilisez une autre carte."
          );
          break;
        case "incorrect_cvc":
          setErrorMsg(
            "Le code de sécurité de la carte est incorrect. Vérifiez le code de sécurité de la carte ou utilisez une autre carte."
          );
          break;
        case "processing_error":
          setErrorMsg(
            "Une erreur s'est produite lors du traitement de la carte. Réessayez plus tard ou avec un autre mode de paiement."
          );
          break;
        case "incorrect_number":
          setErrorMsg(
            "Le numéro de carte est incorrect. Vérifiez le numéro de la carte ou utilisez une autre carte."
          );
          break;
        case "invalid_expiry_year":
          setErrorMsg("L'année d'expiration de votre carte n'est pas valide.");
        default:
          break;
      }
    }
    document.getElementById("prewaiting").setAttribute("hidden", "true");
  };

  return (
    <form
      className="flex flex-col gap-y-3 mt-4"
      autoComplete="on"
      onSubmit={handleSubmit(onSubmit)}
    >
      <HorizontalInputLayout>
        {/* <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
          <InputLabel htmlFor="carte">Numéro de carte</InputLabel>
          <Input
            id="carte"
            autoComplete="off"
            placeholder="0000000000000000"
            pattern="[0-9]{16}"
            maxLength={16}
            {...register("card_number", { required: true })}
            className="w-full"
          />
          {errors?.card_number ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div> */}
        <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
          <InputLabel htmlFor="carte">Numéro de carte</InputLabel>
          <Input
            id="carte"
            autoComplete="off"
            placeholder="0000 0000 0000 0000"
            {...register("card_number", { required: true })}
            // pattern="[0-9]{16}"
            // pattern="\d{4} \d{4} \d{4} \d{4}"
            onInput={(event) => {
              let input = document.getElementById("carte");
              let inputValue = input.value;

              // Remove all non-digit characters from the input
              inputValue = inputValue.replace(/\D/g, "");

              // Insert a space after every 4 digits
              inputValue = inputValue.replace(/(\d{4})(?=\d)/g, "$1 ");

              // Set the formatted value back to the input
              input.value = inputValue;
            }}
            maxLength={19}
            className="w-full"
            type="text"
          />
          {errors?.card_number ? (
            <InputError message="Le numéro de carte doit être saisi." />
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
          <InputLabel htmlFor="titulaire">Nom du titulaire</InputLabel>
          <Input
            id="titulaire"
            autoComplete="off"
            placeholder="Nom du titulaire"
            {...register("card_holdername", { required: true })}
            className="w-full"
          />
          {errors?.card_holdername ? (
            <InputError message={"Ce champ est obligatoire."} />
          ) : (
            <></>
          )}
        </div>
      </HorizontalInputLayout>
      <HorizontalInputLayout>
        {/* <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
          <InputLabel htmlFor="expiration">Date d'expiration</InputLabel>
          <Input
            id="expiration"
            autoComplete="off"
            placeholder="MM/AA"
            pattern="(0[1-9]|1[0-2])\/\d{2}"
            {...register("expiration_date", { required: true })}
            className="w-full"
          />
          {errors?.expiration_date ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div> */}
        <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
          <InputLabel htmlFor="expiration">Date d'expiration</InputLabel>
          <Input
            id="expiration"
            autoComplete="off"
            placeholder="MM/AA"
            // pattern="(0[1-9]|1[0-2])\/\d{2}"
            {...register("expiration_date", { required: true })}
            className="w-full"
            maxLength={5}
            onInput={() => {
              let expiration = document.getElementById("expiration").value;
              expiration = expiration.replace(/^(\d{2})\/?(\d{2})$/, "$1/$2");
              document.getElementById("expiration").value = expiration;
            }}
          />
          {errors?.expiration_date ? (
            <InputError message="La date d'expiration doit être renseignée." />
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
          <InputLabel htmlFor="cvc">CVC</InputLabel>
          <Input
            id="cvc"
            autoComplete="off"
            placeholder="xxx"
            pattern="[0-9]{3}"
            extra="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            maxLength={3}
            {...register("cvc", { required: true })}
            className="w-full"
          />
          {errors?.cvc ? (
            <InputError message={"Ce champ est obligatoire."} />
          ) : (
            <></>
          )}
        </div>
      </HorizontalInputLayout>
      <div className="flex justify-center sm:justify-end mt-4">
        <button className="bg-primary text-white rounded-[100px] px-10 py-2 text-xs w-48 h-10">
          <p className="font-medium text-sm text-center">Valider</p>
        </button>
      </div>
      {isOpen && (
        <BaseModal
          closeModal={() => {
            setIsOpen(false);
          }}
          purpose="Error"
        >
          <div
            className={
              "flex flex-row rounded-[20px] bg-[#DB5461] w-full h-109 p-6"
            }
          >
            <div className="mr-5">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1718_9621)">
                  <path
                    d="M14.668 9.33329H17.3346V12H14.668V9.33329ZM14.668 14.6666H17.3346V22.6666H14.668V14.6666ZM16.0013 2.66663C8.6413 2.66663 2.66797 8.63996 2.66797 16C2.66797 23.36 8.6413 29.3333 16.0013 29.3333C23.3613 29.3333 29.3346 23.36 29.3346 16C29.3346 8.63996 23.3613 2.66663 16.0013 2.66663ZM16.0013 26.6666C10.1213 26.6666 5.33464 21.88 5.33464 16C5.33464 10.12 10.1213 5.33329 16.0013 5.33329C21.8813 5.33329 26.668 10.12 26.668 16C26.668 21.88 21.8813 26.6666 16.0013 26.6666Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1718_9621">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="flex flex-col gap-y-3 text-white">
              <div>Votre paiement a échoué !</div>
              <div>Veuillez réessayer ou utiliser une nouvelle carte.</div>
            </div>
          </div>
        </BaseModal>
      )}
    </form>
  );
};

export default memo(KeywordNewCard);
