import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { SendVerifyCode } from "services/UserAuthentication";

const CountDownTimer = ({ totalSec, email }) => {
  const countRef = useRef("");
  const [restart, setRestart] = useState(true);
  const [timee, setTime] = useState(Date.now() + totalSec);

  const resendVerificationCode = async (e, apiii) => {
    e.preventDefault();
    setTime(Date.now() + totalSec);
    // apiii.start();
    document.getElementById("prewaiting").removeAttribute("hidden");
    const response = await SendVerifyCode(email);
    if (response?.status === 200) {
    } else {
    }
    document.getElementById("prewaiting").setAttribute("hidden", "true");
  };

  const renderer = ({ hours, minutes, seconds, completed, api }) => {
    if (completed) {
      return (
        <div>
          <span onClick={(e) => resendVerificationCode(e, api)} className="hover:cursor-pointer text-[20px] text-white border-[1px] rounded-xl p-2 bg-red">Resend</span>
        </div>
      );
    } else {
      return (
        <div>
          <span>{minutes > 9 ? minutes : `0 ${minutes}`}</span>
          <span>{" : "}</span>
          <span>{seconds > 9 ? seconds : `0 ${seconds}`}</span>
        </div>
      );
    }
  };

  useEffect(() => {
    if (restart) {
      countRef?.current?.start();
    }
  }, [restart, timee]);

  return (
    <Countdown
      date={timee}
      renderer={renderer}
      autoStart={false}
      ref={countRef}
    ></Countdown>
  );
};

export default CountDownTimer;
