import Container from "components/Container/Container";
import Card from "components/Card/Card";
import ReputationScore from "components/ReputationScore/ReputationScore";
import DebitCard from "components/DebitCard/DebitCard";
import LineChart from "components/Charts/Dashboard/LineChart";
import AreaChart from "components/Charts/Dashboard/AreaChart";
import CustomColumnChart from "components/Charts/Dashboard/ColumnChart";
import RadialChart from "components/Charts/Dashboard/RadialChart";
import CustomDisplayInfo from "components/DisplayChartInfo/CustomDisplayInfo";
import DatePicker from "components/DatePicker/DatePicker";

import { dataInterventions } from "constants/interventions";
import { BsThreeDots } from "react-icons/bs";
import TransferInput from "components/TransferInput/TransferInput";
import graph0 from "assets/svg/graph0.svg";
import graph1 from "assets/svg/graph1.svg";
import graph2 from "assets/svg/graph2.svg";
import graph3 from "assets/svg/graph3.svg";
import graph4 from "assets/svg/graph4.svg";
import ProgressBar from "components/ProgressBar/ProgressBar";
import InterventionPreview from "components/Dashboard/InterventionPreview";
import {
  areachart,
  columnchart,
  fourthRadialBar,
  linechart1,
  linechart2,
  radialbar,
} from "constants/charts/Dashboard/config";
import { infos } from "constants/radialChartInfo";
import { useEffect, useState } from "react";
import { GetUserDashboardData } from "services/Dashboard";
import { useNavigate } from "react-router-dom";
import MenuDropdown from "components/Dropdowns/MenuDropdown";
import {
  MenuDropdownButton,
  CustomMenuDropdown,
} from "components/Dropdowns/MenuDropdown";
import { DeleteCard } from "services/Parametres";
import { BsThreeDotsVertical } from "react-icons/bs";
import DashboardColumnChart from "components/Charts/Dashboard/DashboardColumnChart";
import DashboardLineChart from "components/Charts/Dashboard/DashboardLineChart";
import DashboardAreaChart from "components/Charts/Dashboard/DashboardAreaChart";
import CustomDashboardLineChart from "components/Charts/Dashboard/CustomDashboardLineChart";

const Dashboard = () => {
  //Effectuer les requêtes pour récupérer les données des graphes et ls
  //remplacer par les valeurs statiques des series, en respectant le même format

  //const interventions = dataInterventions.slice(0, 5);
  const user = JSON.parse(localStorage.getItem("user"));

  const [interventions, setInterventions] = useState({});
  const [dashboard, setDashboard] = useState({});
  const [cardInfo, setCardInfo] = useState({});
  const [lastMonthCheck, setLastMonthCheck] = useState(true);
  const [thisMonthCheck, setThisMonthCheck] = useState(true);
  const [positiveArray, setPositiveArray] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [generalArray, setGeneralArray] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetUserDashboardData();

      if (response?.status === 200) {
        setDashboard(response?.data);
        setCardInfo(response?.data?.latest_card_data);
        setInterventions(response?.data?.inter_req_data);

        var array = new Array(6 - response?.data?.cron_history?.length);
        array.fill(0);
        response?.data?.cron_history?.map((value, index) => {
          array.push(
            parseInt(
              ((response?.data?.cron_history?.[index]?.positive_search_result ??
                0) *
                100.0) /
                (response?.data?.cron_history?.[index]?.total_search_result ??
                  1)
            )
          );
        });
        setPositiveArray(array);

        array = new Array(6 - response?.data?.cron_history?.length);
        array.fill(0);
        response?.data?.cron_history?.map((value, index) => {
          array.push(
            parseInt(
              ((response?.data?.cron_history?.[index]?.negative_search_result ??
                0) *
                100.0) /
                (response?.data?.cron_history?.[index]?.total_search_result ??
                  1)
            )
          );
        });
        setNegativeArray(array);

        array = new Array(6 - response?.data?.cron_history?.length);
        array.fill(0);
        response?.data?.cron_history?.map((value, index) => {
          array.push(
            parseInt(
              ((response?.data?.cron_history?.[index]?.total_search_result -
                response?.data?.cron_history?.[index]?.negative_search_result ??
                0) *
                100.0) /
                (response?.data?.cron_history?.[index]?.total_search_result ??
                  1)
            )
          );
        });
        setGeneralArray(array);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, []);

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">
            Bonjour {user.full_name}
          </p>
          <p className="text-secondary hidden text-sm md:block font-normal">
            Ravie de vous revoir !
          </p>
        </div>
        <div className="flex justify-center">
          <DatePicker />
        </div>
      </Container>

      {/* Les graphes */}
      <Card extra={"w-full pb-6 mt-8 !bg-transparent rounded-none "}>
        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-x-8 gap-y-8">
          <ReputationScore extra="bg-main text-white">
            <p className="text-white font-semibold text-base">
              Score de réputation
            </p>
            <div className="flex w-full justify-between">
              {/* <p className="text-white font-medium text-[32px]">{dashboard?.reputation_score?.positive_count ?? 0}/{dashboard?.reputation_score?.total_count ?? 0}</p> */}
              <p className="text-white font-medium text-[32px]">
                {dashboard?.reputation_score?.reputation_score ?? 0}/100
              </p>
              <span className="flex items-center gap-x-3">
                <span>
                  <img src={graph4} alt="graph-4" className="w-full h-full" />
                </span>
                {/* <p className="font-normal text-white text-base">4%</p> */}
                <p className="font-normal text-white text-base">
                  {(
                    (dashboard?.reputation_score?.positive_count * 100.0) /
                    (dashboard?.reputation_score?.total_count === 0
                      ? 1
                      : dashboard?.reputation_score?.total_count)
                  ).toFixed(0) ?? 0}{" "}
                  %
                </p>
              </span>
            </div>
            <ProgressBar
              percentage={dashboard?.reputation_score?.reputation_score}
            />
            {/* <p className="font-medium md:text-lg text-sm text-white60">
              Lorem ipsum doler sit amet. Lorem ipsum doler sit amet. Lorem
              ipsum doler sit amet
            </p> */}
            <p className="font-medium md:text-lg text-sm text-white60">
              Votre score de réputation est un indicateur précieux et représente
              une vision globale de votre présence. Améliorez ce score en
              augmentant vos résultats positifs
            </p>
            <div className="flex justify-end">
              <span
                className="cursor-pointer font-medium hover:underline"
                onClick={(event) => {
                  navigate("/analyse");
                }}
              >
                Voir plus
              </span>
            </div>
          </ReputationScore>
          <div className="flex flex-col gap-y-4 max-xl:mt-12">
            <div
              className="grid grid-cols-2 max-md:grid-cols-1
              max-md:gap-y-4 gap-x-4 justify-between"
            >
              {/* <DashboardLineChart
                title="Positif"
                amount={dashboard?.reputation_score?.positive_count}
                percentage={Math.abs(dashboard?.cron_score?.positive_raisen)}
                imgUrl={dashboard?.cron_score?.positive_raisen >= 0 ? graph : graph2}
                options={linechart1.options}
                type="line"
                series={[
                  {
                    name: "Clicks",
                    data: [500, 418, 600, 456, 526, 356, 456],
                    color: "#284F60",
                  },
                ]}
              /> */}
              <CustomDashboardLineChart
                title="Positif"
                amount={dashboard?.reputation_score?.positive_count}
                percentage={Math.abs(dashboard?.cron_score?.positive_raisen)}
                imgUrl={
                  dashboard?.cron_score?.positive_raisen >= 0 ? graph0 : graph1
                }
                options={linechart1.options}
                type="line"
                series={[
                  {
                    name: "Clicks",
                    data: positiveArray,
                    color: "#284F60",
                  },
                ]}
              />
              {/* <DashboardLineChart
                // title="Title"
                title="Négative"
                amount={dashboard?.reputation_score?.negative_count}
                percentage={Math.abs(dashboard?.cron_score?.negative_raisen)}
                imgUrl={dashboard?.cron_score?.negative_raisen >= 0 ? graph : graph2}
                options={linechart2.options}
                series={[
                  {
                    name: "Clicks",
                    data: [400, 48, 540, 210, 600, 56, 526, 56, 256, 200],
                    color: "#284F60",
                  },
                ]}
              /> */}
              <CustomDashboardLineChart
                // title="Title"
                title="Négative"
                amount={dashboard?.reputation_score?.negative_count}
                percentage={Math.abs(dashboard?.cron_score?.negative_raisen)}
                imgUrl={
                  dashboard?.cron_score?.negative_raisen >= 0 ? graph2 : graph3
                }
                options={linechart2.options}
                series={[
                  {
                    name: "Clicks",
                    data: negativeArray,
                    color: "#284F60",
                  },
                ]}
              />
            </div>

            <CustomDashboardLineChart
              // title="Weekly Wallet Usage"
              title="General"
              amount={`${
                dashboard?.reputation_score?.total_count -
                  dashboard?.reputation_score?.negative_count ?? 0
              }`}
              imgUrl={
                dashboard?.cron_score?.general_raisen >= 0 ? graph0 : graph1
              }
              options={areachart.options}
              percentage={dashboard?.cron_score?.general_raisen}
              series={[
                {
                  name: "Clicks",
                  // data: [400, 48, 540, 210, 600, 56, 526, 56, 256, 200],
                  data: generalArray,
                },
              ]}
            />
          </div>

          {/* Diagramme en baton  */}
          <DashboardColumnChart
            options={columnchart.options}
            lastMonthCheck={lastMonthCheck}
            thisMonthCheck={thisMonthCheck}
            setLastMonthCheck={setLastMonthCheck}
            setThisMonthCheck={setThisMonthCheck}
            series={[
              {
                name: "Ce mois-ci",
                color: "#284F60",
                data: lastMonthCheck
                  ? [
                      { x: "Week 1", y: dashboard?.weekly_wallet_useage?.[0] },
                      { x: "Week 2", y: dashboard?.weekly_wallet_useage?.[1] },
                      { x: "Week 3", y: dashboard?.weekly_wallet_useage?.[2] },
                      { x: "Week 4", y: dashboard?.weekly_wallet_useage?.[3] },
                      { x: "Week 5", y: dashboard?.weekly_wallet_useage?.[4] },
                    ]
                  : [
                      { x: "Week 1", y: 0 },
                      { x: "Week 2", y: 0 },
                      { x: "Week 3", y: 0 },
                      { x: "Week 4", y: 0 },
                      { x: "Week 5", y: 0 },
                    ],
              },
              {
                name: "Le mois dernier",
                color: "rgba(39, 79, 96, 0.55)",
                data: thisMonthCheck
                  ? [
                      {
                        x: "Week 1",
                        y: dashboard?.pre_weekly_wallet_useage?.[0],
                      },
                      {
                        x: "Week 2",
                        y: dashboard?.pre_weekly_wallet_useage?.[1],
                      },
                      {
                        x: "Week 3",
                        y: dashboard?.pre_weekly_wallet_useage?.[2],
                      },
                      {
                        x: "Week 4",
                        y: dashboard?.pre_weekly_wallet_useage?.[3],
                      },
                      {
                        x: "Week 5",
                        y: dashboard?.pre_weekly_wallet_useage?.[4],
                      },
                    ]
                  : [
                      { x: "Week 1", y: 0 },
                      { x: "Week 2", y: 0 },
                      { x: "Week 3", y: 0 },
                      { x: "Week 4", y: 0 },
                      { x: "Week 5", y: 0 },
                    ],
              },
            ]}
          />

          {/* Diagrammes circulaire  */}
          <div
            className="bg-white rounded-[20px] p-4
            w-full flex md:items-center shadow-md
            max-md:flex-col gap-y-6 justify-center"
          >
            <div
              className="flex flex-col md:gap-y-12
              gap-y-6 md:w-1/4 md:items-center
              max-md:px-8"
            >
              <span>
                <p
                  className="text-main text-lg
                font-medium md:mr-8"
                >
                  Catégorie
                </p>
              </span>
              <div className="flex flex-col gap-y-8">
                {/* {infos.map((info) => (
                  <DisplayInfo
                    key={info.id}
                    bgColor={info.bgColor}
                    price={info.amount}
                    text={info.text}
                  />
                ))} */}
                <CustomDisplayInfo
                  bgColor="bg-[#1EB6E7]"
                  price={dashboard?.reputation_score?.reputation_score ?? 0}
                  text="Général"
                />
                <CustomDisplayInfo
                  bgColor="bg-[#40D4A8]"
                  price={dashboard?.reputation_score?.positive_count ?? 0}
                  text="Positif"
                />
                <CustomDisplayInfo
                  bgColor="bg-main"
                  price={
                    dashboard?.reputation_score?.total_count -
                      dashboard?.reputation_score?.negative_count -
                      dashboard?.reputation_score?.positive_count ?? 0
                  }
                  text="Neutre"
                />
                <CustomDisplayInfo
                  bgColor="bg-[#DB5461]"
                  price={dashboard?.reputation_score?.negative_count ?? 0}
                  text="Négatif"
                />
              </div>
            </div>
            <div
              className="grid md:grid-cols-2
              grid-cols-1 gap-y-6 gap-x-6"
            >
              <RadialChart
                options={fourthRadialBar.options}
                series={dashboard?.reputation_score?.reputation_score ?? 0}
                bgColor="bg-white"
                extra="border border-[#1EB6E7]"
                textColor="text-main"
                legend="Général"
              />
              <RadialChart
                options={radialbar.options}
                series={(
                  (dashboard?.reputation_score?.positive_count /
                    (dashboard?.reputation_score?.total_count === 0
                      ? 1
                      : dashboard?.reputation_score?.total_count)) *
                  100
                ).toFixed(2)}
                bgColor="bg-[#40D4A8]"
                textColor="text-white"
                legend="Positif"
              />
              <RadialChart
                options={radialbar.options}
                series={(
                  ((dashboard?.reputation_score?.total_count -
                    dashboard?.reputation_score?.negative_count -
                    dashboard?.reputation_score?.positive_count) /
                    (dashboard?.reputation_score?.total_count === 0
                      ? 1
                      : dashboard?.reputation_score?.total_count)) *
                  100
                ).toFixed(2)}
                bgColor="bg-main"
                textColor="text-white"
                legend="Neutre"
              />
              <RadialChart
                options={radialbar.options}
                series={(
                  (dashboard?.reputation_score?.negative_count /
                    (dashboard?.reputation_score?.total_count === 0
                      ? 1
                      : dashboard?.reputation_score?.total_count)) *
                  100
                ).toFixed(2)}
                bgColor="bg-[#DB5461]"
                textColor="text-white"
                legend="Négatif"
              />
            </div>
          </div>

          {interventions?.length >= 0 && (
            <InterventionPreview data={interventions} />
          )}

          {/* Carte de crédit  */}
          {/* <div
            className="bg-white rounded-[20px] p-8
            w-full flex flex-col md:justify-between
            shadow-md gap-y-4"
          > */}
          <div
            className="bg-white rounded-[20px] p-8
            w-full flex flex-col shadow-md gap-y-4"
          >
            <div
              className="flex justify-between items-center
              w-full"
            >
              <div className="flex flex-col gap-y-2">
                <p className="font-medium text-sm text-main">
                  Votre carte de crédit active
                </p>
                {/* <p className="font-normal text-[11px] text-secondary">
                  Lorem ipsum dolor sit amet, consectetur
                </p> */}
              </div>
              <div className="flex items-center gap-x-2">
                {/* <BsThreeDots /> */}
                <CustomMenuDropdown
                  trigger={
                    <button className="active:scale-95 transition-all">
                      <BsThreeDotsVertical size={18} />
                    </button>
                  }
                  menu={[
                    <MenuDropdownButton
                      text="Changer de carte"
                      onClick={async (event) => {
                        // document.getElementById("prewaiting").removeAttribute("hidden");
                        // const response = await DeleteCard({ "card_id": parseInt(selected) });
                        // if (response?.status === 200) {
                        //   window.location.href = "/parametres";
                        // }
                        // document.getElementById("prewaiting").setAttribute("hidden", "true");
                        navigate("/parametres");
                      }}
                    />,
                    <MenuDropdownButton
                      text="Supprimer la carte"
                      onClick={async (event) => {
                        if (cardInfo?.id === null) return;
                        document
                          .getElementById("prewaiting")
                          .removeAttribute("hidden");
                        const response = await DeleteCard({
                          card_id: parseInt(cardInfo?.id),
                        });
                        if (response?.status === 200) {
                        }
                        document
                          .getElementById("prewaiting")
                          .setAttribute("hidden", "true");
                      }}
                    />,
                  ]}
                />
              </div>
            </div>
            <br />
            <div>
              {cardInfo?.id && (
                <DebitCard
                  cardInfo={cardInfo}
                  weekly_wallet_useage={dashboard?.weekly_wallet_useage?.reduce(
                    (sum, value) => {
                      return sum + value;
                    }
                  )}
                />
              )}
            </div>
            <div hidden>
              <TransferInput />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Dashboard;
