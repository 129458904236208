import RightButton from "components/Buttons/RightButton";
import Card from "components/Card/Card";
import Container from "components/Container/Container";
import Spacer from "components/Spacer/Spacer";
import { HiDownload } from "react-icons/hi";
import { MdInfoOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import stripe from "assets/card/stripe logo.svg";
import cards from "assets/card/all.png";
import card from "assets/card/card.svg";
import visa from "assets/card/visa_logo.svg";
import { DevisModal } from "components/Modals/Modals";
import { useParams } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";
import { GetInvoiceByIdService, PayForInvoice } from "services/Invoice";
import BaseModal from "components/Modals/BaseModal";

const DevisInterventions = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [invoice, setInvoice] = useState(null);
  const [cardData, setCardData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const paiement = useCallback(async () => {
    document.getElementById("prewaiting").removeAttribute("hidden");
    const response = await PayForInvoice(parseInt(params.interventionId));
    document.getElementById("prewaiting").setAttribute("hidden", "true");
    if (response?.status === 200) {
      return navigate("/demande-dinterventions", { replace: true });
    } else {
      setIsOpen(true);
    }
  }, [navigate]);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const calcTotalPrice = (invoice) => {
    let totalPrice = 0;
    invoice?.invoice_detail?.invoice_detail.forEach((item) => {
      totalPrice += item.price * item.count;
    });
    return totalPrice;
  };

  useEffect(() => {
    const fetchData = async (id) => {
      const response = await GetInvoiceByIdService(id);

      if (response?.status === 200) {
        setInvoice(response?.data?.quote);
        setCardData(response?.data?.card_data);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
    };

    fetchData(params.interventionId);
  }, [params]);

  return (
    invoice && (
      <>
        <Container>
          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-2xl text-main">
              Votre devis d'interventions
            </p>
            {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
          </div>
          <RightButton
            icon={<HiDownload />}
            text="Télécharger votre devis"
            onClick={(event) => {
              if (invoice?.pdf_url === null) return;
              window.open(
                process.env.REACT_APP_SERVER_URL + invoice?.pdf_url,
                "_blank"
              );
            }}
          />
        </Container>
        <Card extra="w-full p-3 sm:p-10 mt-8 mb-8">
          <div className="text-sm mb-3">ID Devis</div>
          <div className="grid grid-cols-2 sm:grid-cols-4 2xl:grid-cols-5 text-sm place-content-between">
            <div className="font-semibold text-2xl mb-8">
              #{params.interventionId}
            </div>
            <Spacer />
            <div className="text-sm font-semibold pt-2 mb-9">
              {invoice?.date ?? ""}
            </div>

            <div className="font-semibold text-sm mb-2">Bill From:</div>
            <Spacer />
            <div className="font-semibold text-sm mb-2">Bill To:</div>

            <div className="mb-2">{invoice?.bill_from.name}</div>
            <Spacer />
            <div className="mb-2">{invoice?.bill_to.name}</div>

            <div className="mb-2">{invoice?.bill_from.address}</div>
            <Spacer />
            <div className="mb-2">{invoice?.bill_to.address}</div>

            <div className="mb-2">{invoice?.bill_from.phone_number}</div>
            <Spacer />
            <div className="mb-2">{invoice?.bill_to.phone_number}</div>
          </div>
          <div className="text-black text-[20px] font-semibold mt-8 mb-4">
            Détails du devis
          </div>
          <div className="whitespace-nowrap overflow-x-scroll scrollbar-hide">
            <table className="w-full text-black text-sm">
              <thead>
                <tr>
                  <th className="text-start border-b border-linecolor pb-3">
                    <span className="font-normal">Détail de la facture</span>
                  </th>
                  <th className="text-center border-b border-linecolor pb-3 px-1">
                    <span className="font-normal">
                      Price ({invoice?.invoice_detail?.currency})
                    </span>
                  </th>
                  <th className="text-center border-b border-linecolor pb-3 px-1">
                    <span className="font-normal">Qté</span>
                  </th>
                  <th className="text-center border-b border-linecolor pb-3 px-1">
                    <span className="font-normal">
                      Total ({invoice?.invoice_detail?.currency})
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoice?.invoice_detail?.invoice_detail.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="py-3 text-start whitespace-normal break-all">
                          {item.service_name}
                        </div>
                      </td>
                      <td className="px-1 text-center">{item.price}</td>
                      <td className="px-1 text-center">{item.count}</td>
                      <td className="px-1 text-center">
                        {item.price * item.count}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={3}
                    className="font-semibold text-base border-t border-linecolor"
                  >
                    <div className="py-3">Total</div>
                  </td>
                  <td className="border-t border-linecolor px-1 pl-[25px] text-center">
                    {calcTotalPrice(invoice)} (
                    {invoice?.invoice_detail?.currency})
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="my-8">
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  name=""
                  id="certification"
                  className="mr-3 focus:ring-0 text-primary cursor-pointer"
                />
                <label
                  htmlFor="certification"
                  className="text-sm font-medium cursor-pointer"
                >
                  Je certifie ne pas être en procédure contre ce site
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  name=""
                  id="reconnaissance"
                  className="mr-3 focus:ring-0 text-primary cursor-pointer"
                />
                <label
                  htmlFor="reconnaissance"
                  className="text-sm font-medium cursor-pointer"
                >
                  Je reconnais demander une intervention des services reeact
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  name=""
                  id="conditions"
                  className="mr-3 focus:ring-0 text-primary cursor-pointer"
                />
                <label
                  htmlFor="conditions"
                  className="text-sm font-medium cursor-pointer"
                >
                  J'accepte les conditions générales
                </label>
              </div>
            </div>
            <div>
              {cardData?.id && (
                <>
                  <h3 className="text-base font-semibold text-black">
                    Méthode de paiement
                  </h3>
                  <div className="flex-col items-center flex sm:flex-row my-3 pl-3 text-sm font-medium gap-y-3">
                    <div className="mr-4 flex">
                      <img className="mr-4" src={card} alt="Card" />
                      <img src={visa} alt="Visa" />
                    </div>
                    <div className="flex">
                      <span className="mr-4 sm:mr-6">
                        {cardData?.card_number?.replace(/(.{4})/g, "$1 ")}
                      </span>
                      <div>{cardData?.card_holder_name}</div>
                    </div>
                  </div>
                </>
              )}
              <div className="flex flex-col items-center sm:flex-row sm:justify-end gap-y-2 gap-x-2 text-xs font-medium">
                <button
                  className="text-primary bg-gray100 rounded-[100px] px-3 py-2 w-48 active:scale-[.98] transition-all border border-[#DEE0E4]"
                  onClick={openModal}
                >
                  Changer ma carte
                </button>
                <button
                  className="text-white bg-primary rounded-[100px] px-3 py-2 w-48 active:scale-[.98] transition-all"
                  onClick={paiement}
                >
                  Payer par carte bancaire
                </button>
              </div>
              <div className="flex justify-end mt-2 gap-x-3">
                <img src={stripe} alt="Stripe" />
                <img src={cards} alt="Cartes" className="mr-4" />
              </div>
            </div>
          </div>

          <div className="flex bg-grayUpload text-sm text-black p-4 rounded-[12px] mb-3 gap-x-3">
            <div>
              <MdInfoOutline size={30} />
            </div>
            <div>
              <h2 className="mb-2">
                Extrait des conditions générales de ventes
              </h2>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Necessitatibus unde qui beatae porro exercitationem harum odio
              corporis officiis ipsa nemo, ipsam maxime. Lorem, ipsum dolor sit
              amet consectetur adipisicing elit.
            </div>
          </div>
          <DevisModal open={open} closeModal={closeModal} />
        </Card>
        {isOpen && (
          <BaseModal
            closeModal={() => {
              setIsOpen(false);
            }}
            purpose="Error"
          >
            <div
              className={
                "flex flex-row rounded-[20px] bg-[#DB5461] w-full h-109 p-6"
              }
            >
              <div className="mr-5">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1718_9621)">
                    <path
                      d="M14.668 9.33329H17.3346V12H14.668V9.33329ZM14.668 14.6666H17.3346V22.6666H14.668V14.6666ZM16.0013 2.66663C8.6413 2.66663 2.66797 8.63996 2.66797 16C2.66797 23.36 8.6413 29.3333 16.0013 29.3333C23.3613 29.3333 29.3346 23.36 29.3346 16C29.3346 8.63996 23.3613 2.66663 16.0013 2.66663ZM16.0013 26.6666C10.1213 26.6666 5.33464 21.88 5.33464 16C5.33464 10.12 10.1213 5.33329 16.0013 5.33329C21.8813 5.33329 26.668 10.12 26.668 16C26.668 21.88 21.8813 26.6666 16.0013 26.6666Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1718_9621">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex flex-col gap-y-3 text-white">
                <div>Votre paiement a échoué !</div>
                <div>Veuillez réessayer ou utiliser une nouvelle carte.</div>
              </div>
            </div>
          </BaseModal>
        )}
      </>
    )
  );
};

export default DevisInterventions;
