import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});


// const user = JSON.parse(localStorage.getItem('user'));

const UploadFileService = async (formData) => {
  try {
    const response = await api.post('upload', formData, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error) {
    return error;
  }
}
const UploadAvatarService = async (formData) => {
  try {
    const response = await api.post('upload_avatar', formData, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}
export {
  UploadFileService,
  UploadAvatarService
}