import DisplayNotifications from "components/Notifications/DisplayNotifications";
import { memo } from "react";
import { Link } from "react-router-dom";

const AlertNotificationDropdown = ({ avatar, open, setOpen, data }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return data.length > 0 &&
    <div
      className={`absolute w-full bg-white h-auto p-6 pb-2
    right-[10%] md:top-16 rounded-[15px] max-w-[280px] shadow-xl
    top-20 ${open ? "scale-100" : "scale-0"} transition-all
    duration-300 ease-in-out origin-top`}
    >
      {data.map((alerte, index) => {
        return (
          <DisplayNotifications
            key={index}
            imgUrl={alerte.label === "Intervention" ? process.env.REACT_APP_SERVER_URL + alerte?.search_id : process.env.REACT_APP_SERVER_URL + user?.avatar_url }
            sujet={alerte.title}
            date={new Date(alerte.created_at).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })}
          />
        );
      })}
      <div className="flex justify-end">
        <Link
          to={user.role === 0 ? "/admin/demande-dinterventions" : "/alertes"}
          onClick={() => setOpen(false)}
          className="hover:bg-gray400 hover:text-primary
              cursor-pointer text-main text-xs font-medium
              px-2 py-2 rounded-md"
        >
          Voir plus
        </Link>
      </div>
    </div>
};

export default memo(AlertNotificationDropdown);
