import { useState } from "react";
import { SendReplyService } from "services/Message";
import { SendReplyAdminService } from "services/Admin/Messagerie";
import { conversations } from "constants/Conversations";
import { useNavigate } from "react-router-dom";

const ConversationContainer = ({ sujet, lastConversation, messageId, setConversations, children }) => {
  const [reply, setReply] = useState('');
  const navigate = useNavigate();

  const handleReply = async () => {
    if (!reply || reply === '') return;

    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      analysis_selection: lastConversation?.analysis_selection,
      object_for: lastConversation?.object_for,
      messaging: reply,
      attachments: [],
      parent_id: lastConversation?.id,
      user_id: user.id,
      //message_to: (lastConversation?.user_id === user.id) ? lastConversation?.message_to : lastConversation?.user_id,
      message_to: lastConversation?.user_id,
    };
    var response = {};
    if (user?.role < 2)
      response = await SendReplyAdminService(data, messageId);
    else
      response = await SendReplyService(data, messageId);
    if (response.status === 200) {
      //setConversations(prev => [...prev, response.data]);
      //setReply('');
      navigate(window.location.pathname);
    }
  }

  return (
    <div
      className="w-full flex flex-col rounded-[20px] h-auto
      p-8 bg-white gap-y-8"
    >
      <h2
        className="text-main font-semibold md:text-2xl
      text-xl"
      >
        {sujet}
      </h2>
      {children}
      <div className="flex flex-col gap-y-2">
        <label htmlFor="analyse" className="text-main text-sm font-normal">
          Message
        </label>
        <textarea
          rows={5}
          className="block p-2.5 w-full text-sm rounded-[20px] text-main
            border border-gray-300 focus:ring-0 focus:border-gray-400"
          placeholder="Enter message"
          value={reply}
          onChange={(e) => setReply(e.target.value)}
        />
      </div>
      <div className="flex justify-end">
        <button
          className="flex items-center justify-center
            bg-main text-white w-52 h-11 rounded-[100px]"
          onClick={handleReply}
        >
          Envoyer
        </button>
      </div>
    </div>
  );
};

export default ConversationContainer;
