import { useState, useMemo, useEffect, useCallback } from "react";

import DisplayParams from "components/DisplaySearchParams/DisplayParams";
import CustomDisplayParams from "components/DisplaySearchParams/CustomDisplayParams";
import DisplayNumber from "components/DisplayResultNumber/DisplayNumber";
import RadialComponent from "components/Charts/AnalyseResultat/RadialComponent";
import DisplayStat from "components/DisplayStat/DisplayStat";
import TableAnalyses from "components/Tables/TableAnalyses";
import Container from "components/Container/Container";
// import { AnalyseModal } from "components/Modals/Modals";
import {
  GetAllAnalysisService,
  GetAnalysisByKeywordUrl,
  AddAdditionalKeywordUrl,
} from "services/Analysis";
import Card from "components/Card/Card";
import {
  radialchart1,
  radialchart2,
  radialchart3,
} from "constants/charts/AnalyseResultat/RadialComponent";

import google from "assets/svg/google.svg";
import plus from "assets/svg/Plus.svg";

import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCirclePlus } from "react-icons/fa6";
import { IoSearchSharp } from "react-icons/io5";

import { areachart } from "constants/charts/AnalyseResultat/AreaComponent";
// import { dataAnalyses } from "constants/analyses";
import BaseModal from "components/Modals/BaseModal";
import Button from "components/Buttons/Button";
import { AlertModal, KeywordPaymentModal } from "components/Modals/Modals";
import { PayForNewKeywordUrl } from "services/Analysis";
import MenuDropdown from "components/Dropdowns/MenuDropdown";
import { MenuDropdownButton } from "components/Dropdowns/MenuDropdown";
import { Dropdown } from "flowbite-react";
import CustomMenuDropdown, {
  CustomMenuDropdownButton,
} from "components/Dropdowns/CustomMenuDropdown";
import { useParams } from "react-router-dom";
import stripe from "assets/card/stripe logo.svg";
import cards from "assets/card/all.png";
import card from "assets/card/card.svg";
import visa from "assets/card/visa_logo.svg";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import "./alert-fade.css";
import DashboardMenuDropdown, {
  DashboardMenuDropdownButton,
} from "components/Dropdowns/DashboardMenuDropdown";
import AnalyseDisplayParams from "components/DisplaySearchParams/AnalyseDisplayParams";
import CustomTableAnalyses from "components/Tables/CustomTableAnalyses";
import AnalyseAreaComponent from "components/Charts/AnalyseResultat/AnalyseAreaComponent";

const AnalyseKeywordUrl = () => {
  // const [modal, setModal] = useState(false);

  const url = window.location.href;
  const parts = url.split("/");
  var lastPart = "";
  if (window.location.href.indexOf("/analyse/") >= 0) {
    lastPart = parts[parts.length - 1];
    lastPart = lastPart.replace(/%20/g, " ");
  }

  const [analyses, setAnalyses] = useState([]);
  const [positiveCount, setPositiveCount] = useState(0);
  const [negativeCount, setNegativeCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isOpen, setIsOpen] = useState(false);
  const [isNewKeywordUrlOpen, setIsNewKeywordUrlOpen] = useState(false);
  const [keywordUrl, setKeywordUrl] = useState("");
  const [keywordUrlId, setKeywordUrlId] = useState("");
  const [keywordUrlLabel, setKeywordUrlLabel] = useState("");
  const [keywordUrls, setKeywordUrls] = useState([]);
  // const [category, setCategory] = useState("positive");
  const [category, setCategory] = useState("");
  const [additionalKeywordUrl, setAdditionalKeywordUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultCardData, setDefaultCardData] = useState({});
  const { keyword_param } = useParams();
  const [modal, setModal] = useState(false);
  const [alert, showAlert] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [comboBoxUrl, setComboBoxUrl] = useState("Vos mots cles/URL");
  const [updateTable, setUpdateTable] = useState(false);
  const [reputationScore, setReputationScore] = useState(0);
  const [generalArray, setGeneralArray] = useState([]);
  const navigate = useNavigate();

  const updateCategory = (value) => {
    navigate("/");
    // setCategory(value);
  };

  const openModal = useCallback(() => {
    setModal(true);
  }, []);

  // useEffect(() => {
  //   if (url.indexOf("/analyse/") >= 0) {
  //     setComboBoxUrl(lastPart);
  //   }
  // }, [lastPart]);

  const closeModal = useCallback(() => {
    setIsOpen(true);
    setModal(false);
  }, []);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        showAlert(false);
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllAnalysisService();

      if (response?.status === 200) {
        var analyseData = response.data?.analyse?.result;
        if (analyseData === undefined) return;
        //analyseData = analyseData.filter(item => item.label === category);
        setKeywordUrls(response.data?.analyse?.keyword_urls);
        //setAnalyses(response.data?.analyse?.result ?? []);
        //setTotalCount(response.data?.analyse?.result?.length ?? 0)
        //setPositiveCount(response.data?.analyse?.positive_count ?? 0);
        //setNegativeCount(response.data?.analyse?.negative_count ?? 0);
        response.data?.analyse?.keyword_urls?.map((value, index) => {
          if (value.search_id === keyword_param) {
            setKeywordUrlLabel(
              response.data?.analyse?.keyword_urls?.[index]?.keyword_url
            );
            setAdditionalKeywordUrl(
              response.data?.analyse?.keyword_urls?.[index]
                ?.additional_keyword_url
            );
            setKeywordUrlId(
              response.data?.analyse?.keyword_urls?.[index]?.search_id
            );

            if (url.indexOf("/analyse/") >= 0) {
              console.log(value?.keyword_url);
              setComboBoxUrl(value?.keyword_url);
            }
          }
        });
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [keyword_param]);

  useEffect(() => {
    const fetchData = async () => {
      // if (!category.length)
      //   return;
      if (!keywordUrlId.length) return;
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAnalysisByKeywordUrl(keywordUrlId);

      if (response?.status === 200) {
        var analyseData = response.data?.analyse?.result;
        if (analyseData === undefined) return;
        if (category.length)
          analyseData = analyseData.filter((item) => item.label === category);
        setAnalyses(analyseData ?? []);
        setTotalCount(response.data?.analyse?.result?.length ?? 0);
        setPositiveCount(response.data?.analyse?.positive_count ?? 0);
        setNegativeCount(response.data?.analyse?.negative_count ?? 0);
        setDefaultCardData(response.data?.payment_data ?? []);
        setReputationScore(response?.data?.reputation_score);

        var array = new Array(6 - response?.data?.cron_history?.length);
        array.fill(0);
        response?.data?.cron_history?.map((value, index) => {
          array.push(
            parseInt(
              ((response?.data?.cron_history?.[index]?.total_search_result -
                response?.data?.cron_history?.[index]?.negative_search_result ??
                0) *
                100.0) /
                (response?.data?.cron_history?.[index]?.total_search_result ??
                  1)
            )
          );
        });

        console.log(array);
        setGeneralArray(array);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, [keywordUrlId, category, modal, updateTable]);

  // const toogleModal = useCallback(() => {
  //   setModal((state) => !state);
  // }, []);

  const columns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <div
            className={`${
              original.label === "positive"
                ? "bg-success"
                : original.label === "negative"
                ? "bg-danger"
                : "bg-primary"
            } w-[29px] h-[9px] rounded-[100px] px-1 mx-auto`}
          ></div>
        ),
      },
      {
        columnId: 1,
        accessor: "title",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex md:text-sm text-xs
          //   font-medium py-6 px-2 2xl:pl-5 overflow-auto whitespace-normal"
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex md:text-sm text-xs
            font-medium py-6 px-2 2xl:pl-5 overflow-auto whitespace-normal"
          >
            <span className="whitespace-normal break-all">{value}</span>
          </div>
        ),
      },
      {
        columnId: 2,
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-xs
          //   font-normal px-1"
          // >
          //   {value}
          // </div>
          // <div
          //   className="flex text-xs
          //   font-normal px-1"
          // >
          <div className="flex text-xs font-normal px-3 w-[90px] text-center max-sm:hidden">
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString("en-US", {
                month: "long",
                day: "2-digit",
                year: "numeric",
              }) ?? ""}
            </span>
          </div>
        ),
      },
      {
        columnId: 3,
        accessor: "link",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-xs
          //   font-normal px-1 overflow-auto whitespace-normal"
          // >
          //   {value}
          // </div>
          <div className="flex text-xs font-normal px-1 overflow-auto whitespace-normal justify-end max-sm:overflow-hidden">
            <span className="whitespace-normal break-all">
              {defaultCardData?.card_holder_name?.length > 0
                ? new URL(value)?.origin
                : value}
            </span>
          </div>
        ),
      },
    ],
    []
  );

  // const data = useMemo(() => dataAnalyses, []);
  const [items, setItems] = useState([]);
  const [loadedCount, setLoadedCount] = useState(5);
  const itemsPerPage = 5;
  const hasMoreData = loadedCount < analyses.length;

  useEffect(() => {
    setItems(analyses.slice(0, loadedCount));
  }, [analyses, loadedCount]);

  const fetchMoreData = () => {
    setTimeout(() => {
      const nextItems = analyses.slice(0, loadedCount + itemsPerPage);
      setItems(nextItems);
      setLoadedCount(loadedCount + itemsPerPage);
    }, 1000);
  };

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Résultat d'analyse</p>
          <span>
            <img src={google} alt="google" className="w-[96px] h-8" />
          </span>
        </div>
        {/* <div className="flex relative w-full flex-row justify-end"> */}
        <div className="flex relative flex-row justify-end">
          <div className="flex">
            <input
              type="text"
              className="w-full h-12 bg-white 2xl:w-[650px] border-none
            rounded-[100px] pl-6 outline-none
            focus:ring-0 max-md:text-sm"
              placeholder="Exemple : http://www.yours.com"
              value={keywordUrl}
              onChange={(event) => {
                setKeywordUrl(event.target.value);
              }}
            />
            {/* <button
            className={`flex items-center justify-center
          bg-main text-white w-52 h-full rounded-[100px]
            md:gap-x-2 active:scale-95 transition-all duration-200
            absolute right-0 max-md:w-24`}
            onClick={(event) => {
              var result = false;
              keywordUrls.forEach((value, index) => {
                if (value.keyword_url === keywordUrl) {
                  setErrorMsg("Ce mot-clé/URL existe déjà.");
                  setIsNewKeywordUrlOpen(true);
                  result = true;
                }
              });
              if (result)
                return;
              if (keywordUrl.length)
                setIsOpen(true);
            }}
          >
            <FaCirclePlus size={20} className="" />
            <span className="font-medium text-xs max-md:hidden">
              Ajouter mot clé/URL
            </span>
          </button> */}
            <button
              className={`flex items-center justify-center
          bg-main text-white w-60 h-full rounded-[100px]
            md:gap-x-2 active:scale-95 transition-all duration-200 max-md:w-24 p-1 ml-[-15px]`}
              onClick={(event) => {
                const user = JSON.parse(localStorage.getItem("user"));
                if (
                  user.subscription_at === null ||
                  user.subscription_at === ""
                )
                  return;
                var result = false;
                keywordUrls.forEach((value, index) => {
                  if (value.keyword_url === keywordUrl) {
                    setErrorMsg("Ce mot-clé/URL existe déjà.");
                    setIsNewKeywordUrlOpen(true);
                    result = true;
                  }
                });
                if (result) return;
                if (keywordUrl.length) setIsOpen(true);
              }}
            >
              <FaCirclePlus size={20} className="" />
              <span className="font-medium text-xs max-md:hidden">
                Ajouter mot clé/URL
              </span>
            </button>
          </div>
          {/* <div className="flex">
            <button className="flex flex-row items-center justify-center gap-x-2 bg-gray400 text-main rounded-[30px] p-1 ml-2 text-xs w-48 border-2 border-[#0000001A]">
              <p>Vos mots cles/URL</p>
              <p><svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.65195 7.75489C6.12064 8.22357 6.88179 8.22357 7.35048 7.75489L12.1498 2.95552C12.4948 2.61057 12.596 2.09688 12.4086 1.64694C12.2211 1.197 11.7861 0.904541 11.2987 0.904541L1.69998 0.908291C1.21629 0.908291 0.777598 1.20075 0.590122 1.65069C0.402647 2.10063 0.507633 2.61432 0.848838 2.95927L5.6482 7.75864L5.65195 7.75489Z" fill="#284F60" />
              </svg></p>
            </button>
          </div> */}
          <div className="flex">
            <DashboardMenuDropdown
              trigger={
                <button className="flex flex-row items-center justify-center gap-x-2 bg-gray400 text-main rounded-[30px] p-1 ml-2 text-xs w-56 border-2 border-[#0000001A] h-full text-[16px] font-bold max-md:w-32">
                  <p className="whitespace-normal break-all">{comboBoxUrl}</p>
                  <p>
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.65195 7.75489C6.12064 8.22357 6.88179 8.22357 7.35048 7.75489L12.1498 2.95552C12.4948 2.61057 12.596 2.09688 12.4086 1.64694C12.2211 1.197 11.7861 0.904541 11.2987 0.904541L1.69998 0.908291C1.21629 0.908291 0.777598 1.20075 0.590122 1.65069C0.402647 2.10063 0.507633 2.61432 0.848838 2.95927L5.6482 7.75864L5.65195 7.75489Z"
                        fill="#284F60"
                      />
                    </svg>
                  </p>
                </button>
              }
              menu={keywordUrls.map((keywordUrl, index) => (
                <DashboardMenuDropdownButton
                  text={`${keywordUrl?.keyword_url} ${
                    !keywordUrl?.additional_keyword_url?.length
                      ? ``
                      : `, ${keywordUrl?.additional_keyword_url}`
                  }`}
                  onClick={async (event) => {
                    setComboBoxUrl(keywordUrl?.keyword_url);
                    //setComboBoxUrl(keywordUrl?.search_id);
                    navigate(`/analyse/${keywordUrl?.search_id}`);
                  }}
                />
              ))}
            />
          </div>
        </div>
      </Container>
      <Card
        extra={
          "w-full sm:overflow-x-auto mt-8 mb-4 h-auto pb-6 !bg-transparent"
        }
      >
        {/* <div
          className="flex max-md:flex-col justify-between md:mb-24 mb-8"
        > */}
        {/* <div
          className="flex max-md:flex-col justify-between md:mb-16 mb-8"
        > */}
        <div className="flex justify-center md:mb-16 mb-8">
          <DisplayStat extra="bg-white text-main shadow-xl">
            <div className="flex max-md:hidden justify-between items-center">
              {/* <div className="flex flex-col gap-y-2">
                <p className="text-sm text-main font-medium text-center">
                  <span className="whitespace-normal break-all">Recherche</span>
                </p>
                <span
                  className="bg-main text-white rounded-full w-14 h-14 flex items-center justify-center cursor-pointer"
                  // onClick={async (event) => {
                  //   // if (!refineSearch.length)
                  //   //   return;
                  //   var data = {
                  //     "keyword_url": keywordUrlLabel,
                  //     "additional_keyword_url": additionalKeywordUrl
                  //   };
                  //   document.getElementById("prewaiting").removeAttribute("hidden");
                  //   const response = await AddAdditionalKeywordUrl(data);
                  //   document.getElementById("prewaiting").setAttribute("hidden", "true");
                  //   if (response?.status === 200) {
                  //     var analyseData = response.data?.analyse?.result;
                  //     // if (analyseData === undefined)
                  //     //   return;
                  //     analyseData = analyseData?.filter(item => item.label === category);
                  //     setAnalyses(analyseData ?? []);
                  //     setTotalCount(response.data?.analyse?.result?.length ?? 0)
                  //     setPositiveCount(response.data?.analyse?.positive_count ?? 0);
                  //     setNegativeCount(response.data?.analyse?.negative_count ?? 0);
                  //   }
                  // }}
                >
                  <IoSearchSharp size={35} />
                </span>
              </div> */}
              {/* <DisplayNumber text="Nombre de résultat" number={analyses?.length ?? 0} /> */}
              <DisplayNumber
                text="Nombre de résultat"
                number={totalCount ?? 0}
              />
              {/* <DisplayParams
                text="Mot clé/url"
                params={user?.site_internet}
              /> */}
              {/* <div className="flex flex-col gap-y-2 text-center">
                <p className="text-sm text-main font-medium">Mot clé/url</p>
                <Dropdown
                  label={keywordUrlLabel}
                  color="black"
                >
                  {keywordUrls?.map((value, index) => {
                    return <Dropdown.Item onClick={async (event) => {
                      setKeywordUrlId(value.search_id);
                      setKeywordUrlLabel(value.keyword_url);
                      setAdditionalKeywordUrl(value.additional_keyword_url);
                    }}>
                      {value.keyword_url}
                    </Dropdown.Item>
                  })}
                </Dropdown>
              </div> */}
              <AnalyseDisplayParams
                text="Mots cles/url analyse"
                setValue={setKeywordUrl}
                value={comboBoxUrl}
                disabled
              />
              {/* <span className="mt-12">
                <img src={plus} alt="plus" sizes={20} />
              </span> */}
              <CustomDisplayParams
                text="Affiner ma recherche"
                setAdditionalKeywordUrl={setAdditionalKeywordUrl}
                value={additionalKeywordUrl}
              />
              {/* <span className="cursor-pointer">
                <BsThreeDotsVertical size={20} />
              </span> */}
              <div className="flex flex-col h-full justify-between">
                <div></div>
                <button
                  className={`flex items-center justify-end
          bg-main text-white w-30 h-10 rounded-[10px]
            md:gap-x-2 active:scale-95 transition-all duration-200 max-md:w-24 max-md:h-20 p-2 whitespace-normal break-words text-sm`}
                  onClick={async (event) => {
                    var data = {
                      keyword_url: keywordUrlLabel,
                      additional_keyword_url: additionalKeywordUrl,
                    };
                    document
                      .getElementById("prewaiting")
                      .removeAttribute("hidden");
                    const response = await AddAdditionalKeywordUrl(data);
                    document
                      .getElementById("prewaiting")
                      .setAttribute("hidden", "true");
                    if (response?.status === 200) {
                      var analyseData = response.data?.analyse?.result;
                      // if (analyseData === undefined)
                      //   return;
                      analyseData = analyseData?.filter(
                        (item) => item.label === category
                      );
                      setAnalyses(analyseData ?? []);
                      setTotalCount(
                        response?.data?.analyse?.result?.length ?? 0
                      );
                      setPositiveCount(
                        response?.data?.analyse?.positive_count ?? 0
                      );
                      setNegativeCount(
                        response?.data?.analyse?.negative_count ?? 0
                      );
                      setKeywordUrls(response?.data?.analyse?.keyword_urls);
                      response?.data?.analyse?.keyword_urls?.forEach(
                        (value, index) => {
                          if (value.keyword_url === keywordUrlLabel) {
                            setKeywordUrlId(value.search_id);
                            return;
                          }
                        }
                      );
                    }
                  }}
                >
                  Relancer I'analyse
                </button>
              </div>
              {/* <CustomMenuDropdown
                trigger={
                  <button className="active:scale-95 transition-all">
                    <BsThreeDotsVertical size={18} />
                  </button>
                }
                menu={[
                  <CustomMenuDropdownButton
                    text="Lancer l’analyse"
                    onClick={async (event) => {
                      var data = {
                        keyword_url: keywordUrlLabel,
                        additional_keyword_url: additionalKeywordUrl,
                      };
                      document
                        .getElementById("prewaiting")
                        .removeAttribute("hidden");
                      const response = await AddAdditionalKeywordUrl(data);
                      document
                        .getElementById("prewaiting")
                        .setAttribute("hidden", "true");
                      if (response?.status === 200) {
                        var analyseData = response.data?.analyse?.result;
                        // if (analyseData === undefined)
                        //   return;
                        analyseData = analyseData?.filter(
                          (item) => item.label === category
                        );
                        setAnalyses(analyseData ?? []);
                        setTotalCount(
                          response?.data?.analyse?.result?.length ?? 0
                        );
                        setPositiveCount(
                          response?.data?.analyse?.positive_count ?? 0
                        );
                        setNegativeCount(
                          response?.data?.analyse?.negative_count ?? 0
                        );
                        setKeywordUrls(response?.data?.analyse?.keyword_urls);
                        response?.data?.analyse?.keyword_urls?.forEach(
                          (value, index) => {
                            if (value.keyword_url === keywordUrlLabel) {
                              setKeywordUrlId(value.search_id);
                              return;
                            }
                          }
                        );
                      }
                    }}
                  />,
                ]}
              /> */}
              {/* <span
                className="bg-main text-white rounded-full
               w-14 h-14 flex items-center justify-center
               cursor-pointer"
                onClick={async (event) => {
                  // if (!refineSearch.length)
                  //   return;
                  var data = {
                    "keyword_url": keywordUrlLabel,
                    "additional_keyword_url": additionalKeywordUrl
                  };
                  document.getElementById("prewaiting").removeAttribute("hidden");
                  const response = await AddAdditionalKeywordUrl(data);
                  document.getElementById("prewaiting").setAttribute("hidden", "true");
                  if (response?.status === 200) {
                    var analyseData = response.data?.analyse?.result;
                    // if (analyseData === undefined)
                    //   return;
                    analyseData = analyseData?.filter(item => item.label === category);
                    setAnalyses(analyseData ?? []);
                    setTotalCount(response.data?.analyse?.result?.length ?? 0)
                    setPositiveCount(response.data?.analyse?.positive_count ?? 0);
                    setNegativeCount(response.data?.analyse?.negative_count ?? 0);
                  }
                }}
              >
                <IoSearchSharp size={35} title="Lancer l’analyse"/>
              </span> */}
              {/* <div className="flex flex-col gap-y-2">
                <p className="text-sm text-main font-medium text-center">
                  <span className="whitespace-normal break-all">
                    Recherche
                  </span>
                </p>
                <span
                  className="bg-main text-white rounded-full
               w-14 h-14 flex items-center justify-center
               cursor-pointer"
                  onClick={async (event) => {
                    // if (!refineSearch.length)
                    //   return;
                    var data = {
                      "keyword_url": keywordUrlLabel,
                      "additional_keyword_url": additionalKeywordUrl
                    };
                    document.getElementById("prewaiting").removeAttribute("hidden");
                    const response = await AddAdditionalKeywordUrl(data);
                    document.getElementById("prewaiting").setAttribute("hidden", "true");
                    if (response?.status === 200) {
                      var analyseData = response.data?.analyse?.result;
                      // if (analyseData === undefined)
                      //   return;
                      analyseData = analyseData?.filter(item => item.label === category);
                      setAnalyses(analyseData ?? []);
                      setTotalCount(response.data?.analyse?.result?.length ?? 0)
                      setPositiveCount(response.data?.analyse?.positive_count ?? 0);
                      setNegativeCount(response.data?.analyse?.negative_count ?? 0);
                    }
                  }}
                >
                  <IoSearchSharp size={35} title="Recherche" />
                </span>
              </div> */}
            </div>
            <div className="justify-between items-center max-md:flex hidden">
              <span
                className="bg-main text-white rounded-full
               md:w-14 md:h-14 flex items-center justify-center
               cursor-pointer w-10 h-10"
              >
                <IoSearchSharp size={25} />
              </span>
              <span className="cursor-pointer">
                <BsThreeDotsVertical size={20} />
              </span>
            </div>
            <div className="max-md:flex hidden flex-col gap-y-3 mt-6">
              <DisplayNumber
                text="Nombre de résultat"
                number={analyses?.length ?? 0}
              />
              <div className="flex flex-col">
                <DisplayParams
                  text="Mot clé/url"
                  params={user?.site_internet}
                />
                {/* <span className="md:mt-12 flex justify-center mt-3">
                  <img src={plus} alt="plus" sizes={20} />
                </span> */}
                <DisplayParams
                  text="Affiner ma recherche"
                  params={user?.keyword_url}
                />
                <div className="flex flex-col items-center h-10 mt-4">
                  <div></div>
                  <button
                    className={`flex items-center justify-center
          bg-main text-white w-full h-10 rounded-[10px]
            md:gap-x-2 active:scale-95 transition-all duration-200 p-2 text-sm`}
                    onClick={async (event) => {
                      var data = {
                        keyword_url: keywordUrlLabel,
                        additional_keyword_url: additionalKeywordUrl,
                      };
                      document
                        .getElementById("prewaiting")
                        .removeAttribute("hidden");
                      const response = await AddAdditionalKeywordUrl(data);
                      document
                        .getElementById("prewaiting")
                        .setAttribute("hidden", "true");
                      if (response?.status === 200) {
                        var analyseData = response.data?.analyse?.result;
                        // if (analyseData === undefined)
                        //   return;
                        analyseData = analyseData?.filter(
                          (item) => item.label === category
                        );
                        setAnalyses(analyseData ?? []);
                        setTotalCount(
                          response?.data?.analyse?.result?.length ?? 0
                        );
                        setPositiveCount(
                          response?.data?.analyse?.positive_count ?? 0
                        );
                        setNegativeCount(
                          response?.data?.analyse?.negative_count ?? 0
                        );
                        setKeywordUrls(response?.data?.analyse?.keyword_urls);
                        response?.data?.analyse?.keyword_urls?.forEach(
                          (value, index) => {
                            if (value.keyword_url === keywordUrlLabel) {
                              setKeywordUrlId(value.search_id);
                              return;
                            }
                          }
                        );
                      }
                    }}
                  >
                    Relancer I'analyse
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="flex max-md:flex-col w-full justify-between max-md:mt-4"> */}
            <div className="flex max-md:flex-col w-full justify-between max-md:mt-4">
              <RadialComponent
                status="Positif"
                number={positiveCount}
                options={radialchart1.options}
                series={
                  isNaN(Math.round((positiveCount / totalCount) * 100))
                    ? 0
                    : Math.round((positiveCount / totalCount) * 100)
                }
                setCategory={updateCategory}
                statuse="positive"
                category={category}
              />
              <RadialComponent
                status="Neutre"
                number={totalCount - positiveCount - negativeCount}
                options={radialchart2.options}
                series={
                  isNaN(
                    100 -
                      Math.round((positiveCount / totalCount) * 100) -
                      Math.round((negativeCount / totalCount) * 100)
                  )
                    ? 0
                    : 100 -
                      Math.round((positiveCount / totalCount) * 100) -
                      Math.round((negativeCount / totalCount) * 100)
                }
                setCategory={updateCategory}
                statuse="neutral"
                category={category}
              />
              <RadialComponent
                status="Négatif"
                number={negativeCount}
                options={radialchart3.options}
                series={
                  isNaN(Math.round((negativeCount / totalCount) * 100))
                    ? 0
                    : Math.round((negativeCount / totalCount) * 100)
                }
                setCategory={updateCategory}
                statuse="negative"
                category={category}
              />
            </div>
          </DisplayStat>
          {/* <div className="flex max-md:mt-16 md:w-[15%] items-center justify-center"> */}
          <div className="flex max-md:mt-16 items-center justify-center w-[20%] max-md:hidden">
            <AnalyseAreaComponent
              options={areachart.options}
              // series={areachart.series}
              series={[
                {
                  name: "Clicks",
                  data: generalArray,
                },
              ]}
              reputationScore={
                isNaN(
                  parseInt(
                    (
                      ((totalCount - negativeCount) / totalCount) *
                      100
                    ).toString()
                  )
                )
                  ? 0
                  : parseInt(
                      (
                        ((totalCount - negativeCount) / totalCount) *
                        100
                      ).toString()
                    )
              }
              total="100"
            />
          </div>
        </div>
        {/* <div className="flex justify-center mb-4">
          <DashboardMenuDropdown
            trigger={
              <button className="flex flex-row items-center justify-center gap-x-2 bg-gray400 text-main rounded-[30px] p-2 ml-2 text-xs w-40 border-2 border-[#0000001A]">
                <p>Catégorie</p>
                <p><svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.65195 7.75489C6.12064 8.22357 6.88179 8.22357 7.35048 7.75489L12.1498 2.95552C12.4948 2.61057 12.596 2.09688 12.4086 1.64694C12.2211 1.197 11.7861 0.904541 11.2987 0.904541L1.69998 0.908291C1.21629 0.908291 0.777598 1.20075 0.590122 1.65069C0.402647 2.10063 0.507633 2.61432 0.848838 2.95927L5.6482 7.75864L5.65195 7.75489Z" fill="#284F60" />
                </svg></p>
              </button>
            }
            menu={
              [<DashboardMenuDropdownButton text={"Positif"} onClick={async (event) => {
                setCategory("positive");
              }} />,
              <DashboardMenuDropdownButton text={"Neutre"} onClick={async (event) => {
                setCategory("neutral");
              }} />,
              <DashboardMenuDropdownButton text={"Négatif"} onClick={async (event) => {
                setCategory("negative");
              }} />,
              <DashboardMenuDropdownButton text={"Tout"} onClick={async (event) => {
                setCategory("");
              }} />
              ]
            }
          />
        </div> */}
        <CustomTableAnalyses
          columns={columns}
          data={items}
          setData={setItems}
          update={fetchMoreData}
          hasMore={hasMoreData}
          category={category}
          setUpdateTable={setUpdateTable}
          defaultCardData={defaultCardData}
          // toogleModal={toogleModal}
        />
      </Card>
      {isOpen && (
        <BaseModal
          purpose="Ajout de mots clés supplémentaire"
          closeModal={() => {
            setIsOpen(false);
          }}
        >
          <div className="flex flex-col whitespace-normal text-[14px] leading-6 gap-y-3 sm:gap-y-3 self-center w-full">
            <CSSTransition
              in={alert}
              timeout={500}
              classNames="fade"
              unmountOnExit
            >
              <div
                className={
                  "flex flex-row rounded-[20px] bg-[#DB5461] w-full h-109 p-6"
                }
              >
                <div className="mr-5">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1718_9621)">
                      <path
                        d="M14.668 9.33329H17.3346V12H14.668V9.33329ZM14.668 14.6666H17.3346V22.6666H14.668V14.6666ZM16.0013 2.66663C8.6413 2.66663 2.66797 8.63996 2.66797 16C2.66797 23.36 8.6413 29.3333 16.0013 29.3333C23.3613 29.3333 29.3346 23.36 29.3346 16C29.3346 8.63996 23.3613 2.66663 16.0013 2.66663ZM16.0013 26.6666C10.1213 26.6666 5.33464 21.88 5.33464 16C5.33464 10.12 10.1213 5.33329 16.0013 5.33329C21.8813 5.33329 26.668 10.12 26.668 16C26.668 21.88 21.8813 26.6666 16.0013 26.6666Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1718_9621">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="flex flex-col gap-y-3 text-white">
                  <div>Votre paiement a échoué !</div>
                  <div>Veuillez réessayer ou utiliser une nouvelle carte.</div>
                </div>
              </div>
            </CSSTransition>
            <div className="text-sm ml-6 text-main font-semibold">
              Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </div>
            <div className="text-2xl ml-6 font-black">Detail du devis</div>
            <div className="text-main rounded-xl text-sm p-6">
              <table className="w-full text-black text-sm">
                <thead>
                  <tr>
                    <th className="text-start border-b border-linecolor pb-3">
                      <span className="font-normal">Détail de la facture</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">Prix</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">Qté</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">Total</span>
                    </th>
                  </tr>
                </thead>
                <tr>
                  <td className="py-3">{keywordUrl}</td>
                  <td className="px-1">10€/mois</td>
                  <td className="px-1">1</td>
                  <td className="px-1">10 €</td>
                </tr>
                <tr className="font-black text-xl leading-[5rem]">
                  <td colSpan={3}>Total</td>
                  <td>10€</td>
                </tr>
              </table>
            </div>
            <div>
              <h3 className="text-base font-semibold text-black">
                Méthode de paiement
              </h3>
              {defaultCardData?.id && (
                <>
                  <div className="flex-col items-center flex sm:flex-row my-3 pl-3 text-sm font-medium gap-y-3">
                    <div className="mr-4 flex">
                      <img className="mr-4" src={card} alt="Card" />
                      <img src={visa} alt="Visa" />
                    </div>
                    <div className="flex">
                      <span className="mr-4 sm:mr-6">
                        {defaultCardData?.card_number?.replace(
                          /(.{4})/g,
                          "$1 "
                        )}
                      </span>
                      <div>{defaultCardData?.card_holder_name}</div>
                    </div>
                  </div>
                </>
              )}
              <div className="flex flex-col items-center sm:flex-row sm:justify-end gap-y-2 gap-x-2 text-xs font-medium">
                <button
                  className="text-primary bg-gray100 rounded-[100px] px-3 py-2 w-48 active:scale-[.98] transition-all border border-[#DEE0E4]"
                  onClick={openModal}
                >
                  Changer ma carte
                </button>
                <button
                  className="text-white bg-primary rounded-[100px] px-3 py-2 w-48 active:scale-[.98] transition-all"
                  onClick={async (event) => {
                    document
                      .getElementById("prewaiting")
                      .removeAttribute("hidden");
                    var postData = {
                      new_keywordurl: keywordUrl,
                    };
                    const response = await PayForNewKeywordUrl(postData);
                    if (response?.status === 200) {
                      setKeywordUrlId(response?.data?.gs_result?.search_id);
                      setKeywordUrlLabel(response?.data?.new_keywordurl);
                      var new_keyword = {
                        keyword_url: response?.data?.new_keywordurl,
                        additional_keyword_url: "",
                        search_id: response?.data?.gs_result?.search_id,
                      };
                      setKeywordUrls([...keywordUrls, new_keyword]);
                      setAdditionalKeywordUrl("");
                      setIsOpen(false);
                      setSuccessModal(true);
                    } else {
                      showAlert(true);
                    }
                    document
                      .getElementById("prewaiting")
                      .setAttribute("hidden", "true");
                  }}
                >
                  Payer par carte bancaire
                </button>
              </div>
            </div>
            {/* <div className="flex justify-end mt-3">
              <button
                className="bg-primary text-white rounded-[100px] px-10
              py-2 text-xs w-48 h-10"
                onClick={async (event) => {
                  document.getElementById("prewaiting").removeAttribute("hidden");
                  var postData = {
                    "new_keywordurl": keywordUrl
                  };
                  const response = await PayForNewKeywordUrl(postData);
                  if (response?.status === 200) {
                    setKeywordUrlId(response?.data?.gs_result?.search_id)
                    setKeywordUrlLabel(response?.data?.new_keywordurl);
                    var new_keyword = {
                      "keyword_url": response?.data?.new_keywordurl,
                      "additional_keyword_url": "",
                      "search_id": response?.data?.gs_result?.search_id
                    }
                    setKeywordUrls([...keywordUrls, new_keyword]);
                    setAdditionalKeywordUrl("");
                  }
                  setIsOpen(false);
                  document.getElementById("prewaiting").setAttribute("hidden", "true");
                }}
              >
                Confirmer
              </button>
            </div> */}
            <div>
              <div className="flex justify-end mt-2 gap-x-3">
                <img src={stripe} alt="Stripe" />
                <img src={cards} alt="Cartes" className="mr-4" />
              </div>
            </div>
            <div
              className={
                "flex flex-row mt-5 rounded-[20px] bg-[#ECEFF0] w-full h-109 p-6 text-black"
              }
            >
              <div className="mr-5">
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1698_7671)">
                    <path
                      d="M14.667 9.79557H17.3337V12.4622H14.667V9.79557ZM14.667 15.1289H17.3337V23.1289H14.667V15.1289ZM16.0003 3.12891C8.64033 3.12891 2.66699 9.10224 2.66699 16.4622C2.66699 23.8222 8.64033 29.7956 16.0003 29.7956C23.3603 29.7956 29.3337 23.8222 29.3337 16.4622C29.3337 9.10224 23.3603 3.12891 16.0003 3.12891ZM16.0003 27.1289C10.1203 27.1289 5.33366 22.3422 5.33366 16.4622C5.33366 10.5822 10.1203 5.79557 16.0003 5.79557C21.8803 5.79557 26.667 10.5822 26.667 16.4622C26.667 22.3422 21.8803 27.1289 16.0003 27.1289Z"
                      fill="#284F60"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1698_7671">
                      <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="translate(0 0.462158)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="flex flex-col gap-y-3 text-black">
                <div>Extrait des conditions générales de vente</div>
                <div>2.FRAIS, PAIEMENT ET MODIFICATIONS DES FORFAITS</div>
                <div>
                  2.1 Vous acceptez de payer les Frais mentionnés dans la
                  Confirmation de Commande, sans déduction ni compensation.
                  L’ensemble des Frais seront payables dans la devise spécifiée
                  dans la Confirmation de Commande. Les Frais sont non
                  annulables et non remboursables.
                </div>
                <div>
                  2.2 L’ensemble des Frais et autres charges s'entendent TTC, y
                  compris TVA et autres taxes locales, charges et prélèvements
                  imposés à Votre utilisation des Produits REEACT concernés,
                  lesquels sont à Votre charge. Le taux de TVA est à 20%
                </div>
              </div>
            </div>
          </div>
        </BaseModal>
      )}
      {isNewKeywordUrlOpen && (
        <BaseModal
          closeModal={() => {
            setIsNewKeywordUrlOpen(false);
          }}
          purpose="Error"
        >
          {errorMsg}
        </BaseModal>
      )}
      <KeywordPaymentModal open={modal} closeModal={closeModal} />
      {successModal && (
        <BaseModal
          closeModal={() => {
            setSuccessModal(false);
          }}
          purpose="Option ajout de mot cle/URL"
        >
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-center text-[#284F60] text-4xl font-black">
              Merci !
            </div>
            <div className="flex flex-col whitespace-normal break-words">
              <p>Votre devis à bien été payé.</p>
              <p>Il est maintenant en cours de traitement par nos services</p>
              <p>
                Nous ne manquerons pas de vous tenir informé de l’évolution de
                votre demande Cordialement
              </p>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-[#284F60] text-white w-56 h-12 rounded-[50px]"
                onClick={(event) => {
                  navigate(`/analyse/${keywordUrlId}`);
                  setSuccessModal(false);
                }}
              >
                Retour à l’accueil
              </button>
            </div>
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default AnalyseKeywordUrl;
