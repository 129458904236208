import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});


const user = JSON.parse(localStorage.getItem('user'));

const SignUpService = async (data) => {
  try {
    const response = await api.post("user/signup", data);
    return response;
  } catch (error) {
    // throw new Error("Failed to sign up");
    return error?.response;
  }
};

const Confirm = async (data) => {
  try {
    const response = await api.post("confirm", data);
    return response;
  } catch (error) {
    // throw new Error("Failed to sign up");
    return error?.response;
  }
};

const SignInService = async (data) => {
  try {
    const response = await api.post("user/login", data);
    return response;
  } catch (error) {
    // throw new Error("Failed to sign in");
    return error?.response;
  }
};

const UpdateUserService = async (data) => {
  try {
    const response = await api.put(`user/update/${user.id}`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}
const PayForSignup = async (data) => {
  try {
    const response = await api.post(`stripe/pay_for_signup`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}
const UserSignup = async (data) => {
  try {
    const response = await api.post(`user/signup`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}
const CheckPromoCode = async (data) => {
  try {
    const response = await api.post(`stipe/check_promocode`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}

const VerifyCode = async (data) => {
  try {
    const response = await api.post(`verify_code`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}

const VerifySettingCode = async (data) => {
  try {
    const response = await api.post(`verify_setting_code`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}

const SendVerifyCode = async (data) => {
  try {
    const response = await api.get(`send_verify_code/${data}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}

const GenerateCode = async (data) => {
  try {
    const response = await api.post(`generate_code`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });

    return response;
  } catch (error) {
    return error;
  }
}

const EmailVerifyForForgotPassword = async (data) => {
  try {
    const response = await api.get(`password_forgot/${data}`);
    return response;
  } catch (error) {
    // throw new Error("Failed to sign in");
    return error?.response;
  }
};

const ChangeNewPassword = async (data) => {
  try {
    const response = await api.post(`change_password`, data,);
    return response;
  } catch (error) {
    // throw new Error("Failed to sign in");
    return error?.response;
  }
};

const ConfirmEmail = async (data) => {
  try {
    const response = await api.post("confirm_email", data,{
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt')
      },
    });
    return response;
  } catch (error) {
    // throw new Error("Failed to sign up");
    return error?.response;
  }
};

const ConfirmUsername = async (data) => {
  try {
    const response = await api.post("confirm_username", data,{
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt')
      },
    });
    return response;
  } catch (error) {
    // throw new Error("Failed to sign up");
    return error?.response;
  }
};

export {
  SignUpService,
  SignInService,
  UpdateUserService,
  PayForSignup,
  UserSignup,
  CheckPromoCode,
  Confirm,
  VerifyCode,
  SendVerifyCode,
  EmailVerifyForForgotPassword,
  ChangeNewPassword,
  ConfirmEmail,
  ConfirmUsername,
  GenerateCode,
  VerifySettingCode
};