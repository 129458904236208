import HorizontalInputLayout from "../Parametres/HorizontalInputLayout";
import InputLabel from "components/InputLabel/InputLabel";
import Input from "components/Input/Input";
import StepsButton from "components/Buttons/StepsButton";
import InputError from "components/InputError/InputError";
import UploadAvatar from "components/UploadFile/UploadAvatar";
import { useState, Component } from "react";
import CustomRadio from "components/Radio/CustomRadio";
import CustomStepsButton from "components/Buttons/CustomStepsButton";
import ImageCrop from "components/ImageCrop/ImageCrop";
import "react-country-state-city/dist/react-country-state-city.css";
import CountrySelect from "components/SelectRegion/CountrySelect";
import { State, City } from "country-state-city";
import { FixedSizeList as List } from "react-window";
import Select from "react-select";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  Confirm,
  SendVerifyCode,
  UserSignup,
} from "services/UserAuthentication";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import BaseModal from "components/Modals/BaseModal";
import { useEffect } from "react";

const height = 35;
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children?.length || 0}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const Step1 = ({
  register,
  watch,
  profile,
  handleSubmit,
  errors,
  nextToPage,
  selectedFiles,
  setSelectedFiles,
  countryName,
  countryCode,
  phoneNumber,
  province,
  setCountryName,
  setCountryCode,
  setPhoneNumber,
  setProvince,
  selected,
  setSelected,
}) => {
  // const [selected, setSelected] = useState("Particulier");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [countryName, setCountryName] = useState("");
  // const [countryCode, setCountryCode] = useState("");
  const [stateData, setStateData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  // const [province, setProvince] = useState("");

  const onSubmit = async (data) => {
    if (!phoneNumber?.length || !countryName?.length || !province?.length)
      return;
    data["user_type"] = selected;
    data["pays"] = countryName;
    data["province"] = province;
    data["phone"] = phoneNumber;
    if (selectedFiles.length) data["avatar_url"] = selectedFiles[0].filepath;

    document.getElementById("prewaiting").removeAttribute("hidden");
    // var response = await Confirm({
    //   email: data.email,
    //   username: profile.full_name,
    // });
    // if (response?.status === 200) {
    //   // response = await SendVerifyCode(data.email);
    //   // if (response?.status === 200) {
    //     nextToPage();
    //   // }
    // } else {
    //   setErrorMsg(response?.data?.detail);
    //   setIsOpen(true);
    // }

    if (!profile.type_email) {
      // user_data["first_name"] = profile.given_name;
      // user_data["last_name"] = profile.family_name;
      data["password"] = profile.id;
      // user_data["email"] = profile.email;
    } else {
      // user_data["first_name"] = profile.firstname;
      // user_data["last_name"] = profile.lastname;
      data["password"] = profile.password;
      // user_data["email"] = profile.email;
    }
    data["role"] = 2;

    data["full_name"] = profile.full_name;
    var postData = {
      user_data: data,
    };
    var response = await UserSignup(postData);
    if (response?.status === 200) {
      const { jwt, user } = response?.data;
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("jwt_type", "Bearer");
      localStorage.setItem("user", JSON.stringify(user));
      navigate("/register-success");
    } else {
      setErrorMsg(response?.data?.detail);
      setIsOpen(true);
    }

    document.getElementById("prewaiting").setAttribute("hidden", "true");
  };

  useEffect(() => {
    handleCountry({ label: "France", value: "FR" });
  }, []);

  const handleCountry = (value) => {
    const data = City.getAllCities();
    let filteredData = [];
    for (let item of data) {
      if (item?.countryCode === value?.value) filteredData.push(item);
    }
    setStateData(filteredData);
  };

  return (
    <div className="flex flex-col gap-y-3">
      <form
        className="flex flex-col gap-y-6 mt-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-main font-medium text-xl">Informations</h2>
        <div className="flex gap-x-16">
          <label className="flex gap-x-6 cursor-pointer">
            <CustomRadio
              id="societe"
              {...register("user_type", { required: true })}
              value="Société"
              selected={selected}
              onChange={(event) => {
                setSelected(event.target.value);
              }}
            />
            <p className="text-sm text-main font-medium">Société</p>
          </label>
          <label className="flex gap-x-6 cursor-pointer">
            <CustomRadio
              id="particulier"
              {...register("user_type", { required: true })}
              value="Particulier"
              selected={selected}
              onChange={(event) => {
                setSelected(event.target.value);
              }}
            />
            <p className="text-sm text-main font-medium">Particulier</p>
          </label>
        </div>
        <div>
          <ImageCrop
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </div>
        {/* {!profile.verified_email ?
          <HorizontalInputLayout>
            <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
              <InputLabel htmlFor="prenom">Prénom</InputLabel>
              <Input
                id="prenom"
                placeholder="Gui"
                autoComplete="given-name"
                {...register("firstname", { required: true })}
                className="w-full"
              />
              {errors.firstname && <InputError message="Prénom est requis." />}
            </div>
            <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
              <InputLabel htmlFor="nom">Nom</InputLabel>
              <Input
                id="nom"
                placeholder="Martin"
                autoComplete="family-name"
                {...register("lastname", { required: true })}
                className="w-full"
              />
              {errors.lastname && <InputError message="Nom est requis." />}
            </div>
          </HorizontalInputLayout> : <></>
        } */}

        <HorizontalInputLayout>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="prenom">Prénom</InputLabel>
            <Input
              id="prenom"
              placeholder="Gui"
              autoComplete="given-name"
              {...register("first_name", { required: true })}
              className="w-full"
            />
            {errors.first_name && <InputError message="Prénom est requis." />}
          </div>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="nom">Nom</InputLabel>
            <Input
              id="nom"
              placeholder="Martin"
              autoComplete="family-name"
              {...register("last_name", { required: true })}
              className="w-full"
            />
            {errors.last_name && <InputError message="Nom est requis." />}
          </div>
        </HorizontalInputLayout>

        {selected === "Société" && (
          <>
            <HorizontalInputLayout>
              <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
                <InputLabel htmlFor="social">Raison sociale</InputLabel>
                <Input
                  id="social"
                  autoComplete="off"
                  {...register("social_reason", { required: true })}
                  className="w-full"
                />
                {errors.social_reason && (
                  <InputError message="Raison sociale est requis." />
                )}
              </div>
              <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
                <InputLabel htmlFor="activity">Activité</InputLabel>
                <Input
                  id="activity"
                  autoComplete="off"
                  {...register("activity", { required: true })}
                  className="w-full"
                />
                {errors.activity && (
                  <InputError message="Activité est requis." />
                )}
              </div>
            </HorizontalInputLayout>
            <HorizontalInputLayout>
              <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
                <InputLabel htmlFor="siret">Numéro de Siret</InputLabel>
                <Input
                  id="siret"
                  autoComplete="off"
                  {...register("number_siret", { required: true })}
                  className="w-full"
                  pattern="\d{9}|\d{14}"
                  placeholder="123456789(12345)"
                  maxLength={16}
                />
                {errors.number_siret && (
                  <InputError message="Numéro de Siret est requis." />
                )}
              </div>
              <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
                <InputLabel htmlFor="tva">Numéro de TVA</InputLabel>
                <Input
                  id="tva"
                  autoComplete="off"
                  {...register("vat_number", { required: true })}
                  className="w-full"
                  pattern="FR\d{11}"
                  placeholder={`FR12345678901`}
                  minLength={4}
                  maxLength={13}
                  onChange={(event) => {
                    var input = document.getElementById("tva");
                    input.addEventListener("input", function (event) {
                      if (event.target.value.length < 2) {
                        event.target.value = "FR" + event.target.value;
                      }
                    });
                  }}
                />
                {errors.vat_number && (
                  <InputError message="Numéro de TVA est requis." />
                )}
              </div>
            </HorizontalInputLayout>
          </>
        )}
        {/* <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="pays">Pays</InputLabel>
          <Input
            id="pays"
            autoComplete="off"
            {...register("pays", { required: true })}
            className="w-full"
          />
          {errors.pays && <InputError message="Pays est requis." />}
        </div> */}
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="address">Adresse</InputLabel>
          <Input
            id="address"
            autoComplete="off"
            {...register("address", { required: true })}
            className="w-full"
          />
          {errors.address && <InputError message="Adresse est requis." />}
        </div>
        <HorizontalInputLayout>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="code-postal">Code postal</InputLabel>
            <Input
              id="code-postal"
              type="text"
              autoComplete="off"
              {...register("postal_code", { required: true })}
              className="w-full"
            />
            {errors.postal_code && (
              <InputError message="Code postal est requis." />
            )}
          </div>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            {/* <InputLabel htmlFor="ville">Ville</InputLabel>
            <Input
              id="ville"
              autoComplete="off"
              {...register("province", { required: true })}
              className="w-full"
            />
            {errors.province && <InputError message="Ville est requis." />} */}
            {/* <Input
              id="ville"
              autoComplete="off"
              placeholder="Entrez votre ville"
              {...register("province", { required: true })}
              className="w-full"
            /> */}
            <InputLabel htmlFor="ville">Ville</InputLabel>
            <Select
              components={{ MenuList }}
              placeholder={
                province?.length ? province : "Sélectionnez votre ville"
              }
              options={stateData}
              getOptionLabel={(options) => options.name}
              getOptionValue={(options) => options.name}
              onChange={(e) => {
                setProvince(e.name);
              }}
            />
            {province?.length ? (
              <></>
            ) : (
              <InputError message={"Ce champ est obligatoire."} />
            )}
          </div>
        </HorizontalInputLayout>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="pays">Pays</InputLabel>
          <CountrySelect
            countryName={countryName}
            setCountryName={setCountryName}
            setCountryCode={setCountryCode}
            handleCountry={handleCountry}
          />
          {countryName?.length ? (
            <></>
          ) : (
            <InputError message={"Ce champ est obligatoire."} />
          )}
        </div>
        <HorizontalInputLayout>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="telephone">Téléphone</InputLabel>
            <PhoneInput
              international
              defaultCountry={countryCode}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
            />
            {phoneNumber && isValidPhoneNumber(phoneNumber) ? (
              <></>
            ) : (
              <InputError message={"Ce champ est obligatoire."} />
            )}
            {/* <Input
              id="telephone"
              type="tel"
              autoComplete="off"
              className="w-full"
              {...register("phone", { required: true })}
            />
            {errors.phone && <InputError message="Téléphone est requis." />} */}
          </div>
          {/* {!profile.verified_email ?
            <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                type="email"
                autoComplete="email"
                placeholder="email@email.com"
                {...register("email", { required: true })}
                className="w-full"
              />
              {errors.email && <InputError message="Email est requis." />}
            </div> : <></>
          } */}
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              type="email"
              autoComplete="email"
              placeholder="email@email.com"
              {...register("email", { required: true })}
              className="w-full"
            />
            {errors.email && <InputError message="Email est requis." />}
          </div>
        </HorizontalInputLayout>
        {/* {!profile.verified_email ?
          <HorizontalInputLayout>
            <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                type="password"
                autoComplete="off"
                className="w-full"
                {...register("password", { required: true })}
              />
              {errors.password && <InputError message="Password est requis." />}
            </div>
            <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
              <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
              <Input
                id="confirm_password"
                type="password"
                className="w-full"
              />
            </div>
          </HorizontalInputLayout> : <></>
        } */}
        <div>
          <InputLabel htmlFor="site">{`Site internet ${
            selected === "Particulier" ? "(facultatif)" : ""
          }`}</InputLabel>
          <Input
            id="site"
            autoComplete="url"
            placeholder="www.reeact.com"
            pattern="^(?:https?:\/\/)?(:www\.)([a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)$"
            // {...register("site_internet", { required: true })}
            {...register("site_internet")}
            className="w-full"
          />
          {/* {errors.site_internet && <InputError message="Site internet est requis." />} */}
        </div>
        {/* <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="Username">Nom d'utilisateur</InputLabel>
          <Input
            id="username"
            placeholder="Entrez votre nom d'utilisateur"
            autoComplete="off"
            {...register("full_name", { required: true })}
            className="w-full"
          />
          {errors.full_name ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div> */}
        <div className="flex flex-col gap-y-4">
          <h2 className="text-main font-medium text-xl">
            <p className="pb-4">
              Renseignez ici le mot-clé que vous souhaitez soumettre à l'analyse
            </p>
            Important : cette recherche est votre analyse de base elle n'est pas
            modifiable - ni supprimable, vous pourrez toutefois l'affiner par la
            suite pour obtenir des résultats plus précis en y ajoutant un mot
            associé. (Exemple mon-nom + avis)
          </h2>
          <div>
            <InputLabel htmlFor="mot">Mot clé/url</InputLabel>
            <Input
              id="mot"
              autoComplete="url"
              placeholder="Indiquez un prénom nom, une marque, un site que vous souhaitez analyser (non modifiable)"
              {...register("keyword_url", { required: true })}
              className="w-full text-[14px]"
            />
            {errors.keyword_url && (
              <InputError message="Mot clé/url est requis." />
            )}
          </div>
        </div>
        <div className="flex justify-end mt-12 mb-6">
          <div className="flex gap-x-3 items-center">
            {/* <StepsButton
                onClick={backToPage}
                extra="bg-gray100"
                textColor="text-main"
              >
                Retour
              </StepsButton> */}
            <CustomStepsButton
              type="submit"
              extra="bg-main"
              textColor="text-white"
            >
              Valider
            </CustomStepsButton>
          </div>
        </div>
      </form>
      {isOpen && (
        <BaseModal
          closeModal={() => {
            setIsOpen(false);
          }}
          purpose="Error"
        >
          {errorMsg}
        </BaseModal>
      )}
    </div>
  );
};

export default Step1;
