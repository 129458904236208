import { useState } from "react";
import { memo } from "react";
import { useForm } from "react-hook-form";
import { MdSearch } from "react-icons/md";
import { SearchContents } from "services/Search";
import { useNavigate } from "react-router-dom";

const SearchForm = () => {
  const { register, handleSubmit, getValues } = useForm();
  const navigate = useNavigate();

  //Effectuer une action lorque la valeur change
  const onChange = (event) => {
  };

  //Exécuter une action à la soumission du formulaire
  const onSubmit = async (data) => {
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="relative">
        <span className="absolute pl-3 inset-y-0 left-0 flex items-center text-main">
          <MdSearch size={20} />
        </span>
        <input
          type="search"
          className="rounded-[61.5px] w-[254px] pl-9 py-3 border-0 bg-gray200 placeholder:text-main focus:border-0 focus:ring-0 text-sm text-main"
          placeholder="Search here..."
          {...register("search", { required: true, onChange: onChange })}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (getValues().search.length)
              {
                if( window.location.href.indexOf("/admin/") >= 0 ) {
                  navigate("/admin/search", { state: getValues().search });
                } else {
                  navigate("/search", { state: getValues().search });
                }
              }
            }
          }}
        />
      </div>
    </form>
  );
};

export default memo(SearchForm);
