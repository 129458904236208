import { useEffect, useState, useCallback, useMemo } from "react";
import Container from "components/Container/Container";
import Card from "components/Card/Card";

import { FcGoogle } from "react-icons/fc";

import TableAlertes from "components/Tables/TableAlertes";
import { AdminSearchContents } from "services/Admin/Search";
import SettingSection from "components/ChangePeriod/SettingSection";
import { useLocation, useNavigate } from "react-router-dom";
import SearchSettingSection from "components/ChangePeriod/SearchSettingSection";
import TableMessagerie from "components/Tables/TableMessagerie";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import TableInterventions from "components/Tables/TableInterventions";
import { Link } from "react-router-dom";
import TableHistoriques from "components/Tables/TableHistoriques";
import AdminSearchSettingSection from "components/ChangePeriod/AdminSearchSettingSection";
import CustomBackTableInterventions from "components/Tables/CustomBackTableInterventions";
import TableUsers from "components/Tables/TableUsers";
import TablePromos from "components/Tables/TablePromos";

const AdminSearch = () => {
    const location = useLocation();
    const state = location.state;

    const [active, setActive] = useState("analyse");
    const [searchWord, setSearchWord] = useState("");
    const [dataUsers, setDataUsers] = useState([]);
    const [dataInterventions, setDataInterventions] = useState([]);
    const [dataFactures, setDataFactures] = useState([]);
    const [dataCodePromos, setDataCodePromos] = useState([]);
    const [dataMessagerie, setDataMessageries] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    const navigate = useNavigate();

    const setUserTabActive = useCallback(() => {
        setActive("clients");
    }, []);

    const setInterventionActive = useCallback(() => {
        setActive("interventions");
    }, []);

    const setFacturesTabActive = useCallback(() => {
        setActive("factures");
    }, []);

    const setCodePromoTabActive = useCallback(() => {
        setActive("promo_code");
    }, []);

    const setMessagerieTabActive = useCallback(() => {
        setActive("messagerie");
    }, []);

    useEffect(() => {
        setSearchWord(state);
    }, [state]);

    useEffect(() => {
        const fetchData = async () => {
            if (!searchWord.length)
                return;
            document.getElementById("prewaiting").removeAttribute("hidden");
            const response = await AdminSearchContents(searchWord);
            if (response?.status === 200) {
                console.log(response?.data ?? []);
                setDataUsers(response?.data?.User ?? []);
                setDataInterventions(response?.data?.Intervention ?? []);
                setDataFactures(response?.data?.Invoice ?? []);
                setDataMessageries(response?.data?.Messaging ?? []);
                setDataCodePromos(response?.data?.Promo ?? []);
            }
            document.getElementById("prewaiting").setAttribute("hidden", "true");
        }
        fetchData();
    }, [searchWord]);
    const clientColumns = useMemo(
        () => [
            {
                Header: "Nom",
                accessor: "full_name",
                Cell: ({ cell: { value },
                    row: {
                        original: { id },
                    }
                }) => {
                    return (
                        <Link to={`/admin/comptes-clients/${id}`}>
                            <div className="py-4 font-semibold text-[12.008px] mx-2 text-center">
                                <span className="whitespace-normal break-words">
                                    {value}
                                </span>
                            </div>
                        </Link>
                    );
                },
            },
            {
                Header: "Raison social",
                accessor: "social_reason",
                Cell: ({
                    cell: { value },
                    row: {
                        original: { id },
                    },
                }) => {
                    return (
                        <Link to={`/admin/comptes-clients/${id}`}>
                            <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                                <span className="whitespace-normal break-words">
                                    {value}
                                </span>
                            </div>
                        </Link>
                    );
                },
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({
                    cell: { value },
                    row: {
                        original: { id },
                    },
                }) => (
                    <Link to={`/admin/comptes-clients/${id}`}>
                        <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                            <span className="whitespace-normal break-all">
                                {value}
                            </span>
                        </div>
                    </Link>
                ),
            },
            {
                Header: "Ville",
                accessor: "province",
                Cell: ({
                    cell: { value },
                    row: {
                        original: { id },
                    },
                }) => {
                    return (
                        <Link to={`/admin/comptes-clients/${id}`}>
                            <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                                <span className="whitespace-normal break-words">
                                    {value}
                                </span>
                            </div>
                        </Link>
                    );
                },
            },
            {
                Header: "Abonnement",
                accessor: "subscription_at",
                Cell: ({
                    cell: { value },
                    row: {
                        original: { id },
                    },
                }) => {
                    return (
                        // <Link to={`/admin/comptes-clients/${id}`}>
                        //   <div className="py-4 text-[12.008px] font-normal mx-2">
                        //     {value}
                        //   </div>
                        // </Link>
                        <Link to={`/admin/comptes-clients/${id}`}>
                            <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                                <span className="whitespace-normal break-all">
                                    {value}
                                </span>
                            </div>
                        </Link>
                    );
                },
            },
        ],
        []
    );
    const interventionColumns = useMemo(
        () =>
            [
                {
                    accessor: "full_name",
                    columnId: 0,
                    Cell: ({ cell: { value } }) => (
                        <div
                            className="flex text-primary text-sm font-medium py-6 px-2 max-md:text-xs w-[110px] text-center"
                        >
                            <span className="whitespace-normal break-words">{value}</span>
                        </div>
                    ),
                },
                {
                    Cell: () => (
                        <div className="flex justify-center md:pl-6 pl-2 md:pr-1 pr-4">
                            <FcGoogle size={20} />
                        </div>
                    ),
                    columnId: 1,
                    accessor: "logo",
                },
                {
                    accessor: "information",
                    columnId: 2,
                    Cell: ({ cell: { value } }) => (
                        <div
                            className="flex text-primary text-sm font-semibold py-6 px-2 max-md:text-xs justify-center text-center"
                        >
                            <span className="whitespace-normal break-words">{value}</span>
                        </div>
                    ),
                },
                {
                    accessor: "updated_at",
                    columnId: 3,
                    Cell: ({ cell: { value } }) => (
                        <div className="flex text-primary text-[12px] font-normal px-1 text-center">
                            <span className="whitespace-normal break-words">
                                {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                            </span>
                        </div>
                    ),
                },
                {
                    accessor: "status",
                    columnId: 5,
                    Cell: ({ cell: { value } }) => {
                        return value === 1 ? (
                            <div className="flex text-sky-500 text-[12px] font-normal px-1 w-[110px]">
                                <span className="whitespace-normal break-words">
                                    En attente d' examen
                                </span>
                            </div>
                        ) : value === 2 ? (
                            <div className="flex text-indigo-700 text-[12px] font-normal px-1">
                                <span className="whitespace-normal break-words">
                                    Devis accepté
                                </span>
                            </div>
                        ) : value === 3 ? (
                            <div className="flex text-danger text-[12px] font-normal px-1">
                                {/* Devis accepté */}
                                <span className="whitespace-normal break-words">
                                    Demande rejetée
                                </span>
                            </div>
                        ) : <div className="flex text-green-500 text-[12px] font-normal px-1">
                            <span className="whitespace-normal break-words">
                                Complété
                            </span>
                        </div>;
                    },
                }
            ]
    );
    const facturesColumns = useMemo(
        () => [
            {
                Header: "ID Invoice",
                accessor: "id",
                Cell: ({ cell: { value, row } }) => {
                    return (
                        <Link to={`/admin/factures/${row.original.id}`}>
                            <div className="py-4 font-semibold text-[12.008px] mx-2 text-center">
                                <span className="whitespace-normal break-words">
                                    #{value}
                                </span>
                            </div>
                        </Link>
                    );
                },
            },
            {
                Header: "Total Amount",
                accessor: "total_amount",
                Cell: ({ cell: { value, row } }) => {
                    return (
                        <Link to={`/admin/factures/${row.original.id}`}>
                            <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                                <span className="whitespace-normal break-words">
                                    {value}
                                </span>
                            </div>
                        </Link>
                    );
                },
            },
            {
                Header: "DateTime",
                accessor: "created_at",
                Cell: ({ cell: { value, row } }) => {
                    return (
                        <Link to={`/admin/factures/${row.original.id}`}>
                            <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                                <span className="whitespace-normal break-words">
                                    {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? ''}
                                </span>
                            </div>
                        </Link>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({
                    cell: { value },
                    row: {
                        original: { id },
                    },
                }) => {
                    return (
                        <Link to={`/admin/factures/${id}`}>
                            {value === "On Hold" ? (
                                <div className="py-4 flex text-[12.008px] font-medium leading-none text-warning mx-2 text-center">
                                    <span className="whitespace-normal break-words">
                                        En attente
                                    </span>
                                </div>
                            ) : value === "Canceled" ? (
                                <div className="py-4 flex text-primary text-[12.008px] font-medium leading-none mx-2 text-center">
                                    <span className="whitespace-normal break-words">
                                        Annulé
                                    </span>
                                </div>
                            ) : (
                                <div className="py-4 flex text-success text-[12.008px] font-medium leading-none mx-2 text-center">
                                    <span className="whitespace-normal break-words">
                                        Complété
                                    </span>
                                </div>
                            )}
                        </Link>
                    );
                },
            },
            {
                Header: "Détails des services",
                accessor: "service_detail",
                Cell: ({
                    cell: { value },
                    row: {
                        original: { id },
                    },
                }) => {
                    return (
                        <Link to={`/admin/factures/${id}`}>
                            <div className="py-4 text-[12.008px] font-normal mx-2 text-center">
                                <span className="whitespace-normal break-words">
                                    {value}
                                </span>
                            </div>
                        </Link>
                    );
                },
            }
        ],
    );
    const promosColumns = useMemo(
        () => [
            {
                Header: "Titre",
                //accessor: "titre",
                accessor: "code_title",
                Cell: ({ cell: { value } }) => {
                    return (
                        <div className="py-4 font-semibold text-xs mx-2 pl-6">{value}</div>
                    );
                },
            },
            {
                Header: "Début",
                //accessor: "debut",
                accessor: "start_at",
                Cell: ({ cell: { value } }) => {
                    return (
                        <div className="py-4 pl-6 text-xs font-normal mx-2">{new Date(value).toLocaleString('en-GB', options)}</div>
                    );
                },
            },
            {
                Header: "Fin",
                //accessor: "fin",
                accessor: "end_at",
                Cell: ({ cell: { value } }) => (
                    <div className="py-4 pl-6 text-xs font-normal mx-2">{new Date(value).toLocaleString('en-GB', options)}</div>
                ),
            },
            {
                Header: "Méthode",
                //accessor: "methode",
                accessor: "method",
                Cell: ({ cell: { value } }) => {
                    return value === false ? (
                        <div className="py-4 pl-6 text-xs font-normal mx-2">Pourcentage</div>
                    ) : <div className="py-4 pl-6 text-xs font-normal mx-2">Montant fixe</div>
                },
            },
            {
                Header: "Montant",
                // accessor: "montant",
                accessor: "amount",
                Cell: ({ cell: { value } }) => {
                    return (
                        <div className="py-4 pl-6 text-xs font-normal mx-2">{value}</div>
                    );
                },
            },
        ],
        []
    );
    const messagerieColumns = useMemo(
        () => [
            {
                columnId: 0,
                accessor: "star",
                Cell: () => {
                    const [starred, setStarred] = useState(false);

                    return (
                        <div className="max-md:pl-6 pr-1">
                            {starred ? (
                                <AiFillStar
                                    size={20}
                                    onClick={() => setStarred(false)}
                                    className="text-main cursor-pointer"
                                />
                            ) : (
                                <AiOutlineStar
                                    size={20}
                                    onClick={() => setStarred(true)}
                                    className="text-main cursor-pointer"
                                    style={{ display: "none" }}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                Cell: () => (
                    <div className="max-md:pl-6 pr-1">
                        <FcGoogle size={20} className="" />
                    </div>
                ),
                columnId: 1,
                accessor: "logo",
            },
            {
                accessor: "full_name",
                columnId: 2,
                Cell: ({ value, row }) => (
                    <div
                        className="flex text-primary text-sm
                font-medium py-6 px-2 2xl:pl-5 cursor-pointer
                justify-center max-md:text-xs text-center"
                        onClick={() => navigate(`/admin/messagerie/${row.original.id}`)}
                    >
                        <span className="whitespace-normal break-words">{value}</span>
                    </div>
                ),
            },
            {
                accessor: "analysis_selection",
                columnId: 3,
                Cell: ({ value, row }) => (
                    <div
                        className="flex text-primary text-sm
                font-medium py-6 px-2 2xl:pl-5 cursor-pointer
                justify-center max-md:text-xs text-center"
                        onClick={() => navigate(`/admin/messagerie/${row.original.id}`)}
                    >
                        <span className="whitespace-normal break-words">{value}</span>
                    </div>
                ),
            },
            {
                accessor: "updated_at",
                columnId: 4,
                Cell: ({ value }) => (
                    <div
                        className="flex text-primary text-xs font-normal px-1 text-center"
                    >
                        <span className="whitespace-normal break-words">
                            {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? ''}
                        </span>
                    </div>
                ),
            }
        ],
    );

    return (
        <>
            <Container>
                <div className="flex flex-col gap-y-5">
                    <p className="font-semibold text-2xl text-main">
                        Résultats de recherche
                    </p>
                    {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
                </div>
            </Container>
            <div className="flex justify-center mt-5">
                <AdminSearchSettingSection
                    setUserTabActive={setUserTabActive}
                    setInterventionActive={setInterventionActive}
                    setFacturesTabActive={setFacturesTabActive}
                    setCodePromoTabActive={setCodePromoTabActive}
                    setMessagerieTabActive={setMessagerieTabActive}
                    usersCount={dataUsers?.length}
                    interventionCount={dataInterventions?.length}
                    codePromosCount={dataCodePromos?.length}
                    facutresCount={dataFactures?.length}
                    messagerieCount={dataMessagerie?.length}
                    active={active}
                />
            </div>
            {/* <Card extra="w-full p-3 sm:p-10 mt-8 mb-8"> */}
            <Card extra="w-full mt-8 mb-8">
                {
                    active === "clients" ?
                        <Card extra={"w-full sm:overflow-x-auto mb-4 !bg-transparent"}>
                            <TableUsers
                                columns={clientColumns}
                                data={dataUsers}
                            />
                        </Card> :
                        active === "interventions" ?
                            <Card extra={"w-full sm:overflow-x-auto mb-4 !bg-transparent"}>
                                {dataInterventions?.length > 0 && <CustomBackTableInterventions
                                    columns={interventionColumns}
                                    data={dataInterventions}
                                    size={8}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                />
                                }
                            </Card> :
                            active === "factures" ?
                                <Card extra={"w-full sm:overflow-x-auto mb-4 !bg-transparent"}>
                                    <TableHistoriques
                                        columns={facturesColumns}
                                        data={dataFactures}
                                    />
                                </Card> :
                                active === "promo_code" ?
                                    <Card extra={"w-full sm:overflow-x-auto !bg-transparent rounded-none"}>
                                        <TablePromos columns={promosColumns} data={dataCodePromos} />
                                    </Card> :
                                    active === "messagerie" ?
                                        <Card extra={"w-full mb-4 !bg-transparent"}>
                                            {dataMessagerie.length > 0 &&
                                                <TableMessagerie columns={messagerieColumns} data={dataMessagerie} />
                                            }
                                        </Card> : <></>
                }
            </Card>
        </>
    );
};

export default AdminSearch;
