import React, { useState, useCallback, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle } from "components/DragHandle/DragHandle";
import { AnalyseModal, KeywordPaymentModal } from "components/Modals/Modals";
import { Link, useNavigate } from "react-router-dom";
import BaseModal from "components/Modals/BaseModal";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import { CheckPromoCode } from "services/UserAuthentication";
import stripe from "assets/card/stripe logo.svg";
import allcards from "assets/card/all.png";
import card from "assets/card/card.svg";
import visa from "assets/card/visa_logo.svg";
import { PayForSignup } from "services/UserAuthentication";
import CustomCardRadio from "components/DisplayCard/CustomCardRadio";
import CustomRadio from "components/Radio/CustomRadio";
import KeywordNewCard from "components/forms/Parametres/KeywordNewCard";
import { GetAllPaymentMethod } from "services/Invoice";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ChangeSearchResult } from "services/Analysis";
import DashboardMenuDropdown, {
  DashboardMenuDropdownButton,
} from "components/Dropdowns/DashboardMenuDropdown";

export const CustomDraggableTableRow = ({
  row,
  setUpdateTable,
  defaultCardData,
  subscription,
  updateSubscription,
  setDefaultCardData,
}) => {
  const [down, setDown] = useState(false);
  const [modal, setModal] = useState(false);
  const [analyseData, setAnalyseData] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const [isPromoCodeOpen, setIsPromoCodeOpen] = useState(false);
  const [payOpen, setPayOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  const [alert, showAlert] = useState(false);

  const [promo, setPromo] = useState(false);
  const [promoCodeTitle, setPromoCodeTitle] = useState("");
  const [method, setMethod] = useState(false);
  const [amount, setAmount] = useState(0);
  const [cardSelected, setCardSelected] = useState("");
  const [cards, setCards] = useState([]);
  const [IsAddSuccess, setIsAddSuccess] = useState(false);
  const [update, setUpdate] = useState(false);
  const [changeTypeText, setChangeTypeText] = useState(
    "Sélectionnez votre option."
  );

  const navigate = useNavigate();

  const toogleModal = useCallback(() => {
    setModal((state) => !state);
  }, []);

  const setUpdateForce = useCallback(() => {
    setUpdate((prev) => !prev);
  }, []);

  const toggleOpen = useCallback(() => {
    setSuccessMsg(
      "Nous avons bien reçu votre demande et reviendrons vers vous rapidement, merci pour votre confiance."
    );
    setIsOpen(true);
    setUpdateTable((state) => !state);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllPaymentMethod();
      if (response?.status === 200) {
        setCards(response?.data);
        response?.data?.forEach((value, index) => {
          if (value?.default) {
            setCardSelected(value.id);
            return;
          }
        });
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [IsAddSuccess, update]);

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.original.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <>
      <tr
        ref={setNodeRef}
        onClick={() => {
          if (
            (subscription === null || subscription === "") &&
            row.original.label !== "neutral"
          ) {
            setPayOpen(true);
          } else {
            setDown(!down);
          }
        }}
        style={style}
        {...row.getRowProps()}
        className={` cursor-pointer ${
          down ? "bg-main text-white" : "bg-gray400"
        } ${
          subscription === null || subscription === ""
            ? row.original.label === "neutral"
              ? ""
              : "blur-sm"
            : ""
        }`}
      >
        {isDragging ? (
          <td colSpan={row.cells.length} className="bg-blue-200">
            &nbsp;
          </td>
        ) : (
          row.cells.map((cell, i) => {
            let color;
            if (cell.column.columnId === 0) {
              color =
                cell.row.original.label === "positive"
                  ? "border-l-success"
                  : cell.row.original.label === "negative"
                  ? "border-l-danger"
                  : "border-l-primary";
            }
            return (
              <td
                {...cell.getCellProps()}
                className={`first:rounded-l-[12px] last:rounded-r-[12px] ${
                  cell.column.columnId === 0 ? "border-l-8 " + color : ""
                }`}
              >
                {i === 0 && (
                  <div className="flex items-center ml-6">
                    <DragHandle {...attributes} {...listeners} />
                    {cell.render("Cell")}
                  </div>
                )}
                {i !== 0 && i <= 3 && cell.render("Cell")}
              </td>
            );
          })
        )}
        <td>
          <div className="pl-6 pr-1">
            {down ? (
              <AiFillCaretUp
                onClick={() => setDown(false)}
                size={20}
                className="cursor-pointer"
              />
            ) : (
              <AiFillCaretDown
                onClick={() => setDown(true)}
                size={20}
                className="cursor-pointer"
              />
            )}
          </div>
        </td>
      </tr>
      {window.innerWidth > 564 && (
        <tr
          role="row"
          className={`bg-white transition-all duration-300 origin-top ${
            down ? "h-[120px]" : " h-0"
          }`}
        >
          <td></td>
          <td
            className={`transition-all pl-6 ${down ? "" : "hidden"}`}
            // colSpan={row.original.label === "negative" ? 2 : 3}
            colSpan={2}
          >
            {/* <p
            className="text-main text-xs font-normal hover-color-blue" style={{textWrap: "balance"}}
          >
            {row.original?.snippet}
          </p> */}
            {/* <p
            className="text-main text-xs font-normal hover-color-blue" style={{ textWrap: "balance" }}
          >
            <span className="whitespace-normal break-all">
              {row.original?.snippet}
            </span>
          </p> */}
            <p className="text-main text-xs font-normal hover-color-blue">
              <span className="whitespace-normal break-all">
                {row.original?.snippet}
              </span>
            </p>
          </td>
          {/* <td className={`transition-all ${down ? "" : "hidden"}`}>
          <Link
            to={row.original.link}
            target="_blank"
            className="flex justify-center items-center
              text-white font-medium bg-primary rounded-[100px]
              px-3 py-2 w-[127px] text-[12px] active:scale-95
              transition-all duration-150 m-auto"
          >
            Voir le site
          </Link>
        </td> */}
          {/* <td className={`transition-all ${down ? "" : "hidden"}`}>
          {row.original.label === "negative" ?
            <button
              onClick={toogleModal}
              className="flex justify-center items-center
              text-white font-medium bg-red rounded-[100px]
              px-3 py-2 w-[186px] text-[12px] active:scale-95
              transition-all duration-150 m-auto"
            >
              Demande d'intervention
            </button> : <></>
          }
        </td> */}
          <td className={`transition-all ${down ? "" : "hidden"}`}>
            <div className="flex flex-row gap-x-2 items-center h-full justify-center">
              <div className="flex">
                <Link
                  to={row.original.link}
                  target="_blank"
                  className="flex justify-center items-center
              text-white font-medium bg-primary rounded-[100px]
              px-3 py-2 w-[127px] text-[12px] active:scale-95
              transition-all duration-150 m-auto ml-3"
                  style={{ margin: "auto" }}
                >
                  Voir le site
                </Link>
              </div>
              <div className="flex">
                {row.original.label === "negative" &&
                  row.original.request_status === false && (
                    <button
                      onClick={toogleModal}
                      className="flex justify-center items-center
              text-white font-medium bg-red rounded-[100px]
              px-3 py-2 w-[186px] text-[12px] active:scale-95
              transition-all duration-150 m-auto"
                    >
                      Demande d'intervention
                    </button>
                  )}
              </div>
              <div className="flex">
                {row.original.label === "negative" &&
                  row.original.request_status === true &&
                  down && (
                    <p className="text-xs font-normal text-red pr-5">
                      Demande
                      <br />
                      envoyée
                    </p>
                  )}
              </div>
            </div>
          </td>
          <td className={`transition-all ${down ? "" : "hidden"}`}>
            {/* <MenuDropdown
            trigger={
              <button className="active:scale-95 transition-all hover:bg-gray200 p-1 rounded-full pr-10">
                <BsThreeDotsVertical size={25} className="cursor-pointer" />
              </button>
            }
            menu={[
              <MenuDropdownButton
                text="Ceci ne me conceme pas (on falt disparaitre de la liste)."
                onClick={async (event) => {
                  const response = await ChangeSearchResult({
                    gs_id: row.original.id,
                    action: "remove",
                  });
                  if (response?.status === 200)
                    setUpdateTable((prevState) => !prevState);
                }}
              />,
              <MenuDropdownButton
                text="Ce n'est pas une critique négative."
                onClick={async (event) => {
                  const response = await ChangeSearchResult({
                    gs_id: row.original.id,
                    action: "negative",
                  });
                  if (response?.status === 200)
                    setUpdateTable((prevState) => !prevState);
                }}
              />,
              <MenuDropdownButton
                text="Deplacer vers le positif."
                onClick={async (event) => {
                  const response = await ChangeSearchResult({
                    gs_id: row.original.id,
                    action: "positive",
                  });
                  if (response?.status === 200)
                    setUpdateTable((prevState) => !prevState);
                }}
              />,
              <MenuDropdownButton
                text="Deplacer vers le neutre."
                onClick={async (event) => {
                  const response = await ChangeSearchResult({
                    gs_id: row.original.id,
                    action: "neutral",
                  });
                  if (response?.status === 200)
                    setUpdateTable((prevState) => !prevState);
                }}
              />,
            ]}
          ></MenuDropdown> */}
            <DashboardMenuDropdown
              trigger={
                <button className="flex flex-row items-center justify-center gap-x-2 bg-gray400 text-main rounded-[30px] p-1 ml-2 text-xs w-48 border-2 border-[#0000001A] h-[35px]">
                  <p className="whitespace-normal break-all">
                    {changeTypeText}
                  </p>
                  <p>
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.65195 7.75489C6.12064 8.22357 6.88179 8.22357 7.35048 7.75489L12.1498 2.95552C12.4948 2.61057 12.596 2.09688 12.4086 1.64694C12.2211 1.197 11.7861 0.904541 11.2987 0.904541L1.69998 0.908291C1.21629 0.908291 0.777598 1.20075 0.590122 1.65069C0.402647 2.10063 0.507633 2.61432 0.848838 2.95927L5.6482 7.75864L5.65195 7.75489Z"
                        fill="#284F60"
                      />
                    </svg>
                  </p>
                </button>
              }
              menu={[
                <DashboardMenuDropdownButton
                  text="Ceci ne me conceme pas."
                  onClick={async (event) => {
                    const response = await ChangeSearchResult({
                      gs_id: row.original.id,
                      action: "remove",
                    });
                    if (response?.status === 200) {
                      setChangeTypeText("Ceci ne me conceme pas.");
                      setDown(false);
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
                <DashboardMenuDropdownButton
                  text="Deplacer vers le négatif."
                  onClick={async (event) => {
                    const response = await ChangeSearchResult({
                      gs_id: row.original.id,
                      action: "negative",
                    });
                    if (response?.status === 200) {
                      setChangeTypeText("Deplacer vers le négatif.");
                      setDown(false);
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
                <DashboardMenuDropdownButton
                  text="Deplacer vers le positif."
                  onClick={async (event) => {
                    const response = await ChangeSearchResult({
                      gs_id: row.original.id,
                      action: "positive",
                    });
                    if (response?.status === 200) {
                      setChangeTypeText("Deplacer vers le positif.");
                      setDown(false);
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
                <DashboardMenuDropdownButton
                  text="Deplacer vers le neutre."
                  onClick={async (event) => {
                    const response = await ChangeSearchResult({
                      gs_id: row.original.id,
                      action: "neutral",
                    });
                    if (response?.status === 200) {
                      setChangeTypeText("Deplacer vers le neutre.");
                      setDown(false);
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
              ]}
            />
          </td>
        </tr>
      )}
      {window.innerWidth <= 564 && (
        <tr
          role="row"
          className={`bg-white transition-all duration-300 origin-top ${
            down ? "h-[120px]" : " h-0"
          }`}
        >
          <td className={`transition-all ${down ? "" : "hidden"}`} colSpan="2">
            <div className="flex flex-row gap-x-2 items-center h-full justify-center">
              {row.original.label !== "negative" && down && (
                <div className="flex">
                  <Link
                    to={row.original.link}
                    target="_blank"
                    className="flex justify-center items-center
              text-white font-medium bg-primary rounded-[100px]
              px-3 py-2 w-[127px] text-[12px] active:scale-95
              transition-all duration-150 m-auto ml-3 max-sm:w-[140px]"
                    style={{ margin: "auto" }}
                  >
                    Voir le site
                  </Link>
                </div>
              )}
              <div className="flex">
                {row.original.label === "negative" &&
                  row.original.request_status === false && (
                    <button
                      onClick={toogleModal}
                      className="flex justify-center items-center
              text-white font-medium bg-red rounded-[100px]
              px-3 py-2 w-[186px] text-[12px] active:scale-95
              transition-all duration-150 m-auto whitespace-normal break-words max-sm:w-[140px]"
                    >
                      Demande d'intervention
                    </button>
                  )}
              </div>
              <div className="flex">
                {row.original.label === "negative" &&
                  row.original.request_status === true &&
                  down && (
                    <p className="text-xs font-normal text-red pr-5 whitespace-normal break-words max-sm:w-[140px]">
                      Demande envoyée
                    </p>
                  )}
              </div>
            </div>
          </td>
          <td className={`transition-all ${down ? "" : "hidden"}`} colSpan="3">
            <DashboardMenuDropdown
              trigger={
                <button className="flex flex-row items-center justify-center gap-x-2 bg-gray400 text-main rounded-[30px] p-1 ml-2 text-xs w-48 border-2 border-[#0000001A] h-[35px]">
                  <p className="whitespace-normal break-words">
                    {changeTypeText}
                  </p>
                  <p>
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.65195 7.75489C6.12064 8.22357 6.88179 8.22357 7.35048 7.75489L12.1498 2.95552C12.4948 2.61057 12.596 2.09688 12.4086 1.64694C12.2211 1.197 11.7861 0.904541 11.2987 0.904541L1.69998 0.908291C1.21629 0.908291 0.777598 1.20075 0.590122 1.65069C0.402647 2.10063 0.507633 2.61432 0.848838 2.95927L5.6482 7.75864L5.65195 7.75489Z"
                        fill="#284F60"
                      />
                    </svg>
                  </p>
                </button>
              }
              menu={[
                <DashboardMenuDropdownButton
                  text="Ceci ne me conceme pas."
                  onClick={async (event) => {
                    const response = await ChangeSearchResult({
                      gs_id: row.original.id,
                      action: "remove",
                    });
                    if (response?.status === 200) {
                      setChangeTypeText("Ceci ne me conceme pas.");
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
                <DashboardMenuDropdownButton
                  text="Deplacer vers le négatif."
                  onClick={async (event) => {
                    const response = await ChangeSearchResult({
                      gs_id: row.original.id,
                      action: "negative",
                    });
                    if (response?.status === 200) {
                      setChangeTypeText("Deplacer vers le négatif.");
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
                <DashboardMenuDropdownButton
                  text="Deplacer vers le positif."
                  onClick={async (event) => {
                    const response = await ChangeSearchResult({
                      gs_id: row.original.id,
                      action: "positive",
                    });
                    if (response?.status === 200) {
                      setChangeTypeText("Deplacer vers le positif.");
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
                <DashboardMenuDropdownButton
                  text="Deplacer vers le neutre."
                  onClick={async (event) => {
                    const response = await ChangeSearchResult({
                      gs_id: row.original.id,
                      action: "neutral",
                    });
                    if (response?.status === 200) {
                      setChangeTypeText("Deplacer vers le neutre.");
                      setUpdateTable((prevState) => !prevState);
                    }
                  }}
                />,
              ]}
            />
          </td>
        </tr>
      )}
      {modal && (
        <AnalyseModal
          closeModal={toogleModal}
          data={row}
          open={modal}
          openConfirmation={toggleOpen}
        />
      )}
      {payOpen && (
        <BaseModal
          closeModal={() => {
            setPayOpen(false);
          }}
          purpose="Payez pour l'utilisation."
        >
          <div className="flex flex-col whitespace-normal text-[14px] leading-6 gap-y-3 sm:gap-y-3 self-center w-full">
            <CSSTransition
              in={alert}
              timeout={500}
              classNames="fade"
              unmountOnExit
            >
              <div
                className={
                  "flex flex-row rounded-[20px] bg-[#DB5461] w-full h-109 p-6"
                }
              >
                <div className="mr-5">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1718_9621)">
                      <path
                        d="M14.668 9.33329H17.3346V12H14.668V9.33329ZM14.668 14.6666H17.3346V22.6666H14.668V14.6666ZM16.0013 2.66663C8.6413 2.66663 2.66797 8.63996 2.66797 16C2.66797 23.36 8.6413 29.3333 16.0013 29.3333C23.3613 29.3333 29.3346 23.36 29.3346 16C29.3346 8.63996 23.3613 2.66663 16.0013 2.66663ZM16.0013 26.6666C10.1213 26.6666 5.33464 21.88 5.33464 16C5.33464 10.12 10.1213 5.33329 16.0013 5.33329C21.8813 5.33329 26.668 10.12 26.668 16C26.668 21.88 21.8813 26.6666 16.0013 26.6666Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1718_9621">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="flex flex-col gap-y-3 text-white">
                  <div>Votre paiement a échoué !</div>
                  <div>Veuillez réessayer ou utiliser une nouvelle carte.</div>
                </div>
              </div>
            </CSSTransition>
            <div className="text-sm ml-6 text-main font-semibold">
              Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </div>
            <div className="text-2xl ml-6 font-black">Detail du devis</div>
            <div className="text-main rounded-xl text-sm p-6">
              <table className="w-full text-black text-sm">
                <thead>
                  <tr>
                    <th className="text-start border-b border-linecolor pb-3">
                      <span className="font-normal">Désignation</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">Prix HT</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">TVA 20%</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">Total TTC</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="">
                      <div className="py-3 hidden md:block">
                        Votre abonnement reeact
                      </div>
                      <div className="py-3 md:hidden">Abonnement</div>
                    </td>
                    <td className="px-1">25.00 €</td>
                    <td className="px-1">4.00 €</td>
                    <td className="px-1">29.00 €</td>
                  </tr>
                  {promo === true && (
                    <tr>
                      <td colSpan={3}>
                        {/* <div className="py-3">Remise code promo "DISCOUNT10"</div> */}
                        <div className="py-3">
                          Remise code promo "{promoCodeTitle}"
                        </div>
                      </td>
                      {/* <td className="px-1">-3.00 €</td> */}
                      {method === false ? (
                        <td className="px-1">-{amount}%</td>
                      ) : (
                        <td className="px-1">-{amount} €</td>
                      )}
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan={3}
                      className="font-semibold text-base border-t border-linecolor"
                    >
                      <div className="py-3">Total</div>
                    </td>
                    {/* <td className="font-semibold border-t border-linecolor px-1 pl-2">
                26.00 €
              </td> */}
                    {method === false ? (
                      <td className="font-semibold border-t border-linecolor px-1 pl-2">
                        {((29 * (100 - amount)) / 100.0).toFixed(2)} €
                      </td>
                    ) : (
                      <td className="font-semibold border-t border-linecolor px-1 pl-2">
                        {(29 - amount).toFixed(2)} €
                      </td>
                    )}
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="flex flex-col gap-y-4 pl-6 pr-6 pb-4">
              <p className="font-normal text-main text-sm">Code promo</p>
              {/* <div className="flex md:w-1/3 relative"> */}
              {/* <div className="flex relative"> */}
              <div className="flex relative w-[350px]">
                <div>
                  <input
                    type="text"
                    className="w-full h-12 bg-white
            rounded-[100px] pl-6 outline-none
            border-gray300 focus:ring-0
            focus:border-gray-400"
                    value={promoCodeTitle}
                    onChange={(event) => {
                      setPromoCodeTitle(event.target.value);
                    }}
                  />
                </div>
                <div>
                  <button
                    onClick={async (event) => {
                      document
                        .getElementById("prewaiting")
                        .removeAttribute("hidden");
                      const response = await CheckPromoCode({
                        code_title: promoCodeTitle,
                      });
                      if (response?.status === 200) {
                        const data = response?.data;
                        if (data?.method === false) {
                          if (
                            parseFloat(
                              ((29 * (100 - data?.amount)) / 100.0).toFixed(2)
                            ) < 1.0
                          ) {
                            setErrorMsg(
                              "Vous ne pouvez pas appliquer ce code promotionnel."
                            );
                            setIsPromoCodeOpen(true);
                            setPromoCodeTitle("");
                            document
                              .getElementById("prewaiting")
                              .setAttribute("hidden", "true");
                            return;
                          }
                        } else {
                          if (
                            parseFloat((29 - data?.amount).toFixed(2)) < 1.0
                          ) {
                            setErrorMsg(
                              "Vous ne pouvez pas appliquer ce code promotionnel."
                            );
                            setIsPromoCodeOpen(true);
                            setPromoCodeTitle("");
                            document
                              .getElementById("prewaiting")
                              .setAttribute("hidden", "true");
                            return;
                          }
                        }
                        setPromoCode(data?.stripe_id);
                        setPromo(true);
                        setMethod(data?.method);
                        setAmount(data?.amount);
                      } else {
                      }
                      document
                        .getElementById("prewaiting")
                        .setAttribute("hidden", "true");
                    }}
                    className="bg-main rounded-[100px] h-full md:w-44
            absolute right-0 w-32 active:scale-95 transition-all
            duration-200"
                  >
                    <p
                      className="text-white font-semibold
              md:text-sm text-xs"
                    >
                      Valider
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-base font-semibold text-black">
                Méthode de paiement
              </h3>
              {/* {defaultCardData?.id && (
                <>
                  <div className="flex-col items-center flex sm:flex-row my-3 pl-3 text-sm font-medium gap-y-3">
                    <div className="mr-4 flex">
                      <img className="mr-4" src={card} alt="Card" />
                      <img src={visa} alt="Visa" />
                    </div>
                    <div className="flex">
                      <span className="mr-4 sm:mr-6">
                        {defaultCardData?.card_number?.replace(
                          /(.{4})/g,
                          "$1 "
                        )}
                      </span>
                      <div>{defaultCardData?.card_holder_name}</div>
                    </div>
                  </div>
                </>
              )} */}
              <div
                className="flex flex-col whitespace-normal text-sm font-medium
      leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
              >
                <div className="flex flex-col gap-y-4">
                  {cards.length > 0 &&
                    cards.map((value, index) => {
                      return (
                        <CustomCardRadio
                          value={value.id}
                          name={value.card_holder_name}
                          selected={cardSelected}
                          setCardSelected={setCardSelected}
                          cardHolderName={value.card_holder_name}
                          cardNumber={value.card_number}
                          setUpdateForce={setUpdateForce}
                        />
                      );
                    })}
                  <div>
                    <label className="flex gap-x-6 md:px-8 cursor-pointer">
                      <CustomRadio
                        selected={cardSelected}
                        onChange={(e) => setCardSelected(e.target.value)}
                        value="newCard"
                        name="cardradio"
                      />
                      <p className="text-sm text-main font-medium">
                        Ajouter une nouvelle carte
                      </p>
                    </label>
                  </div>
                  {cardSelected === "newCard" && (
                    <div className="flex flex-col gap-y-4 mt-6">
                      <h2 className="text-main font-semibold text-xl">
                        Nouvelle carte
                      </h2>
                      <KeywordNewCard setIsAddSuccess={setIsAddSuccess} />
                    </div>
                  )}
                </div>
              </div>
              {cardSelected !== "newCard" && (
                <div className="flex flex-col items-center sm:flex-row sm:justify-end gap-y-2 gap-x-2 text-xs font-medium">
                  {/* <button
                  className="text-primary bg-gray100 rounded-[100px] px-3 py-2 w-48 active:scale-[.98] transition-all border border-[#DEE0E4]"
                  onClick={() => {
                    setPaymentModal(true);
                  }}
                >
                  Changer ma carte
                </button> */}
                  <button
                    className="text-white bg-primary rounded-[100px] px-3 py-2 w-48 active:scale-[.98] transition-all"
                    onClick={async (event) => {
                      document
                        .getElementById("prewaiting")
                        .removeAttribute("hidden");
                      var postData = {};
                      if (promoCode) postData["promo_code"] = promoCode;
                      const response = await PayForSignup(postData);
                      setPayOpen(false);
                      if (
                        response?.name === "AxiosError" ||
                        response?.data === false
                      ) {
                        setErrorMsg(
                          "Vous ne pouvez pas vous abonner avec votre carte. Vérifiez à nouveau les informations de votre carte ou essayez une autre carte."
                        );
                        setIsPromoCodeOpen(true);
                        document
                          .getElementById("prewaiting")
                          .setAttribute("hidden", "true");
                        return;
                      }
                      if (response?.status === 200) {
                        console.log(localStorage.getItem("jwt"));
                        localStorage.setItem("jwt", response?.data?.jwt);
                        console.log(localStorage.getItem("jwt"));
                        var user = JSON.parse(localStorage.getItem("user"));
                        user["subscription_at"] =
                          response?.data?.subscription_at;
                        localStorage.setItem("user", JSON.stringify(user));
                        updateSubscription(response?.data?.subscription_at);
                        setIsOpen(true);
                        setSuccessMsg("Votre abonnement est réussi.");
                      }
                      document
                        .getElementById("prewaiting")
                        .setAttribute("hidden", "true");
                    }}
                  >
                    Payer par carte bancaire
                  </button>
                </div>
              )}
            </div>
            {/* <div className="flex justify-end mt-3">
              <button
                className="bg-primary text-white rounded-[100px] px-10
              py-2 text-xs w-48 h-10"
                onClick={async (event) => {
                  document.getElementById("prewaiting").removeAttribute("hidden");
                  var postData = {
                    "new_keywordurl": keywordUrl
                  };
                  const response = await PayForNewKeywordUrl(postData);
                  if (response?.status === 200) {
                    setKeywordUrlId(response?.data?.gs_result?.search_id)
                    setKeywordUrlLabel(response?.data?.new_keywordurl);
                    var new_keyword = {
                      "keyword_url": response?.data?.new_keywordurl,
                      "additional_keyword_url": "",
                      "search_id": response?.data?.gs_result?.search_id
                    }
                    setKeywordUrls([...keywordUrls, new_keyword]);
                    setAdditionalKeywordUrl("");
                  }
                  setIsOpen(false);
                  document.getElementById("prewaiting").setAttribute("hidden", "true");
                }}
              >
                Confirmer
              </button>
            </div> */}
            <div>
              <div className="flex justify-end mt-2 gap-x-3">
                <img src={stripe} alt="Stripe" />
                <img src={allcards} alt="Cartes" className="mr-4" />
              </div>
            </div>
          </div>
        </BaseModal>
      )}
      {paymentModal && (
        <KeywordPaymentModal
          open={paymentModal}
          closeModal={() => {
            setPaymentModal(false);
            setPayOpen(true);
            setUpdateTable((prev) => !prev);
          }}
        />
      )}
      {isOpen && (
        <BaseModal
          closeModal={() => {
            setIsOpen(false);
          }}
          purpose="Success"
        >
          <span className="whitespace-normal break-words">
            Nous avons bien reçu votre demande et reviendrons vers vous
            rapidement, merci pour votre confiance.
          </span>
        </BaseModal>
      )}
      {isPromoCodeOpen && (
        <BaseModal
          closeModal={() => {
            setIsPromoCodeOpen(false);
          }}
          purpose="Error"
        >
          <span className="whitespace-normal break-words">{errorMsg}</span>
        </BaseModal>
      )}
    </>
  );
};
