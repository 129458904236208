import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

// const user = JSON.parse(localStorage.getItem('user'));

const GetAllAnalysisService = async () => {
  try {
    const response = await api.get(`google_search_analyse`, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

const GetAnalysisByKeywordUrl = async (search_id) => {
  try {
    const response = await api.get(`google_search_analyse/${search_id}`, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const AddAdditionalKeywordUrl = async (data) => {
  try {
    const response = await api.post(`add_additional_keyword_url`, data, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const SendRequestForAnalysisService = async (data) => {
  try {
    const response = await api.post(`intervention_requests`, data, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const ChangeRankService = async (data) => {
  try {
    const response = await api.post(`analysis_ranking`, data, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const PayForNewKeywordUrl = async (data) => {
  try {
    const response = await api.post(`stripe/pay_for_new_keywordurl`, data, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const ChangeSearchResult = async (data) => {
  try {
    const response = await api.post(`change_search_result`, data, {
      headers: {
        authorization:
          localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export {
  GetAllAnalysisService,
  SendRequestForAnalysisService,
  ChangeRankService,
  PayForNewKeywordUrl,
  GetAnalysisByKeywordUrl,
  AddAdditionalKeywordUrl,
  ChangeSearchResult
};
