import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});



const GetRequestInterventions = async () => {
    try {
        const response = await api.get(`admin/interventions`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

const GetRequestInterventionsByActive = async (active, browseType) => {
    try {
        const response = await api.get(`admin/interventions/${active}?inter_type=${browseType}`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

const SendInterventionRequest = async (intervention_id, information) => {
    try {
        const response = await api.post(`admin/intervention/${intervention_id}`, { "response_type": 0, "information": information }, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

const GetInterventionsById = async (intervention_id) => {
    try {
        const response = await api.get(`admin/intervention/${intervention_id}`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const SendInterventionReject = async (intervention_id) => {
    try {
        const response = await api.post(`admin/intervention/${intervention_id}`, { "response_type": 2 }, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const SendInterventionQuote = async (intervention_id, data) => {
    try {
        const response = await api.post(`admin/intervention/${intervention_id}`, data, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export {
    GetRequestInterventions,
    SendInterventionRequest,
    GetInterventionsById,
    SendInterventionReject,
    SendInterventionQuote,
    GetRequestInterventionsByActive
};