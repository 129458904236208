import { useEffect, useState, useCallback, useMemo } from "react";
import Container from "components/Container/Container";
import Card from "components/Card/Card";

import { FcGoogle } from "react-icons/fc";

import TableAlertes from "components/Tables/TableAlertes";
import { SearchContents } from "services/Search";
import SettingSection from "components/ChangePeriod/SettingSection";
import { useLocation, useNavigate } from "react-router-dom";
import SearchSettingSection from "components/ChangePeriod/SearchSettingSection";
import TableMessagerie from "components/Tables/TableMessagerie";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import TableInterventions from "components/Tables/TableInterventions";
import { Link } from "react-router-dom";
import TableHistoriques from "components/Tables/TableHistoriques";
import TableAnalyses from "components/Tables/TableAnalyses";

const Search = () => {
  const location = useLocation();
  const state = location.state;

  const [active, setActive] = useState("analyse");
  const [searchWord, setSearchWord] = useState("");
  const [dataAlertes, setDataAlertes] = useState([]);
  const [dataMessagerie, setDataMessageries] = useState([]);
  const [dataInterventions, setDataInterventions] = useState([]);
  const [dataFactures, setDataFactures] = useState([]);
  const [dataAnalyses, setDataAnalyses] = useState([]);
  const navigate = useNavigate();

  const setAnalyseTabActive = useCallback(() => {
    setActive("analyse");
  }, []);

  const setInterventionActive = useCallback(() => {
    setActive("intervention");
  }, []);

  const setAlertTabActive = useCallback(() => {
    setActive("alert");
  }, []);

  const setFacturesTabActive = useCallback(() => {
    setActive("factures");
  }, []);

  const setMessagerieTabActive = useCallback(() => {
    setActive("messagerie");
  }, []);

  useEffect(() => {
    setSearchWord(state);
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      if (!searchWord.length)
        return;
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await SearchContents(searchWord);
      if (response?.status === 200) {
        console.log(response?.data ?? []);
        setDataAlertes(response?.data?.Alert ?? []);
        setDataMessageries(response?.data?.Messaging ?? []);
        setDataInterventions(response?.data?.Intervention ?? []);
        setDataFactures(response?.data?.Invoice ?? []);
        setDataAnalyses(response?.data?.Analyse ?? []);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }
    fetchData();
  }, [searchWord]);
  
  const analyseColumns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "logo",
        Cell: () => (
          <div className="pl-6 pr-1">
            <FcGoogle size={20} />
          </div>
        ),
      },
      {
        columnId: 1,
        accessor: "label",
        Cell: ({ row: { original } }) => (
          <div
            className={`${original.label === "positive"
              ? "bg-success"
              : original.label === "negative"
                ? "bg-danger"
                : "bg-primary"
              } w-[29px] h-[9px] rounded-[100px] px-1 mx-auto`}
          ></div>
        ),
      },
      {
        columnId: 2,
        accessor: "title",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-primary text-sm font-medium
          // py-6 px-2 2xl:pl-5 max-md:text-xs"
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex text-primary text-sm font-medium
          py-6 px-2 2xl:pl-5 max-md:text-xs"
          >
            <span className="whitespace-normal break-all">{value}</span>
          </div>
        ),
      },
      {
        columnId: 3,
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          // <div className="flex text-primary text-xs font-normal px-1">
          //   {value}
          // </div>
          <div className="flex text-primary text-xs font-normal px-1">
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? ''}
            </span>
          </div>
        ),
      },
      {
        columnId: 4,
        accessor: "link",
        Cell: ({ cell: { value } }) => (
          <div className="flex text-primary text-xs font-normal px-1">
            <span className="whitespace-normal break-all">
              {new URL(value).origin}
            </span>
          </div>
        ),
      },
    ],
    []
  );
  const alertColumns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "logo",
        Cell: () => (
          <div className="pl-6 pr-1">
            <FcGoogle size={20} />
          </div>
        ),
      },
      {
        columnId: 1,
        accessor: "label",
        Cell: ({ row: { original } }) => (
          <div
            className={`${original.label === "positive"
              ? "bg-success"
              : original.label === "negative"
                ? "bg-danger"
                : "bg-primary"
              } w-[29px] h-[9px] rounded-[100px] px-1 mx-auto`}
          ></div>
        ),
      },
      {
        columnId: 2,
        accessor: "title",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-primary text-sm font-medium
          // py-6 px-2 2xl:pl-5 max-md:text-xs"
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex text-primary text-sm font-medium
          py-6 px-2 2xl:pl-5 max-md:text-xs"
          >
            <span className="whitespace-normal break-all">{value}</span>
          </div>
        ),
      },
      {
        columnId: 3,
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          // <div className="flex text-primary text-xs font-normal px-1">
          //   {value}
          // </div>
          <div className="flex text-primary text-xs font-normal px-1">
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? ''}
            </span>
          </div>
        ),
      },
      {
        columnId: 4,
        accessor: "site_url",
        Cell: ({ cell: { value } }) => (
          <div className="flex text-primary text-xs font-normal px-1">
            <span className="whitespace-normal break-all">
              {new URL(value).origin}
            </span>
          </div>
        ),
      },
    ],
    []
  );
  const messagerieColumns = useMemo(
    () => [
      {
        columnId: 1,
        accessor: "star",
        Cell: () => {
          const [starred, setStarred] = useState(false);

          return (
            <div className="max-md:pl-6 pr-1" hidden>
              {starred ? (
                <AiFillStar
                  size={20}
                  onClick={() => setStarred(false)}
                  className="text-main cursor-pointer"
                />
              ) : (
                <AiOutlineStar
                  size={20}
                  onClick={() => setStarred(true)}
                  className="text-main cursor-pointer"
                />
              )}
            </div>
          );
        },
      },
      {
        Cell: () => (
          <div className="max-md:pl-6 pr-1">
            <FcGoogle size={20} className="" />
          </div>
        ),
        columnId: 2,
        accessor: "logo",
      },
      {
        accessor: "analysis_selection",
        columnId: 3,
        Cell: ({ value, row }) => (
          <div
            className="flex text-primary text-sm
            font-medium py-6 px-2 2xl:pl-5 cursor-pointer
            justify-center max-md:text-xs"
            onClick={() => navigate(`/messagerie/${row.original.id}`)}
          >
            <span className="whitespace-normal break-all">{value}</span>
          </div>
        ),
      },
      {
        accessor: "updated_at",
        columnId: 5,
        Cell: ({ value }) => (
          <div
            className="flex text-primary text-xs
          font-normal px-1"
          >
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })} {new Date(value).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", second: "2-digit" })}
            </span>
          </div>
        ),
      }
    ]
  );
  const interventionColumns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "id",
        Cell: () => (
          <div className="md:pl-6 pl-2 md:pr-1 pr-4">
            <FcGoogle size={20} />
          </div>
        ),
      },
      // {
      //   columnId: 1,
      //   accessor: "intervetion_type",
      //   Cell: ({ row }) => {
      //     return row.original.label === "positive" ? (
      //       <div
      //         className="bg-primary w-[29px] h-[9px] rounded-[100px] mx-auto px-1"
      //       />
      //     ) : row.original.label === "neutral" ? (
      //       <div
      //         className="bg-success w-[29px] h-[9px] rounded-[100px] mx-auto px-1"
      //       />
      //     ) : row.original.label === "negative" ? (
      //       <div
      //         className="bg-danger w-[29px] h-[9px] rounded-[100px] mx-auto px-1"
      //       />
      //     ) : <></>;
      //   },
      // },
      {
        columnId: 1,
        accessor: "intervetion_type",
        Cell: ({ row: { original } }) => (
          <div
            className={`${original.label === "positive"
              ? "bg-success"
              : original.label === "negative"
                ? "bg-danger"
                : "bg-primary"
              } w-[29px] h-[9px] rounded-[100px] px-1 mx-auto`}
          ></div>
        ),
      },
      {
        columnId: 2,
        accessor: "information",
        Cell: ({ value }) => (
          <div
            className="flex text-primary text-sm font-medium py-6 px-2 max-md:text-xs"
          >
            <span className="whitespace-normal break-all">{value}</span>
          </div>
        ),
      },
      {
        accessor: "updated_at",
        columnId: 3,
        Cell: ({ value, row }) => (
          <div className="flex text-primary text-xs font-medium py-6 px-2 max-md:text-xs">
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? ''}
            </span>
          </div>
        ),
      },
      {
        accessor: "site_url",
        columnId: 4,
        Cell: ({ value }) => (
          <div className="flex text-primary text-sm font-medium py-6 px-2 max-md:text-xs">
            <span className="whitespace-normal break-all">{new URL(value).origin}</span>
          </div>
        ),
      },
      {
        accessor: "status",
        columnId: 5,
        Cell: ({ cell: { value } }) => {
          return value === 1 ? (
            <div className="flex text-sky-500 text-[12px] font-normal px-1">
              <span className="whitespace-normal break-all">
                En attente d' examen
              </span>
            </div>
          ) : value === 2 ? (
            <div className="flex text-indigo-700 text-[12px] font-normal px-1">
              <span className="whitespace-normal break-all">
                {/* Demande envoyée */}
                Devis accepté
              </span>
            </div>
          ) : value === 3 ? (
            <div className="flex text-danger text-[12px] font-normal px-1">
              <span className="whitespace-normal break-all">
                Demande rejetée
              </span>
            </div>
          ) : (
            <div className="flex text-success text-[12px] font-normal px-1">
              <span className="whitespace-normal break-all">
                Complété
              </span>
            </div>
          );
        },
      }
    ],
    []
  );
  const facturesColumns = useMemo(
    () => [
      {
        Header: "ID Invoice",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <Link to={`/factures/${value}`}>
              <div className="py-4 font-semibold text-[12.008px] mx-2 ">
                <span className="whitespace-normal break-all">
                  #{value}
                </span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        Cell: ({ cell: { value, row } }) => {
          return row.original.status === "Completed" ? (
            <Link to={`/factures/${row.original.id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2">
                <span className="whitespace-normal break-all">
                  {value}
                </span>
              </div>
            </Link>
          ) : <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
            <div className="py-4 text-[12.008px] font-normal mx-2">
              <span className="whitespace-normal break-all">
                {value}
              </span>
            </div>
          </Link>
        },
      },
      {
        Header: "DateTime",
        accessor: "created_at",
        Cell: ({ cell: { value, row } }) => {
          return row.original.status === "Completed" ? (
            <Link to={`/factures/${row.original.id}`}>
              <div className="py-4 text-xs font-normal mx-2">
                <span className="whitespace-normal break-all">
                  {new Date(value).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) ?? ''}
                </span>
              </div>
            </Link>
          ) : <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
            <div className="py-4 text-xs font-normal mx-2">
              <span className="whitespace-normal break-all">
                {new Date(value).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) ?? ''}
              </span>
            </div>
          </Link>
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          cell: { value, row },
        }) => {
          return row.original.status === "Completed" ? (
            <Link to={`/factures/${row.original.id}`}>
              {value === "On Hold" ? (
                <div className="py-4 flex text-[12.008px] font-medium leading-none text-warning mx-2">
                  En attente
                </div>
              ) : value === "Canceled" ? (
                <div className="py-4 flex text-primary text-[12.008px] font-medium leading-none mx-2">
                  Annulé
                </div>
              ) : (
                <div className="py-4 flex text-success text-[12.008px] font-medium leading-none mx-2">
                  Complété
                </div>
              )}
            </Link>
          ) : (
            <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
              {value === "On Hold" ? (
                <div className="py-4 flex text-[12.008px] font-medium leading-none text-warning mx-2">
                  En attente
                </div>
              ) : value === "Canceled" ? (
                <div className="py-4 flex text-primary text-[12.008px] font-medium leading-none mx-2">
                  Annulé
                </div>
              ) : (
                <div className="py-4 flex text-success text-[12.008px] font-medium leading-none mx-2">
                  Complété
                </div>
              )}
            </Link>
          )
        },
      },
      {
        Header: "Détails des services",
        accessor: "service_detail",
        Cell: ({
          cell: { value, row },
        }) => {
          return row.original.status === "Completed" ? (
            <Link to={`/factures/${row.original.id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2">
                <span className="whitespace-normal break-all">
                  {value}
                </span>
              </div>
            </Link>
          ) : <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
            <div className="py-4 text-[12.008px] font-normal mx-2">
              <span className="whitespace-normal break-all">
                {value}
              </span>
            </div>
          </Link>
        },
      }
    ]
  );

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-5">
          <p className="font-semibold text-2xl text-main">
            Résultats de recherche
          </p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
      </Container>
      <div className="flex justify-center mt-5">
        <SearchSettingSection
          setAnalyseTabActive={setAnalyseTabActive}
          setInterventionActive={setInterventionActive}
          setAlertTabActive={setAlertTabActive}
          setFacturesTabActive={setFacturesTabActive}
          setMessagerieTabActive={setMessagerieTabActive}
          analyseCount={dataAnalyses?.length}
          interventionCount={dataInterventions?.length}
          alertCount={dataAlertes?.length}
          facutresCount={dataFactures?.length}
          messagerieCount={dataMessagerie?.length}
          active={active}
        />
      </div>
      {/* <Card extra="w-full p-3 sm:p-10 mt-8 mb-8"> */}
      <Card extra="w-full mt-8 mb-8">
        {
          active === "analyse" ?
            <Card extra={"w-full sm:overflow-x-auto mb-4 !bg-transparent"}>
              {dataAnalyses?.length > 0 && <TableAlertes columns={analyseColumns} data={dataAnalyses} />}
            </Card> :
            active === "intervention" ?
              <Card extra={"w-full sm:overflow-x-auto mb-4 !bg-transparent"}>
                <TableInterventions columns={interventionColumns} data={dataInterventions} />
              </Card> :
              active === "alert" ?
                <Card extra={"w-full sm:overflow-x-auto mb-4 !bg-transparent"}>
                  {dataAlertes?.length > 0 && <TableAlertes columns={alertColumns} data={dataAlertes} />}
                </Card> :
                active === "factures" ?
                  <Card extra={"w-full sm:overflow-x-auto !bg-transparent rounded-none"}
                  >
                    {dataFactures.length > 0 && <TableHistoriques
                      columns={facturesColumns}
                      data={dataFactures}
                    />}
                  </Card> :
                  active === "messagerie" ?
                    <Card extra={"w-full mb-4 !bg-transparent"}>
                      <TableMessagerie
                        data={dataMessagerie}
                        columns={messagerieColumns}
                      />
                    </Card> : <></>
        }
      </Card>
    </>
  );
};

export default Search;
