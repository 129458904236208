import React, { Component } from "react";
import Flags from "country-flag-icons/react/1x1";
import { components } from "react-select";
import { getData } from "country-list";
import { AsyncPaginate } from "react-select-async-paginate";

// const options = getData()?.map(({ code, name }) => ({
//   value: code,
//   label: name
// }));

// const options = [
//   { label: "Albania", value: "AL" },
//   { label: "Andorra", value: "AD" },
//   { label: "Austria", value: "AT" },
//   { label: "Belarus", value: "BY" },
//   { label: "Belgium", value: "BE" },
//   { label: "Bosnia and Herzegovina", value: "BA" },
//   { label: "Bulgaria", value: "BG" },
//   { label: "Croatia", value: "HR" },
//   { label: "Cyprus", value: "CY" },
//   { label: "Czech Republic", value: "CZ" },
//   { label: "Denmark", value: "DK" },
//   { label: "Estonia", value: "EE" },
//   { label: "Finland", value: "FI" },
//   { label: "France", value: "FR" },
//   { label: "Germany", value: "DE" },
//   { label: "Greece", value: "GR" },
//   { label: "Hungary", value: "HU" },
//   { label: "Iceland", value: "IS" },
//   { label: "Ireland", value: "IE" },
//   { label: "Italy", value: "IT" },
//   { label: "Kosovo", value: "XK" },
//   { label: "Latvia", value: "LV" },
//   { label: "Liechtenstein", value: "LI" },
//   { label: "Lithuania", value: "LT" },
//   { label: "Luxembourg", value: "LU" },
//   { label: "Malta", value: "MT" },
//   { label: "Moldova", value: "MD" },
//   { label: "Monaco", value: "MC" },
//   { label: "Montenegro", value: "ME" },
//   { label: "Netherlands", value: "NL" },
//   { label: "North Macedonia", value: "MK" },
//   { label: "Norway", value: "NO" },
//   { label: "Poland", value: "PL" },
//   { label: "Portugal", value: "PT" },
//   { label: "Romania", value: "RO" },
//   { label: "Russia", value: "RU" },
//   { label: "San Marino", value: "SM" },
//   { label: "Serbia", value: "RS" },
//   { label: "Slovakia", value: "SK" },
//   { label: "Slovenia", value: "SI" },
//   { label: "Spain", value: "ES" },
//   { label: "Sweden", value: "SE" },
//   { label: "Switzerland", value: "CH" },
//   { label: "Ukraine", value: "UA" },
//   { label: "United Kingdom", value: "GB" },
//   { label: "Vatican City", value: "VA" }
// ];

const options = [
  { label: "France", value: "FR" }
];

const sleep = (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms));

const loadOptions = async (search, prevOptions) => {
  await sleep(100);

  let filteredOptions = options;

  if (search) {
    const searchLower = search.toLowerCase();
    filteredOptions = filteredOptions.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + 10
  );

  return {
    options: slicedOptions,
    hasMore
  };
};

const { Option } = components;
const IconOption = (props) => {
  const Flag = Flags[props.data.value];
  return (
    <Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Flag style={{ width: "32px", height: "32px" }} />
        <span style={{ marginLeft: "10px" }}>{props.data.label}</span>
      </div>
    </Option>
  );
};

const CountrySelect = ({ countryName, setCountryName, setCountryCode, handleCountry }) => {
  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      cacheOptions
      defaultOptions
      components={{ Option: IconOption }}
      placeholder={countryName}
      onChange={(event) => {
        handleCountry(event);
        setCountryCode(event.value);
        setCountryName(event.label);
      }}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 99
        }),
        control: (provided, sta) => ({
          ...provided,
          "&:hover": {
            border: "1px solid #000",
          }
        })
      }}
    />
  );
};

export default CountrySelect;
