import { useState, useCallback, useMemo, useEffect } from "react";

import BackTableInterventions from "components/Tables/BackTableInterventions";
import ChangePeriod from "components/ChangePeriod/ChangePeriod";
import {
  InterventionsDevis,
  InfoComplementaire,
} from "components/Modals/Modals";
import Container from "components/Container/Container";
import Widget from "components/Widget/Widget";
import Card from "components/Card/Card";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";

import { BsThreeDotsVertical } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { LiaCheckCircle } from "react-icons/lia";
import {
  GetRequestInterventions,
  SendInterventionReject,
  GetRequestInterventionsByActive,
  GetInterventionsById,
} from "services/Admin/Intervention";
import { act } from "react-dom/test-utils";
import { useNavigate } from "react-router-dom";
import CustomBackTableInterventions from "components/Tables/CustomBackTableInterventions";
import CustomWidget from "components/Widget/CustomWidget";
import BaseModal from "components/Modals/BaseModal";

const BackInterventions = () => {
  const [active, setActive] = useState("monthly");
  const [backInterventions, setBackInterventions] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [paidInvoices, setPaidInvoices] = useState(0);
  const [sentInvoices, setSentInvoices] = useState(0);
  const navigate = useNavigate();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [browseType, setBrowseType] = useState("total_count");
  const [detailModal, setDetailModal] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [data, setData] = useState({});

  const [open, setOpen] = useState("closed");

  const closeModal = () => {
    setOpen("closed");
  };

  const showModal = useCallback((term) => {
    setOpen(term);
  }, []);

  const setMonthly = useCallback(() => {
    setActive("monthly");
  }, []);

  const setWeekly = useCallback(() => {
    setActive("weekly");
  }, []);

  const setToday = useCallback(() => {
    setActive("today");
  }, []);

  /*
  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetRequestInterventions();

      if (response?.status === 200) {
        setBackInterventions(response.data.interventions);
        setTotalInvoices(response?.data?.total_count);
        setPaidInvoices(response?.data?.in_progress_count);
        setSentInvoices(response?.data?.pending_count);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }
    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetRequestInterventions();

      if (response?.status === 200) {
        setBackInterventions(response?.data?.interventions);
        setTotalInvoices(response?.data?.total_count);
        setPaidInvoices(response?.data?.in_progress_count);
        setSentInvoices(response?.data?.pending_count);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }
    fetchData();
  }, [forceUpdate])
  */

  useEffect(() => {
    const fetchData = async () => {
      //document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetRequestInterventionsByActive(
        active,
        browseType
      );

      if (response?.status === 200) {
        setBackInterventions(response?.data?.inter_data);
        setTotalInvoices(response?.data?.total_count);
        setPaidInvoices(response?.data?.in_progress_count);
        setSentInvoices(response?.data?.pending_count);
      } else {
        setIsOpen(true);
        setErrorMsg(response?.data?.detail);
      }
      //document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetRequestInterventionsByActive(
        active,
        browseType
      );

      if (response?.status === 200) {
        setBackInterventions(response?.data?.inter_data);
        setTotalInvoices(response?.data?.total_count);
        setPaidInvoices(response?.data?.in_progress_count);
        setSentInvoices(response?.data?.pending_count);
      } else {
        setIsOpen(true);
        setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [forceUpdate]);

  useEffect(() => {
    if (!active.length) return;
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetRequestInterventionsByActive(
        active,
        browseType
      );

      if (response?.status === 200) {
        setBackInterventions(response?.data?.inter_data);
        setTotalInvoices(response?.data?.total_count);
        setPaidInvoices(response?.data?.in_progress_count);
        setSentInvoices(response?.data?.pending_count);
      } else {
        setIsOpen(true);
        setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [active, browseType]);

  const BrowseByType = (type) => {
    switch (type) {
      case "Total interventions":
        setBrowseType("total_count");
        break;
      case "Interventions en cours":
        setBrowseType("in_progress_count");
        break;
      case "Intervention en attente":
        setBrowseType("pending_count");
        break;
      default:
        break;
    }
  };

  const columns = useMemo(
    () => [
      {
        accessor: "full_name",
        columnId: 0,
        hidden: true,
        Cell: ({ cell: { value } }) => (
          //   <div
          //     className="flex text-primary text-sm font-medium py-6 px-2
          // max-md:text-xs"
          //   >
          //     <span>{value}</span>
          //   </div>
          <div className="flex text-primary text-sm font-medium justify-center py-6 px-2 max-md:text-xs w-[110px] text-center">
            <span className="whitespace-normal break-words">{value}</span>
          </div>
        ),
      },
      {
        Cell: () => (
          <div className="flex justify-center md:pl-6 pl-2 md:pr-1 pr-4">
            <FcGoogle size={20} />
          </div>
        ),
        columnId: 1,
        accessor: "logo",
      },
      {
        accessor: "title",
        columnId: 2,
        hidden: true,
        Cell: ({ cell: { value } }) => (
          //   <div
          //     className="flex text-primary text-sm font-semibold py-6 px-2
          // max-md:text-xs justify-center"
          //   >
          //     <span>{value}</span>
          //   </div>
          <div className="flex text-primary text-sm font-semibold py-6 px-2 max-md:text-xs justify-center text-center">
            <span className="whitespace-normal break-words">{value}</span>
          </div>
        ),
      },
      {
        accessor: "updated_at",
        columnId: 3,
        hidden: true,
        Cell: ({ cell: { value } }) => (
          // <div className="flex text-primary text-[12px] font-normal px-1">
          //   {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          // </div>
          <div className="flex text-primary text-[12px] font-normal px-1 text-center">
            <span className="whitespace-normal break-words">
              {new Date(value).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </span>
          </div>
        ),
      },
      // {
      //   accessor: "site_url",
      //   columnId: 4,
      //   Cell: ({ cell: { value } }) => (
      //     // <div className="flex text-primary text-[12px] font-normal px-1">
      //     //   {value}
      //     // </div>
      //     <div className="flex text-primary text-[12px] font-normal px-1">
      //       <span className="whitespace-normal break-all">
      //         {new URL(value).origin}
      //       </span>
      //     </div>
      //   ),
      // },
      {
        accessor: "information",
        columnId: 4,
        Cell: ({ cell: { value }, row }) => (
          // <div className="flex text-primary text-[12px] font-normal px-1">
          //   {value}
          // </div>
          <div className="flex text-primary text-[12px] font-normal px-1 text-center justify-center">
            <button
              className="bg-main text-white rounded-[35px] p-3 whitespace-normal break-words"
              onClick={(event) => {
                setDetailModal(true);
                setDetailData(row?.original);
              }}
            >
              Voir la demande d'intervention
            </button>
          </div>
        ),
      },
      {
        accessor: "status",
        columnId: 5,
        Cell: ({ cell: { value } }) => {
          // return value === 1 ? (
          //   <div className="flex text-sky-500 text-[12px] font-normal px-1">
          //     En attente d' examen
          //   </div>
          // ) : value === 2 ? (
          //   <div className="flex text-indigo-700 text-[12px] font-normal px-1">
          //     Demande envoyée
          //   </div>
          // ) : value === 3 ? (
          //   <div className="flex text-danger text-[12px] font-normal px-1">
          //     {/* Devis accepté */}
          //     Demande rejetée
          //   </div>
          // ) : <div className="flex text-green-500 text-[12px] font-normal px-1">
          //   Complété
          // </div>;
          return value === 0 ? (
            <div className="flex text-sky-500 text-[13px] font-normal px-1 w-[110px]">
              <span className="whitespace-normal break-words">
                En attente d' examen
              </span>
            </div>
          ) : value === 1 ? (
            <div className="flex text-[#14b8a6] text-[13px] font-normal px-1 w-[110px]">
              <span className="whitespace-normal break-words">En cours</span>
            </div>
          ) : value === 2 ? (
            <div className="flex text-indigo-700 text-[13px] font-normal px-1">
              <span className="whitespace-normal break-words">
                {/* Devis accepté */}
                En attente
              </span>
            </div>
          ) : value === 3 ? (
            <div className="flex text-danger text-[13px] font-normal px-1">
              {/* Devis accepté */}
              <span className="whitespace-normal break-words">
                Demande rejetée
              </span>
            </div>
          ) : (
            <div className="flex text-green-500 text-[13px] font-normal px-1">
              <span className="whitespace-normal break-words">Complété</span>
            </div>
          );
        },
      },
      {
        id: "dots",
        Cell: ({ row }) => {
          // const [open, setOpen] = useState(modalName);

          // const closeModal = () => {
          //   setOpen("closed");
          // };

          // const showModal = useCallback((term) => {
          //   setOpen(term);
          // }, []);
          // if (row.original.status === 0 || row.original.status === 1 || row.original.status === 3)
          return (
            <>
              <MenuDropdown
                reverseColor={true}
                trigger={
                  <button className="active:scale-95 transition-all hover:bg-gray200 p-1 rounded-full">
                    <BsThreeDotsVertical size={18} className="cursor-pointer" />
                  </button>
                }
                menu={[
                  <MenuDropdownButton
                    key={0}
                    text="Envoyer un devis"
                    onClick={async () => {
                      showModal("devis");
                      setData(row.original);
                      const response = await GetInterventionsById(
                        row.original.id
                      );
                      if (response?.status === 200) {
                        setForceUpdate((prev) => !prev);
                      }
                    }}
                  />,
                  <MenuDropdownButton
                    key={1}
                    text="Demande d'informations"
                    onClick={() => {
                      showModal("info");
                      setData(row.original);
                      setForceUpdate((prev) => !prev);
                    }}
                  />,
                  <MenuDropdownButton
                    key={2}
                    text="Refuser la demande"
                    // Ici mettre la fonction correspondante
                    // pour refuser la demande
                    onClick={async (event) => {
                      const response = await SendInterventionReject(
                        row.original.id
                      );
                      if (response?.status === 200) {
                        setForceUpdate((prev) => !prev);
                      } else {
                        setIsOpen(true);
                        setErrorMsg(response?.data?.detail);
                      }
                    }}
                  />,
                ]}
              />
              {/* utiliser data pour passer des données au modal */}
              {/* <InterventionsDevis
                  data={row.original}
                  open={open}
                  closeModal={closeModal}
                  setIsOpen={setIsOpen}
                  setErrorMsg={setErrorMsg}
                />
                <InfoComplementaire
                  data={row.original}
                  open={open}
                  closeModal={closeModal}
                  setIsOpen={setIsOpen}
                  setErrorMsg={setErrorMsg}
                /> */}
            </>
          );
        },
      },
      {
        accessor: "read_status",
        columnId: 7,
        Cell: ({ row }) => {
          return row.original.admin_read_status === false ? (
            <div className="px-3">
              <span className="flex h-3 w-3 pointer-events-none">
                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-pink-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500"></span>
              </span>
            </div>
          ) : (
            <></>
          );
        },
      },
    ]
    //   [
    //     {
    //       accessor: "name",
    //       columnId: 0,
    //       Cell: ({ cell: { value } }) => (
    //         <div
    //           className="flex text-primary text-sm font-medium py-6 px-2
    //       max-md:text-xs"
    //         >
    //           <span>{value}</span>
    //         </div>
    //       ),
    //     },
    //     {
    //       Cell: () => (
    //         <div className="flex justify-center md:pl-6 pl-2 md:pr-1 pr-4">
    //           <FcGoogle size={20} />
    //         </div>
    //       ),
    //       columnId: 1,
    //       accessor: "logo",
    //     },
    //     {
    //       accessor: "title",
    //       columnId: 2,
    //       Cell: ({ cell: { value } }) => (
    //         <div
    //           className="flex text-primary text-sm font-semibold py-6 px-2
    //       max-md:text-xs justify-center"
    //         >
    //           <span>{value}</span>
    //         </div>
    //       ),
    //     },
    //     {
    //       accessor: "date",
    //       columnId: 3,
    //       Cell: ({ cell: { value } }) => (
    //         <div className="flex text-primary text-[12px] font-normal px-1">
    //           {value}
    //         </div>
    //       ),
    //     },
    //     {
    //       accessor: "site",
    //       columnId: 4,
    //       Cell: ({ cell: { value } }) => (
    //         <div className="flex text-primary text-[12px] font-normal px-1">
    //           {value}
    //         </div>
    //       ),
    //     },
    //     {
    //       accessor: "statut",
    //       columnId: 5,
    //       Cell: ({ cell: { value } }) => {
    //         return value === "PENDING" ? (
    //           <div className="flex text-[12.008px] font-medium leading-none text-primary px-1">
    //             En attente d'examen
    //           </div>
    //         ) : value === "REJECTED" ? (
    //           <div className="flex text-danger text-[12.008px] font-medium leading-none px-1">
    //             Demande rejetée
    //           </div>
    //         ) : (
    //           <div className="flex text-success text-[12.008px] font-medium leading-none px-1">
    //             Demande approuvée
    //           </div>
    //         );
    //       },
    //     },
    //     {
    //       id: "bouton",
    //       Cell: () => (
    //         <div className="">
    //           <button
    //             className="flex justify-center items-center
    //           text-white font-medium bg-primary rounded-[100px]
    //           px-3 py-2 w-[127px] text-[12px] active:scale-95
    //           transition-all duration-150 m-auto"
    //           >
    //             Voir le site
    //           </button>
    //         </div>
    //       ),
    //     },
    //     {
    //       id: "dots",
    //       Cell: ({ row }) => {
    //         const [open, setOpen] = useState("closed");

    //         const closeModal = () => {
    //           setOpen("closed");
    //         };

    //         const showModal = useCallback((term) => {
    //           setOpen(term);
    //         }, []);

    //         return (
    //           <>
    //             <MenuDropdown
    //               reverseColor={false}
    //               trigger={
    //                 <button
    //                   className="active:scale-95 transition-all hover:bg-gray200
    //               p-1 rounded-full"
    //                 >
    //                   <BsThreeDotsVertical size={18} className="cursor-pointer" />
    //                 </button>
    //               }
    //               menu={[
    //                 <MenuDropdownButton
    //                   key={0}
    //                   text="Envoyer un devis"
    //                   onClick={() => showModal("devis")}
    //                 />,
    //                 <MenuDropdownButton
    //                   key={1}
    //                   text="Demande d'informations"
    //                   onClick={() => showModal("info")}
    //                 />,
    //                 <MenuDropdownButton
    //                   key={2}
    //                   text="Refuser la demande"
    //                   // Ici mettre la fonction correspondante
    //                   // pour refuser la demande
    //                   onClick={() => { }}
    //                 />,
    //               ]}
    //             />
    //             {/* utiliser data pour passer des données au modal */}
    //             <InterventionsDevis
    //               data={row.original}
    //               open={open}
    //               closeModal={closeModal}
    //             />
    //             <InfoComplementaire
    //               data={row.original}
    //               open={open}
    //               closeModal={closeModal}
    //             />
    //           </>
    //         );
    //       },
    //     },
    //   ],
    // []
  );

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">
            Demande d'interventions
          </p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <div className="flex">
          <ChangePeriod
            setMonthly={setMonthly}
            setWeekly={setWeekly}
            setToday={setToday}
            active={active}
          />
        </div>
      </Container>
      <div className="my-6 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <CustomWidget
          title="Total interventions"
          value={totalInvoices}
          icon={<FaFileInvoiceDollar className="text-[#858585]" size={20} />}
          text="total_count"
          type={browseType}
          BrowseByType={BrowseByType}
        />
        <CustomWidget
          title="Interventions en cours"
          value={paidInvoices}
          icon={<LiaCheckCircle className="text-success" size={22} />}
          text="in_progress_count"
          type={browseType}
          BrowseByType={BrowseByType}
        />
        <CustomWidget
          title="Intervention en attente"
          value={sentInvoices}
          icon={<FaFileInvoiceDollar className="text-success" size={20} />}
          text="pending_count"
          type={browseType}
          BrowseByType={BrowseByType}
        />
      </div>
      <Card extra={"w-full pb-6 mt-8 !bg-transparent rounded-none"}>
        {backInterventions?.length >= 0 && (
          <CustomBackTableInterventions
            columns={columns}
            data={backInterventions}
            size={8}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </Card>
      {/* <div className="my-6 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <Widget
          title="Total Factures"
          value={totalInvoices}
          icon={<FaFileInvoiceDollar className="text-[#858585]" size={20} />}
        />
        <Widget
          title="Factures Payées"
          value={paidInvoices}
          icon={<LiaCheckCircle className="text-success" size={22} />}
        />
        <Widget
          title="Total Factures Envoyées"
          value={totalInvoices}
          icon={<FaFileInvoiceDollar className="text-success" size={20} />}
        />
      </div> */}
      {detailModal && (
        <BaseModal
          closeModal={() => {
            setDetailModal(false);
          }}
          purpose={detailData?.title}
        >
          <div className="flex flex-col gap-y-4">
            <p className="text-2xl font-black">URL du site:</p>
            <div className="flex justify-center text-[#284F60] text-2xl font-black whitespace-normal break-all">
              <a href={`${detailData?.site_url}`} target="_blank">
                {detailData?.site_url}
              </a>
            </div>
            <div className="flex flex-col whitespace-normal break-words gap-y-4">
              {/* <p className="text-2xl font-black">Information:</p>
              <p className="bg-slate-300 p-3 rounded-2xl">{detailData?.information}</p> */}
              <p className="text-2xl font-black">
                Informations Complémentaires:
              </p>
              <p className="bg-gray-300 p-3 rounded-2xl">
                {detailData?.additional_information}
              </p>
            </div>
            {/* <div className="flex justify-center">
              <button className="bg-[#284F60] text-white w-56 h-12 rounded-[50px]" onClick={(event) => {
                navigate("/");
              }}>
                Retour à l’accueil
              </button>
            </div> */}
          </div>
        </BaseModal>
      )}
      {isOpen && (
        <BaseModal
          closeModal={() => {
            setIsOpen(false);
          }}
          purpose="Error"
        >
          {errorMsg}
        </BaseModal>
      )}
      <InterventionsDevis
        data={data}
        open={open}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        setErrorMsg={setErrorMsg}
      />
      <InfoComplementaire
        data={data}
        open={open}
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        setErrorMsg={setErrorMsg}
      />
    </>
  );
};

export default BackInterventions;
