import { useState, useMemo, useEffect } from "react";

import AreaComponent from "components/Charts/AnalyseResultat/AreaComponent";
import DisplayParams from "components/DisplaySearchParams/DisplayParams";
import CustomDisplayParams from "components/DisplaySearchParams/CustomDisplayParams";
import DisplayNumber from "components/DisplayResultNumber/DisplayNumber";
import RadialComponent from "components/Charts/AnalyseResultat/RadialComponent";
import DisplayStat from "components/DisplayStat/DisplayStat";
import TableAnalyses from "components/Tables/TableAnalyses";
import Container from "components/Container/Container";
// import { AnalyseModal } from "components/Modals/Modals";
import { GetAllAnalysisService, GetAnalysisByKeywordUrl, AddAdditionalKeywordUrl } from "services/Analysis";
import Card from "components/Card/Card";
import {
  radialchart1,
  radialchart2,
  radialchart3,
} from "constants/charts/AnalyseResultat/RadialComponent";

import google from "assets/svg/google.svg";
import plus from "assets/svg/Plus.svg";

import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCirclePlus } from "react-icons/fa6";
import { IoSearchSharp } from "react-icons/io5";

import { areachart } from "constants/charts/AnalyseResultat/AreaComponent";
// import { dataAnalyses } from "constants/analyses";
import BaseModal from "components/Modals/BaseModal";
import Button from "components/Buttons/Button";
import { AlertModal } from "components/Modals/Modals";
import { PayForNewKeywordUrl } from "services/Analysis";
import MenuDropdown from "components/Dropdowns/MenuDropdown";
import { MenuDropdownButton } from "components/Dropdowns/MenuDropdown";
import { Dropdown } from 'flowbite-react';
import CustomMenuDropdown, { CustomMenuDropdownButton } from "components/Dropdowns/CustomMenuDropdown";


const Analyse = () => {
  // const [modal, setModal] = useState(false);
  const [analyses, setAnalyses] = useState([]);
  const [positiveCount, setPositiveCount] = useState(0);
  const [negativeCount, setNegativeCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const user = JSON.parse(localStorage.getItem('user'));
  const [isOpen, setIsOpen] = useState(false);
  const [isNewKeywordUrlOpen, setIsNewKeywordUrlOpen] = useState(false);
  const [keywordUrl, setKeywordUrl] = useState("");
  const [keywordUrlId, setKeywordUrlId] = useState("");
  const [keywordUrlLabel, setKeywordUrlLabel] = useState("");
  const [keywordUrls, setKeywordUrls] = useState([]);
  // const [category, setCategory] = useState("positive");
  const [category, setCategory] = useState("");
  const [additionalKeywordUrl, setAdditionalKeywordUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState('');
  const [cardData, setCardData] = useState({});

  const updateCategory = (value) => {
    setCategory(value);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetAllAnalysisService();

      if (response?.status === 200) {
        var analyseData = response.data?.analyse?.result;
        if (analyseData === undefined)
          return;
        //analyseData = analyseData.filter(item => item.label === category);
        setKeywordUrls(response.data?.analyse?.keyword_urls)
        setAnalyses(response.data?.analyse?.result ?? []);
        setTotalCount(response.data?.analyse?.result?.length ?? 0)
        setPositiveCount(response.data?.analyse?.positive_count ?? 0);
        setNegativeCount(response.data?.analyse?.negative_count ?? 0);
        setKeywordUrlLabel(response.data?.analyse?.keyword_urls?.[0].keyword_url);
        setAdditionalKeywordUrl(response.data?.analyse?.keyword_urls?.[0].additional_keyword_url);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
    }
    fetchData();
  }, []);

  /*
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetAllAnalysisService(keywordUrlId);

      if (response?.status === 200) {
        var analyseData = response.data?.analyse?.result;
        if (analyseData === undefined)
          return;
        analyseData = analyseData.filter(item => item.label === category);
        setAnalyses(analyseData ?? []);
        setTotalCount(response.data?.analyse?.result?.length ?? 0)
        setPositiveCount(response.data?.analyse?.positive_count ?? 0);
        setNegativeCount(response.data?.analyse?.negative_count ?? 0);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
    }

    fetchData();
  }, [category]);
  */

  useEffect(() => {
    const fetchData = async () => {
      if (!category.length)
        return;

      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAnalysisByKeywordUrl(keywordUrlId);

      if (response?.status === 200) {
        var analyseData = response.data?.analyse?.result;
        if (analyseData === undefined)
          return;
        analyseData = analyseData.filter(item => item.label === category);
        setAnalyses(analyseData ?? []);
        setTotalCount(response.data?.analyse?.result?.length ?? 0)
        setPositiveCount(response.data?.analyse?.positive_count ?? 0);
        setNegativeCount(response.data?.analyse?.negative_count ?? 0);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }

    fetchData();
  }, [keywordUrlId, category]);

  // const toogleModal = useCallback(() => {
  //   setModal((state) => !state);
  // }, []);

  const columns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <div
            className={`${original.label === "positive"
              ? "bg-success"
              : original.label === "negative"
                ? "bg-danger"
                : "bg-primary"
              } w-[29px] h-[9px] rounded-[100px] px-1 mx-auto`}
          ></div>
        ),
      },
      {
        columnId: 1,
        accessor: "title",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex md:text-sm text-xs
          //   font-medium py-6 px-2 2xl:pl-5 overflow-auto whitespace-normal"
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex md:text-sm text-xs
            font-medium py-6 px-2 2xl:pl-5 overflow-auto whitespace-normal"
          >
            <span className="whitespace-normal break-all">{value}</span>
          </div>
        ),
      },
      {
        columnId: 2,
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-xs
          //   font-normal px-1"
          // >
          //   {value}
          // </div>
          // <div
          //   className="flex text-xs
          //   font-normal px-1"
          // >
          <div
            className="flex text-xs
            font-normal px-1 w-[110px]"
          >
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? ''}
            </span>
          </div>
        ),
      },
      {
        columnId: 3,
        accessor: "link",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-xs
          //   font-normal px-1 overflow-auto whitespace-normal"
          // >
          //   {value}
          // </div>
          <div
            className="flex text-xs
            font-normal px-1 overflow-auto whitespace-normal"
          >
            <span className="whitespace-normal break-all">
              {value}
            </span>
          </div>
        ),
      },
    ], []
  );

  // const data = useMemo(() => dataAnalyses, []);
  const [items, setItems] = useState([]);
  const [loadedCount, setLoadedCount] = useState(5);
  const itemsPerPage = 5;
  const hasMoreData = loadedCount < analyses.length;

  useEffect(() => {
    setItems(analyses.slice(0, loadedCount));
  }, [analyses, loadedCount]);

  const fetchMoreData = () => {
    setTimeout(() => {
      const nextItems = analyses.slice(0, loadedCount + itemsPerPage);
      setItems(nextItems);
      setLoadedCount(loadedCount + itemsPerPage);
    }, 1000);
  };

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Résultat d'analyse</p>
          <span>
            <img src={google} alt="google" className="w-[96px] h-8" />
          </span>
        </div>
        <div className="flex relative w-full max-w-xl">
          <input
            type="text"
            className="w-full h-12 bg-white
            rounded-[100px] pl-6 outline-none
            border-none focus:ring-0 max-md:text-sm"
            placeholder="Exemple : http://www.yours.com"
            value={keywordUrl}
            onChange={(event) => {
              setKeywordUrl(event.target.value);
            }}
          />
          <button
            className={`flex items-center justify-center
          bg-main text-white w-52 h-full rounded-[100px]
            md:gap-x-2 active:scale-95 transition-all duration-200
            absolute right-0 max-md:w-24`}
            onClick={(event) => {
              var result = false;
              keywordUrls.forEach((value, index) => {
                if (value.keyword_url === keywordUrl) {
                  setErrorMsg("Ce mot-clé/URL existe déjà.");
                  setIsNewKeywordUrlOpen(true);
                  result = true;
                }
              });
              if (result)
                return;
              if (keywordUrl.length)
                setIsOpen(true);
            }}
          >
            <FaCirclePlus size={20} className="" />
            <span className="font-medium text-xs max-md:hidden">
              Ajouter mot clé/URL
            </span>
          </button>
        </div>
      </Container>
      <Card
        extra={
          "w-full sm:overflow-x-auto mt-8 mb-4 h-auto pb-6 !bg-transparent"
        }
      >
        <div
          className="flex max-md:flex-col justify-between
          md:mb-24 mb-8"
        >
          <DisplayStat extra="bg-white text-main shadow-xl">
            <div
              className="flex max-md:hidden justify-between
              items-center"
            >
              <div className="flex flex-col gap-y-2">
                <p className="text-sm text-main font-medium text-center">
                  <span className="whitespace-normal break-all">
                    Recherche
                  </span>
                </p>
                <span
                  className="bg-main text-white rounded-full
               w-14 h-14 flex items-center justify-center
               cursor-pointer"
                // onClick={async (event) => {
                //   // if (!refineSearch.length)
                //   //   return;
                //   var data = {
                //     "keyword_url": keywordUrlLabel,
                //     "additional_keyword_url": additionalKeywordUrl
                //   };
                //   document.getElementById("prewaiting").removeAttribute("hidden");
                //   const response = await AddAdditionalKeywordUrl(data);
                //   document.getElementById("prewaiting").setAttribute("hidden", "true");
                //   if (response?.status === 200) {
                //     var analyseData = response.data?.analyse?.result;
                //     // if (analyseData === undefined)
                //     //   return;
                //     analyseData = analyseData?.filter(item => item.label === category);
                //     setAnalyses(analyseData ?? []);
                //     setTotalCount(response.data?.analyse?.result?.length ?? 0)
                //     setPositiveCount(response.data?.analyse?.positive_count ?? 0);
                //     setNegativeCount(response.data?.analyse?.negative_count ?? 0);
                //   }
                // }}
                >
                  <IoSearchSharp size={35} />
                </span>
              </div>
              {/* <DisplayNumber text="Nombre de résultat" number={analyses?.length ?? 0} /> */}
              <DisplayNumber text="Nombre de résultat" number={totalCount ?? 0} />
              {/* <DisplayParams
                text="Mot clé/url"
                params={user?.site_internet}
              /> */}
              <div className="flex flex-col gap-y-2 text-center">
                <p className="text-sm text-main font-medium">Mot clé/url</p>
                <Dropdown
                  label={keywordUrlLabel}
                  color="black"
                >
                  {keywordUrls?.map((value, index) => {
                    return <Dropdown.Item onClick={async (event) => {
                      setKeywordUrlId(value.search_id);
                      setKeywordUrlLabel(value.keyword_url);
                      setAdditionalKeywordUrl(value.additional_keyword_url);
                    }}>
                      {value.keyword_url}
                    </Dropdown.Item>
                  })}
                </Dropdown>
              </div>
              {/* <span className="mt-12">
                <img src={plus} alt="plus" sizes={20} />
              </span> */}
              <CustomDisplayParams text="Affiner ma recherche" setAdditionalKeywordUrl={setAdditionalKeywordUrl} value={additionalKeywordUrl} />
              {/* <span className="cursor-pointer">
                <BsThreeDotsVertical size={20} />
              </span> */}
              <CustomMenuDropdown
                trigger={
                  <button className="active:scale-95 transition-all">
                    <BsThreeDotsVertical size={18} />
                  </button>
                }
                menu={[
                  <CustomMenuDropdownButton text="Lancer l’analyse" onClick={async (event) => {
                    var data = {
                      "keyword_url": keywordUrlLabel,
                      "additional_keyword_url": additionalKeywordUrl
                    };
                    document.getElementById("prewaiting").removeAttribute("hidden");
                    const response = await AddAdditionalKeywordUrl(data);
                    document.getElementById("prewaiting").setAttribute("hidden", "true");
                    if (response?.status === 200) {
                      var analyseData = response.data?.analyse?.result;
                      // if (analyseData === undefined)
                      //   return;
                      analyseData = analyseData?.filter(item => item.label === category);
                      setAnalyses(analyseData ?? []);
                      setTotalCount(response?.data?.analyse?.result?.length ?? 0);
                      setPositiveCount(response?.data?.analyse?.positive_count ?? 0);
                      setNegativeCount(response?.data?.analyse?.negative_count ?? 0);
                      setKeywordUrls(response?.data?.analyse?.keyword_urls);
                      response?.data?.analyse?.keyword_urls?.forEach((value, index) => {
                        if (value.keyword_url === keywordUrlLabel) {
                          setKeywordUrlId(value.search_id);
                          return;
                        }
                      });
                    }
                  }} />
                ]}
              />
              {/* <span
                className="bg-main text-white rounded-full
               w-14 h-14 flex items-center justify-center
               cursor-pointer"
                onClick={async (event) => {
                  // if (!refineSearch.length)
                  //   return;
                  var data = {
                    "keyword_url": keywordUrlLabel,
                    "additional_keyword_url": additionalKeywordUrl
                  };
                  document.getElementById("prewaiting").removeAttribute("hidden");
                  const response = await AddAdditionalKeywordUrl(data);
                  document.getElementById("prewaiting").setAttribute("hidden", "true");
                  if (response?.status === 200) {
                    var analyseData = response.data?.analyse?.result;
                    // if (analyseData === undefined)
                    //   return;
                    analyseData = analyseData?.filter(item => item.label === category);
                    setAnalyses(analyseData ?? []);
                    setTotalCount(response.data?.analyse?.result?.length ?? 0)
                    setPositiveCount(response.data?.analyse?.positive_count ?? 0);
                    setNegativeCount(response.data?.analyse?.negative_count ?? 0);
                  }
                }}
              >
                <IoSearchSharp size={35} title="Lancer l’analyse"/>
              </span> */}
              {/* <div className="flex flex-col gap-y-2">
                <p className="text-sm text-main font-medium text-center">
                  <span className="whitespace-normal break-all">
                    Recherche
                  </span>
                </p>
                <span
                  className="bg-main text-white rounded-full
               w-14 h-14 flex items-center justify-center
               cursor-pointer"
                  onClick={async (event) => {
                    // if (!refineSearch.length)
                    //   return;
                    var data = {
                      "keyword_url": keywordUrlLabel,
                      "additional_keyword_url": additionalKeywordUrl
                    };
                    document.getElementById("prewaiting").removeAttribute("hidden");
                    const response = await AddAdditionalKeywordUrl(data);
                    document.getElementById("prewaiting").setAttribute("hidden", "true");
                    if (response?.status === 200) {
                      var analyseData = response.data?.analyse?.result;
                      // if (analyseData === undefined)
                      //   return;
                      analyseData = analyseData?.filter(item => item.label === category);
                      setAnalyses(analyseData ?? []);
                      setTotalCount(response.data?.analyse?.result?.length ?? 0)
                      setPositiveCount(response.data?.analyse?.positive_count ?? 0);
                      setNegativeCount(response.data?.analyse?.negative_count ?? 0);
                    }
                  }}
                >
                  <IoSearchSharp size={35} title="Recherche" />
                </span>
              </div> */}
            </div>
            <div className="justify-between items-center max-md:flex hidden">
              <span
                className="bg-main text-white rounded-full
               md:w-14 md:h-14 flex items-center justify-center
               cursor-pointer w-10 h-10"
              >
                <IoSearchSharp size={25} />
              </span>
              <span className="cursor-pointer">
                <BsThreeDotsVertical size={20} />
              </span>
            </div>
            <div className="max-md:flex hidden flex-col gap-y-3 mt-6">
              <DisplayNumber text="Nombre de résultat" number={analyses?.length ?? 0} />
              <div className="flex flex-col">
                <DisplayParams
                  text="Mot clé/url"
                  params={user?.site_internet}
                />
                {/* <span className="md:mt-12 flex justify-center mt-3">
                  <img src={plus} alt="plus" sizes={20} />
                </span> */}
                <DisplayParams text="Affiner ma recherche" params={user?.keyword_url} />
              </div>
            </div>
            <div
              className="flex max-md:flex-col w-full justify-between
            max-md:mt-4"
            >
              <RadialComponent
                status="Positif"
                number={positiveCount}
                options={radialchart1.options}
                series={isNaN(Math.round(positiveCount / totalCount * 100)) ? 0 : Math.round(positiveCount / totalCount * 100)}
                setCategory={updateCategory}
                statuse="positive"
                category={category}
              />
              <RadialComponent
                status="Neutre"
                number={totalCount - positiveCount - negativeCount}
                options={radialchart2.options}
                series={isNaN(100 - Math.round(positiveCount / totalCount * 100) - Math.round(negativeCount / totalCount * 100)) ?
                  0 : (100 - Math.round(positiveCount / totalCount * 100) - Math.round(negativeCount / totalCount * 100))}
                setCategory={updateCategory}
                statuse="neutral"
                category={category}
              />
              <RadialComponent
                status="Négatif"
                number={negativeCount}
                options={radialchart3.options}
                series={isNaN(Math.round(negativeCount / totalCount * 100)) ? 0 : (Math.round(negativeCount / totalCount * 100))}
                setCategory={updateCategory}
                statuse="negative"
                category={category}
              />
            </div>
          </DisplayStat>
          {/* <div
            className="flex max-md:mt-16 md:w-[15%]
            items-center justify-center"
          >
            <AreaComponent
              options={areachart.options}
              series={areachart.series}
              currentPrice="4000"
              total="10000"
            />
          </div> */}
        </div>
        <TableAnalyses
          columns={columns}
          data={items}
          setData={setItems}
          update={fetchMoreData}
          hasMore={hasMoreData}
          category={category}
        // toogleModal={toogleModal}
        />
      </Card>
      {
        isOpen &&
        /*
        <BaseModal purpose="Confirmer" closeModal={() => { setIsOpen(false) }}>
          <div className="flex flex-col">
            <div className="justify-center">
              <div className="whitespace-nowrap overflow-x-scroll scrollbar-hide">
                <table className="w-full text-black text-sm" style={{ border: "1px solid #e5e7eb" }}>
                  <thead>
                    <tr>
                      <th className="text-start border-b border-linecolor pb-3">
                        <span className="font-normal">Détail de la facture</span>
                      </th>
                      <th className="text-start border-b border-linecolor pb-3 px-1">
                        <span className="font-normal">Prix</span>
                      </th>
                      <th className="text-start border-b border-linecolor pb-3 px-1">
                        <span className="font-normal">Qté</span>
                      </th>
                      <th className="text-start border-b border-linecolor pb-3 px-1">
                        <span className="font-normal">Total</span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="w-full flex justify-center space-x-4">
                <button
                  className="bg-success text-white rounded-[100px] px-10 py-2 text-[12px]"
                  onClick={async (event) => {
                    document.getElementById("prewaiting").removeAttribute("hidden");
                    var postData = {
                      "new_keywordurl": keywordUrl
                    };
                    const response = await PayForNewKeywordUrl(postData);
                    setIsOpen(false);
                    document.getElementById("prewaiting").setAttribute("hidden", "true");
                  }}
                >
                  Oui
                </button>
                <button
                  className="bg-danger text-white rounded-[100px] px-10 py-2 text-[12px]"
                  onClick={(event) => {
                    document.getElementById("prewaiting").removeAttribute("hidden");
                    setIsOpen(false);
                    document.getElementById("prewaiting").setAttribute("hidden", "true");
                  }}
                >
                  Non
                </button>
              </div>
            </div>
          </div>
        </BaseModal> */
        <BaseModal purpose="Option ajout de mot cle/URL" closeModal={() => { setIsOpen(false) }}>
          <div
            className="flex flex-col whitespace-normal text-[14px]
        leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
          >
            <div className="text-sm ml-6 text-main font-semibold">
              Date: {new Date().toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })}
            </div>
            <div className="text-2xl ml-6 font-black">
              Detail du devis
            </div>
            <div className="text-main rounded-xl text-sm p-6">
              <table className="w-full text-black text-sm">
                <thead>
                  <tr>
                    <th className="text-start border-b border-linecolor pb-3">
                      <span className="font-normal">Détail de la facture</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">Prix</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">Qté</span>
                    </th>
                    <th className="text-start border-b border-linecolor pb-3 px-1">
                      <span className="font-normal">Total</span>
                    </th>
                  </tr>
                </thead>
                <tr>
                  <td className="py-3">New Keyword/URL</td>
                  <td className="px-1">10€/mois</td>
                  <td className="px-1">1</td>
                  <td className="px-1">10 €</td>
                </tr>
                <tr className="font-black text-xl leading-[5rem]">
                  <td colSpan={3}>
                    Total
                  </td>
                  <td>
                    10€
                  </td>
                </tr>
              </table>
            </div>

            <div className="flex justify-end mt-6">
              <button
                className="bg-primary text-white rounded-[100px] px-10
              py-2 text-xs w-48 h-10"
                onClick={async (event) => {
                  document.getElementById("prewaiting").removeAttribute("hidden");
                  var postData = {
                    "new_keywordurl": keywordUrl
                  };
                  const response = await PayForNewKeywordUrl(postData);
                  if (response?.status === 200) {
                    setKeywordUrlId(response?.data?.gs_result?.search_id)
                    setKeywordUrlLabel(response?.data?.new_keywordurl);
                    var new_keyword = {
                      "keyword_url": response?.data?.new_keywordurl,
                      "additional_keyword_url": "",
                      "search_id": response?.data?.gs_result?.search_id
                    }
                    setKeywordUrls([...keywordUrls, new_keyword]);
                    setAdditionalKeywordUrl("");
                  }
                  setIsOpen(false);
                  document.getElementById("prewaiting").setAttribute("hidden", "true");
                }}
              >
                Confirmer
              </button>
            </div>
          </div>
        </BaseModal>
      }
      {
        isNewKeywordUrlOpen &&
        <BaseModal closeModal={() => { setIsNewKeywordUrlOpen(false) }} purpose="Error">
          {errorMsg}
        </BaseModal>
      }
    </>
  );
};

export default Analyse;
