import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputError from "components/InputError/InputError";
import InputLabel from "components/InputLabel/InputLabel";
import Button from "components/Buttons/Button";
import Input from "components/Input/Input";
import { Confirm, SignUpService } from "services/UserAuthentication";
import BaseModal from "components/Modals/BaseModal";
import GoogleSignIn from "components/GoogleSignIn";
import GoogleSignUp from "components/GoogleSignUp";
import { saveAs } from "file-saver";

const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});

  // Soumission du formulaire d'inscription
  const onSubmit = async (data) => {
    // const fullName = `${data.firstname} ${data.lastname}`;
    // const updatedData = { ...data, full_name: fullName };
    // const response = await SignUpService(updatedData);
    // if (response?.status === 200) {
    //   const { jwt, user } = response?.data;
    //   localStorage.setItem('jwt', jwt);
    //   localStorage.setItem('jwt_type', 'Bearer');
    //   localStorage.setItem('user', JSON.stringify(user));
    //   navigate('/register-success');
    // } else {
    //   setErrorMsg(response?.data?.detail);
    //   setIsOpen(true);
    // }
    var userData = {
      firstname: data.firstname,
      lastname: data.lastname,
      password: data.password,
      email: data.email,
      full_name: data.full_name,
      verified_email: false,
      type_email: true,
    };

    document.getElementById("prewaiting").removeAttribute("hidden");
    const response = await Confirm({
      email: data.email,
      username: data.full_name,
    });
    document.getElementById("prewaiting").setAttribute("hidden", "true");
    if (response?.status === 200) {
      navigate("/register-steps", { state: { userData } });
    } else {
      setErrorMsg(response?.data?.detail);
      setIsOpen(true);
    }
    // navigate("/register-steps", { state: { userData } });
  };

  const updateParentState = async (userData) => {
    document.getElementById("prewaiting").removeAttribute("hidden");
    const response = await Confirm({
      email: userData.email,
      username: userData?.given_name + " " + userData?.family_name,
    });
    document.getElementById("prewaiting").setAttribute("hidden", "true");

    if (response?.status === 200) {
      userData["type_email"] = false;
      userData["firstname"] = userData?.given_name;
      userData["lastname"] = userData?.family_name;
      userData["full_name"] =
        userData?.given_name + " " + userData?.family_name;
      setProfile(userData);
      navigate("/register-steps", { state: { userData } });
    } else {
      setErrorMsg(response?.data?.detail);
      setIsOpen(true);
    }
  };

  return (
    <div className="flex flex-col gap-y-2 max-sm:pt-8 ml-4 mr-4">
      <div className="flex flex-col gap-y-2">
        <h2
          className="font-bold text-main
          sm:text-3xl text-2xl"
        >
          Welcome to Reeact!
        </h2>
        <p
          className="font-medium sm:text-xl
        text-gray800"
        >
          Connectez vous à votre compte
        </p>
      </div>
      <GoogleSignUp updateParentState={updateParentState} />
      <p className="text-gray600 text-[14px]">
        ou utiliser votre adresse mail pour vous enregistrer
      </p>

      {/* Formulaire d'inscription */}
      <form className="flex flex-col gap-y-2" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="flex max-sm:flex-col justify-between
        max-sm:gap-y-4"
        >
          <div className="flex flex-col gap-y-2">
            <InputLabel htmlFor="firstname">Prénom</InputLabel>
            <Input
              id="firstname"
              {...register("firstname", { required: true })}
              placeholder="Entrez le prénom."
              autoComplete="given-name"
              className="sm:w-[218px] h-12 pl-4 outline-none w-full"
            />
            {errors.firstname && <InputError message="Firstname is required" />}
          </div>
          <div className="flex flex-col gap-y-2">
            <InputLabel htmlFor="lastname">Nom</InputLabel>
            <Input
              id="lastname"
              {...register("lastname", { required: true })}
              placeholder="Entrez le nom de famille."
              autoComplete="family-name"
              className="sm:w-[218px] h-12 pl-4 outline-none w-full"
            />
            {errors.lastname && <InputError message="Lastname is required" />}
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="email">Adresse email</InputLabel>
          <Input
            type="email"
            id="email"
            autoComplete="email"
            {...register("email", { required: true })}
            placeholder="Entrez votre adresse email"
            className="sm:w-[452px] h-12 pl-4 outline-none w-full"
          />
          {errors.email && <InputError message="Email is required" />}
        </div>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="Username">Nom d'utilisateur</InputLabel>
          <Input
            id="username"
            placeholder="Entrez votre nom d'utilisateur"
            autoComplete="off"
            {...register("full_name", { required: true })}
            className="w-full"
          />
          {errors.full_name ? (
            <InputError message={"Ce champ est obligatoire."} />
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="password">Mot de passe</InputLabel>
          <Input
            type="password"
            id="password"
            autoComplete="new-password"
            {...register("password", { required: true })}
            placeholder="Entrez votre mot de passe"
            className="sm:w-[452px] h-12 pl-4 outline-none w-full"
            minLength={6}
          />
          {errors.password && <InputError message="Password is required" />}
        </div>
        <span className="flex items-center gap-x-4 mt-4">
          <input
            type="checkbox"
            id="checked"
            {...register("checked", { required: true })}
            className={`w-6 h-6 rounded-md form-checkbox cursor-pointer focus:ring-0 ${
              errors.checked ? "text-red border-red" : "text-primary"
            }`}
          />
          <InputLabel
            htmlFor="checked"
            className={`font-[400] text-[14px] cursor-pointer select-none ${
              errors.checked && "text-red"
            }`}
          >
            J’accepte les{" "}
            <span
              className="hover:underline font-bold"
              onClick={(event) => {
                window.open(
                  process.env.REACT_APP_SERVER_URL +
                    "static/terms/CGV-CGU-RGPD REEACT V2.pdf"
                );
              }}
            >
              conditions générales de ventes
            </span>
          </InputLabel>
        </span>
        <Button
          type="submit"
          className="sm:w-[452px] h-12 rounded-[48px]
          text-white bg-main mb-4 mt-4 w-full"
        >
          S’inscrire
        </Button>
      </form>
      <div
        className="flex flex-row justify-center gap-x-2
         font-medium text-gray800 text-[14px]
         max-sm:mb-4"
      >
        <span className="text-center">
          Vous avez un compte Reeact ?{" "}
          <Link to="/login" className="hover:underline">
            Se connecter
          </Link>
        </span>
      </div>
      {isOpen && (
        <BaseModal
          closeModal={() => {
            setIsOpen(false);
          }}
          purpose="Error"
        >
          {errorMsg}
        </BaseModal>
      )}
    </div>
  );
};

export default SignUp;
