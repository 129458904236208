import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});



const GetStatistics = async (active) => {
    try {
        const response = await api.get(`admin/statistics/${active}`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

const DownloadStatisticsReport = async (active) => {
    try {
        const response = await api.get(`admin/turnover/download/${active}`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export {
    GetStatistics,
    DownloadStatisticsReport
};