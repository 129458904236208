const AnalyseDisplayParams = ({ text, setAdditionalKeywordUrl, value, disabled = 0 }) => {
  return (
    <div className="flex flex-row">
      <div className="flex flex-col gap-y-2">
        <p className="text-sm text-main font-medium">
          <span className="whitespace-normal break-all">
            {text}
          </span>
        </p>
        {/* <span
        className="flex items-center pl-3 w-full
      bg-gray200 rounded-[100px] md:w-52 h-10"
      >
        <p className="text-main text-sm font-semibold">{params}</p>
      </span> */}
        <input
          type="text"
          onChange={(event) => {
            setAdditionalKeywordUrl(event.target.value);
          }}
          // className="flex items-center pl-3 w-full bg-gray200 rounded-[100px] md:w-52 h-10 focus:outline-none border-none"
          className="flex items-center pl-3 w-full bg-gray200 rounded-[100px] xl:w-52 max-xl:w-32 h-10 focus:outline-none border-none"
          value={value}
          disabled={disabled}
        />
      </div>
      <div className="flex items-end pb-2 pl-5">
        <svg width="22" height="23" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 18.0105C11.3869 18.0105 13.6761 17.0623 15.364 15.3745C17.0518 13.6866 18 11.3974 18 9.0105C18 6.62355 17.0518 4.33436 15.364 2.64654C13.6761 0.95871 11.3869 0.010498 9 0.010498C6.61305 0.010498 4.32387 0.95871 2.63604 2.64654C0.948212 4.33436 0 6.62355 0 9.0105C0 11.3974 0.948212 13.6866 2.63604 15.3745C4.32387 17.0623 6.61305 18.0105 9 18.0105ZM8.15625 12.1042V9.85425H5.90625C5.43867 9.85425 5.0625 9.47808 5.0625 9.0105C5.0625 8.54292 5.43867 8.16675 5.90625 8.16675H8.15625V5.91675C8.15625 5.44917 8.53242 5.073 9 5.073C9.46758 5.073 9.84375 5.44917 9.84375 5.91675V8.16675H12.0938C12.5613 8.16675 12.9375 8.54292 12.9375 9.0105C12.9375 9.47808 12.5613 9.85425 12.0938 9.85425H9.84375V12.1042C9.84375 12.5718 9.46758 12.948 9 12.948C8.53242 12.948 8.15625 12.5718 8.15625 12.1042Z" fill="#284F60" />
        </svg>
      </div>
    </div>
  );
};

export default AnalyseDisplayParams;
