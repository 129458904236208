import { useCallback, useRef, useState } from "react";

import logo from "assets/logo.png";
import {
  MdOutlineChat,
  MdOutlineNotifications,
  MdOutlineSettings,
} from "react-icons/md";
import { CgMenuRight, CgArrowLeft } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import NotificationBadge from "components/Badges/NotificationBadge/NotificationBadge";
import NotificationDropdown from "components/Dropdowns/NotificationDropdown";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import SearchForm from "components/forms/Navbar/SearchForm";
import Temperature from "./Temperature";
// import { dataAlertes } from "constants/alertes";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";
import { userRoles } from "constants/Lists";
import { useEffect } from "react";
import { GetUnReadCount, GetLastThreeAlertes, GetLastThreeAdminAlertes } from "services/Navbar";
import CustomNotificationBadge from "components/Badges/NotificationBadge/CustomNotificationBadge";
import AlertNotificationDropdown from "components/Dropdowns/AlertNotificationDropdown";

const Navbar = ({ open, setOpensidebar, admin = false }) => {
  //Etat du dropdown de notification
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [message, setMessage] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadAlert, setUnreadAlert] = useState(0);
  const [dataAlertes, setDataAlertes] = useState(0);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    // Connect to WebSocket server
    const socket = new WebSocket(`wss://${process.env.REACT_APP_SOCKET_SERVER_URL}/ws/${user.id}`);

    // Connection opened
    socket.onopen = () => {

      // Send a message to the server
      // socket.send('Hello, server!');
    };

    // Message received from server
    socket.onmessage = (event) => {
      setMessage(JSON.parse(event.data));
    };

    // Connection closed
    socket.onclose = () => {
    };


    // Clean up the WebSocket connection
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetUnReadCount();
      if (response.status === 200) {
        setUnreadCount(response?.data?.msg_unread_count);
        setUnreadAlert(response?.data?.alert_unread_count);
      }
    }
    const interval = setInterval(() => {
      fetchData();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Fermerture du dropdown
  const handleClose = useCallback(() => {
    setNotificationDropdown(false);
  }, []);

  useOutsideAlerter(wrapperRef, handleClose);

  // Ouverture du dropdown
  const openNotificationDropdown = useCallback(async () => {
    //document.getElementById("prewaiting").removeAttribute("hidden");
    const user = JSON.parse(localStorage.getItem('user'));
    const response = user.role === 0 ? await GetLastThreeAdminAlertes() : await GetLastThreeAlertes();
    if (response?.status === 200) {
      setDataAlertes(response?.data?.last_three_alert);
      setNotificationDropdown(!notificationDropdown);
    }
    //document.getElementById("prewaiting").setAttribute("hidden", "true");
  }, [notificationDropdown]);

  //Ouverture ou fermeture de la Sidebar
  const toggleSidebar = useCallback(() => {
    setOpensidebar((state) => !state);
  }, [setOpensidebar]);

  //Sign out
  const handleSignOut = () => {
    localStorage.clear();
    navigate('/login');
  }

  //Go to settings
  const goToSettings = () => {
    navigate(admin ? "/admin/parametres" : "/parametres");
  }


  return (
    <header className="fixed w-full top-0 flex justify-between items-center py-3 sm:py-2 z-30 bg-white px-1 sm:pl-10 sm:pr-6 z-50">
      <div className="flex items-center gap-x-2">
        <Link to={admin ? "/admin" : "/"}>
          <img src={logo} alt="Reeact logo" className="w-28 sm:w-full" />
        </Link>
        <div
          onClick={toggleSidebar}
          className={`text-primary lg:hidden transition-all duration-300 ease-in-out ${open ? "rotate--90" : ""
            }`}
        >
          {open ? <CgArrowLeft size={25} /> : <CgMenuRight size={25} />}
        </div>
      </div>
      {/* Formulaire de recherche */}
      <div className="hidden lg:block transition-all ease-linear duration-300">  
        <SearchForm />
      </div>
      <div className="flex justify-end items-center">
        <Temperature temperature={message?.current?.temp_c ?? 0} localisation={message?.location?.name} icon={message?.current?.condition?.icon} />

        {/* Alertes  */}
        <div ref={wrapperRef}>
          <CustomNotificationBadge
            icon={
              <MdOutlineNotifications
                onClick={openNotificationDropdown}
                size={20}
              />
            }
            value={unreadAlert}
          />
          <AlertNotificationDropdown
            avatar={process.env.REACT_APP_SERVER_URL + user.avatar_url}
            open={notificationDropdown}
            setOpen={setNotificationDropdown}
            data={dataAlertes ?? []}
          />
        </div>

        {/* <div ref={wrapperRef}>
          <CustomNotificationBadge
            icon={
              <MdOutlineNotifications
                onClick={(event) => {
                  navigate("/alertes");
                }}
                size={20}
              />
            }
            value={unreadAlert}
          />
          <NotificationDropdown
            avatar={process.env.REACT_APP_SERVER_URL + user.avatar_url}
            open={notificationDropdown}
            setOpen={setNotificationDropdown}
            data={dataAlertes.slice(0, 3)}
          />
        </div> */}

        {/* Messagerie  */}
        <Link to={admin ? "/admin/messagerie" : "/messagerie"}>
          <CustomNotificationBadge icon={<MdOutlineChat size={20} />} value={unreadCount} />
        </Link>
        {/* Paramètres */}
        <Link to={admin ? "/admin/parametres" : "/parametres"}>
          <CustomNotificationBadge icon={<MdOutlineSettings size={20} />} value={0} />
        </Link>
        <MenuDropdown
          trigger={
            <div className="flex items-center cursor-pointer">
              <div className="hidden sm:flex flex-col text-primary mr-3">
                <span className="text-[12px] font-bold">
                  {user.full_name}
                </span>
                <span className="text-[10px]">{userRoles[user.role]}</span>
              </div>
              <img src={process.env.REACT_APP_SERVER_URL + user.avatar_url} alt="Avatar" className="h-10 w-10 rounded-full" />
            </div>
          }
          menu={[
            <MenuDropdownButton
              text="Settings"
              onClick={goToSettings}
            />,
            <MenuDropdownButton
              text="Sign out"
              onClick={handleSignOut}
            />,
          ]}
        />
      </div>
    </header>
  );
};
export default Navbar;
