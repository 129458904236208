import { useCallback, useState, useMemo } from "react";

import Container from "components/Container/Container";
import { MdOutlineSettings } from "react-icons/md";
import eebleu from "assets/ee-bleu.png";
import { AlertModal } from "components/Modals/Modals";
import Card from "components/Card/Card";
import TableAlertes from "components/Tables/TableAlertes";
import { FcGoogle } from "react-icons/fc";
import RightButton from "components/Buttons/RightButton";
import { GetAlertes } from "services/Alertes";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Alertes = () => {
  const [modal, setModal] = useState(false);
  const [dataAlertes, setDataAlertes] = useState([]);

  //const data = dataAlertes;

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAlertes();
      if (response.status === 200) {
        setDataAlertes(response?.data?.alert_data);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [modal]);

  const columns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "logo",
        Cell: () => (
          <div className="pl-6 pr-1">
            <FcGoogle size={20} />
          </div>
        ),
      },
      {
        columnId: 1,
        accessor: "label",
        Cell: ({ row: { original } }) => (
          <div
            className={`${
              original.label === "positive"
                ? "bg-success"
                : original.label === "negative"
                ? "bg-danger"
                : original.label === "neutral"
                ? "bg-primary"
                : "bg-warning"
            } w-[29px] h-[9px] rounded-[100px] px-1 mx-auto`}
          ></div>
        ),
      },
      {
        columnId: 2,
        accessor: "title",
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-primary text-sm font-medium
          // py-6 px-2 2xl:pl-5 max-md:text-xs"
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex text-primary text-sm font-medium
          py-6 px-2 2xl:pl-5 max-md:text-xs"
          >
            <span className="whitespace-normal break-all">{value}</span>
          </div>
        ),
      },
      {
        columnId: 3,
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          // <div className="flex text-primary text-xs font-normal px-1">
          //   {value}
          // </div>
          <div className="flex text-primary text-xs font-normal px-1">
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              }) ?? ""}
            </span>
          </div>
        ),
      },
      {
        columnId: 4,
        accessor: "site_url",
        Cell: ({ cell: { value } }) => (
          <div className="flex text-primary text-xs font-normal px-1">
            <span className="whitespace-normal break-all">
              <Link to={value} className="hover:underline" target="_blank">
                {new URL(value).origin}
              </Link>
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const toogleModal = useCallback(() => {
    setModal((state) => !state);
  }, []);

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Alertes</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>

        <RightButton
          icon={<MdOutlineSettings size="1.3rem" />}
          text="Paramètre des alertes"
          onClick={toogleModal}
        />
      </Container>

      {dataAlertes?.length === 0 ? (
        <div className="flex flex-col">
          <div className="sm:mt-48 mt-24">
            <p
              className="font-semibold sm:text-4xl text-main
          text-center text-xl"
            >
              Vous n’avez pas d'alertes
              <br />
              pour le moment.
            </p>
          </div>
          <div className="absolute bottom-0 left-0">
            <img
              src={eebleu}
              alt="ee-logo"
              className="w-2/3 h-2/3 sm:w-full sm:h-full"
            />
          </div>
        </div>
      ) : (
        <Card
          extra={"w-full pb-6 sm:overflow-x-auto mt-8 mb-4 !bg-transparent"}
        >
          {dataAlertes?.length >= 0 && (
            <TableAlertes columns={columns} data={dataAlertes} />
          )}
        </Card>
      )}
      {modal && <AlertModal closeModal={toogleModal} />}
    </>
  );
};

export default Alertes;
