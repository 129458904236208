import Card from "components/Card/Card";
import { useState } from "react";
import { memo } from "react";

const CustomWidget = ({ icon, value, title, text, type, BrowseByType }) => {
  return (
    <Card extra={`bg-white ${text === type ? "border-double border-2 border-cyan-900" : ""} rounded-[22px] px-4 pb-8 md:pb-3 pt-8`} onClick={(event) => {
      BrowseByType(title);
    }}>
      <div className="flex justify-between">
        <div className="flex flex-col text-primary">
          <span className="text-[28.519px] font-semibold">{value}</span>
          <span className="text-[#0f0f0fb3] text-[13.509px] font-normal">
            {title}
          </span>
        </div>
        <div className="w-12 h-14 rounded-full border flex justify-center items-center">
          {icon}
        </div>
      </div>
    </Card>
  );
};

export default memo(CustomWidget);
