import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});


// const user = JSON.parse(localStorage.getItem('user'));

const GetAllInvoicesService = async () => {
  try {
    const response = await api.get(`invoices`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const GetInvoicesByType = async (type) => {
  try {
    const response = await api.get(`invoices/${type}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const GetInvoiceByIdService = async (id) => {
  try {
    const response = await api.get(`invoice/${id}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}
const PayForInvoice = async (id) => {
  try {
    const response = await api.post(`stripe/pay_for_invoice/${id}`, {}, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}
const DownloadInvoices = async (ids) => {
  try {
    const response = await api.post(`invoices/download`, ids, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}
const DeleteInvoices = async (ids) => {
  try {
    const response = await api.post(`invoices/delete`, ids, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

const GetAllPaymentMethod = async (data) => {
  try {
    const response = await api.get(`all_payment_methods`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}


export {
  GetAllInvoicesService,
  GetInvoiceByIdService,
  DownloadInvoices,
  PayForInvoice,
  GetInvoicesByType,
  DeleteInvoices,
  GetAllPaymentMethod
};