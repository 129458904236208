import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});



const GetPromocodes = async () => {
    try {
        const response = await api.get(`admin/promocodes`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const DeletePromocodeById = async (id) => {
    try {
        const response = await api.post(`admin/promocodes/delete`, { "promo_id": id }, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const AddPromocodes = async (payload) => {
    try {
        const response = await api.post(`admin/promocodes`, payload, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export {
    GetPromocodes,
    AddPromocodes,
    DeletePromocodeById
};