import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});



const GetUnReadCount = async () => {
    try {
        const response = await api.get(`get_unread`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.detail === "Code d'autorisation invalide." ||
            error?.response?.data?.detail === "Jeton invalide ou jeton expiré." ||
            error?.response?.data?.detail === "Schéma d'authentification invalide.") {
            localStorage.removeItem("jwt");
            localStorage.removeItem("jwt_type");
            localStorage.removeItem("user");
            window.location.href = "/login";
        }
        return error;
    }
}

const GetLastThreeAlertes = async () => {
    try {
        const response = await api.get(`get_limit_alert`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const GetLastThreeAdminAlertes = async () => {
    try {
        const response = await api.get(`admin/get_limit_alert`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export {
    GetUnReadCount,
    GetLastThreeAlertes,
    GetLastThreeAdminAlertes
};