import { useEffect, useState, useCallback } from "react";

import UserInfo from "components/forms/Parametres/UserInfo";
import NewCard from "components/forms/Parametres/NewCard";
import CustomCardRadio from "components/DisplayCard/CustomCardRadio";
import Container from "components/Container/Container";
import CustomRadio from "components/Radio/CustomRadio";
import Card from "components/Card/Card";

import { FcGoogle } from "react-icons/fc";

import avatar from "assets/avatars/men.png";
import { GetSettingInformation, Unsubscribe } from "services/Parametres";
import SettingSection from "components/ChangePeriod/SettingSection";
import { act } from "react-dom/test-utils";
import { DeleteKeywordModal, UnsubscribeModal } from "components/Modals/Modals";
import "./alert-fade.css";
import { CSSTransition } from "react-transition-group";

const Parametres = () => {
  const [cardSelected, setCardSelected] = useState("");
  const [active, setActive] = useState("subscribe");

  const [userInfo, setUserInfo] = useState({});
  const [keywordUrlData, setKeywordUrlData] = useState();
  const [paymentData, setPaymentData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [deleteKeywordModal, setDeleteKeywordModal] = useState(false);
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);

  const [alert, showAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [update, setUpdate] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const setUpdateForce = useCallback(() => {
    setUpdate((state) => !state);
  }, []);

  const toogleDeleteKeywordModal = useCallback(() => {
    setDeleteKeywordModal((state) => !state);
  }, []);

  const toggleUnsubscribeModal = useCallback(() => {
    setUnsubscribeModal((state) => !state);
  }, []);

  const setSubscribeSetting = useCallback(() => {
    setActive("subscribe");
  }, []);

  const setCardSetting = useCallback(() => {
    setActive("card");
  }, []);

  const setUserSetting = useCallback(() => {
    setActive("user");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetSettingInformation();
      if (response?.status === 200) {
        setUserInfo(response?.data?.user_data ?? []);
        setKeywordUrlData(response?.data?.keyword_url_data ?? []);
        setPaymentData(response?.data?.payment_data ?? []);
        response?.data?.payment_data?.forEach((value, index) => {
          if (value.default) {
            setCardSelected(value.id);
            return;
          }
        })
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }
    fetchData();
  }, [update]);

  useEffect(() => {
    const fetchData = async () => {
      if (!alert)
        return;
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetSettingInformation();
      if (response?.status === 200) {
        setUserInfo(response?.data?.user_data ?? []);
        setKeywordUrlData(response?.data?.keyword_url_data ?? []);
        setPaymentData(response?.data?.payment_data ?? []);
        response?.data?.payment_data?.forEach((value, index) => {
          if (value.default) {
            setCardSelected(value.id);
            return;
          }
        });
        setTimeout(() => {
          showAlert(false);
        }, 3000);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }
    fetchData();
  }, [alert]);

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-5">
          <p className="font-semibold text-2xl text-main">
            Paramètres de compte
          </p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
      </Container>
      <div className="flex justify-center mt-5">
        <SettingSection
          setSubscribeSetting={setSubscribeSetting}
          setCardSetting={setCardSetting}
          setUserSetting={setUserSetting}
          active={active}
        />
      </div>
      <CSSTransition
        in={alert}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div className={"flex flex-row mt-5 rounded-[20px] bg-[#DB5461] w-full h-109 p-6"}>
          <div className="mr-5">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1718_9621)">
                <path d="M14.668 9.33329H17.3346V12H14.668V9.33329ZM14.668 14.6666H17.3346V22.6666H14.668V14.6666ZM16.0013 2.66663C8.6413 2.66663 2.66797 8.63996 2.66797 16C2.66797 23.36 8.6413 29.3333 16.0013 29.3333C23.3613 29.3333 29.3346 23.36 29.3346 16C29.3346 8.63996 23.3613 2.66663 16.0013 2.66663ZM16.0013 26.6666C10.1213 26.6666 5.33464 21.88 5.33464 16C5.33464 10.12 10.1213 5.33329 16.0013 5.33329C21.8813 5.33329 26.668 10.12 26.668 16C26.668 21.88 21.8813 26.6666 16.0013 26.6666Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1718_9621">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="flex flex-col gap-y-3 text-white">
            <div>Votre demande a bien été enregistrée</div>
            <div>Votre abonnement prendra fin a la date anniversaire a laquelle il a été souscrit.</div>
          </div>
        </div>
      </CSSTransition>
      {/* <Card extra="w-full p-3 sm:p-10 mt-8 mb-8"> */}
      <Card extra="w-full mt-8 mb-8">
        {
          active === "subscribe" ?
            <div className="flex w-full bg-white rounded-[20px] pt-8 md:px-16 justify-center pb-8 px-4">
              {/* <div className="flex w-full flex-col gap-y-10">
                <div className="flex justify-between">
                  <div className="flex gap-x-4 items-center">
                    <span className="flex">
                      <img
                        src={process.env.REACT_APP_SERVER_URL + user.avatar_url}
                        alt="Avatar"
                        className="h-10 w-10 rounded-full"
                      />
                    </span>
                    <div className="flex flex-col gap-y-2">
                      <p className="font-semibold text-sm text-main">
                        {userInfo.full_name}
                      </p>
                      <p className="font-normal text-main text-sm">{
                        userInfo.role === 0 ? "Super Admin" : userInfo.role === 1 ? "Admin" : "User"
                      }</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-2 md:mr-14">
                  <div className="flex flex-col gap-y-2">
                    <p className="text-green text-sm font-semibold">Actif</p>
                    {keywordUrlData?.length > 0 && <p className="font-normal text-sm text-main">{`29€ ${keywordUrlData.length > 1 ? `+ ${10 * (keywordUrlData?.length - 1)}€` : ""}`}</p>}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col gap-y-2 md:ml-14">
                    <p className="font-bold text-sm text-main">Mots clés</p>
                    <p className="text-main text-sm font-semibold">
                      {
                        keywordUrlData?.length > 0 && keywordUrlData?.map((value, index) => {
                          if (index === 0)
                            return `${value.keyword_url}`;
                          else
                            return ` | ${value.keyword_url}`;
                        })
                      }
                    </p>
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <p className="font-semibold text-sm text-main">
                      Moteurs/Réseaux
                    </p>
                    <FcGoogle size={20} />
                  </div>
                </div>
              </div> */}
              <div className="flex w-full flex-col gap-y-10 text-[#284F60]">
                <div className="flex justify-between items-center text-[19px] font-semibold gap-x-5 max-xl:text-[13px]">
                  <span className="flex whitespace-normal break-words items-center gap-x-3">
                    <span>Votre abonnement Reeact :&nbsp;</span><span className={`flex ${userInfo?.subscription_at ? `text-[#2BC155]` : `text-danger`}`}>{userInfo?.subscription_at ? "Actif" : "Inactif"}</span>
                  </span>
                  <span className="flex">
                    {userInfo?.subscription_at ? "29€/mois" : ""}
                  </span>
                  <span className="flex">
                    {userInfo?.subscription_at ? <svg width="81" height="27" viewBox="0 0 81 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.8539 9.35896H10.4283V12.1513H17.1041C16.7761 16.0789 13.5045 17.736 10.4283 17.736C6.47868 17.736 3.05408 14.6378 3.05408 10.2955C3.05408 6.06207 6.32732 2.81249 10.4283 2.81249C13.5912 2.81249 15.469 4.8417 15.469 4.8417L17.432 2.81249C17.432 2.81249 14.9235 0.0201416 10.3416 0.0201416C4.51568 0.0201416 0 4.95207 0 10.253C0 15.4671 4.25395 20.5504 10.4945 20.5504C15.9925 20.5504 20.0068 16.7758 20.0068 11.2116C20.0068 10.0354 19.8539 9.35896 19.8539 9.35896Z" fill="#4285F4" />
                      <path d="M27.5551 7.32971C23.6937 7.32971 20.9219 10.3617 20.9219 13.8746C20.9219 17.4522 23.6054 20.5299 27.5976 20.5299C31.2193 20.5299 34.1646 17.7596 34.1646 13.9629C34.1883 9.57652 30.7416 7.32971 27.5551 7.32971ZM27.5992 9.92654C29.4976 9.92654 31.3076 11.4544 31.3076 13.9408C31.3076 16.3626 29.5181 17.9551 27.5992 17.9551C25.4833 17.9551 23.8246 16.2744 23.8246 13.9408C23.8246 11.6278 25.4612 9.92654 27.5992 9.92654Z" fill="#EA4335" />
                      <path d="M41.9965 7.32971C38.1351 7.32971 35.3633 10.3617 35.3633 13.8746C35.3633 17.4522 38.0468 20.5299 42.039 20.5299C45.6607 20.5299 48.606 17.7596 48.606 13.9629C48.6076 9.57652 45.1593 7.32971 41.9965 7.32971ZM42.0406 9.92654C43.939 9.92654 45.749 11.4544 45.749 13.9408C45.749 16.3626 43.9595 17.9551 42.0406 17.9551C39.9247 17.9551 38.266 16.2744 38.266 13.9408C38.2439 11.6278 39.901 9.92654 42.0406 9.92654Z" fill="#FBBC05" />
                      <path d="M56.1398 7.32971C52.5828 7.32971 49.8125 10.4279 49.8125 13.9188C49.8125 17.8889 53.0416 20.5299 56.0957 20.5299C57.9719 20.5299 58.9763 19.7888 59.7174 18.9153V20.224C59.7174 22.4929 58.3425 23.8678 56.2486 23.8678C54.2415 23.8678 53.2166 22.362 52.8666 21.5122L50.336 22.5812C51.23 24.4795 53.0416 26.4646 56.2707 26.4646C59.8057 26.4646 62.4892 24.2399 62.4892 19.5917V7.72231H59.7189V8.83547C58.8659 7.9399 57.6881 7.32971 56.1398 7.32971ZM56.4015 9.92654C58.147 9.92654 59.9365 11.4102 59.9365 13.9408C59.9365 16.5156 58.147 17.9331 56.3795 17.9331C54.4811 17.9331 52.7357 16.4052 52.7357 13.9629C52.7136 11.4307 54.5473 9.92654 56.4015 9.92654Z" fill="#4285F4" />
                      <path d="M74.7695 7.32971C71.4316 7.32971 68.6172 9.99119 68.6172 13.9188C68.6172 18.086 71.7596 20.552 75.0974 20.552C77.8898 20.552 79.6131 19.0241 80.6175 17.6508L78.3265 16.123C77.7368 17.0391 76.7341 17.9346 75.0974 17.9346C73.2432 17.9346 72.3918 16.9098 71.8684 15.9275L80.7263 12.2601L80.2675 11.1911C79.4176 9.07512 77.4105 7.32971 74.7695 7.32971ZM74.9004 9.86032C76.1002 9.86032 76.9737 10.4926 77.3442 11.2778L71.4316 13.7437C71.1699 11.8249 72.9815 9.86032 74.9004 9.86032Z" fill="#EA4335" />
                      <path d="M67.3074 0.696533H64.4062V20.1578H67.3074V0.696533Z" fill="#34A853" />
                    </svg> : <></>}
                  </span>
                  <span className="flex">
                    {userInfo?.subscription_at ? <button
                      className="bg-main text-white rounded-[48px] active:scale-90 transition-all duration-200 h-10 w-52 max-sm:hidden"
                      onClick={async (event) => {
                        toggleUnsubscribeModal();
                      }}
                    >
                      <p className="font-medium text-sm text-center">Me désabonner</p>
                    </button> : <></>}
                  </span>
                </div>
                <div className="flex flex-col gap-y-10">
                  <p className="font-bold whitespace-normal break-all text-xl">
                    Vos mot cle /URL actif :
                  </p>
                  <div className="rounded-xl">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="text-start border-b border-linecolor pb-3 text-base max-xl:text-lg max-sm:hidden">
                            <span className="font-bold">ID</span>
                          </th>
                          <th className="text-start border-b border-linecolor pb-3 text-base max-xl:text-lg">
                            <span className="font-bold whitespace-normal break-all">Mots clés</span>
                          </th>
                          <th className="text-start border-b border-linecolor pb-3 px-1d text-base max-xl:text-lg">
                            <span className="font-bold whitespace-normal break-all">Montant</span>
                          </th>
                          <th className="text-start border-b border-linecolor pb-3 px-1 text-base max-xl:text-lg max-sm:hidden">
                            <span className="font-bold whitespace-normal break-all">Moteurs/Réseaux</span>
                          </th>
                          <th className="text-start border-b border-linecolor pb-3 px-1 font-bold">
                            <span className="font-normal"></span>
                          </th>
                        </tr>
                      </thead>
                      {keywordUrlData?.length > 0 && keywordUrlData?.map((data, index) => (
                        <tr>
                          <td className="py-3 max-sm:hidden">{index + 1}</td>
                          <td className="px-1">{data?.keyword_url} {data?.additional_keyword_url ? `, ${data?.additional_keyword_url}` : ""}</td>
                          <td className="px-1">{index === 0 ? "29€/mois" : "10€/mois"}</td>
                          <td className="px-1 max-sm:hidden"><FcGoogle size={20} /></td>
                          <td className="px-1">{data?.stripe_id ? <button className="bg-[#F7F9FD] text-[#284F60] rounded-[48px] active:scale-95 transition-all duration-200 w-190 p-3"
                            onClick={async (event) => {
                              setDeleteId(data?.id);
                              toogleDeleteKeywordModal();
                            }}>
                            <p className="font-semibold text-sm text-center">Me désabonner de cette option</p>
                          </button> : <></>}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div> :
            active === "card" ?
              <Card extra="bg-white py-4 px-5 sm:py-5 sm:px-10 mt-8">
                <h2 className="text-main font-medium text-xl">Informations</h2>
                <div className="flex flex-col gap-y-8">
                  {paymentData.length > 0 &&
                    paymentData?.map((value, index) => {
                      return <CustomCardRadio
                        value={value.id}
                        name={value.card_holder_name}
                        selected={cardSelected}
                        setCardSelected={setCardSelected}
                        cardHolderName={value.card_holder_name}
                        cardNumber={value.card_number}
                        setUpdateForce={setUpdateForce}
                      />
                    })
                  }
                  {/* <CardRadio
              value="1"
              name="cardradio"
              selected={cardSelected}
              setCardSelected={setCardSelected}
            />
            <CardRadio
              value="2"
              name="cardradio"
              selected={cardSelected}
              setCardSelected={setCardSelected}
            />
            <CardRadio
              value="3"
              name="cardradio"
              selected={cardSelected}
              setCardSelected={setCardSelected}
            /> */}
                  <label className="flex gap-x-6 md:px-8 cursor-pointer">
                    <CustomRadio
                      selected={cardSelected}
                      onChange={(e) => setCardSelected(e.target.value)}
                      value="newCard"
                      name="cardradio"
                    />
                    <p className="text-sm text-main font-medium">
                      Ajouter une nouvelle carte
                    </p>
                  </label>
                </div>
                {cardSelected === "newCard" ? (
                  <div className="flex flex-col gap-y-4 mt-6">
                    <h2 className="text-main font-semibold text-xl">
                      Nouvelle carte
                    </h2>
                    <NewCard setUpdateForce={setUpdateForce} />
                  </div>
                ) : (
                  <div className="flex justify-center sm:justify-end mt-4">
                    <button
                      className="bg-main text-white rounded-[48px]
              active:scale-95 transition-all duration-200 h-14 w-64"
                    >
                      <p className="font-medium text-sm text-center">Valider</p>
                    </button>
                  </div>
                )}
              </Card> :
              <UserInfo userInfo={userInfo} />
        }
        {deleteKeywordModal &&
          <DeleteKeywordModal closeModal={toogleDeleteKeywordModal} id={deleteId} open={deleteKeywordModal} showAlert={showAlert} />
        }
        {unsubscribeModal &&
          <UnsubscribeModal closeModal={toggleUnsubscribeModal} open={unsubscribeModal} showAlert={showAlert} />
        }
      </Card>
    </>
  );
};

export default Parametres;
