import UploadFile from "components/UploadFile/UploadFile";
import { Loader } from "components/Loader/Loader";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputError from "components/InputError/InputError";
import InputLabel from "components/InputLabel/InputLabel";
import Button from "components/Buttons/Button";
import { SendNewMessageService } from "services/Message";

const Messagerie = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onSubmit = async (data) => {
    data.upload_files = selectedFiles;

    setIsloading(true);
    const response = await SendNewMessageService(data);
    setIsloading(false);

    if (response.status === 200) navigate('/messagerie');
  };

  return (
    <div
      className="w-full flex flex-col rounded-[20px] h-auto
      p-8 bg-white gap-y-4"
    >
      <h2 className="text-main font-semibold md:text-2xl text-xl">
        Nouveau message
      </h2>
      <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="analysis_selection" className="text-main text-sm font-normal">
            Sélection analyse
          </InputLabel>
          {/* <select
            id="analysis_selection"
            {...register("analysis_selection", { required: true })}
            className="block h-12 w-full p-2.5 rounded-[100px] text-main
          border border-gray-300 focus:ring-0 focus:border-gray-400
          text-sm font-normal"
          >
            <option>Support client inexistant chez Nike</option>
          </select> */}
          <select
            id="analysis_selection"
            {...register("analysis_selection", { required: true })}
            className="block h-12 w-full p-2.5 rounded-[100px] text-main
          border border-gray-300 focus:ring-0 focus:border-gray-400
          text-sm font-normal"
          >
            {/* <option>Support client inexistant chez Nike</option> */}
            <option>Département d'analyse</option>
            <option>Département des ventes</option>
            <option>Service de facturation</option>
            <option>Soutien technique</option>
          </select>
          {/* <input
            id="analysis_selection"
            {...register("analysis_selection", { required: true })}
            className="block h-12 w-full p-2.5 rounded-[100px] text-main
          border border-gray-300 focus:ring-0 focus:border-gray-400
          text-sm font-normal"
          /> */}
          {errors.analysis_selection && <InputError message="Ce champ est obligatoire." />}
        </div>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="object_for" className="text-main text-sm font-normal">
            Objet du message
          </InputLabel>
          {/* <select
            id="object_for"
            {...register("object_for", { required: true })}
            className="block h-12 w-full p-2.5 rounded-[100px] text-main
          border border-gray-300 focus:ring-0 focus:border-gray-400
          text-sm font-normal"
          >
            <option>Problème technique</option>
          </select> */}
          <input
            id="object_for"
            {...register("object_for", { required: true })}
            className="block h-12 w-full p-2.5 rounded-[100px] text-main
          border border-gray-300 focus:ring-0 focus:border-gray-400
          text-sm font-normal"
          />
          {errors.object_for && <InputError message="Ce champ est obligatoire." />}
        </div>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="messaging" className="text-main text-sm font-normal">
            Message
          </InputLabel>
          <textarea
            id="messaging"
            {...register("messaging", { required: true })}
            rows={5}
            className="block p-2.5 w-full text-sm rounded-[20px] text-main
            border border-gray-300 focus:ring-0 focus:border-gray-400"
            placeholder="Enter message"
          />
          {errors.messaging && <InputError message="Message is required" />}
        </div>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="attachments" className="text-main text-sm">
            Pièce jointe
          </InputLabel>
          <UploadFile selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
        </div>
        <div className="flex justify-end mt-6">
          <Button
            type="submit"
            className={`flex items-center justify-center text-white w-52 h-11 rounded-[100px] ${!isloading && 'bg-main'}`}
          >
            {isloading ? <Loader /> : 'Envoyer'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Messagerie;
