import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});


// const user = JSON.parse(localStorage.getItem('user'));

const GetAlertes = async () => {
    try {
        const response = await api.get(`alert`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const UpdateAlertSettings = async (data) => {
    try {
        const response = await api.post(`alert_setting`, { "alert_setting_data": data }, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const GetAlertSetting = async () => {
    try {
        const response = await api.get(`alert_setting`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export {
    GetAlertes,
    UpdateAlertSettings,
    GetAlertSetting
};