import { useState, useMemo, useEffect } from "react";

import Container from "components/Container/Container";
import ChangePeriod from "components/ChangePeriod/ChangePeriod";
import Card from "components/Card/Card";
import TableAnalyses from "components/Tables/TableAnalyses";
// import { AnalyseModal } from "components/Modals/Modals";
import { GetAllAnalysisService } from "services/Analysis";
// import { dataAnalyses } from "constants/analyses";

const AnalyseResultats = () => {
  const [analyses, setAnalyses] = useState([]);
  const [active, setActive] = useState("today");
  // const [modal, setModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => { 
      const response = await GetAllAnalysisService();
      if (response?.status === 200) {
        setAnalyses(response.data?.anlayse ?? []);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
    }

    fetchData();
  }, [])

  // const toogleModal = useCallback(() => {
  //   setModal((state) => !state);
  // }, []);

  const setMonthly = () => {
    setActive("monthly");
  };

  const setWeekly = () => {
    setActive("weekly");
  };

  const setToday = () => {
    setActive("today");
  };

  const columns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "barre",
        Cell: ({ row: { original } }) => (
          <div
            className={`${
              original.label === "positive"
                ? "bg-success"
                : original.label === "neutral"
                ? "bg-primary"
                : "bg-danger"
            } w-[29px] h-[9px] rounded-[100px] px-1 mx-auto`}
          ></div>
        ),
      },
      {
        columnId: 1,
        accessor: "title",
        Cell: ({ cell: { value } }) => (
          <div
            className="flex md:text-sm text-xs
          font-medium py-6 px-2 2xl:pl-5"
          >
            <span>{value}</span>
          </div>
        ),
      },
      {
        columnId: 2,
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <div
            className="flex text-xs
            font-normal px-1"
          >
            {value}
          </div>
        ),
      },
      {
        columnId: 3,
        accessor: "link",
        Cell: ({ cell: { value } }) => (
          <div
            className="flex text-xs
            font-normal px-1 overflow-auto whitespace-normal"
          >
            {value}
          </div>
        ),
      },
    ], []
  );

  // const data = useMemo(() => analyses, []);
  const [items, setItems] = useState([]);
  const [loadedCount, setLoadedCount] = useState(10);
  const itemsPerPage = 5;
  const hasMoreData = loadedCount < analyses.length;

  useEffect(() => {
    setItems(analyses.slice(0, loadedCount));
  }, [analyses, loadedCount]);

  const fetchMoreData = () => {
    setTimeout(() => {
      const nextItems = analyses.slice(0, loadedCount + itemsPerPage);
      setItems(nextItems);
      setLoadedCount(loadedCount + itemsPerPage);
    }, 1000);
  };

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Analyse</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <div className="flex">
          <ChangePeriod
            setMonthly={setMonthly}
            setWeekly={setWeekly}
            setToday={setToday}
            active={active}
          />
        </div>
      </Container>
      <Card extra={"w-full pb-6 sm:overflow-x-auto mt-8 mb-4 !bg-transparent"}>
        <TableAnalyses
          columns={columns}
          data={items}
          setData={setItems}
          update={fetchMoreData}
          hasMore={hasMoreData}
          // toogleModal={toogleModal}
        />
      </Card>
    </>
  );
};

export default AnalyseResultats;
