import { useCallback, useEffect, useMemo, useState } from "react";
import ChangePeriod from "components/ChangePeriod/ChangePeriod";
import { FcGoogle } from "react-icons/fc";
import { MdWarning } from "react-icons/md";
import { InterventionsModal } from "components/Modals/Modals";
import Container from "components/Container/Container";
import Card from "components/Card/Card";
import TableInterventions from "components/Tables/TableInterventions";
import eebleu from "assets/ee-bleu.png";
import { useNavigate } from "react-router";
import {
  GetAllIntervenstionsService,
  GetFacturesId,
  RefuseIntervention,
} from "services/Intervention";

const Interventions = () => {
  const [active, setActive] = useState("monthly");
  const [interventions, setInterventions] = useState([]);
  const [success, setSuccess] = useState(false);
  const [update, setUpdate] = useState(false);

  const setMonthly = useCallback(() => {
    setActive("monthly");
  }, []);

  const setWeekly = useCallback(() => {
    setActive("weekly");
  }, []);

  const setToday = useCallback(() => {
    setActive("today");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllIntervenstionsService(active);

      if (response?.status === 200) {
        setInterventions(response.data?.intervention_requests ?? []);
        setSuccess(true);
      } else {
        // setErrorMsg(response?.data?.detail);
        setSuccess(false);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, [active, update]);

  const columns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "id",
        Cell: () => (
          <div className="md:pl-6 pl-2 md:pr-1 pr-4">
            <FcGoogle size={20} />
          </div>
        ),
      },
      // {
      //   columnId: 1,
      //   accessor: "intervetion_type",
      //   Cell: ({ row }) => {
      //     return row.original.label === "positive" ? (
      //       <div className="bg-primary w-[29px] h-[9px] rounded-[100px] mx-auto px-1" />
      //     ) : row.original.label === "neutral" ? (
      //       <div className="bg-success w-[29px] h-[9px] rounded-[100px] mx-auto px-1" />
      //     ) : row.original.label === "negative" ? (
      //       <div className="bg-danger w-[29px] h-[9px] rounded-[100px] mx-auto px-1" />
      //     ) : (
      //       <></>
      //     );
      //   },
      // },
      {
        accessor: "title",
        columnId: 1,
        hidden: true,
        Cell: ({ value }) => (
          <div className="flex text-primary text-sm font-medium py-6 px-2 max-md:text-xs">
            <span className="whitespace-normal break-words">{value}</span>
          </div>
        ),
      },
      {
        accessor: "updated_at",
        columnId: 2,
        hidden: true,
        Cell: ({ value, row }) => (
          // <div className="flex text-primary text-[12px] font-normal px-1">
          //   {/* {value ? value : row.original.created_at} */}
          //   {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) ?? ''}
          // </div>
          // <div className="flex text-primary text-sm font-medium py-6 px-2 max-md:text-xs">
          //   <span className="whitespace-normal break-words">
          //     {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) ?? ''}
          //   </span>
          // </div>
          <div className="flex text-primary text-sm font-medium py-6 px-2 max-md:text-xs">
            <span className="whitespace-normal break-all">
              {new Date(value).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              }) ?? ""}
            </span>
          </div>
        ),
      },
      {
        accessor: "site_url",
        columnId: 3,
        Cell: ({ value }) => (
          // <div className="flex text-primary text-[12px] font-normal px-1">
          <div className="flex text-primary text-sm font-medium py-6 px-2 max-md:text-xs">
            {/* <span className="whitespace-normal break-words">{new URL(value).origin}</span> */}
            <span className="whitespace-normal break-all">
              {new URL(value).origin}
            </span>
          </div>
        ),
      },
      {
        accessor: "status",
        columnId: 4,
        hidden: true,
        Cell: ({ cell: { value } }) => {
          // return value === 1 ? (
          //   <div className="flex text-[12.008px] font-medium leading-none text-primary px-1">
          //     {interventionType[value]}
          //   </div>
          // ) : value === 3 ? (
          //   <div className="flex text-danger text-[12.008px] font-medium leading-none px-1">
          //     {interventionType[value]}
          //   </div>
          // ) : value === 2 ? (
          //   <div className="flex text-success text-[12.008px] font-medium leading-none px-1">
          //     {interventionType[value]}
          //   </div>
          // ) : <></>;
          // return value === 1 ? (
          //   <div className="flex text-sky-500 text-[12px] font-normal px-1">
          //     En attente d' examen
          //   </div>
          // ) : value === 2 ? (
          //   <div className="flex text-indigo-700 text-[12px] font-normal px-1">
          //     Demande envoyée
          //   </div>
          // ) : value === 3 ? (
          //   <div className="flex text-danger text-[12px] font-normal px-1">
          //     {/* Devis accepté */}
          //     Demande rejetée
          //   </div>
          // ) : (
          //   <div className="flex text-success text-[12px] font-normal px-1">
          //     Complété
          //   </div>
          // );
          return value === 0 ? (
            <div className="flex text-sky-500 text-[13px] font-normal px-1 w-[110px]">
              <span className="whitespace-normal break-words">
                En attente d' examen
              </span>
            </div>
          ) : value === 1 ? (
            <div className="flex text-[#14b8a6] text-[13px] font-normal px-1 w-[110px]">
              <span className="whitespace-normal break-words">En cours</span>
            </div>
          ) : value === 2 ? (
            <div className="flex text-indigo-700 text-[13px] font-normal px-1">
              <span className="whitespace-normal break-words">En attente</span>
            </div>
          ) : value === 3 ? (
            <div className="flex text-danger text-[13px] font-normal px-1">
              {/* Devis accepté */}
              <span className="whitespace-normal break-words">
                Demande rejetée
              </span>
            </div>
          ) : value === 4 ? (
            <div className="flex text-green-500 text-[13px] font-normal px-1">
              <span className="whitespace-normal break-words">Accepté</span>
            </div>
          ) : (
            <div className="flex text-red text-[13px] font-normal px-1">
              <span className="whitespace-normal break-words">Refused</span>
            </div>
          );
        },
      },
      {
        accessor: "bouton",
        columnId: 5,
        Cell: ({ row }) => {
          const navigate = useNavigate();
          const [open, setOpen] = useState(false);

          const closeModal = () => {
            setOpen(false);
          };

          return (
            <div className="flex justify-center px-1">
              {row.original.status === 1 ||
              row.original.status === 0 ||
              row.original.status === 4 ? (
                <button
                  className="flex justify-center items-center text-white font-medium bg-warning rounded-[100px] px-3 py-2 max-xl:w-[100px] w-[186px] text-[12px] active:scale-95 transition-all duration-150 whitespace-normal break-all"
                  onClick={() => {
                    setOpen(true);
                    setUpdate((prev) => !prev);
                  }}
                >
                  <MdWarning className="mr-[1px] max-xl:hidden" size={13}/>
                  Demande d'information
                </button>
              ) : row.original.status === 3 ||
                row.original.status === 5 ? null : (
                <button
                  className="flex justify-center items-center text-white font-medium bg-primary rounded-[100px] px-3 py-2 max-xl:w-[100px] w-[186px] text-[12px] active:scale-95 transition-all duration-150"
                  onClick={async (event) => {
                    document
                      .getElementById("prewaiting")
                      .removeAttribute("hidden");
                    const response = await GetFacturesId(row.original.id);
                    if (response?.status === 200) {
                      navigate(
                        `/demande-dinterventions/${response?.data?.quote}/devis`
                      );
                    }
                    document
                      .getElementById("prewaiting")
                      .setAttribute("hidden", "true");
                  }}
                >
                  Voir le devis
                </button>
              )}
              <InterventionsModal
                data={row.original}
                open={open}
                closeModal={closeModal}
              />
            </div>
          );
        },
      },
      {
        accessor: "refuse",
        columnId: 6,
        Cell: ({ row }) => {
          return row.original.status === 2 ? (
            <button
              className="flex justify-center items-center text-white font-medium bg-danger rounded-[100px] px-3 py-2 max-xl:w-[100px] w-[186px] text-[12px] active:scale-95 transition-all duration-150"
              onClick={async (event) => {
                const response = await RefuseIntervention(row.original.id);
                setUpdate((prev) => !prev);
              }}
            >
              Refuse
            </button>
          ) : null;
        },
      },
      {
        accessor: "read_status",
        columnId: 7,
        Cell: ({ row }) => {
          return row.original.user_read_status === false ? (
            <div className="px-3">
              <span className="flex h-3 w-3 pointer-events-none">
                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-pink-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500"></span>
              </span>
            </div>
          ) : (
            <></>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">
            Demande d'interventions
          </p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <div className="flex">
          <ChangePeriod
            setMonthly={setMonthly}
            setWeekly={setWeekly}
            setToday={setToday}
            active={active}
          />
        </div>
        {/* <RightButton
          text="Nouveau message"
        /> */}
      </Container>
      {success ? (
        <Card
          extra={"w-full pb-6 sm:overflow-x-auto mt-8 mb-4 !bg-transparent"}
        >
          <TableInterventions columns={columns} data={interventions} />
        </Card>
      ) : (
        <div className="flex flex-col">
          <div className="sm:mt-48 mt-24">
            <p
              className="font-semibold sm:text-4xl text-main
      text-center text-xl"
            >
              Vous n'êtes pas abonné.
            </p>
          </div>
          <div className="absolute bottom-0 left-0">
            <img
              src={eebleu}
              alt="ee-logo"
              className="w-2/3 h-2/3 sm:w-full sm:h-full"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Interventions;
