import router from "Router";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import ImageCropProvider from "providers/ImageCropProvider";

function App() {

  return (
    <HelmetProvider>
      <ImageCropProvider>
        <RouterProvider router={router} />
      </ImageCropProvider>
    </HelmetProvider>
  );
}

export default App;
