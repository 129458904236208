import { useState } from "react";
import {
  PayForSignup,
  UserSignup,
  VerifyCode,
} from "services/UserAuthentication";
import { useNavigate } from "react-router-dom";
import CustomStepsButton from "components/Buttons/CustomStepsButton";
import AuthCode from "react-auth-code-input";
import CountDownTimer from "./CountDownTimer";
import BaseModal from "components/Modals/BaseModal";
import "./style.css";

const Step3 = ({
  register,
  errors,
  promoCode,
  setPromoCode,
  inputError,
  page,
  backToPage,
  handleSubmit,
  getValues,
  getValues2,
  profile,
  selectedFiles,
  countryName,
  countryCode,
  phoneNumber,
  province,
  selected,
  paymentId,
}) => {
  //const { register } = useForm();

  const [verifyCode, setVerifyCode] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleOnChange = (res) => {
    setVerifyCode(res);
  };
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    document.getElementById("prewaiting").removeAttribute("hidden");
    var user_data = getValues();
    var payment_data = getValues2();

    //Normalement naviguer vers la page de paiement d'abord

    // var payload = {
    //   "email": profile.email,
    //   "verify_code": verifyCode
    // }
    var payload = {
      email: user_data?.email,
      verify_code: verifyCode,
    };

    var response = await VerifyCode(payload);
    var emailVerifyCode = "";
    if (response?.status === 200) {
      emailVerifyCode = response?.data;
    } else {
      document.getElementById("prewaiting").setAttribute("hidden", "true");
      setErrorMsg("Code de vérification invalide");
      setIsOpen(true);
      return;
    }

    if (!profile.type_email) {
      // user_data["first_name"] = profile.given_name;
      // user_data["last_name"] = profile.family_name;
      user_data["password"] = profile.id;
      // user_data["email"] = profile.email;
    } else {
      // user_data["first_name"] = profile.firstname;
      // user_data["last_name"] = profile.lastname;
      user_data["password"] = profile.password;
      // user_data["email"] = profile.email;
    }
    user_data["role"] = 2;

    user_data["full_name"] = profile.full_name;
    //user_data["full_name"] = user_data["firstname"] + " " + user_data["lastname"];

    user_data["pays"] = countryName;
    user_data["province"] = province;
    user_data["phone"] = phoneNumber;
    user_data["user_type"] = selected;
    if (selectedFiles.length)
      user_data["avatar_url"] = selectedFiles[0].filepath;
    //console.log(user_data);

    // const expiration_date = payment_data.expiration_date.split("/");
    // const cardData = {
    //   type: "card",
    //   "card[number]": payment_data.card_number,
    //   "card[exp_month]": expiration_date[0],
    //   "card[exp_year]": expiration_date[1],
    //   "card[cvc]": payment_data.cvc,
    // };

    // var postData = {
    //   user_data: user_data,
    //   payment_data: {
    //     pm_id: paymentId,
    //   },
    //   email_verify_token: emailVerifyCode,
    // };
    // if (promoCode) postData["promo_code"] = promoCode;

    // response = await PayForSignup(postData);
    // if (response?.name === "AxiosError" || response?.data === false) {
    //   setErrorMsg(
    //     "Vous ne pouvez pas vous abonner avec votre carte. Vérifiez à nouveau les informations de votre carte ou essayez une autre carte."
    //   );
    //   setIsOpen(true);
    //   document.getElementById("prewaiting").setAttribute("hidden", "true");
    //   return;
    // }
    // user_data["subscription_at"] = response?.data["subscription_at"];

    // var paymentResult = {};
    // paymentResult["payment_method_id"] = response?.data["payment_method_id"];
    // paymentResult["card_holdername"] = payment_data?.["card_holdername"];

    var postData = {
      user_data: user_data,
      email_verify_token: emailVerifyCode
    };
    response = await UserSignup(postData);
    document.getElementById("prewaiting").setAttribute("hidden", "true");
    if (response?.status === 200) {
      const { jwt, user } = response?.data;
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("jwt_type", "Bearer");
      localStorage.setItem("user", JSON.stringify(user));
      navigate("/register-success");
    }
  };

  return (
    <div className="flex flex-col gap-y-3 mt-6 items-center">
      <h2 className="font-semibold text-xl text-black text-center">
        Veuillez vérifier votre email.
      </h2>
      <div className="text-center">
        <CountDownTimer totalSec={20 * 1000} email={profile.email} />
        <div className="mt-5">
          <AuthCode
            allowedCharacters="numeric"
            onChange={handleOnChange}
            inputClassName="input"
            length={6}
          />
        </div>
      </div>
      <div className="flex justify-end mt-12 mb-6">
        {page === 1 ? (
          <div className="flex gap-x-3 items-center">
            <CustomStepsButton
              onClick={backToPage}
              extra="bg-gray100"
              textColor="text-main"
            >
              Retour
            </CustomStepsButton>
            <CustomStepsButton
              // type="submit"
              extra="bg-main"
              textColor="text-white"
              onClick={onSubmit}
            >
              Valider
            </CustomStepsButton>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isOpen && (
        <BaseModal
          closeModal={() => {
            setIsOpen(false);
            if (
              errorMsg ===
              "Vous ne pouvez pas vous abonner avec votre carte. Vérifiez à nouveau les informations de votre carte ou essayez une autre carte."
            )
              backToPage();
          }}
          purpose="Error"
        >
          {errorMsg}
        </BaseModal>
      )}
    </div>
  );
};

export default Step3;
