import { BsThreeDotsVertical } from "react-icons/bs";

import card from "assets/svg/card.svg";
import visa from "assets/svg/visalogo.svg";
import { memo } from "react";
import { SetDefaultCard, DeleteCard } from "services/Parametres";
import MenuDropdown from "components/Dropdowns/MenuDropdown";
import { MenuDropdownButton } from "components/Dropdowns/MenuDropdown";

const CustomCardRadio = ({ value, name, selected, setCardSelected, cardHolderName, cardNumber, setUpdateForce }) => {
  const onOptionChange = async (e) => {
    setCardSelected(e.target.value);
    document.getElementById("prewaiting").removeAttribute("hidden");
    const response = await SetDefaultCard({ "card_id": parseInt(e.target.value) });
    document.getElementById("prewaiting").setAttribute("hidden", "true");
  };

  return (
    <label
      className={`flex justify-between w-full hover:bg-grayUpload
      cursor-pointer rounded-[20px] items-center md:px-8 pt-3 pb-3 ${selected.toString() === value.toString() ? "bg-grayUpload" : "bg-white"
        } max-md:gap-x-4 px-2`}
    >
      <input
        type="radio"
        name={name}
        value={value}
        checked={selected.toString() === value.toString()}
        onChange={onOptionChange}
        className="w-4 h-4 bg-gray-100
        focus:bg-gray-500 focus:ring-0
        cursor-pointer text-gray-600 outline-none
        focus:border-gray-700 border"
      />
      <span>
        <img src={card} alt="card" className="w-full h-full" />
      </span>
      <span>
        <img src={visa} alt="card" className="w-full h-full" />
      </span>
      <p className="text-main max-md:truncate font-medium md:text-sm text-xs">
        {cardNumber.replace(/(.{4})/g, "$1 ")}
      </p>
      {/* <p className="text-main max-md:truncate font-medium md:text-sm text-xs">Gui Martin</p> */}
      <p className="text-main max-md:truncate font-medium md:text-sm text-xs">{cardHolderName}</p>
      {/* <BsThreeDotsVertical size={20} /> */}
      <MenuDropdown
        trigger={
          <button className="active:scale-95 transition-all">
            <BsThreeDotsVertical size={selected.toString() === value.toString() ? 1 : 20} />
          </button>
        }
        menu={[
          <MenuDropdownButton text="Supprimer la carte" onClick={async (event) => {
            document.getElementById("prewaiting").removeAttribute("hidden");
            const response = await DeleteCard({ "card_id": parseInt(value) });
            if (response?.status === 200) {
              setUpdateForce();
            }
            document.getElementById("prewaiting").setAttribute("hidden", "true");
          }} />
        ]}
      />
    </label>
  );
};

export default memo(CustomCardRadio);
