import InputLabel from "components/InputLabel/InputLabel";
import HorizontalInputLayout from "../Parametres/HorizontalInputLayout";
import Input from "components/Input/Input";
import Card from "components/Card/Card";
import { useForm } from "react-hook-form";
import Button from "components/Buttons/Button";
import { AddClient } from "services/Admin/Users";
import { useNavigate } from "react-router-dom";
import { useState, Component } from "react";
import InputError from "components/InputError/InputError";
import CustomRadio from "components/Radio/CustomRadio";
import "react-country-state-city/dist/react-country-state-city.css";
import CountrySelect from "components/SelectRegion/CountrySelect";
import { City } from "country-state-city";
import { FixedSizeList as List } from "react-window";
import Select from "react-select";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import ImageCrop from "components/ImageCrop/ImageCrop";
import 'react-phone-number-input/style.css';
import BaseModal from "components/Modals/BaseModal";

const height = 35;
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children?.length || 0}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const NewClient = () => {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [role, setRole] = useState(0);
  const [userType, setUserType] = useState("Particulier");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [stateData, setStateData] = useState([]);
  const [province, setProvince] = useState("");
  const [errorMsg, setErrorMsg] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      //type: "Société",
      role: 0,
      vat_number: "FR"
    },
  });

  const onSubmit = async (data) => {
    if (!phoneNumber?.length || !countryName?.length || !province?.length)
      return;
    //data["full_name"] = data["firstname"] + " " + data["lastname"];
    data["subscription_at"] = null;
    data["role"] = role;
    data["user_type"] = userType;

    if (userType === "Particulier") {
      data["number_siret"] = "";
      data["activity"] = "";
      data["social_reason"] = "";
      data["vat_number"] = "";
    }
    data["pays"] = countryName;
    data["province"] = province;
    data["phone"] = phoneNumber;

    if (selectedFiles.length)
      data["avatar_url"] = selectedFiles?.[0].filepath;
    console.log(data);
    document.getElementById("prewaiting").removeAttribute("hidden");
    const response = await AddClient(data);
    if (response?.status === 200) {
      navigate("/admin/comptes-clients");
    } else {
      setErrorMsg(response?.response?.data?.detail);
      setIsOpen(true);
    }
    document.getElementById("prewaiting").setAttribute("hidden", "true");
  };

  const handleCountry = (value) => {
    const data = City.getAllCities();
    let filteredData = [];
    for (let item of data) {
      if (item?.countryCode === value?.value) filteredData.push(item);
    }
    setStateData(filteredData);
  }

  return (
    <Card extra="bg-white py-4 px-5 sm:py-5 sm:px-10 mt-8 ">
      <form
        className="flex flex-col gap-y-3 mt-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-main font-medium text-xl">Role</h2>
        <div className="flex gap-x-12">
          <div className="flex gap-x-1 items-center">
            <CustomRadio
              name="role"
              id="utilisateur"
              {...register("role", { required: true })}
              value={2}
              selected={role}
              onChange={(event) => {
                setRole(event.target.value);
              }}
            />
            <label htmlFor="utilisateur" className="cursor-pointer">
              Utilisateur
            </label>
          </div>
          <div className="flex gap-x-1 items-center">
            <CustomRadio
              name="role"
              id="admin"
              {...register("role", { required: true })}
              value={1}
              selected={role}
              onChange={(event) => {
                setRole(event.target.value);
              }}
            />
            <label htmlFor="admin" className="cursor-pointer">
              Admin
            </label>
          </div>
        </div>
        {errors.role ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        <h2 className="text-main font-medium text-xl mt-4">Informations</h2>
        <div className="flex gap-x-12">
          <div className="flex gap-x-1 items-center">
            <CustomRadio
              name="type"
              id="societe"
              {...register("user_type", { required: true })}
              value="Société"
              selected={userType}
              onChange={(event) => {
                setUserType(event.target.value);
              }}
            />
            <label htmlFor="societe" className="cursor-pointer">
              Société
            </label>
          </div>
          <div className="flex gap-x-1 items-center">
            <CustomRadio
              name="type"
              id="particulier"
              {...register("user_type", { required: true })}
              value="Particulier"
              selected={userType}
              onChange={(event) => {
                setUserType(event.target.value);
              }}
            />
            <label htmlFor="particulier" className="cursor-pointer">
              Particulier
            </label>
          </div>
        </div>
        {errors.type ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        <div className="flex flex-col gap-y-3">
          {/* <UploadAvatar selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
          {selectedFiles.length === 0 && <InputError message="Avatar est requis." />} */}
          <ImageCrop selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} newClient={1} />
        </div>

        <HorizontalInputLayout>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="prenom">Prénom</InputLabel>
            <Input
              id="prenom"
              placeholder="Entrez votre prénom"
              autoComplete="given-name"
              {...register("first_name", { required: true })}
              className="w-full"
            />
            {errors.first_name ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          </div>
          <div className="flex flex-col w-full sm:w-[45%] gap-y-2">
            <InputLabel htmlFor="nom">Nom</InputLabel>
            <Input
              id="nom"
              placeholder="Entrez votre nom"
              autoComplete="family-name"
              {...register("last_name", { required: true })}
              className="w-full"
            />
            {errors.last_name ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          </div>
        </HorizontalInputLayout>
        {userType === "Société" && (
          <>
            <HorizontalInputLayout>
              <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
                <InputLabel htmlFor="activite">Activité</InputLabel>
                <Input
                  id="activite"
                  autoComplete="off"
                  placeholder="Graphiste"
                  {...register("activity", { required: true })}
                  className="w-full"
                />
                {errors.activity ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
              </div>
              <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
                <InputLabel htmlFor="social">Raison sociale</InputLabel>
                <Input
                  id="social"
                  autoComplete="off"
                  placeholder="Novaweb"
                  {...register("social_reason", { required: true })}
                  className="w-full"
                />
                {errors.social_reason ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
              </div>
            </HorizontalInputLayout>
          </>
        )}
        <div className="flex flex-col gap-y-2">
          {/* <InputLabel htmlFor="pays">Pays</InputLabel>
          <Input
            id="pays"
            autoComplete="off"
            placeholder="Entrez votre pays"
            {...register("pays", { required: true })}
            className="w-full"
          />
          {errors.pays ? <InputError message={"Ce champ est obligatoire."} /> : <></>} */}
          <InputLabel htmlFor="pays">Pays</InputLabel>
          <CountrySelect
            countryName={countryName}
            setCountryName={setCountryName}
            setCountryCode={setCountryCode}
            handleCountry={handleCountry}
          />
          {countryName?.length ? <></> : <InputError message={"Ce champ est obligatoire."} />}
        </div>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="address">Adresse</InputLabel>
          <Input
            id="address"
            placeholder="Entrez votre adresse"
            autoComplete="off"
            {...register("address", { required: true })}
            className="w-full"
          />
          {errors.address ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div>
        <HorizontalInputLayout>
          <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
            <InputLabel htmlFor="code-postal">Code postal</InputLabel>
            <Input
              id="code-postal"
              placeholder="69500"
              autoComplete="off"
              {...register("postal_code", { required: true })}
              className="w-full"
            />
            {errors.postal_code ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          </div>
          <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
            {/* <InputLabel htmlFor="ville">Ville</InputLabel>
            <Input
              id="ville"
              autoComplete="off"
              placeholder="Entrez votre ville"
              {...register("province", { required: true })}
              className="w-full"
            />
            {errors.province ? <InputError message={"Ce champ est obligatoire."} /> : <></>} */}
            <InputLabel htmlFor="ville">Ville</InputLabel>
            <Select
              components={{ MenuList }}
              placeholder={province?.length ? province : "Sélectionnez votre ville"}
              options={stateData}
              getOptionLabel={(options) => options.name}
              getOptionValue={(options) => options.name}
              onChange={(e) => {
                setProvince(e.name);
              }}
            />
            {province?.length ? <></> : <InputError message={"Ce champ est obligatoire."} />}
          </div>
        </HorizontalInputLayout>
        {userType === "Société" && (
          <HorizontalInputLayout>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <InputLabel htmlFor="siret">Numéro de Siret</InputLabel>
              <Input
                id="siret"
                autoComplete="off"
                pattern="\d{9}|\d{14}"
                placeholder="123456789(12345)"
                {...register("number_siret", { required: true })}
                className="w-full"
                maxLength={14}
              />
              {errors.number_siret ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
            </div>
            <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
              <InputLabel htmlFor="tva">Numéro de TVA</InputLabel>
              <Input
                id="tva"
                autoComplete="off"
                {...register("vat_number", { required: true })}
                className="w-full"
                pattern="FR\d{9}"
                placeholder={`FR123456789`}
                maxLength={11}
                onChange={(event) => {
                  var input = document.getElementById("tva");
                  input.addEventListener("input", function (event) {
                    if (event.target.value.length < 2) {
                      event.target.value = "FR" + event.target.value;
                    }
                  });
                }}
              />
              {errors.vat_number ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
            </div>
          </HorizontalInputLayout>
        )}
        <HorizontalInputLayout>
          <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
            <InputLabel htmlFor="telephone">Téléphone</InputLabel>
            <PhoneInput
              international
              defaultCountry={countryCode}
              value={phoneNumber}
              onChange={phone => setPhoneNumber(phone)}
            />
            {phoneNumber && isValidPhoneNumber(phoneNumber) ? <></> : <InputError message={"Ce champ est obligatoire."} />}
          </div>
          <div className="flex flex-col gap-y-2 w-full sm:w-[45%]">
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              type="email"
              autoComplete="email"
              placeholder="Entrez votre email"
              {...register("email", { required: true })}
              className="w-full"
            />
            {errors.email ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
          </div>
        </HorizontalInputLayout>
        {userType === "Société" && (
          <div>
            <InputLabel htmlFor="site">Site internet</InputLabel>
            <Input
              id="site"
              placeholder="www.reeact.com"
              pattern="^(?:https?:\/\/)?(:www\.)([a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+)$"
              autoComplete="url"
              {...register("site_internet",
                // { required: true }
              )}
              className="w-full"
            />
            {/* {errors.site_internet ? <InputError message={"Ce champ est obligatoire."} /> : <></>} */}
          </div>
        )}
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="username">Nom d'utilisateur</InputLabel>
          <Input
            id="username"
            placeholder="Entrez votre nom d'utilisateur"
            autoComplete="off"
            {...register("full_name", { required: true })}
            className="w-full"
          />
          {errors.full_name ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div>
        {/* <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="password">Nouveau de passe</InputLabel>
          <Input
            id="password"
            type="password"
            autoComplete="off"
            placeholder="XXXXXXXXXXXXXXX"
            {...register("password1", { required: true })}
            className="w-full"
          />
          {errors.password1 ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div> */}
        <InputLabel htmlFor="password">Password</InputLabel>
        <div className="relative w-full">
          <div className="absolute inset-y-0 right-0 flex items-center px-2">
            <input className="hidden js-password-toggle" id="toggle" type="checkbox" onClick={(event) => {
              const password = document.querySelector('.js-password'),
                passwordLabel = document.querySelector('.js-password-label')
              if (password.type === 'password') {
                password.type = 'text'
                passwordLabel.innerHTML = 'hide'
              } else {
                password.type = 'password'
                passwordLabel.innerHTML = 'show'
              }
              password.focus()
            }} />
            <label className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label" for="toggle" style={{ marginTop: "0px" }}>show</label>
          </div>
          <Input {...register("password", { required: true })} className="appearance-none border-3 rounded-[100px] w-full py-3 px-3 leading-tight border-gray-300 focus:outline-none focus:bg-white text-gray-700 pr-16 font-mono js-password" id="password" type="password" autocomplete="off" />
          {errors.password ? <InputError message={"Ce champ est obligatoire."} /> : <></>}
        </div>
        <div className="flex justify-center sm:justify-end gap-x-2 text-sm">
          <Button
            type="button"
            className="bg-gray100 border border-primary text-primary rounded-[48px] my-4 py-2 px-16"
            onClick={(event) => {
              navigate("/admin/comptes-clients");
            }}
          >
            Retour
          </Button>
          <Button
            type="submit"
            className="bg-main text-white rounded-[48px] my-4 px-16 py-2"
          >
            Valider
          </Button>
        </div>
      </form>
      {
        isOpen &&
        <BaseModal closeModal={() => { setIsOpen(false) }} purpose="Error">
          {errorMsg}
        </BaseModal>
      }
    </Card>
  );
};

export default NewClient;
