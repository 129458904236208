import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});

const RefundInvoice = async (invoice_id) => {
    try {
      const response = await api.get(`admin/refund_invoice/${invoice_id}`, {
        headers: {
          authorization:
            localStorage.getItem("jwt_type") + " " + localStorage.getItem("jwt"),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };

const GetAllFactures = async () => {
    try {
        const response = await api.get(`admin/invoices`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const GetFacturesByType = async (type) => {
    try {
        const response = await api.get(`admin/invoices/${type}`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const GetFacturesById = async (id) => {
    try {
        const response = await api.get(`admin/invoice/${id}`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const DownloadAdminInvoices = async (ids) => {
    try {
        const response = await api.post(`admin/invoices/download`, ids, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });

        return response;
    } catch (error) {
        return error;
    }
}
export {
    GetAllFactures,
    GetFacturesById,
    DownloadAdminInvoices,
    GetFacturesByType,
    RefundInvoice
};