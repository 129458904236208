import { useCallback, useMemo, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import TableHistoriques from "components/Tables/TableHistoriques";
import Container from "components/Container/Container";
import RightButton from "components/Buttons/RightButton";
import CheckBox from "components/CheckBox/CheckBox";
import CustomWidget from "components/Widget/CustomWidget";
import Card from "components/Card/Card";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";
import { LiaCheckCircle, LiaTimesCircle } from "react-icons/lia";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import {
  GetAllInvoicesService,
  DownloadInvoices,
  GetInvoicesByType,
  DeleteInvoices,
} from "services/Invoice";
import eebleu from "assets/ee-bleu.png";

const Factures = () => {
  const [invoices, setInvoices] = useState([]);
  const [paidCount, setPaidCount] = useState(0);
  const [unpaidCount, setUnpaidCount] = useState(0);
  const [sentCount, setSentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [type, setType] = useState("total_invoices");

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllInvoicesService();

      if (response?.status === 200) {
        const data = response.data?.invoices;
        setInvoices(data?.invoices ?? []);
        setTotalCount(data?.invoices.length ?? 0);
        setPaidCount(data?.paid_invoices_count ?? 0);
        setUnpaidCount(data?.unpaid_invoices_count ?? 0);
        setSentCount(data?.sent_invoices_count ?? 0);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!type.length) return;
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetInvoicesByType(type);

      if (response?.status === 200) {
        setInvoices(response?.data?.invoices ?? []);
        // setTotalCount(data?.invoices.length ?? 0)
        // setPaidCount(data?.paid_invoices_count ?? 0);
        // setUnpaidCount(data?.unpaid_invoices_count ?? 0);
        // setSentCount(data?.sent_invoices_count ?? 0);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, [type]);

  const BrowseInvoicesByType = (invoiceType) => {
    switch (invoiceType) {
      case "Total Factures":
        setType("total_invoices");
        break;
      case "Factures Payées":
        setType("paid_invoices");
        break;
      case "Total Factures Impayées":
        setType("unpaid_invoices");
        break;
      case "Total Factures Envoyées":
        setType("invoices_sent");
        break;
      default:
        break;
    }
  };

  /*
  const removeCheckedElement = useCallback(
    (elements) => {
      setData(
        data.filter((d) => {
          return !elements.includes(d.id);
        })
      );
    },
    [data]
  ); */
  const removeCheckedElement = useCallback(
    (elements) => {
      setInvoices(
        invoices.filter((d) => {
          return !elements.includes(d.id);
        })
      );
    },
    [invoices]
  );

  const columns = useMemo(
    () => [
      {
        id: "checkbox",
        Cell: ({
          selected,
          addToSelected,
          removeFromSelected,
          row: {
            original: { id },
          },
        }) => {
          const handleOnChange = useCallback(() => {
            //Supprimer du tableau si c'était coché
            if (selected.includes(id)) {
              removeFromSelected(id);
              // eslint-disable-next-line react-hooks/exhaustive-deps
              selected = selected.filter((item) => item !== id);
            } else {
              addToSelected(id);
              selected.push(id);
            }
            setSelectedIDs(selected);
          }, [addToSelected, removeFromSelected, id, selected]);

          return (
            <CheckBox
              onChange={handleOnChange}
              checked={selected.includes(id)}
              extra="border-2 border-[#BEBEBE]"
            />
          );
        },
        columnId: 0,
      },
      {
        Header: "ID Invoice",
        accessor: "id",
        columnId: 1,
        Cell: ({ cell: { value } }) => {
          // return (
          //   <Link to={`/factures/${value}`}>
          //     <div className="py-4 font-semibold text-[12.008px] mx-2 ">
          //       #{value}
          //     </div>
          //   </Link>
          // );
          return (
            <Link to={`/factures/${value}`}>
              <div className="py-4 font-semibold text-[12.008px] mx-2 ">
                <span className="whitespace-normal break-all">#{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        columnId: 2,
        Cell: ({ cell: { value, row } }) => {
          // return row.original.status === "Completed" ? (
          //   <Link to={`/factures/${row.original.id}`}>
          //     <div className="py-4 text-[12.008px] font-normal mx-2">
          //       {value}
          //     </div>
          //   </Link>
          // ) : <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
          //   <div className="py-4 text-[12.008px] font-normal mx-2">
          //     {value}
          //   </div>
          // </Link>
          return row.original.status === "Completed" ? (
            <Link to={`/factures/${row.original.id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2">
                <span className="whitespace-normal break-all">{value}</span>
              </div>
            </Link>
          ) : (
            <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
              <div className="py-4 text-[12.008px] font-normal mx-2">
                <span className="whitespace-normal break-all">{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "DateTime",
        accessor: "created_at",
        columnId: 3,
        Cell: ({ cell: { value, row } }) => {
          // return row.original.status === "Completed" ? (
          //   <Link to={`/factures/${row.original.id}`}>
          //     <div className="py-4 text-[12.008px] font-normal mx-2">
          //       {value}
          //     </div>
          //   </Link>
          // ) : <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
          //   <div className="py-4 text-[12.008px] font-normal mx-2">
          //     {value}
          //   </div>
          // </Link>
          return row.original.status === "Completed" ? (
            <Link to={`/factures/${row.original.id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2">
                <span className="whitespace-normal break-all">
                  {new Date(value).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }) ?? ""}
                </span>
              </div>
            </Link>
          ) : (
            <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
              <div className="py-4 text-[12.008px] font-normal mx-2">
                <span className="whitespace-normal break-all">
                  {new Date(value).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }) ?? ""}
                </span>
              </div>
            </Link>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        columnId: 4,
        Cell: ({
          cell: { value, row },
          // row: {
          //   original: { id },
          // },
        }) => {
          return row.original.status === "Completed" ? (
            <Link to={`/factures/${row.original.id}`}>
              {value === "On Hold" ? (
                <div className="py-4 flex text-[12.008px] font-medium leading-none text-warning mx-2">
                  En attente
                </div>
              ) : value === "Canceled" ? (
                <div className="py-4 flex text-primary text-[12.008px] font-medium leading-none mx-2">
                  Annulé
                </div>
              ) : (
                <div className="py-4 flex text-success text-[12.008px] font-medium leading-none mx-2">
                  Complété
                </div>
              )}
            </Link>
          ) : (
            <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
              {value === "On Hold" ? (
                <div className="py-4 flex text-[12.008px] font-medium leading-none text-warning mx-2">
                  En attente
                </div>
              ) : value === "Canceled" ? (
                <div className="py-4 flex text-primary text-[12.008px] font-medium leading-none mx-2">
                  Annulé
                </div>
              ) : (
                <div className="py-4 flex text-success text-[12.008px] font-medium leading-none mx-2">
                  Complété
                </div>
              )}
            </Link>
          );
        },
      },
      {
        Header: "Détails des services",
        accessor: "service_detail",
        columnId: 5,
        Cell: ({
          cell: { value, row },
          // row: {
          //   original: { id },
          // },
        }) => {
          // return (
          //   <Link to={`/factures/${id}`}>
          //     <div className="py-4 text-[12.008px] font-normal mx-2">
          //       {value}
          //     </div>
          //   </Link>
          // );
          // return row.original.status === "Completed" ? (
          //   <Link to={`/factures/${row.original.id}`}>
          //     <div className="py-4 text-[12.008px] font-normal mx-2">
          //       {value}
          //     </div>
          //   </Link>
          // ) : <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
          //   <div className="py-4 text-[12.008px] font-normal mx-2">
          //     {value}
          //   </div>
          // </Link>
          return row.original.status === "Completed" ? (
            <Link to={`/factures/${row.original.id}`}>
              <div className="py-4 text-[12.008px] font-normal mx-2">
                <span className="whitespace-normal break-all">{value}</span>
              </div>
            </Link>
          ) : (
            <Link to={`/demande-dinterventions/${row.original.id}/devis`}>
              <div className="py-4 text-[12.008px] font-normal mx-2">
                <span className="whitespace-normal break-all">{value}</span>
              </div>
            </Link>
          );
        },
      },
      {
        id: "dots",
        columnId: 6,
        Cell: ({
          row: {
            original: { id },
          },
        }) => {
          const navigate = useNavigate();
          const removeElement = useCallback(async () => {
            document.getElementById("prewaiting").removeAttribute("hidden");
            const response = await DeleteInvoices({ invoice_ids: [id] });
            document
              .getElementById("prewaiting")
              .setAttribute("hidden", "true");
            if (response?.status === 200) removeCheckedElement([id]);
          }, [id]);

          const seeFacture = useCallback(() => {
            navigate(`/factures/${id}`);
          }, [id, navigate]);

          return (
            <MenuDropdown
              trigger={
                <button className="active:scale-95 transition-all hover:bg-gray200 p-1 rounded-full">
                  <BsThreeDotsVertical size={18} className="cursor-pointer" />
                </button>
              }
              menu={[
                <MenuDropdownButton
                  text="Voir la facture"
                  onClick={seeFacture}
                />,
                // <MenuDropdownButton
                //   text="Supprimer la facture"
                //   onClick={removeElement}
                // />,
                <MenuDropdownButton
                  text="Télécharger la facture"
                  onClick={async () => {
                    var postData = { invoice_ids: [id] };
                    const response = await DownloadInvoices(postData);
                    if (response?.data === undefined) return;
                    window.open(
                      process.env.REACT_APP_SERVER_URL + response?.data
                    );
                  }}
                />,
              ]}
            />
          );
        },
      },
    ],
    [removeCheckedElement]
  );
  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Factures</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
      </Container>

      {invoices.length > 0 ? (
        <>
          <div className="my-6 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {/* case 'Total Factures':
        setType("total_invoices");
        break;
      case 'Factures Payées':
        setType("paid_invoices");
        break;
      case 'Total Factures Impayées':
        setType("unpaid_invoices");
        break;
      case 'Total Factures Envoyées':
        setType("invoices_sent");
        break; */}
            <CustomWidget
              title="Total Factures"
              value={totalCount}
              text="total_invoices"
              icon={
                <FaFileInvoiceDollar className="text-[#858585]" size={20} />
              }
              type={type}
              BrowseByType={BrowseInvoicesByType}
            />
            <CustomWidget
              title="Factures Payées"
              value={paidCount}
              text="paid_invoices"
              icon={<LiaCheckCircle className="text-success" size={22} />}
              type={type}
              BrowseByType={BrowseInvoicesByType}
            />
            <CustomWidget
              title="Total Factures Impayées"
              value={unpaidCount}
              text="unpaid_invoices"
              icon={<LiaTimesCircle className="text-danger" size={22} />}
              type={type}
              BrowseByType={BrowseInvoicesByType}
            />
            <CustomWidget
              title="Total Factures Envoyées"
              value={sentCount}
              text="invoices_sent"
              icon={<FaFileInvoiceDollar className="text-success" size={20} />}
              type={type}
              BrowseByType={BrowseInvoicesByType}
            />
          </div>

          <Container extra="mt-8 sm:mt-10">
            <div className="flex flex-col gap-y-2">
              <p className="font-semibold text-2xl text-main">Historiques</p>
              {/* <p className="text-secondary hidden md:block text-sm font-normal ">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
            </div>
            <RightButton
              icon={<HiDownload />}
              text="Télécharger vos Factures"
              onClick={async (event) => {
                var postData = { invoice_ids: selectedIDs };
                const response = await DownloadInvoices(postData);
                if (response?.data === undefined) return;
                window.open(process.env.REACT_APP_SERVER_URL + response?.data);
              }}
            />
          </Container>

          <Card
            extra={
              "w-full pb-16 sm:overflow-x-auto mt-8 !bg-transparent rounded-none"
            }
          >
            <TableHistoriques
              columns={columns}
              data={invoices}
              removeCheckedElement={removeCheckedElement}
            />
          </Card>
        </>
      ) : (
        <div className="flex flex-col">
          <div className="sm:mt-48 mt-24">
            <p
              className="font-semibold sm:text-4xl text-main
      text-center text-xl"
            >
              Vous n'êtes pas abonné.
            </p>
          </div>
          <div className="absolute bottom-0 left-0">
            <img
              src={eebleu}
              alt="ee-logo"
              className="w-2/3 h-2/3 sm:w-full sm:h-full"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Factures;
