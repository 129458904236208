import ChangePeriod from "components/ChangePeriod/ChangePeriod";
import TableDashboard from "components/Tables/TableDashboard";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GetAllIntervenstionsService } from "services/Intervention";

const InterventionPreview = ({ data }) => {
  // const [active, setActive] = useState("today");
  const [active, setActive] = useState("monthly");
  const [interventions, setInterventions] = useState({});

  const setMonthly = () => {
    setActive("monthly");
  };

  const setWeekly = () => {
    setActive("weekly");
  };

  const setToday = () => {
    setActive("today");
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetAllIntervenstionsService(active);

      if (response?.status === 200) {
        setInterventions(response.data?.intervention_requests.slice(0, 5) ?? []);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
    }

    fetchData();
  }, [active]);

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        columnId: 0,
        accessor: "title",
        Cell: ({ row }) => (
          <div className="flex flex-col gap-y-2 pr-1">
            <p
              className="font-medium text-sm text-main 2xl:text-xs
            md:whitespace-pre-wrap max-md:truncate max-md:w-20"
            >
              {row.original.title}
            </p>
            <p className="font-normal text-[10.5px] text-main">
              {row.original.site_url}
            </p>
          </div>
        ),
      },
      {
        columnId: 1,
        accessor: "updated_at",
        Cell: ({ row }) => (
          <div className="flex flex-col gap-y-2 pr-1">
            <p
              className="font-medium text-xs text-main
            md:whitespace-pre-wrap max-md:truncate max-md:w-12"
            >
              {new Date(row.original.updated_at).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })}
            </p>
            <p className="font-normal text-[10.5px] text-main">
              {row.original.label}
            </p>
          </div>
        ),
      },
      // {
      //   columnId: 2,
      //   accessor: "additional_information",
      //   Cell: ({ cell: { value } }) => (
      //     <div
      //       className="flex text-primary text-xs
      //     font-semibold py-6 px-2 2xl:pl-5 justify-center"
      //     >
      //       <p>{value.slice(0, 20)}...</p>
      //     </div>
      //   ),
      // },
      {
        columnId: 3,
        accessor: "status",

        Cell: ({ cell: { value } }) => {
          // return value === 1 ? (
          //   <div className="flex text-sky-500 text-[12px] font-normal px-1">
          //     En attente d' examen
          //   </div>
          // ) : value === 2 ? (
          //   <div className="flex text-indigo-700 text-[12px] font-normal px-1">
          //     Devis accepté
          //   </div>
          // ) : value === 3 ? (
          //   <div className="flex text-danger text-[12px] font-normal px-1">
          //     {/* Devis accepté */}
          //     Demande rejetée
          //   </div>
          // ) : (
          //   <div className="flex text-success text-[12px] font-normal px-1">
          //     Complété
          //   </div>
          // );
          return value === 0 ? (
            <div className="flex text-sky-500 text-[13px] font-normal px-1 w-[110px]">
              <span className="whitespace-normal break-words">
                En attente d' examen
              </span>
            </div>
          ) : value === 1 ? (
            <div className="flex text-[#14b8a6] text-[13px] font-normal px-1 w-[110px]">
              <span className="whitespace-normal break-words">
                En cours
              </span>
            </div>
          ) : value === 2 ? (
            <div className="flex text-indigo-700 text-[13px] font-normal px-1">
              <span className="whitespace-normal break-words">
                {/* Devis accepté */}
                En attente
              </span>
            </div>
          ) : value === 3 ? (
            <div className="flex text-danger text-[13px] font-normal px-1">
              {/* Devis accepté */}
              <span className="whitespace-normal break-words">
                Demande rejetée
              </span>
            </div>
          ) : <div className="flex text-green-500 text-[13px] font-normal px-1">
            <span className="whitespace-normal break-words">
              Complété
            </span>
          </div>;
        },
      },
    ],
    []
  );

  return (
    <div
      className="bg-white rounded-[20px] p-8
            w-full flex flex-col
            shadow-md"
    >
      <div
        className="flex justify-between md:items-center
              w-full md:mb-8 max-md:flex-col max-md:gap-y-2
              mb-4"
      >
        <div className="flex flex-col gap-y-2">
          <p className="font-medium text-sm text-main">
            Demande d'intervention
          </p>
          {/* <p className="font-normal text-[11px] text-secondary">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <div className="flex items-center gap-x-2">
          <ChangePeriod
            setMonthly={setMonthly}
            setWeekly={setWeekly}
            setToday={setToday}
            active={active}
          />
        </div>
      </div>
      <div>
        {interventions.length >= 0 && <TableDashboard columns={columns} data={interventions} />}
      </div>
      <div className="flex justify-end">
        <span
          onClick={() => navigate("/demande-dinterventions")}
          className="text-main text-sm font-medium
                cursor-pointer hover:bg-main hover:text-white
                p-2 rounded-md"
        >
          Voir plus
        </span>
      </div>
    </div>
  );
};

export default InterventionPreview;
