import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

const jwt = localStorage.getItem("jwt");
const jwt_type = localStorage.getItem("jwt_type");

const GetSettingInformation = async () => {
  try {
    const response = await api.get(`user/setting`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const UpdateSettingInformation = async (data) => {
  try {
    const response = await api.post(`user/setting`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const AddNewCard = async (data) => {
  try {
    const response = await api.post(`add_new_card`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const SetDefaultCard = async (data) => {
  try {
    const response = await api.post(`set_default_card`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteCard = async (data) => {
  try {
    const response = await api.post(`delete_card`, data, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const Unsubscribe = async () => {
  try {
    const response = await api.get(`/user/unsubscribe`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteKeyword = async (keyword_id) => {
  try {
    const response = await api.get(`/user/delete_keyword/${keyword_id}`, {
      headers: {
        authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const CreatePaymentMethod = async (data) => {
  try {
    const response = await api.post(
      `https://api.stripe.com/v1/payment_methods`,
      data,
      {
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_STRIPE_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export {
  GetSettingInformation,
  UpdateSettingInformation,
  CreatePaymentMethod,
  AddNewCard,
  SetDefaultCard,
  DeleteCard,
  Unsubscribe,
  DeleteKeyword,
};
