import { useEffect } from "react";
const AxeptioIntegration = () => {
  useEffect(() => {
    if (!window.axeptioSettings) {
      window.axeptioSettings = {
        clientId: "6560b2ad53f0b39d6380eea2",
        cookiesVersion: "youtube contextual consent react-en",
      };

      (function (d, s) {
        var t = d.getElementsByTagName(s)[0],
          e = d.createElement(s);
        e.async = true;
        e.src = "//static.axept.io/sdk-slim.js";
        t.parentNode.insertBefore(e, t);
      })(document, "script");
    }
  }, []);

  return null;
};

export default AxeptioIntegration;