const CustomDisplayParams = ({ text, setAdditionalKeywordUrl, value, disabled = 0 }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <p className="text-sm text-main font-medium">
        <span className="whitespace-normal break-all">
          {text}
        </span>
      </p>
      {/* <span
        className="flex items-center pl-3 w-full
      bg-gray200 rounded-[100px] md:w-52 h-10"
      >
        <p className="text-main text-sm font-semibold">{params}</p>
      </span> */}
      <input
        type="text"
        onChange={(event) => {
          setAdditionalKeywordUrl(event.target.value);
        }}
        // className="flex items-center pl-3 w-full bg-gray200 rounded-[100px] md:w-52 h-10 focus:outline-none border-none"
        className="flex items-center pl-3 w-full bg-gray200 rounded-[100px] xl:w-40 max-xl:w-30 h-10 focus:outline-none border-none 2xl:w-80"
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomDisplayParams;
