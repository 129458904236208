import { useState } from 'react';
import defaultUser from "assets/avatars/default.png";
import Modal from 'components/ImageCrop/base/Modal';
import { readFile } from 'helpers/cropImage';
import ImageCropModalContent from './ImageCropModalContent';
import { useImageCropContext } from '../../providers/ImageCropProvider';
import { UploadAvatarService } from 'services/Upload';

const ImageCrop = ({ selectedFiles, setSelectedFiles, newClient = 0 }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [openModal, setOpenModal] = useState(false);
  // const [preview, setPreview] = useState(user1);
  const [preview, setPreview] = useState(newClient ? defaultUser : user?.avatar_url?.length ? process.env.REACT_APP_SERVER_URL + user?.avatar_url : defaultUser);

  const { getProcessedImage, setImage, resetStates } = useImageCropContext();

  const handleDone = async () => {
    var avatar = await getProcessedImage();
    const filename = avatar?.name;
    const extension = filename.split(".").pop();
    setPreview(window.URL.createObjectURL(avatar));
    resetStates();
    setOpenModal(false);
    avatar["preview"] = {
      "type": "image",
      "url": window.URL.createObjectURL(avatar)
    }
    avatar["extension"] = extension;
    const formData = new FormData();
    formData.append("file_0", avatar);

    const response = await UploadAvatarService(formData);
    if (response.status === 200) {
      setSelectedFiles([...response.data]);
    }
  };

  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];
    if (file === null)
      return;
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
    setOpenModal(true);
  };

  return (
    // <div className="bg-gray-100 flex justify-center items-center">
    <div className="flex justify-center items-center">
      <input
        type="file"
        onChange={handleFileChange}
        className="hidden"
        id="avatarInput"
        accept="image/*"
      />
      <label htmlFor="avatarInput" className="cursor-pointer">
        <img
          src={preview}
          height={192}
          width={192}
          className="object-cover rounded-full h-48 w-48"
          alt=""
        />
      </label>

      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        <ImageCropModalContent handleDone={handleDone} handleClose={() => setOpenModal(false)} />
      </Modal>
    </div>
  );
};

export default ImageCrop;
