import { useState, useMemo, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import TableInterventions from "components/Tables/TableInterventions";
import ChangePeriod from "components/ChangePeriod/ChangePeriod";
import ColumnChart from "components/Charts/Admin/ColumnChart";
import DonutChart from "components/Charts/Admin/DonutChart";
import Container from "components/Container/Container";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";
import Widget from "components/Widget/Widget";
import Card from "components/Card/Card";

import { BsThreeDotsVertical, BsPerson } from "react-icons/bs";
import { LiaCheckCircle } from "react-icons/lia";
import { FcGoogle } from "react-icons/fc";

import { columnchart, donutchart } from "constants/charts/Dashboard/config";
import { statInterventions } from "constants/interventions";
import { useEffect } from "react";
import { GetStatistics } from "services/Admin/Statistiques";
import BackTableInterventions from "components/Tables/BackTableInterventions";
import CustomColumnChart from "components/Charts/Dashboard/CustomColumnChart";
import StatisticsColumnChart from "components/Charts/Admin/StatisticsColumnChart";

const Statistiques = () => {
  const [active, setActive] = useState("monthly");
  const [statistics, setStatistics] = useState({});
  const [inscriptionCount, setInscriptionCount] = useState(0);
  const [unscriptionCount, setUnscriptionCount] = useState(0);
  const [data, setData] = useState({});

  const setMonthly = useCallback(() => {
    setActive("monthly");
  }, []);

  const setWeekly = useCallback(() => {
    setActive("weekly");
  }, []);

  const setToday = useCallback(() => {
    setActive("today");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetStatistics(active);

      if (response?.status === 200) {
        setStatistics(response?.data);
        const acc_count = response?.data?.acc_count;
        setInscriptionCount(acc_count?.map(item => item.inscription).reduce((acc, curr) => acc + curr, 0));
        setUnscriptionCount(acc_count?.map(item => item.unscription_cnt).reduce((acc, curr) => acc + curr, 0));
        setData(response?.data?.intervention_data?.inter_data);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    }

    fetchData();
  }, [active]);

  const columns = useMemo(
    () => [
      {
        accessor: "full_name",
        columnId: 0,
        hidden: true,
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-primary text-sm font-medium py-6 px-2
          // max-md:text-xs"
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex text-primary text-sm font-medium py-6 px-2 max-md:text-xs text-center"
          >
            <span className="whitespace-normal break-words">{value}</span>
          </div>
        ),
      },
      {
        Cell: () => (
          <div className="flex justify-center md:pl-6 pl-2 md:pr-1 pr-4">
            <FcGoogle size={20} />
          </div>
        ),
        columnId: 1,
        accessor: "logo",
      },
      {
        accessor: "title",
        columnId: 2,
        hidden: true,
        Cell: ({ cell: { value } }) => (
          // <div
          //   className="flex text-primary text-sm font-semibold py-6 px-2
          // max-md:text-xs justify-center"
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex text-primary text-sm font-semibold py-6 px-2 max-md:text-xs justify-center text-center"
          >
            <span className="whitespace-normal break-words">{value}</span>
          </div>
        ),
      },
      {
        accessor: "updated_at",
        columnId: 3,
        hidden: true,
        Cell: ({ cell: { value } }) => (
          // <div className="flex text-primary text-[12px] font-normal px-1">
          //   {value}
          // </div>
          <div className="flex text-primary text-[12px] font-normal px-1 text-center">
            <span className="whitespace-normal break-words">
              {new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) ?? ''}
            </span>
          </div>
        ),
      },
      {
        accessor: "site_url",
        columnId: 4,
        Cell: ({ cell: { value } }) => (
          // <div className="flex text-primary text-[12px] font-normal px-1">
          //   {value}
          // </div>
          <div className="flex text-primary text-[12px] font-normal px-1 text-center">
            <span className="whitespace-normal break-words">
              {new URL(value).origin}
            </span>
          </div>
        ),
      },
      {
        accessor: "status",
        columnId: 5,
        Cell: ({ cell: { value } }) => {
          // return value === 1 ? (
          //   <div className="flex text-sky-500 text-[12px] font-normal px-1 text-center">
          //     <span className="whitespace-normal break-words">
          //       En attente d' examen
          //     </span>
          //   </div>
          // ) : value === 2 ? (
          //   <div className="flex text-indigo-700 text-[12px] font-normal px-1 text-center">
          //     <span className="whitespace-normal break-words">
          //       Demande envoyée
          //     </span>
          //   </div>
          // ) : value === 3 ? (
          //   <div className="flex text-danger text-[12px] font-normal px-1 text-center">
          //     {/* Devis accepté */}
          //     <span className="whitespace-normal break-words">
          //       Demande rejetée
          //     </span>
          //   </div>
          // ) : (
          //   <div className="flex text-success text-[12px] font-normal px-1 text-center">
          //     <span className="whitespace-normal break-words">
          //       Complété
          //     </span>
          //   </div>
          // );
          return value === 0 ? (
            <div className="flex text-sky-500 text-[13px] font-normal px-1 w-[110px]">
              <span className="whitespace-normal break-words">
                En attente d' examen
              </span>
            </div>
          ) : value === 1 ? (
            <div className="flex text-[#14b8a6] text-[13px] font-normal px-1 w-[110px]">
              <span className="whitespace-normal break-words">
                En cours
              </span>
            </div>
          ) : value === 2 ? (
            <div className="flex text-indigo-700 text-[13px] font-normal px-1">
              <span className="whitespace-normal break-words">
                {/* Devis accepté */}
                En attente
              </span>
            </div>
          ) : value === 3 ? (
            <div className="flex text-danger text-[13px] font-normal px-1">
              {/* Devis accepté */}
              <span className="whitespace-normal break-words">
                Demande rejetée
              </span>
            </div>
          ) : <div className="flex text-green-500 text-[13px] font-normal px-1">
            <span className="whitespace-normal break-words">
              Complété
            </span>
          </div>;
        },
      },
      {
        id: "dots",
        Cell: () => {
          const navigate = useNavigate();

          const seeIntervention = useCallback(() => {
            navigate(`/admin/demande-dinterventions`);
          }, [navigate]);

          return (
            <MenuDropdown
              trigger={
                <button
                  className="active:scale-95 transition-all hover:bg-gray200
                p-1 rounded-full"
                >
                  <BsThreeDotsVertical size={18} className="cursor-pointer" />
                </button>
              }
              menu={[
                <MenuDropdownButton
                  key={0}
                  text="Voir la demande"
                  onClick={seeIntervention}
                />,
              ]}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Statistiques</p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
        <div className="flex">
          <ChangePeriod
            setMonthly={setMonthly}
            setWeekly={setWeekly}
            setToday={setToday}
            active={active}
          />
        </div>
      </Container>
      <div
        className="my-6 grid grid-cols-1 max-md:gap-y-5 md:grid-cols-2 lg:grid-cols-3
        gap-x-8"
      >
        <Widget
          title="Utilisateurs en ligne"
          value={statistics?.active_account}
          icon={<BsPerson className="text-[#858585]" size={22} />}
        />
        <Widget
          title={statistics?.pre_month}
          value={statistics?.pre_month_acc}
          icon={<LiaCheckCircle className="text-success" size={22} />}
        />
        <Widget
          title={statistics?.pre1_month}
          value={statistics?.pre1_month_acc}
          icon={<LiaCheckCircle className="text-success" size={22} />}
        />
      </div>

      <Container extra="mt-8 sm:mt-10">
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">
            Statistiques inscriptions
          </p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal ">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>
      </Container>

      <Card extra={"w-full pb-6 sm:overflow-x-auto mt-8 mb-4 !bg-transparent"}>
        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-x-8 gap-y-8">
          {/* Diagramme en baton  */}
          <StatisticsColumnChart
            options={columnchart.options}
            series={[
              {
                name: "Inscription",
                color: "#284F60",
                data: active === "today" ?
                  [
                    { x: "Mon", y: statistics?.acc_count?.[0]?.inscription },
                    { x: "Tue", y: statistics?.acc_count?.[1]?.inscription },
                    { x: "Wed", y: statistics?.acc_count?.[2]?.inscription },
                    { x: "Thu", y: statistics?.acc_count?.[3]?.inscription },
                    { x: "Fri", y: statistics?.acc_count?.[4]?.inscription },
                    { x: "Sat", y: statistics?.acc_count?.[5]?.inscription },
                    { x: "Sun", y: statistics?.acc_count?.[6]?.inscription },
                  ] : active === "weekly" ? [
                    { x: "Week 1", y: statistics?.acc_count?.[0]?.inscription },
                    { x: "Week 2", y: statistics?.acc_count?.[1]?.inscription },
                    { x: "Week 3", y: statistics?.acc_count?.[2]?.inscription },
                    { x: "Week 4", y: statistics?.acc_count?.[3]?.inscription },
                    { x: "Week 5", y: statistics?.acc_count?.[4]?.inscription },
                  ] : active === "monthly" ? [
                    { x: "Jan", y: statistics?.acc_count?.[0]?.inscription },
                    { x: "Feb", y: statistics?.acc_count?.[1]?.inscription },
                    { x: "Mar", y: statistics?.acc_count?.[2]?.inscription },
                    { x: "Apr", y: statistics?.acc_count?.[3]?.inscription },
                    { x: "May", y: statistics?.acc_count?.[4]?.inscription },
                    { x: "Jun", y: statistics?.acc_count?.[5]?.inscription },
                    { x: "Jul", y: statistics?.acc_count?.[6]?.inscription },
                    { x: "Aug", y: statistics?.acc_count?.[7]?.inscription },
                    { x: "Sep", y: statistics?.acc_count?.[8]?.inscription },
                    { x: "Oct", y: statistics?.acc_count?.[9]?.inscription },
                    { x: "Nov", y: statistics?.acc_count?.[10]?.inscription },
                    { x: "Dec", y: statistics?.acc_count?.[11]?.inscription },
                  ] : [],
              },
              {
                name: "Désabonnement",
                color: "rgba(39, 79, 96, 0.55)",
                data: active === "today" ?
                  [
                    { x: "Mon", y: statistics?.acc_count?.[0]?.unscription_cnt },
                    { x: "Tue", y: statistics?.acc_count?.[1]?.unscription_cnt },
                    { x: "Wed", y: statistics?.acc_count?.[2]?.unscription_cnt },
                    { x: "Thu", y: statistics?.acc_count?.[3]?.unscription_cnt },
                    { x: "Fri", y: statistics?.acc_count?.[4]?.unscription_cnt },
                    { x: "Sat", y: statistics?.acc_count?.[5]?.unscription_cnt },
                    { x: "Sun", y: statistics?.acc_count?.[6]?.unscription_cnt },
                  ] : active === "weekly" ? [
                    { x: "Week 1", y: statistics?.acc_count?.[0]?.unscription_cnt },
                    { x: "Week 2", y: statistics?.acc_count?.[1]?.unscription_cnt },
                    { x: "Week 3", y: statistics?.acc_count?.[2]?.unscription_cnt },
                    { x: "Week 4", y: statistics?.acc_count?.[3]?.unscription_cnt },
                    { x: "Week 5", y: statistics?.acc_count?.[4]?.unscription_cnt },
                  ] : active === "monthly" ? [
                    { x: "Jan", y: statistics?.acc_count?.[0]?.unscription_cnt },
                    { x: "Feb", y: statistics?.acc_count?.[1]?.unscription_cnt },
                    { x: "Mar", y: statistics?.acc_count?.[2]?.unscription_cnt },
                    { x: "Apr", y: statistics?.acc_count?.[3]?.unscription_cnt },
                    { x: "May", y: statistics?.acc_count?.[4]?.unscription_cnt },
                    { x: "Jun", y: statistics?.acc_count?.[5]?.unscription_cnt },
                    { x: "Jul", y: statistics?.acc_count?.[6]?.unscription_cnt },
                    { x: "Aug", y: statistics?.acc_count?.[7]?.unscription_cnt },
                    { x: "Sep", y: statistics?.acc_count?.[8]?.unscription_cnt },
                    { x: "Oct", y: statistics?.acc_count?.[9]?.unscription_cnt },
                    { x: "Nov", y: statistics?.acc_count?.[10]?.unscription_cnt },
                    { x: "Dec", y: statistics?.acc_count?.[11]?.unscription_cnt },
                  ] : [],
              },
            ]}
            firstText="Moteur"
            // firstText={<span className="whitespace-normal break-all">Moteur</span>}
            secondText="Réseaux sociaux"
          // secondText={<span className="whitespace-normal break-all">Réseaux sociaux</span>}
          />

          {/* Ici le poucentage 23, représente le pourcentage non occupé, donc
          prévoir un pourcentage pour le vide, qui sera la soustration des
          pourcentages peu Inscription et Désabonnemnt par exemple */}
          {/* <DonutChart options={donutchart.options} series={[54, 23.5, 23]} /> */}
          <DonutChart options={donutchart.options} series={[unscriptionCount, inscriptionCount]} />
        </div>
        <Container extra="mt-8 sm:mt-10">
          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-2xl text-main">
              Statistiques chiffre d'affaire
            </p>
            {/* <p className="text-secondary hidden md:block text-sm font-normal ">
              Lorem ipsum dolor sit amet, consectetur
            </p> */}
          </div>
        </Container>
        <div className="flex w-full mt-8">
          <CustomColumnChart
            options={columnchart.options}
            series={[
              {
                name: "Année en cours",
                color: "#284F60",
                data: active === "today" ?
                  [
                    { x: "Mon", y: statistics?.turnover_data?.[0]?.inscription },
                    { x: "Tue", y: statistics?.turnover_data?.[1]?.inscription },
                    { x: "Wed", y: statistics?.turnover_data?.[2]?.inscription },
                    { x: "Thu", y: statistics?.turnover_data?.[3]?.inscription },
                    { x: "Fri", y: statistics?.turnover_data?.[4]?.inscription },
                    { x: "Sat", y: statistics?.turnover_data?.[5]?.inscription },
                    { x: "Sun", y: statistics?.turnover_data?.[6]?.inscription },
                  ] : active === "weekly" ? [
                    { x: "Week 1", y: statistics?.turnover_data?.[0]?.inscription },
                    { x: "Week 2", y: statistics?.turnover_data?.[1]?.inscription },
                    { x: "Week 3", y: statistics?.turnover_data?.[2]?.inscription },
                    { x: "Week 4", y: statistics?.turnover_data?.[3]?.inscription },
                    { x: "Week 5", y: statistics?.turnover_data?.[4]?.inscription },
                  ] : active === "monthly" ? [
                    { x: "Jan", y: statistics?.turnover_data?.[0]?.inscription },
                    { x: "Feb", y: statistics?.turnover_data?.[1]?.inscription },
                    { x: "Mar", y: statistics?.turnover_data?.[2]?.inscription },
                    { x: "Apr", y: statistics?.turnover_data?.[3]?.inscription },
                    { x: "May", y: statistics?.turnover_data?.[4]?.inscription },
                    { x: "Jun", y: statistics?.turnover_data?.[5]?.inscription },
                    { x: "Jul", y: statistics?.turnover_data?.[6]?.inscription },
                    { x: "Aug", y: statistics?.turnover_data?.[7]?.inscription },
                    { x: "Sep", y: statistics?.turnover_data?.[8]?.inscription },
                    { x: "Oct", y: statistics?.turnover_data?.[9]?.inscription },
                    { x: "Nov", y: statistics?.turnover_data?.[10]?.inscription },
                    { x: "Dec", y: statistics?.turnover_data?.[11]?.inscription },
                  ] : [],
              },
              {
                name: "Année précédente",
                color: "rgba(39, 79, 96, 0.55)",
                data: active === "today" ?
                  [
                    { x: "Mon", y: statistics?.turnover_data?.[0]?.unscription },
                    { x: "Tue", y: statistics?.turnover_data?.[1]?.unscription },
                    { x: "Wed", y: statistics?.turnover_data?.[2]?.unscription },
                    { x: "Thu", y: statistics?.turnover_data?.[3]?.unscription },
                    { x: "Fri", y: statistics?.turnover_data?.[4]?.unscription },
                    { x: "Sat", y: statistics?.turnover_data?.[5]?.unscription },
                    { x: "Sun", y: statistics?.turnover_data?.[6]?.unscription },
                  ] : active === "weekly" ? [
                    { x: "Week 1", y: statistics?.turnover_data?.[0]?.unscription },
                    { x: "Week 2", y: statistics?.turnover_data?.[1]?.unscription },
                    { x: "Week 3", y: statistics?.turnover_data?.[2]?.unscription },
                    { x: "Week 4", y: statistics?.turnover_data?.[3]?.unscription },
                    { x: "Week 5", y: statistics?.turnover_data?.[4]?.unscription },
                  ] : active === "monthly" ? [
                    { x: "Jan", y: statistics?.turnover_data?.[0]?.unscription },
                    { x: "Feb", y: statistics?.turnover_data?.[1]?.unscription },
                    { x: "Mar", y: statistics?.turnover_data?.[2]?.unscription },
                    { x: "Apr", y: statistics?.turnover_data?.[3]?.unscription },
                    { x: "May", y: statistics?.turnover_data?.[4]?.unscription },
                    { x: "Jun", y: statistics?.turnover_data?.[5]?.unscription },
                    { x: "Jul", y: statistics?.turnover_data?.[6]?.unscription },
                    { x: "Aug", y: statistics?.turnover_data?.[7]?.unscription },
                    { x: "Sep", y: statistics?.turnover_data?.[8]?.unscription },
                    { x: "Oct", y: statistics?.turnover_data?.[9]?.unscription },
                    { x: "Nov", y: statistics?.turnover_data?.[10]?.unscription },
                    { x: "Dec", y: statistics?.turnover_data?.[11]?.unscription },
                  ] : [],
              },
            ]}
            firstText="Afficher année en cours"
            secondText="Afficher année précédente"
            active={active}
          />
        </div>
        <Container extra="mt-8 sm:mt-10">
          <div className="flex flex-col gap-y-2">
            <p className="font-semibold text-2xl text-main">
              Dernières demandes d'interventions
            </p>
            {/* <p className="text-secondary hidden md:block text-sm font-normal ">
              Lorem ipsum dolor sit amet, consectetur
            </p> */}
          </div>
        </Container>
        <div className="flex flex-col w-full mt-8">

          {/* Ici size le nombre de lignes du tableau à afficher */}
          {/* {data?.length && <TableInterventions
            columns={columns}
            data={data}
            size={4}
          />
          } */}
          {data?.length > 0 && <BackTableInterventions
            columns={columns}
            data={data}
            size={8}
          />
          }
        </div>
      </Card>
    </>
  );
};

export default Statistiques;