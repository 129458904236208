import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import InputError from "components/InputError/InputError";
import InputLabel from "components/InputLabel/InputLabel";
import Button from "components/Buttons/Button";
import Input from "components/Input/Input";

import { EmailVerifyForForgotPassword, ChangeNewPassword } from "services/UserAuthentication";
import BaseModal from "components/Modals/BaseModal";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [purpose, setPurpose] = useState("Error")

  // Soumission du formulaire de connexion
  const onSubmit = async (data) => {
    var response;
    document.getElementById("prewaiting").removeAttribute("hidden");
    if (token?.length) {
      response = await ChangeNewPassword({
        "password": data.password,
        "token": token
      });
      if (response?.status === 200) {
        setPurpose("Success");
        setIsOpen(true);
        setMsg(response?.data);
      } else {
        setPurpose("Error");
        setMsg(response?.data?.detail);
        setIsOpen(true);
      }
    } else {
      response = await EmailVerifyForForgotPassword(data.email);
      if (response?.status === 200) {
        setPurpose("Success");
        setIsOpen(true);
        setMsg(response?.data);
      } else {
        setPurpose("Error");
        setMsg(response?.data?.detail);
        setIsOpen(true);
      }
    }
    document.getElementById("prewaiting").setAttribute("hidden", "true");
  };

  useEffect(() => {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    setToken(urlParams.get('token'));
  }, []);

  return (
    <div className="flex flex-col gap-y-2 max-sm:pt-8">
      <div className="flex flex-col gap-y-2">
        <h2
          className="font-bold text-main
          sm:text-3xl text-2xl"
        >
          Welcome to Reeact!
        </h2>
        <p
          className="font-medium sm:text-xl
        text-gray800"
        >
          Changez votre mot de passe.
        </p>
      </div>
      {/* <p className="text-gray600 text-[14px]">
        or use your email for registration
      </p> */}

      {/* Formulaire de connexion */}
      <form className="flex flex-col gap-y-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-2">
          {token === null ? <div>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              type="email"
              id="email"
              {...register("email", { required: true })}
              placeholder="Enter Email"
              autoComplete="email"
              className="sm:w-[452px] h-12 pl-4
            outline-none w-full"
            />
            {errors.email && <InputError message="Email is required" />}
          </div> : <div>
            <InputLabel htmlFor="password">Mot de passe</InputLabel>
            <Input
              type="password"
              id="password"
              {...register("password", { required: true })}
              placeholder="nouveau mot de passe"
              className="sm:w-[452px] h-12 pl-4 outline-none w-full"
            />
            {errors.password && <InputError message="Mot de passe requis." />}
            <br />
            <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
            <Input
              type="password"
              id="confirm_password"
              {...register("confirm_password", {
                required: true,
                validate: (value) => value === watch('password')
              })}
              placeholder="Entrez Confirmer le mot de passe"
              className="sm:w-[452px] h-12 pl-4 outline-none w-full"
            />
            {errors.confirm_password && <InputError message="Le mot de passe ne correspond pas." />}
          </div>}
        </div>
        <Button
          type="submit"
          className="sm:w-[452px] h-12 rounded-[48px]
          text-white bg-main mb-4 mt-4 w-full"
        >
          {token === null ? "Vérifier le courriel" : "Changer le mot de passe"}
        </Button>
        <Link
          to="/login"
          className="text-gray800 text-center
        text-[14px] font-medium"
        >
          Se connecter
        </Link>
      </form>
      <span
        className="flex justify-center gap-x-2
        font-medium text-gray800 text-[14px]"
      >
        <p>Vous n'avez pas encore de compte ?</p>
        <Link to="/register" className="hover:underline">
          S'inscrire
        </Link>
      </span>
      {
        isOpen &&
        <BaseModal closeModal={() => { setIsOpen(false) }} purpose={purpose}>
          {msg}
        </BaseModal>
      }
    </div>
  );
};

export default ChangePassword;
