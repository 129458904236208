import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import InputError from "components/InputError/InputError";
import InputLabel from "components/InputLabel/InputLabel";
import Button from "components/Buttons/Button";
import Input from "components/Input/Input";

import { SignInService } from "services/UserAuthentication";
import BaseModal from "components/Modals/BaseModal";
import GoogleSignIn from "components/GoogleSignIn/GoogleSignIn";
import { Spinner } from "@material-tailwind/react";

const SignIn = () => {
  const [profile, setProfile] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const updateParentState = (data) => {
    setProfile(data);
  };

  useEffect(() => {
    const LoginWithGmail = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      if (profile.verified_email) {
        const data = {
          email: profile.email,
          password: profile.id,
        };
        const response = await SignInService(data);
        if (response?.status === 200) {
          const { jwt, user } = response?.data;
          localStorage.setItem("jwt", jwt);
          localStorage.setItem("jwt_type", "Bearer");
          localStorage.setItem("user", JSON.stringify(user));
          //navigate('/admin');
          window.location.href = "/admin";
        } else {
          setErrorMsg(response?.data?.detail);
          setIsOpen(true);
        }
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    LoginWithGmail();
  }, [profile]);

  // Soumission du formulaire de connexion
  const onSubmit = async (data) => {
    document.getElementById("prewaiting").removeAttribute("hidden");
    const response = await SignInService(data);
    if (response?.status === 200) {
      const { jwt, user } = response?.data;
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("jwt_type", "Bearer");
      localStorage.setItem("user", JSON.stringify(user));
      //navigate('/admin');
      window.location.href = "/admin";
    } else {
      setErrorMsg(response?.data?.detail);
      setIsOpen(true);
    }
    document.getElementById("prewaiting").setAttribute("hidden", "true");
  };

  return (
    <div className="flex flex-col gap-y-2 max-sm:pt-8 ml-5 mr-5">
      <div className="flex flex-col gap-y-2">
        <h2
          className="font-bold text-main
          sm:text-3xl text-2xl"
        >
          Welcome to Reeact!
        </h2>
        <p
          className="font-medium sm:text-xl
        text-gray800"
        >
          Connectez vous à votre compte
        </p>
      </div>
      <GoogleSignIn updateParentState={updateParentState} />
      <p className="text-gray600 text-[14px]">
        ou utiliser votre adresse mail pour vous enregistrer
      </p>

      {/* Formulaire de connexion */}
      <form className="flex flex-col gap-y-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="email">Adresse email / Identifiant</InputLabel>
          <Input
            // type="email"
            id="email"
            {...register("email", { required: true })}
            placeholder="Renseignez votre adresse mail"
            autoComplete="email"
            className="sm:w-[452px] h-12 pl-4
            outline-none w-full"
          />
          {errors.email && <InputError message="Email is required" />}
        </div>
        <div className="flex flex-col gap-y-2">
          <InputLabel htmlFor="password">Mot de passe</InputLabel>
          <Input
            type="password"
            id="password"
            {...register("password", { required: true })}
            placeholder="Renseignez votre mot de passe"
            autoComplete="new-password"
            className="sm:w-[452px] h-12 pl-4
            outline-none w-full"
          />
          {errors.password && <InputError message="Password is required" />}
        </div>
        <Button
          type="submit"
          className="sm:w-[452px] h-12 rounded-[48px]
          text-white bg-main mb-4 mt-4 w-full"
        >
          Se connecter
        </Button>
        <Link
          to="/forgot-password"
          className="text-gray800 text-center
        text-[14px] font-medium"
        >
          Mot de passe oublié ?
        </Link>
      </form>
      <span
        className="flex justify-center gap-x-2
        text-gray800 text-[16px] font-bold text-center"
      >
        <span>
          Vous n'avez pas encore de compte ?{" "}
          <Link to="/register" className="hover:underline">
            S'inscrire
          </Link>
        </span>
      </span>
      {isOpen && (
        <BaseModal
          closeModal={() => {
            setIsOpen(false);
          }}
          purpose="Error"
        >
          {errorMsg}
        </BaseModal>
      )}
    </div>
  );
};

export default SignIn;
