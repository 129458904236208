import { useNavigate } from "react-router-dom";

import Container from "components/Container/Container";
import Card from "components/Card/Card";
import { useState, useMemo, useCallback, useEffect } from "react";
import eebleu from "assets/ee-bleu.png";
import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";

import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GetAllMessages } from "services/Admin/Messagerie";
import { DeleteMessageById } from "services/Admin/Messagerie";
import TableMessagerie from "components/Tables/TableMessagerie";
import RightButton from "components/Buttons/RightButton";

const MessagerieAdmin = () => {
  const navigate = useNavigate();

  //Remplacer messages ici par les messages admin
  const [data, setData] = useState([]);

  const removeCheckedElement = useCallback(
    (elements) => {
      setData(
        data.filter((d) => {
          return !elements.includes(d.id);
        })
      );
    },
    [data]
  );

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllMessages();

      if (response?.status === 200) {
        setData(response?.data ?? []);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   id: "checkbox",
      //   Cell: ({
      //     selected,
      //     addToSelected,
      //     removeFromSelected,
      //     row: {
      //       original: { id },
      //     },
      //   }) => {
      //     const handleOnChange = useCallback(() => {
      //       //Supprimer du tableau si c'était coché
      //       if (selected.includes(id)) {
      //         removeFromSelected(id);
      //       } else {
      //         addToSelected(id);
      //       }
      //     }, [addToSelected, removeFromSelected, id, selected]);

      //     return (
      //       <CheckBox
      //         onChange={handleOnChange}
      //         checked={selected.includes(id)}
      //         extra="border-2"
      //       />
      //     );
      //   },
      //   columnId: 0,
      // },
      // {
      //   columnId: 0,
      //   accessor: "star",
      //   Cell: () => {
      //     const [starred, setStarred] = useState(false);

      //     return (
      //       <div className="max-md:pl-6 pr-1">
      //         {starred ? (
      //           <AiFillStar
      //             size={20}
      //             onClick={() => setStarred(false)}
      //             className="text-main cursor-pointer"
      //           />
      //         ) : (
      //           <AiOutlineStar
      //             size={20}
      //             onClick={() => setStarred(true)}
      //             className="text-main cursor-pointer"
      //             style={{ display: "none" }}
      //           />
      //         )}
      //       </div>
      //     );
      //   },
      // },
      {
        Cell: () => (
          <div className="max-md:pl-6 pr-1">
            <FcGoogle size={20} className="" />
          </div>
        ),
        columnId: 1,
        accessor: "logo",
      },
      {
        accessor: "full_name",
        columnId: 2,
        Cell: ({ value, row }) => (
          // <div
          //   className="flex text-primary text-sm
          //   font-medium py-6 px-2 2xl:pl-5 cursor-pointer
          //   justify-center max-md:text-xs"
          //   onClick={() => navigate(`/admin/messagerie/${row.original.id}`)}
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex text-primary text-sm
            font-medium py-6 px-2 2xl:pl-5 cursor-pointer
            justify-center max-md:text-xs text-center"
            onClick={() => navigate(`/admin/messagerie/${row.original.id}`)}
          >
            <span className="whitespace-normal break-words">{value}</span>
          </div>
        ),
      },
      {
        accessor: "analysis_selection",
        columnId: 3,
        Cell: ({ value, row }) => (
          // <div
          //   className="flex text-primary text-sm
          //   font-medium py-6 px-2 2xl:pl-5 cursor-pointer
          //   justify-center max-md:text-xs"
          //   onClick={() => navigate(`/admin/messagerie/${row.original.id}`)}
          // >
          //   <span>{value}</span>
          // </div>
          <div
            className="flex text-primary text-sm
            font-medium py-6 px-2 2xl:pl-5 cursor-pointer
            justify-center max-md:text-xs text-center"
            onClick={() => navigate(`/admin/messagerie/${row.original.id}`)}
          >
            <span className="whitespace-normal break-words">{value}</span>
          </div>
        ),
      },
      {
        accessor: "updated_at",
        columnId: 4,
        Cell: ({ value }) => (
          // <div
          //   className="flex text-primary text-xs
          // font-normal px-1"
          // >
          //   {value}
          // </div>
          <div className="flex text-primary text-xs font-normal px-1 text-center">
            <span className="whitespace-normal break-words">
              {new Date(value).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              }) ?? ""}
            </span>
          </div>
        ),
      },
      {
        accessor: "dots",
        columnId: 5,
        Cell: ({
          row: {
            original: { id },
          },
        }) => {
          const removeElement = useCallback(async () => {
            //const response = await DeleteMsgsService([id]);
            document.getElementById("prewaiting").removeAttribute("hidden");
            const response = await DeleteMessageById(id);

            if (response.status === 200) removeCheckedElement([id]);

            document
              .getElementById("prewaiting")
              .setAttribute("hidden", "true");
          }, [id]);
          const seeMessage = useCallback(() => {
            navigate(`/admin/messagerie/${id}`);
          }, [id]);
          return (
            <>
              <MenuDropdown
                trigger={
                  //<button className="active:scale-95 transition-all">
                  <button
                    className="active:scale-95 transition-all hover:bg-gray200
                  p-1 rounded-full"
                  >
                    <BsThreeDotsVertical size={18} className="cursor-pointer" />
                  </button>
                }
                menu={[
                  <MenuDropdownButton
                    key={0}
                    text="VOIR"
                    onClick={seeMessage}
                  />,
                  <MenuDropdownButton
                    key={1}
                    text="SUPPRIMER"
                    onClick={removeElement}
                  />,
                ]}
              />
            </>
          );
        },
      },
    ],
    [navigate, removeCheckedElement]
  );

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Messagerie</p>
          {/* <p className="text-secondary hidden md:block text-sm font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>

        <RightButton
          text="Nouveau message"
          onClick={() => navigate("/admin/messagerie/nouveau")}
        />
      </Container>

      <Card extra={"w-full pb-16 sm:overflow-x-auto mt-8 mb-4 !bg-transparent"}>
        {data.length > 0 ? (
          <TableMessagerie columns={columns} data={data} />
        ) : (
          <div className="flex flex-col">
            <div className="sm:mt-48 mt-24">
              <p
                className="font-semibold sm:text-4xl text-main
                text-center text-xl"
              >
                Vous n’avez pas de message
                <br />
                pour le moment.
              </p>
            </div>
            <div className="absolute bottom-0 left-0">
              <img
                src={eebleu}
                alt="ee-logo"
                className="w-2/3 h-2/3 sm:w-full sm:h-full"
              />
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default MessagerieAdmin;
