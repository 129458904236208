import Chart from "react-apexcharts";
import { memo } from "react";

const AnalyseAreaComponent = ({ options, series, reputationScore, total }) => {
  return (
    // <div className="flex flex-col h-full justify-between max-md:w-full">
    <div className="flex flex-col h-full justify-between">
      <div
        className="rounded-[22px] h-60 bg-area
        relative mb-6 xl:w-40 overflow-hidden max-xl:w-32 2xl:w-48"
      >
        <Chart options={options} series={series} type="area" height={200} />
      </div>
      <div className="flex flex-col gap-y-2">
        <p className="text-main text-center text-xs font-normal">Sentiment Global</p>
        <p className="text-main text-center text-xs font-normal">Score de réputation</p>
        <p className="text-main text-sm text-center">
          <span className="font-semibold">{reputationScore}</span> / {total}
        </p>
      </div>
    </div>
  );
};

export default memo(AnalyseAreaComponent);
