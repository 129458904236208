import { useMemo, useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import RightButton from "components/Buttons/RightButton";
import TablePromos from "components/Tables/TablePromos";
import Container from "components/Container/Container";

import MenuDropdown, {
  MenuDropdownButton,
} from "components/Dropdowns/MenuDropdown";

import { dataPromos } from "constants/codepromos";

import { BsThreeDotsVertical } from "react-icons/bs";

import eebleu from "assets/ee-bleu.png";
import Card from "components/Card/Card";
import { GetPromocodes, DeletePromocodeById } from "services/Admin/Promos";
import { useEffect } from "react";

const Promos = () => {
  const navigate = useNavigate();

  const date = new Date();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  //const [data, setData] = useState(dataPromos);
  const [data, setData] = useState([]);

  const removeCodePromo = useCallback(
    (elements) => {
      setData(
        data.filter((d) => {
          return !elements.includes(d.id);
        })
      );
    },
    [data]
  );

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetPromocodes();

      if (response?.status === 200) {
        setData(response?.data);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Titre",
        //accessor: "titre",
        accessor: "code_title",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="py-4 font-semibold text-xs mx-2 pl-6">{value}</div>
          );
        },
      },
      {
        Header: "Début",
        hidden: true,
        accessor: "start_at",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="py-4 pl-6 text-xs font-normal mx-2">
              {new Date(value).toLocaleString("en-US", options)}
            </div>
          );
        },
      },
      {
        Header: "Fin",
        hidden: true,
        accessor: "end_at",
        Cell: ({ cell: { value } }) => (
          <div className="py-4 pl-6 text-xs font-normal mx-2">
            {new Date(value).toLocaleString("en-US", options)}
          </div>
        ),
      },
      {
        Header: "Usage",
        accessor: "usage",
        Cell: ({ cell: { value } }) => (
          <div className="py-4 pl-6 text-xs font-normal mx-2">
            {isNaN(value) ? "0" : value}
          </div>
        ),
      },
      {
        Header: "Méthode",
        accessor: "method",
        Cell: ({ cell: { value } }) => {
          return value === false ? (
            <div className="py-4 pl-6 text-xs font-normal mx-2">
              Pourcentage
            </div>
          ) : (
            <div className="py-4 pl-6 text-xs font-normal mx-2">
              Montant fixe
            </div>
          );
        },
      },
      {
        Header: "Montant",
        hidden: true,
        accessor: "amount",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="py-4 pl-6 text-xs font-normal mx-2">{value}</div>
          );
        },
      },
      // {
      //   Header: "Statut",
      //   accessor: "statut",
      //   Cell: ({ cell: { value } }) => {
      //     let finishedOrActive =
      //       value === "FINISHED" ? (
      //         <div
      //           className="py-4 flex text-gray-400 text-xs
      //           font-semibold leading-none mx-2 pl-6"
      //         >
      //           Expiré
      //         </div>
      //       ) : (
      //         <div
      //           className="py-4 flex text-success text-xs
      //           font-semibold pl-6 leading-none mx-2"
      //         >
      //           En cours
      //         </div>
      //       );
      //     return (
      //       <div className="py-4 text-xs font-normal mx-2">
      //         {finishedOrActive}
      //       </div>
      //     );
      //   },
      // },
      {
        id: "dots",
        Cell: ({ row }) => {
          const removeElement = useCallback(async () => {
            removeCodePromo([row.original.id]);
            document.getElementById("prewaiting").removeAttribute("hidden");
            const response = await DeletePromocodeById(row.original.id);
            document
              .getElementById("prewaiting")
              .setAttribute("hidden", "true");
          }, [row.original.id]);

          return (
            <MenuDropdown
              trigger={
                <button className="active:scale-95 transition-all hover:bg-gray200 p-1 rounded-full">
                  <BsThreeDotsVertical size={18} className="cursor-pointer" />
                </button>
              }
              menu={[
                <MenuDropdownButton
                  key={1}
                  text="Mettre à jour le code"
                  onClick={() => {
                    // alert('1234');
                    navigate("/admin/codes-promos/update", { state: row.original });
                  }}
                />,
                <MenuDropdownButton
                  key={1}
                  text="Supprimer le code"
                  onClick={removeElement}
                />,
              ]}
            />
          );
        },
      },
    ],
    [removeCodePromo]
  );

  const gotoAddPromo = () => {
    navigate("/admin/codes-promos/new");
  };

  return (
    <>
      <Container>
        <div className="flex flex-col gap-y-2">
          <p className="font-semibold text-2xl text-main">Codes Promos</p>
          {/* <p className="text-secondary hidden md:block text-[14px] font-normal">
            Lorem ipsum dolor sit amet, consectetur
          </p> */}
        </div>

        <RightButton text="Créer un code promo" onClick={gotoAddPromo} />
      </Container>
      {data.length === 0 ? (
        <div className="flex flex-col items-center">
          <div className="sm:mt-48 mt-24 mb-8">
            <p
              className="font-semibold sm:text-4xl text-main
          text-center text-xl"
            >
              Vous n’avez pas de codes
              <br />
              promo pour le moment.
            </p>
          </div>
          <RightButton
            className={"z-[50]"}
            text="Créer un code promo"
            // onClick={() => navigate("/codes-promos/nouveau")}
            onClick={() => navigate("/admin/codes-promos/new")}
          />
          <div className="absolute bottom-0 left-0">
            <img
              src={eebleu}
              alt="ee-logo"
              className="w-2/3 h-2/3 sm:w-full sm:h-full"
            />
          </div>
        </div>
      ) : (
        <Card extra="w-full pb-14 sm:overflow-x-auto mt-8 mb-4 !bg-transparent">
          <TablePromos columns={columns} data={data} />
        </Card>
      )}
    </>
  );
};

export default Promos;
