import React from "react";

const SettingSection = ({ setSubscribeSetting, setCardSetting, setUserSetting, active }) => {
  return (
    <div className="flex flex-col max-xl:w-full">
      <div className="flex rounded-[36.75px] items-center bg-white font-semibold max-sm:mt-2 max-xl:hidden">
        <span
          onClick={setSubscribeSetting}
          className={`max-md:px-10 px-20 py-3 cursor-pointer text-secondary text-[13.5px] rounded-[36.75px] whitespace-normal break-all
       ${active === "subscribe" ? "bg-primary text-white" : "text-[#284F60]"}`}
        >
          Mon abonnement
        </span>
        <span
          onClick={setCardSetting}
          className={`text-[13.5px] max-md:px-10 py-3 text-secondary rounded-[36.75px] cursor-pointer px-20 whitespace-normal break-all
      ${active === "card" ? "bg-primary text-white" : "text-[#284F60]"}`}
        >
          Mes moyens de paiement
        </span>
        <span
          onClick={setUserSetting}
          className={`flex text-[13.5px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-20 py-3 whitespace-normal break-all
        ${active === "user" ? "bg-primary  text-white" : "text-[#284F60]"}`}
        >
          Mes informations
        </span>
      </div>
      <div className="flex flex-col rounded-[36.75px] items-center bg-white font-semibold max-sm:mt-2 xl:hidden">
        <span
          onClick={setSubscribeSetting}
          className={`max-md:px-10 px-20 py-3 cursor-pointer text-secondary text-[13.5px] rounded-[36.75px] whitespace-normal break-all
       ${active === "subscribe" ? "bg-primary text-white" : "text-[#284F60]"}`}
        >
          Mon abonnement
        </span>
        <span
          onClick={setCardSetting}
          className={`text-[13.5px] max-md:px-10 py-3 text-secondary rounded-[36.75px] cursor-pointer px-20 whitespace-normal break-all
      ${active === "card" ? "bg-primary text-white" : "text-[#284F60]"}`}
        >
          Mes moyens de paiement
        </span>
        <span
          onClick={setUserSetting}
          className={`flex text-[13.5px] text-secondary rounded-[36.75px] cursor-pointer max-md:px-10 px-20 py-3 whitespace-normal break-all
        ${active === "user" ? "bg-primary  text-white" : "text-[#284F60]"}`}
        >
          Mes informations
        </span>
      </div>
    </div>
  );
};

export default SettingSection;
