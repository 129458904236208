import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});



const GetSettingInformation = async () => {
    try {
        const response = await api.get(`admin/setting`, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const UpdateSettingInformation = async (data) => {
    try {
        const response = await api.post(`admin/setting`, data, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
const AddClient = async (payload) => {
    try {
        const response = await api.post(`admin/users`, { "user_data": payload }, {
            headers: {
                authorization: localStorage.getItem('jwt_type') + " " + localStorage.getItem('jwt'),
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export {
    GetSettingInformation,
    UpdateSettingInformation
};