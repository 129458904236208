import { useCallback, useEffect, useState } from "react";

import BaseModal from "./BaseModal";
import Toggle from "components/Toggle/Toggle";
import Radio from "components/Radio/Radio";
import InputLabel from "components/InputLabel/InputLabel";
import Input from "components/Input/Input";
import CheckBox from "components/CheckBox/CheckBox";
import CardRadio from "components/DisplayCard/CardRadio";
import MessageIntervention from "components/MessageIntervention/MessageIntervention";
// import { useNavigate } from "react-router";
import {
  SendInterventionRequest,
  GetInterventionsById,
} from "services/Admin/Intervention";
import { SendRequestForAnalysisService } from "services/Analysis";
import { SendInterventionQuote } from "services/Admin/Intervention";
import {
  GetIntervenstionByIdService,
  SendRequestForInterventionService,
} from "services/Intervention";
import InputError from "components/InputError/InputError";
import CustomToggle from "components/Toggle/CustomToggle";
import { UpdateAlertSettings, GetAlertSetting } from "services/Alertes";
import CustomRadio from "components/Radio/CustomRadio";
import { GetAllPaymentMethod } from "services/Invoice";
import CustomCardRadio from "components/DisplayCard/CustomCardRadio";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AddNewCard, DeleteKeyword, Unsubscribe } from "services/Parametres";
import KeywordNewCard from "components/forms/Parametres/KeywordNewCard";
import NewCard from "components/forms/Parametres/NewCard";

// Modal du datepicker
export const DatePickerModal = ({ closeModal, children }) => {
  return (
    <BaseModal closeModal={closeModal} purpose="Changer la période">
      <div className="w-full flex justify-center">{children}</div>
    </BaseModal>
  );
};

// Modal de la page d'alertes
export const AlertModal = ({ closeModal }) => {
  const [selectedValue, setSelectedValue] = useState("Mensuel");
  const [positive, setPositive] = useState(false);
  const [negative, setNegative] = useState(false);
  const [netural, setNetural] = useState(false);
  const [searchEngine, setSearchEngine] = useState(false);
  const [blog, setBlog] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState(false);
  const [email, setEmail] = useState(false);
  const [sms, setSMS] = useState(false);
  const navigate = useNavigate();

  const onOptionChange = (e) => {
    setSelectedValue(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetAlertSetting();
      if (response.status === 200) {
        const alert_setting = response?.data?.alert_setting;
        setPositive(alert_setting?.positive);
        setNegative(alert_setting?.negative);
        setNetural(alert_setting?.netural);
        setSearchEngine(alert_setting?.search_engine);
        setBlog(alert_setting?.blog);
        setSocialNetworks(alert_setting?.social_networks);
        setEmail(alert_setting?.email);
        setSMS(alert_setting?.sms);
      }
    };
    fetchData();
  }, []);

  return (
    <BaseModal
      closeModal={async () => {
        // var data = {};
        // data = {
        //   "positive": positive,
        //   "negative": negative,
        //   "netural": netural,
        //   "search_engine": searchEngine,
        //   "blog": blog,
        //   "social_networks": socialNetworks,
        //   "email": email,
        //   "sms": sms,
        //   "contact_frequency": true
        // }
        // //document.getElementById("prewaiting").removeAttribute("hidden");
        // const response = await UpdateAlertSettings(data);
        // //document.getElementById("prewaiting").setAttribute("hidden", "true");
        closeModal();
      }}
      purpose="Paramètres d'alertes"
    >
      <div className="w-full justify-between m-auto ">
        <div className="flex max-md:flex-col max-md:gap-y-2 justify-between">
          <div className="flex flex-col sm:gap-y-4 gap-y-2 sm:w-80">
            <p className="text-main font-semibold">Types de mentions</p>
            <label className="flex justify-between cursor-pointer">
              <p className="text-main text-sm font-normal">Positives</p>
              <CustomToggle value={positive} setValue={setPositive} />
            </label>
            <label className="flex justify-between cursor-pointer">
              <p className="text-main text-sm font-normal">Neutres</p>
              <CustomToggle value={netural} setValue={setNetural} />
            </label>
            <label className="flex justify-between cursor-pointer">
              <p className="text-main text-sm font-normal">Négative</p>
              <CustomToggle value={negative} setValue={setNegative} />
            </label>
          </div>
          <div className="flex flex-col sm:gap-y-4 gap-y-2 sm:w-80">
            <p className="text-main font-semibold">Source de la mention</p>
            <label className="flex justify-between cursor-pointer">
              <p className="text-main text-sm font-normal">
                Moteur de recherche
              </p>
              <CustomToggle value={searchEngine} setValue={setSearchEngine} />
            </label>
            <label className="flex justify-between cursor-pointer">
              <p className="text-main text-sm font-normal">Blog</p>
              <CustomToggle value={blog} setValue={setBlog} disabled />
            </label>
            <label className="flex justify-between cursor-pointer">
              <p className="text-main text-sm font-normal">Réseau sociaux</p>
              <CustomToggle
                value={socialNetworks}
                setValue={setSocialNetworks}
                disabled
              />
            </label>
          </div>
        </div>
        <div className="w-full mt-4 mb-4 h-px bg-linecolor" />
        <div className="flex max-md:flex-col max-md:gap-y-2 justify-between">
          <div className="flex flex-col sm:gap-y-4 gap-y-2 sm:w-80">
            <p className="text-main font-semibold">Préférences de contact</p>
            <label className="flex justify-between cursor-pointer">
              <p className="text-main text-sm font-normal">Email</p>
              <CustomToggle value={email} setValue={setEmail} />
            </label>
            <label className="flex justify-between cursor-pointer">
              <p className="text-main text-sm font-normal">SMS</p>
              <CustomToggle value={sms} setValue={setSMS} disabled />
            </label>
          </div>
          <div className="flex flex-col sm:gap-y-4 gap-y-2 sm:w-80">
            <p className="text-main font-semibold">Fréquences de contact</p>
            <label className="flex gap-x-3 cursor-pointer">
              <CustomRadio
                value="Mensuel"
                checked={selectedValue === "Mensuel"}
                selectedValue={selectedValue}
                onChange={onOptionChange}
              />
              <p className="text-main text-sm font-normal">Mensuel</p>
            </label>
            <label className="flex gap-x-3 cursor-pointer">
              <CustomRadio
                value="Hebdomadaire"
                // checked={selectedValue === "Hebdomadaire"}
                selectedValue={selectedValue}
                onChange={onOptionChange}
                disabled
              />
              <p className="text-main text-sm font-normal">Hebdomadaire</p>
            </label>
            <label className="flex gap-x-3 cursor-pointer">
              <CustomRadio
                value="Journalier"
                // checked={selectedValue === "Journalier"}
                selectedValue={selectedValue}
                onChange={onOptionChange}
                disabled
              />
              <p className="text-main text-sm font-normal">Journalier</p>
            </label>
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            className="bg-primary text-white rounded-[100px] px-10
            py-2 text-xs w-48 h-10"
            onClick={async (event) => {
              var data = {};
              data = {
                positive: positive,
                negative: negative,
                netural: netural,
                search_engine: searchEngine,
                blog: blog,
                social_networks: socialNetworks,
                email: email,
                sms: sms,
                contact_frequency: 0,
              };
              document.getElementById("prewaiting").removeAttribute("hidden");
              const response = await UpdateAlertSettings(data);
              document
                .getElementById("prewaiting")
                .setAttribute("hidden", "true");
              closeModal();
            }}
          >
            Confirmer
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

// Lodal de la page d'intervention
export const InterventionsModal = ({ open, closeModal, data }) => {
  // const [answers, setAnswers] = useState([]);
  const [userResponse, setUserResponse] = useState("");
  const [adminQuestions, setAdminQuestions] = useState([]);
  const [customerResponses, setCustomerResponses] = useState([]);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [update, setUpdate] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (open === false) return;
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetIntervenstionByIdService(data.id);

      let questions = [];
      let answers = [];

      if (response?.status === 200) {
        const datas = response?.data;
        setQuestionAnswers(datas);
        datas.map((value, index) => {
          if (value["respond_to"] === 0) {
            questions.push(value["response"]);
          }
          if (value["respond_to"] === 1) {
            answers.push(value["response"]);
          }
        });
        let diff = questions?.length - answers?.length;
        if (diff > 0) {
          answers.splice(0, 0, ...Array(diff).fill(""));
        } else if (diff < 0) {
          questions.splice(0, 0, ...Array(-diff).fill(""));
        }

        setAdminQuestions(questions);
        setCustomerResponses(answers);
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [data.id, update, open]);
  return open ? (
    <BaseModal closeModal={closeModal} purpose="Demande d'informations">
      <div className="flex flex-col whitespace-normal text-[14px] font-medium leading-6 gap-y-3 sm:gap-y-8 self-center w-full">
        {adminQuestions.length > 0 && (
          <div className="bg-grayUpload text-main rounded-xl text-sm p-6 flex flex-col gap-y-4 max-h-[450px] overflow-auto">
            {/* <div>{data.question}</div> */}
            {/* {
          answers.map((value, index) => {
            return (
              <div key={index}>{value.response}</div>
            )
          })
        } */}
            {/* {
              adminQuestions.map((value, index) => {
                return (
                  <div>
                    {value.length > 0 &&
                      <MessageIntervention
                        origin="Question admin"
                        message={value}
                      />
                    }
                    <br />
                    {customerResponses.at(index).length > 0 &&
                      <MessageIntervention
                        origin="Réponse client"
                        message={customerResponses.at(index)}
                      />
                    }
                  </div>
                )
              })} */}
            {questionAnswers?.map((value, index) =>
              value?.respond_to === 0 ? (
                <MessageIntervention
                  origin="Question admin"
                  message={value?.response}
                />
              ) : (
                <MessageIntervention
                  origin="Réponse client"
                  message={value?.response}
                />
              )
            )}
          </div>
        )}
        {customerResponses.length == 0 && (
          <div className="text-main text-sm whitespace-normal break-all">
            Pourquoi un commentaire apparait-il sur ce site alors que tous les
            autres commentaires sont positifs?
          </div>
        )}
        <div>
          <div className="flex flex-col gap-y-2">
            <InputLabel value="Réponse" />
            <textarea
              rows={5}
              className="block p-2.5 w-full text-sm rounded-[20px] text-main
              border border-gray-300 focus:ring-0 focus:border-gray-400
              placeholder:text-gray-500 placeholder:text-[14px] placeholder:font-medium"
              placeholder="Entrer votre réponse..."
              value={userResponse}
              onChange={(event) => {
                setUserResponse(event.target.value);
              }}
            />
          </div>
          <div className="flex justify-end mt-6">
            <button
              className="bg-primary text-white rounded-[100px] px-10
            py-2 text-[12px]"
              onClick={async (event) => {
                const postData = {
                  response_type: 0,
                  information: userResponse,
                };
                document.getElementById("prewaiting").removeAttribute("hidden");
                const response = await SendRequestForInterventionService(
                  postData,
                  data.id
                );
                document
                  .getElementById("prewaiting")
                  .setAttribute("hidden", "true");
                // window.location.href = "/demande-dinterventions";
                if (response?.status === 200) {
                  setUpdate((prev) => !prev);
                }
              }}
            >
              Envoyer la réponse
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  ) : null;
};

//Modal pour changer sa carte sur la page de devis d'interventions
export const DevisModal = ({ open, closeModal }) => {
  const [cardSelected, setCardSelected] = useState("");
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();
  const [IsAddSuccess, setIsAddSuccess] = useState(false);
  const params = useParams();

  const onSelectionChange = useCallback(
    (value) => {
      setCardSelected(value);
      closeModal();
    },
    [closeModal]
  );

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllPaymentMethod();
      if (response?.status === 200) {
        setCards(response?.data);
        response?.data?.forEach((value, index) => {
          if (value?.default) {
            setCardSelected(value.id);
            return;
          }
        });
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [IsAddSuccess]);

  return open ? (
    <BaseModal closeModal={closeModal} purpose="Méthode de paiement">
      <div
        className="flex flex-col whitespace-normal text-sm font-medium
      leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
      >
        <div className="flex flex-col gap-y-4">
          {cards.length > 0 &&
            cards.map((value, index) => {
              return (
                <CustomCardRadio
                  value={value.id}
                  name={value.card_holder_name}
                  selected={cardSelected}
                  setCardSelected={setCardSelected}
                  cardHolderName={value.card_holder_name}
                  cardNumber={value.card_number}
                />
              );
            })}
          {/* <CardRadio
            value="2"
            name="cardradio"
            selected={cardSelected}
            setCardSelected={onSelectionChange}
          />
          <CardRadio
            value="3"
            name="cardradio"
            selected={cardSelected}
            setCardSelected={onSelectionChange}
          /> */}
        </div>
        <div>
          <label className="flex gap-x-6 md:px-8 cursor-pointer">
            <CustomRadio
              selected={cardSelected}
              onChange={(e) => setCardSelected(e.target.value)}
              value="newCard"
              name="cardradio"
            />
            <p className="text-sm text-main font-medium">
              Ajouter une nouvelle carte
            </p>
          </label>
        </div>
        {cardSelected === "newCard" ? (
          <div className="flex flex-col gap-y-4 mt-6">
            <h2 className="text-main font-semibold text-xl">Nouvelle carte</h2>
            <KeywordNewCard setIsAddSuccess={setIsAddSuccess} />
          </div>
        ) : (
          <div className="flex justify-end">
            <button
              className="bg-primary text-white rounded-[100px] px-10 py-2 text-xs w-48 h-10"
              onClick={async (event) => {
                navigate(
                  `/demande-dinterventions/${params?.interventionId}/devis`,
                  { replace: true }
                );
                closeModal();
              }}
            >
              Confirmer
            </button>
          </div>
        )}
      </div>
    </BaseModal>
  ) : null;
};

export const KeywordPaymentModal = ({ open, closeModal }) => {
  const [cardSelected, setCardSelected] = useState("");
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const [IsAddSuccess, setIsAddSuccess] = useState(false);

  const onSelectionChange = useCallback(
    (value) => {
      setCardSelected(value);
      closeModal();
    },
    [closeModal]
  );

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllPaymentMethod();
      if (response?.status === 200) {
        setCards(response?.data);
        response?.data?.forEach((value, index) => {
          if (value?.default) {
            setCardSelected(value.id);
            return;
          }
        });
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetAllPaymentMethod();
      if (response?.status === 200) {
        setCards(response?.data);
        response?.data?.forEach((value, index) => {
          if (value?.default) {
            setCardSelected(value.id);
            return;
          }
        });
      }
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    fetchData();
  }, [IsAddSuccess]);

  return open ? (
    <BaseModal closeModal={closeModal} purpose="Méthode de paiement">
      <div
        className="flex flex-col whitespace-normal text-sm font-medium
      leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
      >
        <div className="flex flex-col gap-y-4">
          {cards.length > 0 &&
            cards.map((value, index) => {
              return (
                <CustomCardRadio
                  value={value.id}
                  name={value.card_holder_name}
                  selected={cardSelected}
                  setCardSelected={setCardSelected}
                  cardHolderName={value.card_holder_name}
                  cardNumber={value.card_number}
                />
              );
            })}
          {/* <CardRadio
            value="2"
            name="cardradio"
            selected={cardSelected}
            setCardSelected={onSelectionChange}
          />
          <CardRadio
            value="3"
            name="cardradio"
            selected={cardSelected}
            setCardSelected={onSelectionChange}
          /> */}
          <div>
            <label className="flex gap-x-6 md:px-8 cursor-pointer">
              <CustomRadio
                selected={cardSelected}
                onChange={(e) => setCardSelected(e.target.value)}
                value="newCard"
                name="cardradio"
              />
              <p className="text-sm text-main font-medium">
                Ajouter une nouvelle carte
              </p>
            </label>
          </div>
          {cardSelected === "newCard" ? (
            <div className="flex flex-col gap-y-4 mt-6">
              <h2 className="text-main font-semibold text-xl">
                Nouvelle carte
              </h2>
              <KeywordNewCard setIsAddSuccess={setIsAddSuccess} />
            </div>
          ) : (
            <div className="flex justify-end">
              <button
                className="bg-primary text-white rounded-[100px] px-10 py-2 text-xs w-48 h-10"
                onClick={async (event) => {
                  closeModal();
                }}
              >
                Confirmer
              </button>
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  ) : null;
};

//Modal pour la page d'analyse et résultat d'analyse
export const AnalyseModal = ({ closeModal, data, open, openConfirmation }) => {
  const [addInfo, setAddInfo] = useState("");
  const [info, setInfo] = useState(
    "Votre demande nécessite l’intervention de nos services par tous les moyens légaux en notre possession, un devis vous sera envoyé avant toute action de notre part et vous aurez le choix de le valider ou le refuser. Une fois le devis validé et payé, nous débuterons nos actions, de courrier, de mise en demeure, de mails etc … afin de faire cesser la nuisance pour laquelle nous avons été mandaté, ces actions seront menées pendant un période de 40 jours consécutif. Si, malgré nos multiples actions, nous n’avons pu faire modifier/supprimer le contenu défavorable, vous pourrez, si vous le souhaitez être mis en relation avec nos avocats spécialisés pour entamer une action en justice et faire valoir vos droits devant les tribunaux."
  );
  const navigate = useNavigate();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  return open ? (
    <BaseModal closeModal={closeModal} purpose="Demande d’intervention">
      <div
        className="flex flex-col whitespace-normal text-[14px]
      font-medium leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
      >
        <div className="bg-grayUpload text-main rounded-xl p-6">
          <p className="font-normal text-sm">
            <p className="mb-4">
              Toutes demandes d’interventions sont préalablement analysées par
              notre service juridique afin d’évaluer les actions qui sont en
              mesures d’être réalisées pour obtenir la suppression de l’avis
              négatif vous concernant.
            </p>
            <p className="mb-4">
              Toutes les publications ne sont pas éligibles à notre intervention
              c’est la raison pour laquelle un devis vous sera envoyé
              gratuitement et sans engagement avant toute action de notre part.
            </p>
            <p className="mb-4">
              Plus de 90% des résultats favorables sont obtenus sous un délai de
              45 jours à compter du démarrage de notre intervention.
            </p>
            <p>
              Si, malgré nos multiples actions, nous n’avons pu faire
              modifier/supprimer le contenu défavorable, vous pourrez, si vous
              le souhaitez être mis en relation avec nos avocats spécialisés
              pour entamer une action en justice et faire valoir vos droits
              devant les tribunaux.
            </p>
          </p>
        </div>
        <form>
          <div className="flex flex-col gap-y-2">
            <InputLabel value="Informations supplémentaires (optionnel)" />
            <textarea
              rows={3}
              className="block p-2.5 w-full text-sm rounded-[20px] text-main
              border border-gray-300 focus:ring-0 focus:border-gray-400
              placeholder:text-gray-500 placeholder:text-[14px] placeholder:font-medium"
              placeholder="Entrer votre réponse"
              value={addInfo}
              onChange={(event) => {
                setAddInfo(event.target.value);
              }}
            />
          </div>
          <div className="flex flex-col gap-y-2 mt-6">
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked1(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked1 ? "text-red border-red" : "text-primary"
                }`}
              >
                Je certifie ne pas etre en procedure contre ce site
              </p>
            </div>
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked2(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked2 ? "text-red border-red" : "text-primary"
                }`}
              >
                Je reconnais que la demande d’intervention necessite un devis{" "}
              </p>
            </div>
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked3(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked3 ? "text-red border-red" : "text-primary"
                }`}
              >
                J’accepte les conditions générales et je demande un devis pour
                la demande d’intervention
              </p>
            </div>
            {/* {checked1 * checked2 * checked3 ? <></> : <InputError message="Tu dois être d'accord." />} */}
          </div>
          <div className="flex justify-end mt-6">
            <button
              className="bg-primary text-white rounded-[100px]
            px-10 py-2 text-[12px]"
              onClick={async (event) => {
                event.preventDefault();
                if (!(checked1 * checked2 * checked3)) return;
                //document.getElementById("prewaiting").removeAttribute("hidden");
                var postData = {};
                postData["title"] = data.original.title;
                postData["additional_information"] = addInfo;
                postData["site_url"] = data.original.link;
                postData["information"] = info;
                postData["id"] = data?.original?.id;
                const response = await SendRequestForAnalysisService(postData);
                if (response?.status === 200) {
                  openConfirmation();
                }
                document
                  .getElementById("prewaiting")
                  .setAttribute("hidden", "true");
                closeModal();
              }}
            >
              Envoyer ma demande
            </button>
          </div>
        </form>
      </div>
    </BaseModal>
  ) : null;
};

export const DeleteKeywordModal = ({ closeModal, id, open, showAlert }) => {
  const [addInfo, setAddInfo] = useState("");
  // const [info, setInfo] = useState(
  //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla"
  // );
  const navigate = useNavigate();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  return open ? (
    <BaseModal closeModal={closeModal} purpose="Se désabonner">
      <div
        className="flex flex-col whitespace-normal text-[14px]
      font-medium leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
      >
        <div className="bg-grayUpload text-main rounded-xl p-6">
          <p className="font-normal text-sm">
            <p className="pb-4">
              Votre abonnement restera actif jusqu'à la date anniversaire de
              votre inscription. Vous continuerez à bénéficier de tous les
              avantages de Reeact jusqu'à cette date. Aucuns frais
              supplémentaires ne seront facturés.
            </p>
            <p className="pb-4">
              L’ajout de mots-clés / URL à votre compte, notez que ces services
              additionnels sont facturés séparément et resteront actifs jusqu'à
              la date anniversaire de leur ajout.
            </p>
            <p className="pb-4">
              Nous espérons que vous avez trouvé Reeact utile pour gérer votre
              e-réputation.
            </p>
            <p>
              Si vous avez des questions ou avez besoin d'assistance pour
              annuler votre abonnement, n'hésitez pas à contacter
              notre service client.
            </p>
          </p>
        </div>
        <form>
          <div className="flex flex-col gap-y-2 mt-6">
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked1(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked1 ? "text-red border-red" : "text-primary"
                }`}
              >
                Je certifie ne vouloir me désabonner du mot cle/Url ajouté en
                option
              </p>
            </div>
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked2(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked2 ? "text-red border-red" : "text-primary"
                }`}
              >
                Je reconnais que toutes mes recherches vont être supprimées et
                ne pourront être récupérées
              </p>
            </div>
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked3(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked3 ? "text-red border-red" : "text-primary"
                }`}
              >
                J’accepte les conditions générales et de résiliations
              </p>
            </div>
            {/* {checked1 * checked2 * checked3 ? <></> : <InputError message="Tu dois être d'accord." />} */}
          </div>
          <div className="flex justify-end mt-6">
            <button
              className="bg-primary text-white rounded-[100px]
            px-10 py-2 text-[12px]"
              onClick={async (event) => {
                event.preventDefault();
                if (!(checked1 * checked2 * checked3)) return;
                document.getElementById("prewaiting").removeAttribute("hidden");
                const response = await DeleteKeyword(id);
                if (response?.status === 200) {
                  showAlert(true);
                }
                document
                  .getElementById("prewaiting")
                  .setAttribute("hidden", "true");
                closeModal();
              }}
            >
              Confirmer ma demande
            </button>
          </div>
        </form>
      </div>
    </BaseModal>
  ) : null;
};

export const UnsubscribeModal = ({ closeModal, open, showAlert }) => {
  const [addInfo, setAddInfo] = useState("");
  // const [info, setInfo] = useState(
  //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla"
  // );
  const navigate = useNavigate();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  return open ? (
    <BaseModal closeModal={closeModal} purpose="Se désabonner">
      <div
        className="flex flex-col whitespace-normal text-[14px]
      font-medium leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
      >
        <div className="bg-grayUpload text-main rounded-xl p-6">
          <p className="font-normal text-sm">
            <p className="pb-4">
              Votre abonnement restera actif jusqu'à la date anniversaire de
              votre inscription. Vous continuerez à bénéficier de tous les
              avantages de Reeact jusqu'à cette date. Aucuns frais
              supplémentaires ne seront facturés.
            </p>
            <p className="pb-4">
              L’ajout de mots-clés / URL à votre compte, notez que ces services
              additionnels sont facturés séparément et resteront actifs jusqu'à
              la date anniversaire de leur ajout.
            </p>
            <p className="pb-4">
              Nous espérons que vous avez trouvé Reeact utile pour gérer votre
              e-réputation.
            </p>
            <p>
              Si vous avez des questions ou avez besoin d'assistance pour
              annuler votre abonnement, n'hésitez pas à contacter
              notre service client.
            </p>
          </p>
        </div>
        <form>
          <div className="flex flex-col gap-y-2 mt-6">
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked1(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked1 ? "text-red border-red" : "text-primary"
                }`}
              >
                Je certifie vouloir me désabonner
              </p>
            </div>
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked2(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked2 ? "text-red border-red" : "text-primary"
                }`}
              >
                Je reconnais que toutes mes recherches vont être supprimées et
                ne pourront être récupérées
              </p>
            </div>
            <div className="flex gap-x-3 items-center">
              <CheckBox
                onChange={(event) => {
                  setChecked3(event.target.checked);
                }}
              />
              <p
                className={`font-medium md:text-sm text-xs text-main ${
                  !checked3 ? "text-red border-red" : "text-primary"
                }`}
              >
                J’accepte les conditions générales et de résiliations
              </p>
            </div>
            {/* {checked1 * checked2 * checked3 ? <></> : <InputError message="Tu dois être d'accord." />} */}
          </div>
          <div className="flex justify-end mt-6">
            <button
              className="bg-primary text-white rounded-[100px]
            px-10 py-2 text-[12px]"
              onClick={async (event) => {
                event.preventDefault();
                if (!(checked1 * checked2 * checked3)) return;
                document.getElementById("prewaiting").removeAttribute("hidden");
                const response = await Unsubscribe();
                if (response?.status === 200) {
                  showAlert(true);
                  console.log(response?.data?.jwt);
                  var user = JSON.parse(localStorage.getItem("user"));
                  user["subscription_at"] = null;
                  localStorage.setItem("user", JSON.stringify(user));
                  localStorage.setItem("jwt", response?.data?.jwt);
                }
                document
                  .getElementById("prewaiting")
                  .setAttribute("hidden", "true");
                closeModal();
              }}
            >
              Confirmer ma demande
            </button>
          </div>
        </form>
      </div>
    </BaseModal>
  ) : null;
};

//Devis demande d'intervention, partie admin
export const InterventionsDevis = ({
  open,
  closeModal,
  data,
  setIsOpen,
  setErrorMsg,
}) => {
  const navgiate = useNavigate();
  return open === "devis" ? (
    <BaseModal closeModal={closeModal} purpose="Devis demande d'intervention">
      <div
        className="flex flex-col whitespace-normal text-[14px] font-medium
      leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
      >
        <div className="bg-grayUpload text-main rounded-xl text-sm p-6">
          <p>{data.information}</p>
          <p>{data.site_url}</p>
        </div>
        <form>
          <div className="flex flex-col gap-y-2">
            <InputLabel>Proposer un devis d'un montant de</InputLabel>
            <Input
              className="block p-2.5 w-full text-sm rounded-[20px] text-main
              border border-gray-300 focus:ring-0 focus:border-gray-400
              placeholder:text-gray-500 placeholder:text-[14px] placeholder:font-medium h-12"
              placeholder="Entrer votre prix"
              id="amount"
              type="number"
              min="0"
            />
          </div>
          <div className="flex justify-end mt-6">
            <button
              className="bg-primary text-white rounded-[100px] px-10
            py-2 text-xs w-48 h-10"
              onClick={async (event) => {
                document.getElementById("prewaiting").removeAttribute("hidden");
                event.preventDefault();
                var postData = {};
                postData["response_type"] = 1;
                postData["amount"] = parseFloat(
                  document.getElementById("amount").value
                );
                // postData["description"] = data.information + " " + data.site_url;
                postData["description"] = data?.title;
                const response = await SendInterventionQuote(data.id, postData);
                if (response?.status === 200) {
                } else {
                  setIsOpen(true);
                  setErrorMsg(response?.response?.data?.detail);
                }
                document
                  .getElementById("prewaiting")
                  .setAttribute("hidden", "true");
                closeModal();
              }}
            >
              Confirmer
            </button>
          </div>
        </form>
      </div>
    </BaseModal>
  ) : null;
};

//Demande d'informations complémentaires
export const InfoComplementaire = ({
  open,
  closeModal,
  data,
  setIsOpen,
  setErrorMsg,
}) => {
  const [text, setText] = useState("");
  const [interventions, setInterventions] = useState([]);
  const [adminQuestions, setAdminQuestions] = useState([]);
  const [customerResponses, setCustomerResponses] = useState([]);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [update, setUpdate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("prewaiting").removeAttribute("hidden");
      const response = await GetInterventionsById(data.id);

      let questions = [];
      let answers = [];
      if (response?.status === 200) {
        const datas = response?.data;
        setQuestionAnswers(datas);
        datas.map((value, index) => {
          if (value["respond_to"] === 0) {
            questions.push(value["response"]);
          }
          if (value["respond_to"] === 1) {
            answers.push(value["response"]);
          }
        });
      } else {
        // setErrorMsg(response?.data?.detail);
      }
      let diff = questions?.length - answers?.length;
      if (diff > 0) {
        answers.splice(0, 0, ...Array(diff).fill(""));
      } else if (diff < 0) {
        questions.splice(0, 0, ...Array(-diff).fill(""));
      }

      setAdminQuestions(questions);
      setCustomerResponses(answers);
      document.getElementById("prewaiting").setAttribute("hidden", "true");
    };
    if (open === "info") fetchData();
  }, [open, update]);

  return open === "info" ? (
    <BaseModal
      closeModal={closeModal}
      purpose="Demande d'informations complémentaires"
    >
      <div
        className="flex flex-col whitespace-normal text-[14px] font-medium
      leading-6 gap-y-3 sm:gap-y-8 self-center w-full"
      >
        {adminQuestions.length > 0 && (
          <div className="bg-grayUpload text-main rounded-xl text-sm p-6 flex flex-col gap-y-4 max-h-[450px] overflow-auto">
            {" "}
            {
              questionAnswers?.map((value, index) =>
                value?.respond_to === 0 ? (
                  <MessageIntervention
                    origin="Question admin"
                    message={
                      value?.response?.indexOf('"quote"') >= 0 ? (
                        <a
                          href={`/admin/factures/${
                            JSON.parse(value?.response)?.quote
                          }`}
                          className="underline"
                          target="_blank"
                        >
                          Aller à la facture:{" "}
                          {JSON.parse(value?.response)?.quote}
                        </a>
                      ) : (
                        value?.response
                      )
                    }
                  />
                ) : (
                  <MessageIntervention
                    origin="Réponse client"
                    message={value?.response}
                  />
                )
              )
              // adminQuestions.map((value, index) => {
              //   return (
              //     <div>
              //       {value.length > 0 &&
              //         <MessageIntervention
              //           origin="Question admin"
              //           message={value}
              //         />
              //       }
              //       <br />
              //       {customerResponses.at(index).length > 0 &&
              //         <MessageIntervention
              //           origin="Réponse client"
              //           message={customerResponses.at(index)}
              //         />
              //       }
              //     </div>
              //   )
            }
          </div>
        )}
        <form>
          <div className="flex flex-col gap-y-2">
            <InputLabel>Informations supplémentaires</InputLabel>
            <textarea
              rows={5}
              className="block p-2.5 w-full text-sm rounded-[20px] text-main
              border border-gray-300 focus:ring-0 focus:border-gray-400
              placeholder:text-gray-500 placeholder:text-[14px] placeholder:font-medium"
              placeholder="Entrer votre réponse"
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
          </div>
          <div className="flex justify-end mt-6">
            <button
              className="bg-primary text-white rounded-[100px] px-10
            py-2 text-xs w-48 h-10"
              onClick={async (event) => {
                event.preventDefault();
                document.getElementById("prewaiting").removeAttribute("hidden");
                const response = await SendInterventionRequest(data.id, text);
                if (response?.status === 200) {
                  //setForceUpdate(prev => !prev);
                  setUpdate((prev) => !prev);
                  setText("");
                } else {
                  setIsOpen(true);
                  setErrorMsg(response?.response?.data?.detail);
                }
                document
                  .getElementById("prewaiting")
                  .setAttribute("hidden", "true");
                //closeModal();
              }}
            >
              Confirmer
            </button>
          </div>
        </form>
      </div>
    </BaseModal>
  ) : null;
};
